/**
 * Auth User Reducers
 */
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGOUT_USER,
    SIGNUP_USER,
    SIGNUP_USER_SUCCESS,
    SIGNUP_USER_FAILURE,
    GET_USER_PROFILE,
    SET_USER_DETAIL,
    GET_VALIDATE_INVITE_CODE,
    GET_SUCCESS_VALIDATE_CODE,
    POST_LIFE_SHERPA_CREATE_USER,
    POST_LIFE_SHERPA_SUCCESS_CREATE_USER,
    SHOW_FIREBASE_AUTH_LOADING,
    HIDE_FIREBASE_AUTH_LOADING,
    RESET_USER_PASSWORD,
    RESET_USER__PASSWORD_SUCCESS,
    VERIFY_USER_ACCOUNT_VIA_MAIL,
    VERIFY_SUCCESS_USER_ACCOUNT_VIA_MAIL,
    RESEND_VERIFICATION_LINK,
    RESEND_VERIFICATION_LINK_SUCCESS,
    GET_ORGANIZATION_PRIVACY_POLICY,
    GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY,
    POST_LIFE_SHERPA_LOGIN,
    POST_LIFE_SHERPA_LOGIN_SUCCESS,
    GET_USER_FIREBASE_TOKEN,
    GET_SUCCESS_USER_FIREBASE_TOKEN,
    GET_USER_INFO,
    GET_USER_ORGANIZATION,
    GET_SUCCESS_USER_ORGANIZATION,
    GET_USER_ORGANIZATION_FROM_TOKEN,
    GET_SUCCESS_USER_ORGANIZATION_FROM_TOKEN,
    CLEAR_PUBLIC_SETTING,
    CLEAR_FIREBASE_CUSTOM_DATA,
    GET_SUCCESS_USER_BY_ID,
    CLEAR_USER_FIREBASE_TOKEN_DATA,
    GET_THEME_LS_LOGO,
    GET_THEME_LS_LOGO_SUCCESS,
    GET_THEME_LS_LOGO_FAILED,
    CLEAR_USER_DATA_FOR_ASSIGN_ACTIVITY,
    SELECTED_IDP_TO_REGISTER
} from '../actions/types';

/**
 * initial auth user
 */
const INIT_STATE = {
    user: null, //localStorage.getItem('user_email'),
    userData: null,
    uid: null,
    inviteCode: null,
    idToken: null,
    loading: false,
    status: null,
    resetPassword: null,
    emailVerified: null,
    resendverifyEmail: null,
    resetPasswordStatus: null,
    resendverifyLinkStatus: null,
    confirmCode: null,
    resetPassResponseData: null,
    privacyPolicy: null,
    firebaseToken: null,
    profile: null,
    userOrgDetail: null,
    userOrgFromToken: null,
    userDetails:null,
    themeData:null,
    themeLoading:false,
    selectedConnection: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case LOGIN_USER:
            return { ...state, loading: true };

        case GET_VALIDATE_INVITE_CODE:
            return { ...state, loading: true };
        
        case  GET_USER_ORGANIZATION_FROM_TOKEN:
            return { ...state, loading: true, selectedConnection: null };

        case  GET_SUCCESS_USER_BY_ID:
            return { ...state, userDetails: action.payload };

        case  CLEAR_USER_DATA_FOR_ASSIGN_ACTIVITY:
            return { ...state, userDetails: null};

        case GET_SUCCESS_USER_ORGANIZATION_FROM_TOKEN:
            const {connections} =  action.payload
            const selectedConnection = connections && connections.length > 0 ? connections[0] : state.selectedConnection
            return { ...state, loading: false , userOrgFromToken: action.payload, selectedConnection};

        case GET_USER_ORGANIZATION:
            return { ...state, loading: true };

        case GET_SUCCESS_USER_ORGANIZATION:
            return { ...state, loading: false , userOrgDetail: action.payload};
        
        case POST_LIFE_SHERPA_LOGIN:
            return { ...state, loading: true };

        case POST_LIFE_SHERPA_LOGIN_SUCCESS:
            return { ...state, loading: false , userData: action.payload};

        case GET_SUCCESS_VALIDATE_CODE:
            const {inviteCode, status} = action.payload
            return { ...state, loading: false, inviteCode, status };

        case GET_ORGANIZATION_PRIVACY_POLICY:
            return { ...state, loading: true, selectedConnection: null };

        case GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY:
            return { ...state, loading: false, privacyPolicy: action.payload};

        case GET_USER_FIREBASE_TOKEN: 
            return { ...state, loading: true,firebaseToken: null };

        case GET_SUCCESS_USER_FIREBASE_TOKEN:
            return { ...state, loading: false, firebaseToken: action.payload };
        case CLEAR_USER_FIREBASE_TOKEN_DATA:
            return { ...state,firebaseToken: null};

        case RESEND_VERIFICATION_LINK:
            return { ...state, loading: true };

        case RESEND_VERIFICATION_LINK_SUCCESS:
            return { ...state, loading: false, resendverifyEmail: action.payload.email, resendverifyLinkStatus: action.payload.status };

        case VERIFY_USER_ACCOUNT_VIA_MAIL: 
            return { ...state, loading: true };

        case VERIFY_SUCCESS_USER_ACCOUNT_VIA_MAIL:
            return { ...state, loading: false, emailVerified: action.payload.status,  confirmCode: action.payload.code};

        case RESET_USER_PASSWORD:
            return { ...state, loading: true };

        case RESET_USER__PASSWORD_SUCCESS: 
            return { ...state, loading: false, resetPassword: action.payload.resetPassword, resetPasswordStatus: action.payload.status, resetPassResponseData: action.payload.data};

        case POST_LIFE_SHERPA_CREATE_USER:
            return {...state, loading: true}

        case POST_LIFE_SHERPA_SUCCESS_CREATE_USER:
            const {user, userData} = action.payload
            return {...state, loading: false, user, userData}

        case GET_USER_PROFILE:
            return { ...state, loading: true };

        case SHOW_FIREBASE_AUTH_LOADING:
            return { ...state, loading: true };

        case HIDE_FIREBASE_AUTH_LOADING:
            return { ...state, loading: false };

        case SET_USER_DETAIL:
            const {uid, idToken} = action.payload
            return { ...state, uid, idToken};

        case LOGIN_USER_SUCCESS:
            return { ...state, loading: false, user: action.payload };

        case LOGIN_USER_FAILURE:
            return { ...state, loading: false };

        case LOGOUT_USER:
            return { ...state, user: null };
        
        case GET_USER_INFO:
            return { ...state, profile: action.payload };

        case SIGNUP_USER:
            return { ...state, loading: true };

        case SIGNUP_USER_SUCCESS:
            return { ...state, loading: false, user: action.payload };

        case SIGNUP_USER_FAILURE:
            return { ...state, loading: false };

        case CLEAR_PUBLIC_SETTING:
            let updatedsetting = state.privacyPolicy
            updatedsetting["publicSettings"] = null
            return { ...state, privacyPolicy: updatedsetting, loading: false };
            
        case CLEAR_FIREBASE_CUSTOM_DATA:
            return {...state, firebaseToken: null};

        case GET_THEME_LS_LOGO:
            return {...state,themeLoading:true,themeData:null}; 

        case GET_THEME_LS_LOGO_SUCCESS:
            return {...state,themeLoading:false,themeData:action.payload};  

        case GET_THEME_LS_LOGO_FAILED:
            return {...state,themeLoading:false};     

        case SELECTED_IDP_TO_REGISTER:
            return {...state, selectedConnection: action.payload};  

        default: return { ...state };
    }
}
