import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { Label } from 'reactstrap'
import IntlMessages from "../../../util/IntlMessages"
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Chip from '@mui/material/Chip';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DialogTitle from "@mui/material/DialogTitle";
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import {FormGroup} from 'reactstrap'

class ActivitiesFilter extends Component {
    state = {
        inActiveActivities:false,
    } 
    componentDidMount() {
        console.group("%c[ActivitiesFilter]%c Rendered activities filter popup","color:black","color:green");
        this.setState({inActiveActivities:this.props.showInactiveActivities})
    }
    componentWillUnmount() {
        console.log("%c Activities filter popup closed","color:red");
        console.groupEnd();
    }
    handleFilter = (id) => {
        this.props.handelFilterOpitonsChange(id);
        this.setState({ collection: id });
    }
    toggleInActiveActivities = () => {
        console.log(" show inactive activities ----->",!this.state.inActiveActivities);
        this.setState({inActiveActivities: !this.state.inActiveActivities});
    }
    render() {
        let { activitiesGroups,filterOptions} = this.props;
       // console.log(filterOptions);
        return (
            <Dialog
                fullScreen={false}
                open={this.props.showFilter}
                onClose={() => this.props.handleCloseFilter()}
                fullWidth={true}
                maxWidth="sm"
                aria-labelledby="responsive-dialog-title"
                aria-labelledby="responsive-dialog-title"
                className={this.props.popupThemeClass}
                data-testid="FilterDialog"
                >
                <DialogTitle className="m-0 p-0">
                    <div className="d-flex align-items-center template-header border-bottom justify-content-between p-3">
                        <Label data-testid="scheduleHeading" className="listItemTitle mb-0"><IntlMessages id="newactivity.activities_filter" /></Label>
                        <div data-testid="FilterBackButton" className="backbutton" onClick={()=>this.props.handleCloseFilter()}><CloseRoundedIcon /></div>
                    </div>
                </DialogTitle>
                <DialogContent className="pl-4 pr-4">
                    {/* <FormGroup data-testid="switch-container" className="mb-2 d-flex align-items-center justify-content-between">
                        <Label className="filter-options-title fw-semi-bold">Show Inactive Activities</Label>
                        <Switch
                            checked={this.state.inActiveActivities}
                            onClick={this.toggleInActiveActivities}
                            color="primary"
                            className="switch-btn"
                        />
                    </FormGroup> */}
                    <Label  className="filter-options-title fw-semi-bold my-2">Activity Groups</Label>
                    <Grid
                        container
                        direction="row"
                        justifycontent="flex-start"
                        alignItems="flex-start"
                        >
                        {activitiesGroups && activitiesGroups.map((list, key) => (
                            <Grid item key={key}>
                                  {filterOptions.find(gid => gid == list.groupId) ?
                                <Chip
                                    data-testid={`group-checked-option-${key + 1}`}
                                    key={key}
                                    className={" mr-1 my-1  chipSelected"}
                                    avatar={<CheckCircleOutlinedIcon data-testid={`check-category-${key + 1}`} />}
                                    label={list.name}
                                    style={{ fontSize: "16px" }}
                                    onClick={() => this.handleFilter(list.groupId)} />
                                    :
                                    <Chip
                                    data-testid={`group-unchecked-option-${key + 1}`}
                                    key={key}
                                    className={" mr-1 my-1  chipStyle"}
                                    label={list.name}
                                    style={{ fontSize: "16px" }}
                                    onClick={() => this.handleFilter(list.groupId)} />
                                }
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions  className=" p-3 filter-buttons-styles ">
                    <Button color="primary" variant="outlined" data-testid="cancel-filter-button" className="  w-50 filter-button-cancel"  onClick={() => this.props.clearFilter()}>
                        CLEAR FILTERS
                     </Button>
                     <Button color="primary" variant="contained" data-testid="apply-filter-button" className=" w-50 filter-button-apply"  onClick={() => this.props.applyFilter(this.state.inActiveActivities)}>
                        APPLY
                     </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default ActivitiesFilter;