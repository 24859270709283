import React from "react";

const PhotoLibrary = ({inputRef, handlePhotoChange, datatestid}) => {

  const handleFileChange = event => {
    event.preventDefault();
    event.stopPropagation();
    handlePhotoChange(event)
  };

  return (
    <div data-testid={datatestid}>
      <input
        style={{display: 'none'}}
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={handleFileChange}
      />
    </div>
  );
};

export default PhotoLibrary;
