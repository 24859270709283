/**
 * Acivity Detail Reducers
 */
import {
    GET_ACTIVITIES_LIST_DETAIL,
    GET_SUCCESS_ACTIVITIES_LIST_DETAIL,
    SHOW_PRIVATE_ACTIVITY_LOADING,
    HIDE_PRIVATE_ACTIVITY_LOADING,
    ADD_PRIVATE_ACTIVITY,
    ADD_PRIVATE_ACTIVITY_SUCCESS,
    UPDATE_PRIVATE_ACTIVITY,
    UPDATE_PRIVATE_ACTIVITY_SUCCESS,
    GET_PRIVATE_ACTIVITY,
    GET_PRIVATE_ACTIVITY_SUCCESS,
    CHECK_DUPLICATE_ACTITY_TITLE,
    CHECK_SUCCESS_DUPLICATE_ACTITY_TITLE,
    CHECK_FAILURE_DUPLICATE_ACTITY_TITLE,
    CLEAR_ACTIVITY_DATA,
    REMOVE_API_ERROR_MESSAGE,
    SHOW_ACTIVITY_CRETAED_MESSAGE,
    GET_ALL_COMMON_ACTIVITIES,
    GET_ACTIVITIES_GROUPS_LIST,
    APPLY_FILTER_ON_ACTIVITY,
    CLEAR_FILTER_FROM_ACTIVITY,
    SET_ACTIVITIES_FILTERED_LIST,
    SET_SELECTED_ACTIVITIES_FOR_ASSIGN,
    GET_ACTIVITTIES_BY_USER_ID,
    GET_ACTIVITTIES_BY_USER_ID_SUCCESS,
    HIDE_LOADING_INDICATOR_ACTIVITIES_LIST,
    UN_SUBSSCRIBE_USER_FROM_ACTIVITY,
    SUCCESS_UN_SUBSSCRIBE_USER_FROM_ACTIVITY
 } from '../actions/types';
 
 /**
  * initial auth user
  */
 const INIT_STATE = {
     activity: null,
     schedules: [],
     steps: [],
     loading: false,
     allSteps: null,
     activityExist: false,
     activityTitle: '',
     errorMessage: '',
     activityState: null,
     successMessage: null,
     errorTitle: null,
     activitiesGroups:null,
     commonAllActivities:null,
     filterOptions:false,
     filteredActivities:[],
     selectedActivities:[],
     userAllActivities:[],
     unSubscribeLoading: false
 };
 
 export default (state = INIT_STATE, action) => {
     switch (action.type) {
 
        case GET_ACTIVITIES_LIST_DETAIL:
            return { ...state, loading: true };
 
        case GET_SUCCESS_ACTIVITIES_LIST_DETAIL:
            return { ...state, activity: action.payload, loading: false };
        case SET_ACTIVITIES_FILTERED_LIST:
            return { ...state, filteredActivities: action.payload, loading: false };
        case GET_ALL_COMMON_ACTIVITIES:
            return { ...state, commonAllActivities: action.payload, loading: false };
        case GET_ACTIVITIES_GROUPS_LIST:
            return { ...state, activitiesGroups: action.payload, loading: false };
        case APPLY_FILTER_ON_ACTIVITY:
            return { ...state, filterOptions: action.payload }; 
        case CLEAR_FILTER_FROM_ACTIVITY:
            return { ...state,filterOptions: false,filteredActivities:[] };
        case ADD_PRIVATE_ACTIVITY:
            return { ...state, loading: true };

        case ADD_PRIVATE_ACTIVITY_SUCCESS:
            return { ...state, activityState: action.payload, loading: false, errorMessage: '', errorTitle: '' };

        case UPDATE_PRIVATE_ACTIVITY:
            return { ...state, loading: true };

        case UPDATE_PRIVATE_ACTIVITY_SUCCESS:
            return { ...state, activityState: action.payload, loading: false, errorMessage: '', errorTitle: ''};

        case GET_PRIVATE_ACTIVITY:
            return { ...state, loading: true };

        case GET_PRIVATE_ACTIVITY_SUCCESS:
            return { ...state, activity: action.payload, loading: false };

        case CHECK_DUPLICATE_ACTITY_TITLE:
            return { ...state, loading: true };

        case CHECK_SUCCESS_DUPLICATE_ACTITY_TITLE:
            const {isExist, title } = action.payload
            return { ...state, loading: false, activityExist: isExist, activityTitle: title, errorMessage: ''};

        case CHECK_FAILURE_DUPLICATE_ACTITY_TITLE:
            return { ...state, loading: false, errorMessage: action.payload.message, errorTitle: action.payload.title};

        case CLEAR_ACTIVITY_DATA: 
            return{...state, loading: false, errorMessage: '', errorTitle: '', activity: null, schedules: [], steps: [], activityExist: false, activityTitle: '', activityState: null}
        
        case REMOVE_API_ERROR_MESSAGE:
            return { ...state, errorMessage: '', errorTitle: '' };

        case SHOW_PRIVATE_ACTIVITY_LOADING: 
            return{...state, loading: true}

        case HIDE_PRIVATE_ACTIVITY_LOADING:
            return{...state, loading: false, unSubscribeLoading: false}
        
        case SHOW_ACTIVITY_CRETAED_MESSAGE: 
            return{...state, successMessage: action.payload}
        case SET_SELECTED_ACTIVITIES_FOR_ASSIGN: 
            return{...state, selectedActivities: action.payload}
            
        case GET_ACTIVITTIES_BY_USER_ID: 
            return{...state, userAllActivities: [], loading:true}
        case GET_ACTIVITTIES_BY_USER_ID_SUCCESS: 
            return{...state, userAllActivities: action.payload, loading:false}
        case HIDE_LOADING_INDICATOR_ACTIVITIES_LIST: 
            return{...state, loading:false, unSubscribeLoading: false}

        case UN_SUBSSCRIBE_USER_FROM_ACTIVITY:
            return {...state, unSubscribeLoading: true, loading: true}
        case SUCCESS_UN_SUBSSCRIBE_USER_FROM_ACTIVITY:
            return {...state, unSubscribeLoading: false, loading: false}

        default: 
            return { ...state };
     }
 }
 
