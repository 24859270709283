/**
 * Responsive Dialog
 */
import React, {Component} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {FormGroup, Input, Label, FormFeedback} from 'reactstrap'
import Switch from '@mui/material/Switch';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import moment from 'moment'
import DatePickers from '../Pickers/DatePickers'
import TimePickers from '../Pickers/TimePickers'
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import RepeatRoundedIcon from '@mui/icons-material/RepeatRounded';
import IntlMessages from "../../util/IntlMessages"
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from '@mui/material/Tooltip';

import { 
    getWeekDay,
    getDateOfMonth,
    getMonthName,
    getISODateString
} from '../../helpers/helpers';


export default class ScheduleUpdate extends Component {

   state = {
      numberOfDays: 1,
      occurenceDays: 1,
      customOnOcuurence: undefined,
   };

   getRepeatOptions(recurrenceMethod){
       let options = [
        'Does not repeat',
        'Daily',
        `Monthly on the ${this.props.getDayOccurences(this.props.currentSchedule.startDate)} ${getWeekDay(this.props.currentSchedule.startDate)}`,
        `Monthly on day ${getDateOfMonth(this.props.currentSchedule.startDate)}`,
        `Every ${getMonthName(this.props.currentSchedule.startDate)} on ${getDateOfMonth(this.props.currentSchedule.startDate)} (Annually)`,
        `Every day on ${getWeekDay(this.props.currentSchedule.startDate)}`,
        'Every Weekday (Monday to Friday)',
        'Custom'
       ]
       if (recurrenceMethod === 'custom') {
            options[7] = this.props.currentSchedule.text 
       }
       return options
   }

   getRepeatOption(recurrenceMethod, text){
       let option = text
        if (recurrenceMethod === 'custom') {
            option = text;
        } else if(text.includes('Does not repeat')) {
            option = 'Does not repeat'
        } else if(text.includes('Daily')) {
            option = 'Daily'
        } else if(text.includes('Monthly on day')) {
            option = `Monthly on day ${getDateOfMonth(this.props.currentSchedule.startDate)}`
        } else if(text.includes('Monthly on')) {
            option = `Monthly on the ${this.props.getDayOccurences(this.props.currentSchedule.startDate)} ${getWeekDay(this.props.currentSchedule.startDate)}`
        } else if(text.includes('Every day on') || text.includes('Every Day on')) {
            option =  `Every day on ${getWeekDay(this.props.currentSchedule.startDate)}`
        } else if(text.includes('Every Weekday')) {
            option =  'Every Weekday (Monday to Friday)'
        } else if(text.includes('Every')) {
            option =  `Every ${getMonthName(this.props.currentSchedule.startDate)} on ${getDateOfMonth(this.props.currentSchedule.startDate)} (Annually)`
        }
        console.log('option : ',option);
        return option
    }

   handleClickOpen = () => {
      this.props.handleClose()
   };

   handleClose = () => {
    this.props.handleClose()
   };

   handleSaveSchedule = () => {
       this.props.handleSaveSchedule()
   }

   toggleCustom = event => {
        this.props.handleToggle()
   }
   handleNotifyStateChange = e => {
       this.props.handleNotifyStateChange()
   }

   handleRepeat(e){
        this.props.handleRepeat(e.target.value)
   }

   handleStartDateChange(date){
        this.props.handleStartDateChange(date)
   }

   handleStartTimeChange(time){
        this.props.handleStartTimeChange(time)
   }
   handleEndTimeChange(time){
        this.props.handleEndTimeChange(time)
   }

   handleCustomRepeatSelection(e) {
       this.props.handleRepeatSelection(e)
   }

  componentDidUpdate(prevProps){
    const objDiv = document.getElementById('schedule-content');
    if (objDiv) {
        objDiv.scrollTop = objDiv.scrollHeight;
    }
  }

   render() {
      const { fullScreen, editSchedule } = this.props;
      const options = this.getRepeatOptions(this.props.currentSchedule.recurrenceMethod);
      const selectedOption = this.getRepeatOption(this.props.currentSchedule.recurrenceMethod, this.props.currentSchedule.text);
      console.log("current schedule -->",this.props.currentSchedule);
      return (
        <div data-testid="scheduleUpdate">
        <Dialog
           fullScreen={fullScreen}
           open={this.props.open}
           onClose={this.handleClose}
           fullWidth={true}
           maxWidth="sm"
           aria-labelledby="responsive-dialog-title"
           className={`${this.props.popupThemeClass} add-schedule-popup`}
        >
            <DialogTitle className="m-0 p-0">
                <div  className="d-flex align-items-center template-header justify-content-between p-3">
                <Label data-testid="scheduleHeading" className="listItemTitle mb-0">
                    {editSchedule ? 
                        <IntlMessages id="scheduleUpdate.EditASchedule"/>
                        :
                        <IntlMessages id="scheduleUpdate.AddASchedule"/>
                    }
                </Label>
                <div data-testid="backButton" className="backbutton" onClick={this.handleClose}><CloseRoundedIcon/></div>
                </div>
            </DialogTitle>
           <DialogContent id="schedule-content" data-testid="data-container" className="pl-4 pr-4">
                <FormGroup data-testid="Start-Date" className="mb-2">
                    <DatePickers value={this.props.currentSchedule.startDate} label={<IntlMessages id="scheduleUpdate.StartDate"/>} testid={"DatePicker"} handleOnDateChange={this.handleStartDateChange.bind(this)}/>
                </FormGroup>
                <FormGroup data-testid="Start-Time" className="mb-2">
                    <TimePickers testid={"StartTime-Picker"} label={<IntlMessages id="scheduleUpdate.StartTime"/>} value={this.props.currentSchedule.objStartTime} handleOnTimeChange={this.handleStartTimeChange.bind(this)}/>
                </FormGroup> 
                <FormGroup data-testid="End-Time" className="mb-2">
                    <TimePickers testid={"EndTime-Picker"} invalidEndTime={this.props.invalidEndTime} objStartTime={this.props.currentSchedule.objStartTime} objEndTime={this.props.currentSchedule.objEndTime} label={<IntlMessages id="scheduleUpdate.EndTime"/>} value={this.props.currentSchedule.objEndTime} handleOnTimeChange={this.handleEndTimeChange.bind(this)}/>
                </FormGroup>
                <FormGroup>
                    <FormControl fullWidth>
                        <label data-testid="repeat-label" className="select-font"><IntlMessages id="scheduleUpdate.Repeat"/></label>
                        <div data-testid="repeat-container" className="d-flex align-items-center border-bottom MuiInputBase-root mt-0 MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
                            <Tooltip title={selectedOption}  arrow followCursor>
                                <Select 
                                    className="w-100"  
                                    style={this.props.customEnabled ? {opacity:"0.6"}: {}} 
                                    native fullWidth 
                                    value={selectedOption} 
                                    variant="standard" 
                                    inputProps={{   id: 'age-native-simple', 'data-testid': "repeat-select-input" }} 
                                    startAdornment = {
                                                    <InputAdornment position="start">
                                                        <RepeatRoundedIcon color="primary"/>
                                                    </InputAdornment>}
                                    onChange={(e) => !this.props.customEnabled && this.handleRepeat(e)}
                                    >
                                        { options.map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))
                                    }
                                </Select>                               
                            </Tooltip>
                        </div>
                    </FormControl>
                </FormGroup>
                <FormGroup  className="mb-2 d-flex align-items-center justify-content-between">
                    <Label className="menuEditItem"><IntlMessages id="scheduleUpdate.notify"/></Label>
                    <Switch
                        checked={Boolean(this.props.currentSchedule.notify)}
                        onClick={this.handleNotifyStateChange}
                        color="primary"
                        className="switch-btn"
                        disabled={this.props.activityType === "Touchpoint" || this.props.activityType === "QT-Reminder"}
                    />     
                </FormGroup>

                <FormGroup data-testid="switch-container" className="mb-2 d-flex align-items-center justify-content-between">
                    <Label data-testid="switch-label" className="menuEditItem"><IntlMessages id="scheduleUpdate.CustomReccurences"/></Label>
                      <Switch
                          checked={this.props.customEnabled}
                          onClick={this.toggleCustom}
                          color="primary"
                          className="switch-btn"
                      />
                </FormGroup>
                {this.props.customEnabled &&
                   <FormGroup  data-testid="repeat-every" >
                       <div className="d-flex align-items-center justify-content-between"> 
                        <Label  data-testid="repeat-every-lbl" className="w-100 mr-1 mb-0 menuEditItem"><IntlMessages id="scheduleUpdate.RepeatEvery"/></Label>
                        <TextField error={this.props.repeatError !== ''} className="mr-1" id="numberOfDays" name="numberOfDays" label="" variant="standard" value={this.props.customInterval} onChange={(e) => this.props.handleCustomInterval(e)}
                            type="number"
                            InputLabelProps={{
                            shrink: true,
                            }}
                        />
                        <Select  value={this.props.customRepeatValue} native fullWidth name="specificInterval" id="specificInterval" variant="standard" inputProps={{ id: 'frq-native', }} onChange={(e) => this.handleCustomRepeatSelection(e)}>
                            <option key={9}>Day</option>
                            <option key={10}>Week</option>
                            <option key={11}>Month</option>
                            <option key={12}>Year</option>
                        </Select>
                        </div>
                        {this.props.repeatError !== '' && <FormFeedback className='d-block'>{this.props.repeatError}</FormFeedback>}
                    </FormGroup> 
                }
                {this.props.customEnabled && this.props.weekSelected &&
                    <FormGroup  data-testid="weekSelected">
                        {this.props.weekDayWithCheck && this.props.weekDayWithCheck.map((item, key) => (
                            <button key={key} className={item.checked ? 'weekDays-selected btn mt-1 mr-2 mb-1' : 'weekDays btn mt-1 mr-2 mb-1'} onClick={() => this.props.handleWeekDayChecked(item, key)}>{item.name}</button>
                        ))
                        }
                        {
                            this.props.weekDayWithCheck.filter(weekday => weekday.checked === true).length === 0 &&  <FormFeedback className="text-left fs-12 d-flex">Please select atleast a day from weekDays.</FormFeedback>
                        }
                    </FormGroup>
                }
                {this.props.customEnabled && this.props.monthSelected &&
                    <FormGroup data-testid="monthSelected">
                        <Select  value={this.props.monthlyOccurenceDay} native fullWidth name="montheInterval" id="montheInterval" variant="standard" inputProps={{ id: 'frq-native-month', }} onChange={(e) => this.props.handleCustomMonthlyRepeat(e)}>
                            <option key={13}>{`Monthly on day ${getDateOfMonth(this.props.currentSchedule.startDate)}`}</option>
                            <option key={14}>{`Monthly on ${this.props.getDayOccurences(this.props.currentSchedule.startDate)} ${getWeekDay(this.props.currentSchedule.startDate)}`}</option>
                            <option key={15}>Last Day of Month</option>
                        </Select>
                    </FormGroup>
                }
                {this.props.customEnabled &&
                    <FormGroup data-testid="ends" tag="fieldset" className="d-flex">
                        <Label className="menuEditItem"><IntlMessages id="scheduleUpdate.Ends"/></Label>
                        <div className="pl-2"> 
                            <FormGroup data-testid="never-radio" check>
                                <Label check className="menuEditItem mr-10">
                                    <Input data-testid="repeatselected0" checked={this.props.repeatType[0].selected} variant="standard" type="radio" name="radio1" onChange={(e) => this.props.handleRepeatType(e, 0)} />{' '}
                                    <IntlMessages id="scheduleUpdate.Never"/>
                                </Label>
                            </FormGroup>
                            <FormGroup data-testid="after-radio" check >
                                <div className="d-flex align-items-center">
                                    <Label check className="mr-10 menuEditItem">
                                        <Input data-testid="repeatselected1" checked={this.props.repeatType[1].selected} variant="standard" type="radio" name="radio1" onChange={(e) => this.props.handleRepeatType(e, 1)} />{' '}
                                        <IntlMessages id="scheduleUpdate.After"/>
                                    </Label>
                                    <TextField error={this.props.customCountError !== ''} className="mr-10" id="occurenceDays" variant="standard" name="occurenceDays" label="" value={this.props.customCount} onChange={(e) => this.props.handleCustomCount(e)}
                                        type="number"
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                    />
                                    <Label className="menuEditItem mb-0"><IntlMessages id="scheduleUpdate.Occurences"/></Label>
                                </div>
                                {this.props.customCountError !== '' && <FormFeedback className='d-block'>{this.props.customCountError}</FormFeedback>}
                            </FormGroup>
                            <FormGroup data-testid="onDate-radio" check className="d-flex align-items-center">
                                <Label check className="mr-10 menuEditItem">
                                    <Input data-testid="repeatselected2" checked={this.props.repeatType[2].selected} variant="standard" type="radio" name="radio1" onChange={(e) => this.props.handleRepeatType(e, 2)} />{' '}
                                    <IntlMessages id="scheduleUpdate.On"/>
                                </Label>
                                <DatePickers minDate={moment(getISODateString(this.props.currentSchedule.startDate)).add(1, 'd').format('MMM DD YYYY')} id="onReccursion" value={this.props.customUntill} label="" handleOnDateChange={(date) => this.props.handleCustomUntill(date)}/>
                            </FormGroup>
                        </div>
                    </FormGroup>
                }
           </DialogContent>
           <DialogActions className="p-4">
            <button data-testid="done-button" onClick={this.handleSaveSchedule} className="scheduleDone">
            <IntlMessages id="scheduleUpdate.DoneButton"/>
            </button>
           </DialogActions> 
        </Dialog>
     </div>
  );
}
}
