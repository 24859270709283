/**
 * Responsive Dialog
 */
import React, {Component} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {Label} from 'reactstrap'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Chip from '@mui/material/Chip'
import IntlMessages from "../../util/IntlMessages"
import Grid from '@mui/material/Grid';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DialogTitle from "@mui/material/DialogTitle";
import Button from '@mui/material/Button';

export default class ScheduleUpdate extends Component {

   handleSelectGroup(key){
        this.props.handleSelectGroup(key)
   }

   handleFilter(){
      this.props.filterTemplateActivity()
   }

   render() {
      const { fullScreen, groups, popupThemeClass } = this.props;
      //console.log("groups----> ",groups);
      return (
         <div>
            <Dialog
               fullScreen={fullScreen}
               open={this.props.open}
               onClose={this.props.handleClose}
               fullWidth={true}
               maxWidth="sm"
               aria-labelledby="responsive-dialog-title"
               className={popupThemeClass}
            >
               <DialogTitle className="m-0 p-0">
                  <div className="d-flex align-items-center template-header justify-content-between p-3">
                     <Label className="listItemTitle mb-0"><IntlMessages id="Template.filter"/></Label>
                     <div className="backbutton" onClick={this.props.handleClose}><CloseRoundedIcon /></div>
                  </div>
               </DialogTitle>
               <DialogContent className="p-3">
                   {/*  <div className="mb-4 row">
                        {groups && groups.map((group, key) => ( 
                            <div key={key} className={group.selected ? "col chip-array-selected" : "col chip-array"}>
                              <Chip 
                                 className="mr-1 mb-1" 
                                 key={key} 
                                 label={group.name} 
                                 onClick={() => this.handleSelectGroup(key)}
                              />
                           </div>
                        ))}
                     </div>*/}
                     <Grid
                        container
                        direction="row"
                        justifycontent="flex-start"
                        alignItems="flex-start"
                        >
                        {groups && groups.map((group, key) => (
                            <Grid item key={key}>
                              {group.selected ?
                                <Chip
                                    className={" mr-1 my-1  chipSelected"}
                                    avatar={<CheckCircleOutlinedIcon data-testid={`check-category-${key + 1}`} />}
                                    label={group.name} 
                                    style={{ fontSize: "16px" }}
                                    onClick={() => this.handleSelectGroup(key)} />
                                    :
                                    <Chip
                                    className={" mr-1 my-1  chipStyle"}
                                    label={group.name} 
                                    style={{ fontSize: "16px" }}
                                    onClick={() => this.handleSelectGroup(key)} />

                                }

                            </Grid>

                        ))}
                    </Grid>
                    
               </DialogContent>
               <DialogActions className="p-3 justify-content-center filter-buttons-styles">
                     <Button color="primary" variant="outlined" data-testid="cancel-filter-button" className="  w-50 filter-button-cancel"  onClick={() => this.props.handleClearFilters()}>
                        <IntlMessages id="Template.ClearFilters"/>
                     </Button>
                     <Button color="primary" variant="contained" data-testid="apply-filter-button" className=" w-50 filter-button-apply"  onClick={() => this.handleFilter()}>
                        <IntlMessages id="Template.Apply"/>
                     </Button>
               </DialogActions> 
            </Dialog>
         </div>
      );
   }
}