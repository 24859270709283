/**
 * Clients List Actions
 */

import {sherpaAPI} from 'SherpaApi'

import {
    GET_MY_ACTIVITY_BY_ID,
    GET_SUCCESS_MY_ACTIVITY_BY_ID,
    SHOW_MY_ACTIVITY_LOADING,
    HIDE_MY_ACTIVITY_LOADING,
    
} from 'Actions/types';


export const getMyActivityById = (userId,idToken,routineId, orgId, scope) => (dispatch) => {
    ///users/:userId/activities/view/:activityId
    let url = `/users/${userId}/activities/view/${routineId}?orgId=${encodeURIComponent(orgId)}&scope=${encodeURIComponent(scope)}`
    dispatch({ type: GET_MY_ACTIVITY_BY_ID });
    sherpaAPI.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        const activityDetail = response.data
        const title = activityDetail && activityDetail.Detail && activityDetail.Detail.Routine ? activityDetail.Detail.Routine.title : null
        const steps = activityDetail && activityDetail.Detail && activityDetail.Detail.Steps ? activityDetail.Detail.Steps : null
        const payloadData = {title , steps}
        dispatch({type: GET_SUCCESS_MY_ACTIVITY_BY_ID, payload: payloadData})
    })
    .catch(error => {
        // error handling
        console.log("error occured ", error);
        const payloadData = error.response && error.response.data ? error.response.data : error
        // dispatch({ type: HIDE_MY_ACTIVITY_LOADING });
        dispatch({type: GET_SUCCESS_MY_ACTIVITY_BY_ID, payload: payloadData})
    })
}

/**
 * Redux Action To Update Client Search
 */
export const showMyActivityLoading = () => ({
    type: SHOW_MY_ACTIVITY_LOADING,
});

/**
 * export const to search Clients
 */
export const hideMyActivityLoading = () => ({
    type: HIDE_MY_ACTIVITY_LOADING,
});