import React, { useRef, useLayoutEffect } from "react";
import { Input } from "reactstrap";
import useHistoryState from "./UseHistoryState";

function filterOut(text, cursor) {
  const beforeCursor = text.slice(0, cursor);
  const afterCursor = text.slice(cursor, text.length);

  const filterdBeforeCursor = beforeCursor
  const filterAfterCursor = afterCursor

  const newText = filterdBeforeCursor + filterAfterCursor;
  const newCursor = filterdBeforeCursor.length;

  return [newText, newCursor];
}

function LSInput({onChange, invalid, onKeyPress, value, datatestid, type, autoCapitalize, name, id, className, placeholder, autoFocus, onBlur}) {
  
  const runAfterUpdate = useRunAfterUpdate();
  const [state, setState, undo, redo, history, pointer] = useHistoryState(value)
  
  window.addEventListener('keydown', function(evt) {
    evt.stopImmediatePropagation();
    if ((evt.key === 'Z' || evt.key === 'z' ) && (evt.ctrlKey || evt.metaKey) && (evt.shiftKey)) {
      // handle redo action
      redo()
    } else if ((evt.key === 'Z' || evt.key === 'z' ) && (evt.ctrlKey || evt.metaKey)) {
      // handle undo action
      undo()
    }
  });
  
  const handleOnChange = evt => {
    const input = evt.target;
    const text = input.value;
    const cursor = input.selectionStart;
    const [newUsername, newCursor] = filterOut(text, cursor);
    setState(text)
    runAfterUpdate(() => {
      input.selectionStart = newCursor;
      input.selectionEnd = newCursor;
    });
    onChange(evt)
  };
  
  return (
    <div className="w-100">
      <Input
        value={state}
        onChange={handleOnChange}
        data-testid={datatestid}
        type={type}
        autoCapitalize={autoCapitalize}
        name={name}
        id={id}
        className={className}
        placeholder={placeholder}
        invalid={invalid}
        onKeyPress={onKeyPress}
        autoFocus={autoFocus}
        onBlur={onBlur}
      />
    </div>
  );
}

function useRunAfterUpdate() {
  const afterPaintRef = useRef(null);
  useLayoutEffect(() => {
    if (afterPaintRef.current) {
      afterPaintRef.current();
      afterPaintRef.current = null;
    }
  });
  const runAfterUpdate = fn => (afterPaintRef.current = fn);
  return runAfterUpdate;
}

export default LSInput
