/**
 * Warning for user exclusive activity
 */

import React, { Component } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';

class UserExclusiveWarning extends Component {

    handleClose() {
        this.props.handleClosePopup()
    }

    goToSuccessPage=() => {
        window.location.href = "/success";
    }

   render() {

        const {displayName, activityTitle} = this.props

        return (
            <Dialog
                fullScreen={false}
                open={this.props.open}
                onClose={this.handleClose}
                fullWidth={true}
                maxWidth="sm"
                aria-labelledby="responsive-dialog-title"
                className={this.props.popupThemeClass}
                > 
                <div className="warning-div">
                    <span className="w-span-1"></span>
                    <span className="w-span-2"></span>
                </div>
                <DialogContent className="p-0">
                    <div className="container text-center">
                        <p className=" warningDialog text-center mt-3 mb-1">{`The activity with title "${activityTitle}" has multiple subscribers.`}</p>
                        <p className=" warningDialog text-center mb-3">{`Would you like to create it as an exclusive activity for the client "${displayName}"?`}</p>
                        <div className="row d-flex align-items-center justify-content-center">
                        <button className="btn btn-grey mb-3 mr-2" onClick={()=> this.goToSuccessPage()}>No</button>
                        <button className="btn btn-warning mb-3 ml-2" onClick={()=> this.handleClose()}>Yes</button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
   }
}
export default UserExclusiveWarning
