/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import QueueAnim from 'rc-queue-anim';
import { Label } from 'reactstrap';
import config from '../../config/config'

class ResetPasswordSuccess extends Component {

   render() {
      return (
         <QueueAnim type="bottom" duration={2000}>
            <div className={config.rctSessionWrapper}>
               <AppBar position="static" className="session-header">
                  <Toolbar>
                     {process.env.REACT_APP === 'DES' ?
                        <div className="container">
                           <div className="d-flex align-items-center justify-content-center mb-2">
                              <p className="mb-0" style={{color: 'gray', marginRight: 10}}>Powered by LifeSherpa</p>
                              <div className="session-logo">
                                 <Link to="/">
                                       <img src={require("Assets/img/lifesherpa-Icon.png")} alt="session-logo" className="img-fluid" width="30" height="30" />
                                 </Link>
                              </div>
                           </div>
                        </div>
                        :
                        <div className="container text-center">
                           <img src={require("Assets/img/life-sherpa-logo-retina.png")} alt="session-logo" className="img-fluid" width="250" height="58" />
                        </div>
                     }
                  </Toolbar>
               </AppBar>
               <div className="session-inner-wrapper">
                  <div className="container">
                     <div className="row row-eq-height">
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                           <div className="session-body text-center text-white">
                              <div className="session-head mb-30">
                                <p className="mb-0">Congratulations!</p>
                                 <Label>Password updated succesfully. Please use new password to login into LifeSherpa.</Label>
                                </div>
                           </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                     </div>
                  </div>
               </div>
            </div>
         </QueueAnim>
      );
   }
}

export default ResetPasswordSuccess;

