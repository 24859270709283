/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input, Label, FormFeedback } from 'reactstrap';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import QueueAnim from 'rc-queue-anim';
import HeaderWithCancelButton from '../components/Header/HeaderWithCancelButton'
import config from '../config/config'

// app config
import AppConfig from 'Constants/AppConfig';

// redux action
import {
   // signinUserInFirebase,
   validateInviteCode,
   getPrivacyPlocy,
} from 'Actions';

// //Auth File
// import Auth from '../Auth/Auth';

// const auth = new Auth();

const checkBoxStyle = {
   position: 'absolute',
   marginTop: '0.1rem',
   marginLeft: '-1.7rem',
   height: 20,
   width: 20
}

class SignInFirebaseToken extends Component {

   state = {
      email: 'ajayspecial0502@gmail.com', //'demo@example.com',
      password: 'Abcd1234', //'test#123',
      isTokenEmpty: false,
      token: '',
      enabledPrivacy: null,
      showError: null,
   }

   componentDidMount(){
      this.props.getPrivacyPlocy("org")
   }

   handlePrivacypolicy (e) {
      console.log(e.target.checked)
      this.setState({enabledPrivacy: e.target.checked, showError: !e.target.checked})
   }

	/**
	 * On User Login
	 */
   onTokenVerify(e) {
      e.preventDefault();
      if (this.state.token === ''){
         this.setState({isTokenEmpty: true})
      } else if(!this.state.enabledPrivacy){
        this.setState({showError: true})
      }
      else {
         this.props.validateInviteCode(this.state.token, this.props.history);
      //   this.props.history.push('/signuplifesherpa');
      }
   }

   // //Auth0 Login
   // loginAuth0() {
   //    auth.login();
   // }

   render() {
      console.log(this.props.privacyPolicy)
      const { token, isTokenEmpty, enabledPrivacy, showError } = this.state;
      const { loading } = this.props;
      const isContinueEnabled = (isTokenEmpty === null || showError === null || (token === null || token === '') ) ? true :  isTokenEmpty || showError
      const showTokenInvalid = this.props.status && this.props.status === 'failed' && this.props.inviteCode && (this.props.inviteCode === token) ? true : false
      return (
         <QueueAnim type="bottom" duration={2000}>
            <div>
            {
               loading &&
                  <div className="d-flex justify-content-center loader-overlay">
                     <CircularProgress className="theame-text" disableShrink/>
                  </div>
            }
            </div>
            <div className={config.rctSessionWrapper}>
               <AppBar position="static" className="session-header">
                  <Toolbar>
                     <HeaderWithCancelButton location={this.props.location} href={`/success`}/>
                  </Toolbar>
               </AppBar>
               <div className="session-inner-wrapper">
                  <div className="container">
                     <div className="row row-eq-height">
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                           <div className={"session-body text-center text-white"}>
                              <div className="session-head mb-30">
                                 <p >The invitation token you have been given is unique to you and should not be shared with any other person.</p>
                                 <p >{"Your use of the application is subject to your acceptance of our "} <a target="_blank" href={this.props.privacyPolicy && this.props.privacyPolicy.privacyURL && this.props.privacyPolicy.privacyURL !== '' ? this.props.privacyPolicy.privacyURL : "https://lifesherpapp.com/privacy-policy/"}>privacy policy.</a></p>
                                 <p >Please enter the token below and press Continue to complete your one-time registration process and to create your login credentials.</p>
                                </div>
                              <Form onKeyPress={e => {
                                 if (e.key === 'Enter') this.onTokenVerify(e);
                               }}>
                                 <FormGroup className="has-wrapper">
                                    <Input
                                       type="text"
                                       value={token}
                                       name="user-token"
                                       autoCapitalize="none"
                                       id="user-token"
                                       className="has-input input-lg text-center"
                                       placeholder="Enter Token Here"
                                       invalid={(isTokenEmpty ? true : false) || showTokenInvalid}
                                       onKeyPress={e => {
                                          if (e.key === 'Enter') this.onTokenVerify(e);
                                        }}
                                       onChange={(event) => this.setState({ token: event.target.value, isTokenEmpty: event.target.value !== '' ? false : true })}
                                    />
                                    {isTokenEmpty ?
                                        <FormFeedback style={{fontSize: 'larger'}}>You must supply a valid invitation token to continue</FormFeedback>
                                        : showTokenInvalid &&
                                        <FormFeedback className="d-block" style={{fontSize: 'larger'}}>The invitation token provided is not valid</FormFeedback>
                                    }
                                 </FormGroup>
                                 <FormGroup>
                                    <Button
                                       className="btn-block text-white w-100"
                                       variant="contained"
                                       size="large"
                                       onClick={(e) => this.onTokenVerify(e)}
                                       style={isContinueEnabled ? {backgroundColor: 'gray', fontSize: 'large'} : {fontSize: 'large'}}
                                       disabled={isContinueEnabled}
                                    >
                                       Continue
                                        </Button>
                                 </FormGroup>
                                 <FormGroup className={`has-wrapper border pt-2 pb-2 ${config.formgpBg}`}>
                                    <Label check className="pl-3">
                                        <Input checked={enabledPrivacy ? enabledPrivacy : false} type="checkbox" id="checkbox1" style={checkBoxStyle} onChange={(e) => this.handlePrivacypolicy(e)} />{' '}
                                        {'I have reviewed & accepted the '}<a target="_blank" href={this.props.privacyPolicy && this.props.privacyPolicy.privacyURL && this.props.privacyPolicy.privacyURL !== '' ? this.props.privacyPolicy.privacyURL : "https://lifesherpapp.com/privacy-policy/"}>privacy policy.</a>
                                    </Label>
                                    {showError && <FormFeedback className="d-block" style={{fontSize: 'larger'}}>Please acknowledge your consent to the Privacy Policy to continue</FormFeedback>}
                                 </FormGroup>
                              </Form>
                           </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                     </div>
                  </div>
               </div>
            </div>
         </QueueAnim>
      );
   }
}

// map state to props
const mapStateToProps = ({ authUser }) => {
   const { user, loading, inviteCode, status, privacyPolicy } = authUser;
   return { user, loading, inviteCode, status, privacyPolicy }
}

export default connect(mapStateToProps, {
   // signinUserInFirebase,
   validateInviteCode,
   getPrivacyPlocy,
})(SignInFirebaseToken);
