/**
 * App Redux Action Types
 */
export const COLLAPSED_SIDEBAR = 'COLLAPSED_SIDEBAR';
export const DARK_MODE = 'DARK_MODE';
export const BOXED_LAYOUT = 'BOXED_LAYOUT';
export const RTL_LAYOUT = 'RTL_LAYOUT';
export const MINI_SIDEBAR = 'MINI_SIDEBAR';
export const SEARCH_FORM_ENABLE = 'SEARCH_FORM_ENABLE';
export const CHANGE_THEME_COLOR = 'CHANGE_THEME_COLOR';
export const TOGGLE_SIDEBAR_IMAGE = 'TOGGLE_SIDEBAR_IMAGE';
export const SET_SIDEBAR_IMAGE = 'SET_SIDEBAR_IMAGE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const START_USER_TOUR = 'START_USER_TOUR';
export const STOP_USER_TOUR = 'STOP_USER_TOUR';
export const TOGGLE_DARK_SIDENAV = 'TOGGLE_DARK_SIDENAV';
// Chat App Actions
export const CHAT_WITH_SELECTED_USER = 'CHAT_WITH_SELECTED_USER';
export const SEND_MESSAGE_TO_USER = 'SEND_MESSAGE_TO_USER';
export const UPDATE_USERS_SEARCH = 'UPDATE_USERS_SEARCH';
export const SEARCH_USERS = 'SEARCH_USERS';
export const GET_RECENT_CHAT_USERS = 'GET_RECENT_CHAT_USERS';
export const SET_CHAT_OPEN = 'SET_CHAT_OPEN';
export const SET_ACTIVITIES_OPEN = 'SET_ACTIVITIES_OPEN';
export const SET_OPEN_CLIENT_DETAIL = 'SET_OPEN_CLIENT_DETAIL';
export const SET_USER_SELECTED = 'SET_USER_SELECTED';
export const SET_SELECTED_USER_ID = 'SET_SELECTED_USER_ID';

// Agency Sidebar
export const AGENCY_TOGGLE_MENU = 'AGENCY_TOGGLE_MENU';
export const CHANGE_AGENCY_LAYOUT_BG = 'CHANGE_AGENCY_LAYOUT_BG';
// Mail App
export const GET_EMAILS = 'GET_EMAILS';
export const GET_EMAIL_SUCCESS = 'GET_EMAIL_SUCCESS';
export const GET_EMAIL_FAILURE = 'GET_EMAIL_FAILURE';
export const SET_EMAIL_AS_STAR = 'SET_EMAIL_AS_STAR';
export const READ_EMAIL = 'READ_EMAIL';
export const HIDE_LOADING_INDICATOR = 'HIDE_LOADING_INDICATOR';
export const FETCH_EMAILS = 'FETCH_EMAILS';
export const ON_SELECT_EMAIL = 'ON_SELECT_EMAIL';
export const UPDATE_EMAIL_SEARCH = 'UPDATE_EMAIL_SEARCH';
export const SEARCH_EMAIL = 'SEARCH_EMAIL';
export const ON_DELETE_MAIL = 'ON_DELETE_MAIL';
export const ON_BACK_PRESS_NAVIGATE_TO_EMAIL_LISTING = 'ON_BACK_PRESS_NAVIGATE_TO_EMAIL_LISTING';
export const GET_SENT_EMAILS = 'GET_SENT_EMAILS';
export const GET_INBOX = 'GET_INBOX';
export const GET_DRAFTS_EMAILS = 'GET_DRAFTS_EMAILS';
export const GET_SPAM_EMAILS = 'GET_SPAM_EMAILS';
export const GET_TRASH_EMAILS = 'GET_TRASH_EMAILS';
export const ON_EMAIL_MOVE_TO_FOLDER = 'ON_EMAIL_MOVE_TO_FOLDER';
export const SELECT_ALL_EMAILS = 'SELECT_ALL_EMAILS';
export const UNSELECT_ALL_EMAILS = 'UNSELECT_ALL_EMAILS';
export const ON_SEND_EMAIL = 'ON_SEND_EMAIL';
export const EMAIL_SENT_SUCCESSFULLY = 'EMAIL_SENT_SUCCESSFULLY';
export const FILTER_EMAILS_WITH_LABELS = 'FILTER_EMAILS_WITH_LABELS';
export const ADD_LABELS_INTO_EMAILS = 'ADD_LABELS_INTO_EMAILS';
// sidebar
export const TOGGLE_MENU = 'TOGGLE_MENU';
// ToDo App
export const GET_TODOS = 'GET_TODOS';
export const FETCH_TODOS = 'FETCH_TODOS';
export const ADD_NEW_TASK = 'ADD_NEW_TASK';
export const ON_SELECT_TODO = 'ON_SELECT_TODO';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const ON_BACK_TO_TODOS = 'ON_BACK_TO_TODOS';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const MARK_AS_STAR_TODO = 'MARK_AS_STAR_TODO';
export const DELETE_TODO = 'DELETE_TODO';
export const ADD_LABELS_INTO_THE_TASK = 'ADD_LABELS_INTO_THE_TASK';
export const GET_ALL_TODO = 'GET_ALL_TODO';
export const GET_COMPLETED_TODOS = 'GET_COMPLETED_TODOS';
export const GET_DELETED_TODOS = 'GET_DELETED_TODOS';
export const GET_STARRED_TODOS = 'GET_STARRED_TODOS';
export const GET_FILTER_TODOS = 'GET_FILTER_TODOS';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const COMPLETE_TASK = 'COMPLETE_TASK';
export const UPDATE_TASK_TITLE = 'UPDATE_TASK_TITLE';
export const UPDATE_TASK_DESCRIPTION = 'UPDATE_TASK_DESCRIPTION';
export const CHANGE_TASK_ASSIGNER = 'CHANGE_TASK_ASSIGNER';
export const ON_CHECK_BOX_TOGGLE_TODO_ITEM = 'ON_CHECK_BOX_TOGGLE_TODO_ITEM';
export const SELECT_ALL_TODO = 'SELECT_ALL_TODO';
export const GET_UNSELECTED_ALL_TODO = 'GET_UNSELECTED_ALL_TODO';
export const SELECT_STARRED_TODO = 'SELECT_STARRED_TODO';
export const SELECT_UNSTARRED_TODO = 'SELECT_UNSTARRED_TODO';
export const ON_LABEL_SELECT = 'ON_LABEL_SELECT';
export const ON_LABEL_MENU_ITEM_SELECT = 'ON_LABEL_MENU_ITEM_SELECT';
export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const SEARCH_TODO = 'SEARCH_TODO';
// Auth Actions
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_BY_ID = 'GET_USER_BY_ID'
export const GET_SUCCESS_USER_BY_ID = 'GET_SUCCESS_USER_BY_ID'
export const POST_LIFE_SHERPA_CREATE_USER = 'POST_LIFE_SHERPA_CREATE_USER'
export const POST_LIFE_SHERPA_SUCCESS_CREATE_USER = 'POST_LIFE_SHERPA_SUCCESS_CREATE_USER'
export const GET_VALIDATE_INVITE_CODE = 'GET_VALIDATE_INVITE_CODE'
export const GET_SUCCESS_VALIDATE_CODE = 'GET_SUCCESS_VALIDATE_CODE'
export const SHOW_FIREBASE_AUTH_LOADING = 'SHOW_FIREBASE_AUTH_LOADING'
export const HIDE_FIREBASE_AUTH_LOADING = 'HIDE_FIREBASE_AUTH_LOADING'
export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD'
export const RESET_USER__PASSWORD_SUCCESS = 'RESET_USER__PASSWORD_SUCCESS'
export const VERIFY_USER_ACCOUNT_VIA_MAIL = 'VERIFY_USER_ACCOUNT_VIA_MAIL'
export const VERIFY_SUCCESS_USER_ACCOUNT_VIA_MAIL = 'VERIFY_SUCCESS_USER_ACCOUNT_VIA_MAIL'
export const RESEND_VERIFICATION_LINK = 'RESEND_VERIFICATION_LINK'
export const RESEND_VERIFICATION_LINK_SUCCESS = 'RESEND_VERIFICATION_LINK_SUCCESS'
export const GET_ORGANIZATION_PRIVACY_POLICY = 'GET_ORGANIZATION_PRIVACY_POLICY'
export const GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY = 'GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY'
export const GET_USER_FIREBASE_TOKEN = 'GET_USER_FIREBASE_TOKEN'
export const GET_SUCCESS_USER_FIREBASE_TOKEN = 'GET_SUCCESS_USER_FIREBASE_TOKEN'
export const GET_USER_INFO = 'GET_USER_INFO'
export const POST_LIFE_SHERPA_LOGIN = 'POST_LIFE_SHERPA_LOGIN'
export const POST_LIFE_SHERPA_LOGIN_SUCCESS = 'POST_LIFE_SHERPA_LOGIN_SUCCESS'
export const GET_USER_ORGANIZATION = 'GET_USER_ORGANIZATION'
export const GET_SUCCESS_USER_ORGANIZATION = 'GET_SUCCESS_USER_ORGANIZATION'
export const GET_USER_ORGANIZATION_FROM_TOKEN = 'GET_USER_ORGANIZATION_FROM_TOKEN'
export const GET_SUCCESS_USER_ORGANIZATION_FROM_TOKEN = 'GET_SUCCESS_USER_ORGANIZATION_FROM_TOKEN'
export const CLEAR_PUBLIC_SETTING = "CLEAR_PUBLIC_SETTING"
export const CLEAR_FIREBASE_CUSTOM_DATA = 'CLEAR_FIREBASE_CUSTOM_DATA'
export const CLEAR_USER_DATA_FOR_ASSIGN_ACTIVITY = 'CLEAR_USER_DATA_FOR_ASSIGN_ACTIVITY'
export const SELECTED_IDP_TO_REGISTER = 'SELECTED_IDP_TO_REGISTER'

// Feedbacks 
export const GET_FEEDBACKS = 'GET_FEEDBACKS';
export const GET_FEEDBACKS_SUCCESS = 'GET_FEEDBACKS_SUCCESS';
export const GET_ALL_FEEDBACKS = 'GET_ALL_FEEDBACKS';
export const ON_CHANGE_FEEDBACK_PAGE_TABS = 'ON_CHANGE_FEEDBACK_PAGE_TABS';
export const MAKE_FAVORITE_FEEDBACK = 'MAKE_FAVORITE_FEEDBACK';
export const ON_DELETE_FEEDBACK = 'ON_DELETE_FEEDBACK';
export const VIEW_FEEDBACK_DETAILS = 'VIEW_FEEDBACK_DETAILS';
export const ADD_NEW_FEEDBACK = 'ADD_NEW_FEEDBACK';
export const SHOW_FEEDBACK_LOADING_INDICATOR = 'SHOW_FEEDBACK_LOADING_INDICATOR';
export const HIDE_FEEDBACK_LOADING_INDICATOR = 'HIDE_FEEDBACK_LOADING_INDICATOR';
export const NAVIGATE_TO_BACK = 'NAVIGATE_TO_BACK';
export const REPLY_FEEDBACK = 'REPLY_FEEDBACK';
export const SEND_REPLY = 'SEND_REPLY';
export const UPDATE_SEARCH_IDEA = 'UPDATE_SEARCH_IDEA';
export const ON_SEARCH_IDEA = 'ON_SEARCH_IDEA';
export const ON_COMMENT_FEEDBACK = 'ON_COMMENT_FEEDBACK';
// ecommerce
export const ON_DELETE_ITEM_FROM_CART = 'ON_DELETE_ITEM_FROM_CART';
export const ON_QUANTITY_CHANGE = 'ON_QUANTITY_CHANGE';
export const ON_ADD_ITEM_TO_CART = 'ON_ADD_ITEM_TO_CART';
//crm 
export const ADD_NEW_CLIENT = 'ADD_NEW_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';

// Activity
export const SET_ACTIVITY_HISTORY_DETAILS = 'SET_ACTIVITY_HISTORY_DETAILS';
export const SET_ACTIVITY_CONFIRMATION = 'SET_ACTIVITY_CONFIRMATION';
export const SET_ACTIVITIES_REMINDER = 'SET_ACTIVITIES_REMINDER'
export const SET_SUCCESS_ACTIVITIES_REMINDER = 'SET_SUCCESS_ACTIVITIES_REMINDER'
export const GET_REALTIME_ACTIVITY_DETAIL = 'GET_REALTIME_ACTIVITY_DETAIL'
export const GET_SUCCESS_REALTIME_ACTIVITY_DETAIL = 'GET_SUCCESS_REALTIME_ACTIVITY_DETAIL'

export const GET_RECENT_ACTIVITIES = 'GET_RECENT_ACTIVITIES'
export const GET_SELECTED_ACTIVITIES = 'GET_SELECTED_ACTIVITIES'
export const UPDATE_ACTIVITIES_SEARCH = 'UPDATE_ACTIVITIES_SEARCH'
export const SEARCH_ACTIVITIES = 'SEARCH_ACTIVITIES'
export const GET_ACTIVITIES_LIST = 'GET_ACTIVITIES_LIST'
export const GET_SUCCESS_ACTIVITIES_LIST = 'GET_SUCCESS_ACTIVITIES_LIST'
export const GET_ACTIVITIES_LIST_DETAIL = 'GET_ACTIVITIES_LIST_DETAIL'
export const GET_SUCCESS_ACTIVITIES_LIST_DETAIL = 'GET_SUCCESS_ACTIVITIES_LIST_DETAIL'
export const SHOW_PRIVATE_ACTIVITY_LOADING = 'SHOW_PRIVATE_ACTIVITY_LOADING'
export const HIDE_PRIVATE_ACTIVITY_LOADING = 'HIDE_PRIVATE_ACTIVITY_LOADING'
export const SHOW_LOADING_INDICATOR_ACTIVITIES_LIST = 'SHOW_LOADING_INDICATOR_ACTIVITIES_LIST'
export const HIDE_LOADING_INDICATOR_ACTIVITIES_LIST = 'HIDE_LOADING_INDICATOR_ACTIVITIES_LIST'
export const GET_ALL_COMMON_ACTIVITIES = 'GET_ALL_COMMON_ACTIVITIES'
export const GET_ACTIVITIES_GROUPS_LIST = 'GET_ACTIVITIES_GROUPS_LIST'
export const APPLY_FILTER_ON_ACTIVITY = 'APPLY_FILTER_ON_ACTIVITY'
export const CLEAR_FILTER_FROM_ACTIVITY = 'CLEAR_FILTER_FROM_ACTIVITY'
export const SET_ACTIVITIES_FILTERED_LIST = 'SET_ACTIVITIES_FILTERED_LIST'

//Activity history   
export const GET_RECENT_HISTORY = 'GET_RECENT_HISTORY'
export const GET_SELECTED_HISTORY = 'GET_SELECTED_HISTORY'
export const UPDATE_HISTORY_SEARCH = 'UPDATE_HISTORY_SEARCH'
export const SEARCH_HISTORY = 'SEARCH_HISTORY'
export const GET_HISTORY_LIST = 'GET_HISTORY_LIST'
export const GET_SUCCESS_HISTORY_LIST = 'GET_SUCCESS_HISTORY_LIST'
export const SET_ACTIVITIES_INPROGESS = 'SET_ACTIVITIES_INPROGESS'
export const GET_ACHEIVEMENTS_DETAIL = 'GET_ACHEIVEMENTS_DETAIL'
export const GET_SUCCESS_ACHEIVEMENTS_DETAIL = 'GET_SUCCESS_ACHEIVEMENTS_DETAIL'
export const POST_EVALUATION_FORM = 'POST_EVALUATION_FORM'
export const POST_SUCCESS_EVALUATION_FORM = 'POST_SUCCESS_EVALUATION_FORM'
export const POST_STEPS_NOTES_FORM = 'POST_STEPS_NOTES_FORM'
export const SET_ACTIVITIES_RESET = 'SET_ACTIVITIES_RESET'
export const SET_SUCCESS_ACTIVITIES_RESET = 'SET_SUCCESS_ACTIVITIES_RESET'
export const POST_SUCCESS_STEPS_NOTES_FORM = 'POST_SUCCESS_STEPS_NOTES_FORM'
export const SET_SELECTED_STEP_ID = 'SET_SELECTED_STEP_ID'
export const SET_ACHIEVEMENTS_HAS_MORE_DATA = 'SET_ACHIEVEMENTS_HAS_MORE_DATA'
export const SET_ACHIEVEMENTS_PAGE_COUNT = 'SET_ACHIEVEMENTS_PAGE_COUNT'
export const SET_ACHIEVEMENTS_DATA_CLEAR = 'SET_ACHIEVEMENTS_DATA_CLEAR'
export const SHOW_LOADING_INDICATOR_HISTORY_DETAIL = 'SHOW_LOADING_INDICATOR_HISTORY_DETAIL'
export const HIDE_LOADING_INDICATOR_HISTORY_DETAIL = 'HIDE_LOADING_INDICATOR_HISTORY_DETAIL'
export const SHOW_LOADING_INDICATOR_HISTORY_LIST = 'SHOW_LOADING_INDICATOR_HISTORY_LIST'
export const HIDE_LOADING_INDICATOR_HISTORY_LIST = 'HIDE_LOADING_INDICATOR_HISTORY_LIST'

//Clients List
export const CHAT_WITH_SELECTED_CLIENT = 'CHAT_WITH_SELECTED_CLIENT';
export const SEND_MESSAGE_TO_CLIENT = 'SEND_MESSAGE_TO_CLIENT';
export const UPDATE_CLIENTS_SEARCH = 'UPDATE_CLIENTS_SEARCH';
export const SEARCH_CLIENTS = 'SEARCH_CLIENTS';
export const GET_RECENT_CHAT_CLIENTS = 'GET_RECENT_CHAT_CLIENTS';
export const GET_CLIENTS_LIST = 'GET_CLIENTS_LIST';
export const GET_SUCCESS_CLIENTS_LIST = 'GET_SUCCESS_CLIENTS_LIST';
export const SHOW_LOADING_INDICATOR_CLIENT_LIST = 'SHOW_LOADING_INDICATOR_CLIENT_LIST';
export const HIDE_LOADING_INDICATOR_CLIENT_LIST = 'HIDE_LOADING_INDICATOR_CLIENT_LIST';

//User Account Details
export const SET_USER_DETAIL = 'SET_USER_DETAIL';

//Group List
export const GET_RECENT_GROUP = 'GET_RECENT_GROUP'
export const GET_SELECTED_GROUP = 'GET_SELECTED_GROUP'
export const UPDATE_GROUP_SEARCH = 'UPDATE_GROUP_SEARCH'
export const SEARCH_GROUP = 'SEARCH_GROUP'
export const GET_GROUP_LIST = 'GET_GROUP_LIST'
export const GET_SUCCESS_GROUP_LIST = 'GET_SUCCESS_GROUP_LIST'
export const GET_GROUP_MEMBERS = 'GET_GROUP_MEMBERS'
export const GET_SUCCESS_GROUP_MEMBERS = 'GET_SUCCESS_GROUP_MEMBERS'
export const SHOW_LOADING_INDICATOR_GROUP_LIST = 'SHOW_LOADING_INDICATOR_GROUP_LIST'
export const HIDE_LOADING_INDICATOR_GROUP_LIST = 'HIDE_LOADING_INDICATOR_GROUP_LIST'
export const SET_SELECTED_GROUP = 'SET_SELECTED_GROUP'
export const SET_SUCCESS_SELECTED_GROUP = 'SET_SUCCESS_SELECTED_GROUP'

//Poke Form 
export const GET_CLIENT_POKE_FORM = 'GET_CLIENT_POKE_FORM'
export const GET_CLIENT_SUCCESS_POKE_FORM = 'GET_CLIENT_SUCCESS_POKE_FORM'
export const SET_CLIENT_POKE_FORM = 'SET_CLIENT_POKE_FORM'
export const SET_CLIENT_SUCCESS_POKE_FORM = 'SET_CLIENT_SUCCESS_POKE_FORM'
export const UPDATE_CLIENT_POKE_FORM = 'UPDATE_CLIENT_POKE_FORM'
export const UPDATE_CLIENT_SUCCESS_POKE_FORM = 'UPDATE_CLIENT_SUCCESS_POKE_FORM'
export const SET_GROUP_SUCCESS_POKE_FORM = 'SET_GROUP_SUCCESS_POKE_FORM'
export const SET_GROUP_POKE_FORM = 'SET_GROUP_POKE_FORM'
export const SET_SELECTED_POKE_FOR_GROUP = 'SET_SELECTED_POKE_FOR_GROUP'
export const SHOW_LOADING_INDICATOR_CLIENT_POKE_FORM = 'SHOW_LOADING_INDICATOR_CLIENT_POKE_FORM'
export const HIDE_LOADING_INDICATOR_CLIENT_POKE_FORM = 'HIDE_LOADING_INDICATOR_CLIENT_POKE_FORM'

//Unconfirm achievements
export const SET_SELECTED_UNCONFIRM_ACTIVITIES = 'SET_SELECTED_UNCONFIRM_ACTIVITIES'
export const SET_UNCONFIRM_ACTIVITIES_LIST = 'SET_UNCONFIRM_ACTIVITIES_LIST'
export const SET_SUCCESS_UNCONFIRM_ACTIVITIES_LIST = 'SET_SUCCESS_UNCONFIRM_ACTIVITIES_LIST'
export const SET_UNCONFIRM_ACTIVITIES_LIST_DETAIL = 'SET_UNCONFIRM_ACTIVITIES_LIST_DETAIL'
export const SET_SUCCESS_UNCONFIRM_ACTIVITIES_LIST_DETAIL = 'SET_SUCCESS_UNCONFIRM_ACTIVITIES_LIST_DETAIL'
export const SET_ACHIEVEMENTS_UNCONFIRM_HAS_MORE_DATA = 'SET_ACHIEVEMENTS_UNCONFIRM_HAS_MORE_DATA'
export const SET_ACHIEVEMENTS_UNCONFIRM_PAGE_COUNT = 'SET_ACHIEVEMENTS_UNCONFIRM_PAGE_COUNT'
export const SET_ACHIEVEMENTS_UNCONFIRM_DATA_CLEAR = 'SET_ACHIEVEMENTS_UNCONFIRM_DATA_CLEAR'
export const SHOW_LOADING_INDICATOR_UNCONFIRM_ACTIVITIES_LIST = 'SHOW_LOADING_INDICATOR_UNCONFIRM_ACTIVITIES_LIST'
export const HIDE_LOADING_INDICATOR_UNCONFIRM_ACTIVITIES_LIST = 'HIDE_LOADING_INDICATOR_UNCONFIRM_ACTIVITIES_LIST'

export const GET_USER_GEOLOCATION = 'GET_USER_GEOLOCATION'
export const GET_SUCCESS_USER_GEOLOCATION = 'GET_SUCCESS_USER_GEOLOCATION'
export const SHOW_LOCATION_LOADING = 'SHOW_LOCATION_LOADING'
export const HIDE_LOCATION_LOADING = 'HIDE_LOCATION_LOADING'

//ACTIVITY PREVIEW

export const ADD_ACTIVITY_SCHEDULE = 'ADD_ACTIVITY_SCHEDULE'
export const ADD_ACTIVITY_SCHEDULE_SUCCESS = 'ADD_ACTIVITY_SCHEDULE_SUCCESS'
export const  DELETE_ACTIVITY_SCHEDULE = 'DELETE_ACTIVITY_SCHEDULE'
export const DELETE_ACTIVITY_SCHEDULE_SUCCESS = 'DELETE_ACTIVITY_SCHEDULE_SUCCESS'
export const UPDATE_ACTIVITY_SCHEDULE = 'UPDATE_ACTIVITY_SCHEDULE'
export const UPDATE_ACTIVITY_SCHEDULE_SUCCESS = 'UPDATE_ACTIVITY_SCHEDULE_SUCCESS'
export const ADD_ACTIVITY_STEP = 'ADD_ACTIVITY_STEP'
export const ADD_ACTIVITY_STEP_SUCCESS = 'ADD_ACTIVITY_STEP_SUCCESS'
export const DELETE_ACTIVITY_STEP = 'DELETE_ACTIVITY_STEP'
export const DELETE_ACTIVITY_STEP_SUCCESS = 'DELETE_ACTIVITY_STEP_SUCCESS'
export const UPDATE_ACTIVITY_STEP = 'UPDATE_ACTIVITY_STEP'
export const UPDATE_ACTIVITY_STEP_SUCCESS = 'UPDATE_ACTIVITY_STEP_SUCCESS'

//NEW ACTIVITY

export const ADD_NEW_ACTIVITY = 'ADD_NEW_ACTIVITY'
export const ADD_NEW_ACTIVITY_SUCCESS = 'ADD_NEW_ACTIVITY_SUCCESS'
export const UPDATE_NEW_ACTIVITY = 'UPDATE_NEW_ACTIVITY'
export const UPDATE_NEW_ACTIVITY_SUCCESS = 'UPDATE_NEW_ACTIVITY_SUCCESS'
export const DELTETE_NEW_ACTIVITY = 'DELTETE_NEW_ACTIVITY'
export const DELETE_NEW_ACTIVITY_SUCCESS = 'DELETE_NEW_ACTIVITY_SUCCESS'
export const UPDATE_NEW_ACTIVITY_ALL_STEPSS = 'UPDATE_NEW_ACTIVITY_ALL_STEPSS'
export const UPDATE_NEW_ACTIVITY_ALL_STEPSS_SUCCESS = 'UPDATE_NEW_ACTIVITY_ALL_STEPSS_SUCCESS'
export const UN_SUBSSCRIBE_USER_FROM_ACTIVITY = "UN_SUBSSCRIBE_USER_FROM_ACTIVITY"
export const SUCCESS_UN_SUBSSCRIBE_USER_FROM_ACTIVITY = "SUCCESS_UN_SUBSSCRIBE_USER_FROM_ACTIVITY"

export const ADD_PRIVATE_ACTIVITY = 'ADD_PRIVATE_ACTIVITY'
export const ADD_PRIVATE_ACTIVITY_SUCCESS = 'ADD_PRIVATE_ACTIVITY_SUCCESS'
export const UPDATE_PRIVATE_ACTIVITY = 'UPDATE_PRIVATE_ACTIVITY'
export const UPDATE_PRIVATE_ACTIVITY_SUCCESS = 'UPDATE_PRIVATE_ACTIVITY_SUCCESS'
export const GET_PRIVATE_ACTIVITY = 'GET_PRIVATE_ACTIVITY'
export const GET_PRIVATE_ACTIVITY_SUCCESS = 'GET_PRIVATE_ACTIVITY_SUCCESS'
export const CHECK_DUPLICATE_ACTITY_TITLE = 'CHECK_DUPLICATE_ACTITY_TITLE'
export const CHECK_SUCCESS_DUPLICATE_ACTITY_TITLE = 'CHECK_SUCCESS_DUPLICATE_ACTITY_TITLE'
export const CHECK_FAILURE_DUPLICATE_ACTITY_TITLE = 'CHECK_FAILURE_DUPLICATE_ACTITY_TITLE'
export const CLEAR_ACTIVITY_DATA = 'CLEAR_ACTIVITY_DATA'
export const REMOVE_API_ERROR_MESSAGE = 'REMOVE_API_ERROR_MESSAGE'
export const SHOW_ACTIVITY_CRETAED_MESSAGE = 'SHOW_ACTIVITY_CRETAED_MESSAGE'

//ACTIVITY TEMPLATE
export const GET_ACTIVITY_TEMPLATE_LIST = 'GET_ACTIVITY_TEMPLATE_LIST'
export const GET_SUCCESS_ACTIVITY_TEMPLATE_LIST = 'GET_SUCCESS_ACTIVITY_TEMPLATE_LIST'
export const GET_ACTIVITY_TEMPLATE_GROUPS = 'GET_ACTIVITY_TEMPLATE_GROUPS'
export const GET_SUCCESS_ACTIVITY_TEMPLATE_GROUPS = 'GET_SUCCESS_ACTIVITY_TEMPLATE_GROUPS'
export const SHOW_ACTIVITY_TEMPLATE_LOADING = 'SHOW_ACTIVITY_TEMPLATE_LOADING'
export const HIDE_ACTIVITY_TEMPLATE_LOADING = 'HIDE_ACTIVITY_TEMPLATE_LOADING'
export const CLEAR_ACTIVITY_TEMPLATE_DATA = 'CLEAR_ACTIVITY_TEMPLATE_DATA'
export const REMOVE_ACTIVITY_TEMPLATE_API_ERROR_MESSAGE = 'REMOVE_ACTIVITY_TEMPLATE_API_ERROR_MESSAGE'
export const ACTIVITY_TEMPLATE_SERVER_ERROR = 'ACTIVITY_TEMPLATE_SERVER_ERROR'
export const SELECT_ACTIVITY_TEMPLATE = 'SELECT_ACTIVITY_TEMPLATE'
export const FILTER_ACTIVITY_TEMPLATE = 'FILTER_ACTIVITY_TEMPLATE'
export const SELECT_ACTIVITY_TEMPLATE_GROUP = 'SELECT_ACTIVITY_TEMPLATE_GROUP'


//Charts 
export const GET_CHARTS_LIST = 'GET_CHARTS_LIST'
export const GET_SUCCESS_CHARTS_LIST = 'GET_SUCCESS_CHARTS_LIST'
export const FILTER_CHARTS_LIST = 'FILTER_CHARTS_LIST'
export const FILTER_SUCCESS_CHARTS_LIST = 'FILTER_SUCCESS_CHARTS_LIST'
export const SHOW_CHARTS_LOADING = 'SHOW_CHARTS_LOADING'
export const HIDE_CHARTS_LOADING = 'HIDE_CHARTS_LOADING'
export const GET_CHART_BY_CHART_ID = 'GET_CHART_BY_CHART_ID'
export const GET_SUCCESS_CHART_BY_CHART_ID = 'GET_SUCCESS_CHART_BY_CHART_ID'
export const FILTER_CHART_DATA = 'FILTER_CHART_DATA'
export const FILTER_SUCCESS_CHART_DATA = 'FILTER_SUCCESS_CHART_DATA'

//My Activity

export const ASSIGN_ACTIVITY = "ASSIGN_ACTIVITY"
export const ASSIGN_ACTIVITY_SUCCESS = "ASSIGN_ACTIVITY_SUCCESS"
export const ASSIGN_ACTIVITY_FAILED = "ASSIGN_ACTIVITY_FAILED"
export const GET_MY_ACTIVITY_BY_ID = "GET_MY_ACTIVITY_BY_ID"
export const GET_SUCCESS_MY_ACTIVITY_BY_ID = "GET_SUCCESS_MY_ACTIVITY_BY_ID"
export const SHOW_MY_ACTIVITY_LOADING = "SHOW_MY_ACTIVITY_LOADING"
export const HIDE_MY_ACTIVITY_LOADING = "HIDE_MY_ACTIVITY_LOADING"
export const SET_SELECTED_ACTIVITIES_FOR_ASSIGN = "SET_SELECTED_ACTIVITIES_FOR_ASSIGN"
export const GET_ACTIVITTIES_BY_USER_ID = "GET_ACTIVITTIES_BY_USER_ID"
export const GET_ACTIVITTIES_BY_USER_ID_SUCCESS = "GET_ACTIVITTIES_BY_USER_ID_SUCCESS"
export const HIDE_assignLoading_INDICATOR_ACTIVITIES_LIST = "HIDE_assignLoading_INDICATOR_ACTIVITIES_LIST"

export const GET_SCHEDULE_LOADING = "GET_SCHEDULE_LOADING"
export const HIDE_SCHEDULE_LOADING = "HIDE_SCHEDULE_LOADING"
export const SCHEDULE_ADDED_SUCCESSFULLY = "SCHEDULE_ADDED_SUCCESSFULLY"
export const SCHEDULE_EDITED_SUCCESSFULLY = "SCHEDULE_EDITED_SUCCESSFULLY"
export const SCHEDULE_DELETED_SUCCESSFULLY = "SCHEDULE_DELETED_SUCCESSFULLY"

export const CLEAR_ASSIGN_ACTIVITY_DATA = "CLEAR_ASSIGN_ACTIVITY_DATA"

export const GET_ACTIVITIES_GROUPS_LIST_SUCCESS = "GET_ACTIVITIES_GROUPS_LIST_SUCCESS"
export const GET_ACTIVITIES_GROUPS_LIST_FAILED = "GET_ACTIVITIES_GROUPS_LIST_FAILED"
export const FETCH_COMMON_ACTIVITY_LIST = "FETCH_COMMON_ACTIVITY_LIST"
export const FETCH_COMMON_ACTIVITY_SUCCESS = "FETCH_COMMON_ACTIVITY_SUCCESS"
export const REMOVE_ASSIGN_ACTIVITY_API_ERROR_MESSAGE = "REMOVE_ASSIGN_ACTIVITY_API_ERROR_MESSAGE"
export const GET_ASSIGN_ACTIVITY_LOADING = "GET_ASSIGN_ACTIVITY_LOADING"
export const HIDE_ASSIGN_ACTIVITY_LOADING = "HIDE_ASSIGN_ACTIVITY_LOADING"
export const UPDATE_COMMON_ACTIVITY_LIST = "UPDATE_COMMON_ACTIVITY_LIST"
export const GET_ACTIVITY_SCHEDULES_LIST = "GET_ACTIVITY_SCHEDULES_LIST"
export const UPDATE_ACTIVITY_SCHEDULES_LIST = "UPDATE_ACTIVITY_SCHEDULES_LIST"
export const GET_USER_ACTIVITY_SCHEDULES_LOADING = "GET_USER_ACTIVITY_SCHEDULES_LOADING"
export const ASSIGN_ACTIVITY_APIS_FAILED_WARNING = "ASSIGN_ACTIVITY_APIS_FAILED_WARNING"
export const GET_ACTIVITY_MEMBERS_DETAILS = "GET_ACTIVITY_MEMBERS_DETAILS"
export const GET_ACTIVITY_MEMBERS_DETAILS_SUCCESS = "GET_ACTIVITY_MEMBERS_DETAILS_SUCCESS"
export const GET_ACTIVITY_MEMBERS_DETAILS_FAILED = "GET_ACTIVITY_MEMBERS_DETAILS_FAILED"

export const UNASSIGN_ACTIVITY_TO_USER = "UNASSIGN_ACTIVITY_TO_USER"
export const UNASSIGN_ACTIVITY_TO_USER_SUCCESS = "UNASSIGN_ACTIVITY_TO_USER_SUCCESS"
export const UNASSIGN_ACTIVITY_TO_USER_FAILED = "UNASSIGN_ACTIVITY_TO_USER_FAILED"
export const SET_USER_ASSIGNED_ACTIVITIES = "SET_USER_ASSIGNED_ACTIVITIES"
export const RESET_REDUX_DATA = "RESET_REDUX_DATA"
export const CLEAR_USER_FIREBASE_TOKEN_DATA = "CLEAR_USER_FIREBASE_TOKEN_DATA"

export const GET_THEME_LS_LOGO = 'GET_THEME_LS_LOGO';
export const GET_THEME_LS_LOGO_SUCCESS = 'GET_THEME_LS_LOGO_SUCCESS';
export const GET_THEME_LS_LOGO_FAILED = 'GET_THEME_LS_LOGO_FAILED';
export const SET_DYNAMIC_THEME_CLASS = 'SET_DYNAMIC_THEME_CLASS';
export const FILTER_ACTIVITY_TEMPLATE_GROUPS_LIST = 'FILTER_ACTIVITY_TEMPLATE_GROUPS_LIST';
