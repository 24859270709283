/**
 * Assign/unAssign activities to User
 */

import React, { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import QueueAnim from 'rc-queue-anim';
import { connect } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import RctCollapsibleCard from '../RctCollapsibleCard/RctCollapsibleCard'
import queryString from 'query-string';
import SweetAlert from 'react-bootstrap-sweetalert'
import AssignActivityHeader from '../Header/AssignActivityHeader';
import IntlMessages from "../../util/IntlMessages"
import WarningDialog from '../../components/Dialog/WarningDialog'
import AssignActivitySchedule from "./AssignActivitySchedule";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import DontsLoader from "../DotsLoader";
import SearchActivities from "./SearchActivities";
import {
    refreshCurrentSchedule,
    getCookies,
    // ellipsify,
} from '../../helpers/helpers';
import { Scrollbars } from 'react-custom-scrollbars';
import AssignActivityCheckBox from "./AssignActivityCheckBox";
import StarIcon from '@mui/icons-material/Star';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import {
    applyActivitiesFilter,
    clearActivitiesFilter,
    setSelectedActivitiesForAssign,
    getActivitiesGroupsList,
    assingActivitiesToUser,
    removeAssignAPIErrorMessage,
    getCommonActivitiesByUserId,
    clearAssingActivityData,
    showAssingActivityLoading,
    hideAssingActivityLoading,
    getActivityMembersDetails,
} from '../../actions/AssignActivityActions'
import {
    getUserById
} from '../../actions/AuthActions'
import PreviewPopup from "./Popups/PreviewPopup";
import DetailsPopup from "./Popups/DetailsPopup";
import ActivitiesFilter from "./Popups/ActivitiesFilter";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import EventNoteIcon from '@mui/icons-material/EventNote';

class AssignActivity extends Component {
    state = {
        idToken: "",
        userId: 'ODWYDyio2RhrEHJGq6fDXrSCpeI2',
        orgId: 'Test',
        activityId: '',
        openedActivity: -1,
        anchorEl: null,
        selectedActivities: [],
        activities: {},
        showFilter: false,
        filterOptions: [],
        showDetailsPopup: false,
        detailsList: [],
        detailsType: "",
        previewPopup: false,
        routineId: "",
        applyedFilterOptions: [],
        applyFiltersOnActivities: false,
        loading:false,
        search:'',
        processSearch:false,
        isDataSaved: true,
        checkedList: [],
        showWarningAlert:"",
        showInactiveActivities:false
    }

    componentDidMount() {
        let testing= localStorage.getItem("testing");
        if(!testing) {
           // console.log('--------Data has been cleared--------')
            this.props.clearAssingActivityData();
        }
        console.group("%c[AssignActivityApp]%c Rendered assign activity app screen","color:black","color:green");
        // document.domain = "3rbehavioralsolutions.com"
        let userId = '0I1aJIkeHPOdG4V896xnmvjrStq1' //'xqekAeFd3qgpZnuxeilfQfiw7QT2' // 
        let idToken = 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImE4YmZhNzU2NDk4ZmRjNTZlNmVmODQ4YWY5NTI5ZThiZWZkZDM3NDUiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiIiwiRGVsZXRlSW1hZ2VzIjp0cnVlLCJvcmdhbml6YXRpb24iOiJUZXN0IiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2xzLWNvbmZpZy1taWdyYXRpb24iLCJhdWQiOiJscy1jb25maWctbWlncmF0aW9uIiwiYXV0aF90aW1lIjoxNjYxMTQwMjQ4LCJ1c2VyX2lkIjoieHFla0FlRmQzcWdwWm51eGVpbGZRZml3N1FUMiIsInN1YiI6InhxZWtBZUZkM3FncFpudXhlaWxmUWZpdzdRVDIiLCJpYXQiOjE2NjExNDAyNDgsImV4cCI6MTY2MTE0Mzg0OCwiZW1haWwiOiJsaWZlc2hlcnBhK2F1dGgwfDVmY2UwZjNjNWIzMjNmMDA2ZmE4NDY0MUAzcmJlaGF2aW9yYWxzb2x1dGlvbnMuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImxpZmVzaGVycGErYXV0aDB8NWZjZTBmM2M1YjMyM2YwMDZmYTg0NjQxQDNyYmVoYXZpb3JhbHNvbHV0aW9ucy5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJjdXN0b20ifX0.qO0xGNEacXZyedYxCN0cgFN6aNLqV9dKLU5CibpGJG3t7kJj8R8oEt09UchLW9e2SobMdpXcFPxZMUa1oK5R5cWJjz-t39TyAG-R68hs7wV5V55d0tlS2XjsQllUw_6lPKDHimvkYuvBmRPRzSmkkwbVPcrBZYx__PGqL_ROYf7iNvqYc_HW1YaCkjLr7sR1A6GLsmaOW00rqPckvqOL9dptsG6Z5K6KIhGWSWg5_658goM3PAG-kfFBZFVOG1HT05w2YoNZhQOW_7yqwvkQnep50_p7X-eLVsQCykplecprBFsQ1W7SmSn226yCfL8__BDkX2IJ8JiFy6JhlhCiGQ'
        let orgId = 'Test'
        let params = this.getParams();
        console.log("params :", params);
        if (params) {
            // let params = queryString.parse(this.props.location.search)  
            idToken = params.idToken || idToken;
            userId = params.userId || userId;
            orgId = params.orgId || orgId;
            params.mode === "web" ? localStorage.setItem("appMode", "web") : localStorage.setItem("appMode", "app");
        } else {
           // userId = 'xqekAeFd3qgpZnuxeilfQfiw7QT2'
            //idToken = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjM1MDM0MmIwMjU1MDAyYWI3NWUwNTM0YzU4MmVjYzY2Y2YwZTE3ZDIiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiIiwiRGVsZXRlSW1hZ2VzIjp0cnVlLCJvcmdhbml6YXRpb24iOiJUZXN0IiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2xzLWNvbmZpZy1taWdyYXRpb24iLCJhdWQiOiJscy1jb25maWctbWlncmF0aW9uIiwiYXV0aF90aW1lIjoxNjMzMzQzNjM2LCJ1c2VyX2lkIjoieHFla0FlRmQzcWdwWm51eGVpbGZRZml3N1FUMiIsInN1YiI6InhxZWtBZUZkM3FncFpudXhlaWxmUWZpdzdRVDIiLCJpYXQiOjE2MzM0OTAwODksImV4cCI6MTYzMzQ5MzY4OSwiZW1haWwiOiJsaWZlc2hlcnBhK2F1dGgwfDVmY2UwZjNjNWIzMjNmMDA2ZmE4NDY0MUAzcmJlaGF2aW9yYWxzb2x1dGlvbnMuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImxpZmVzaGVycGErYXV0aDB8NWZjZTBmM2M1YjMyM2YwMDZmYTg0NjQxQDNyYmVoYXZpb3JhbHNvbHV0aW9ucy5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJjdXN0b20ifX0.zsXfljuECZ5vG0lDy4ZC9-TAdWcdAWxgPUvGJoea4worMz7E2qXT5DrXVxP1-vmt-gz3coXQhmvLc8cRUv7eP9ziP-pTto4dUVhxlGxGsTQz4f3gApgEeAUrA64uTDqz-Mac3XCoG02MBLwkdf0W7WT9cWi6d9OkELAUcQmpiuTMUJIwawMNwCVpILLh7BCqxsMCprNUCmGAoc7QE7kelpOkBDPWoJJRnukMPVl-x6wzcVn7vH36vV2BUQG0Lp8nwntHuYAy2Pa-hB-3s-P5eE1XsQAwDL3j8iI_uNT8ijyy0s1v-7j6Pb_asMGtF_AJ8Q5s-RkERblLoJXbiZWcQw'
            //orgId = 'Test'
        }
        this.props.getActivitiesGroupsList(userId, idToken,orgId);
        this.props.getCommonActivitiesByUserId(userId, idToken, orgId);
        this.props.getUserById(userId, idToken);
        this.setState({ idToken, userId, orgId, currentSchedule: refreshCurrentSchedule() })
    }

   showWarningAlertPopup = (str) => {
       this.setState({...this.state,showWarningAlert:str});
   }

   closeWarningAlert = () => {
       this.setState({...this.state,showWarningAlert:""});
   }

    setSelectedActivities = (activityId) => {
        let { selectedActivities = [],commonAllActivities} = this.props;
        let index = selectedActivities.findIndex(id => id == activityId);
        if (index >= 0) {
            selectedActivities.splice(index, 1); 
            console.log("%c Activity unchecked for activityId:"+activityId,"color:red");  
        }    
        else {
            console.log("%c Activity checked for activityId:"+activityId,"color:green");  
            selectedActivities.push(activityId);     
        }
        this.props.setSelectedActivitiesForAssign(selectedActivities,commonAllActivities);
        this.setState({...this.state,isDataSaved:false}); 
    }

    updateSelectedActivities = (activityId, schedules) => {
        let { selectedActivities = [] } = this.props;
        let tempState = { ...this.state };
        let index = selectedActivities.findIndex(activity => activity.activityId == activityId);
        if (index >= 0) {
            let activity = selectedActivities[index];
            activity.schedules = schedules;
            selectedActivities.splice(index, 1, activity);
            this.props.setSelectedActivitiesForAssign(selectedActivities)
            this.setState(tempState);
        }
    }

    setOpenActivity = (activityId) => {
        let { openedActivity ,userId,orgId,idToken} = this.state;
        if (activityId == openedActivity) {
            this.setState({ ...this.state, openedActivity: -1 })
        }
        else {
            this.setState({ ...this.state, openedActivity: activityId })
            this.props.getActivityMembersDetails(userId,orgId, activityId, idToken)
        }
    }

    getParams() {
        let params = null
        if (this.props.location.search) {
            params = queryString.parse(this.props.location.search)
        } else if (getCookies('objCookieData')) {
            params = JSON.parse(getCookies('objCookieData'))
        }
        // console.log('params-->', params)
        return params
    }

    handleSaveAssignedActivity() {
        let { idToken, userId ,orgId} = this.state;
        let { selectedActivities = [] ,userActivities} = this.props;
        this.props.assingActivitiesToUser(userId, orgId, idToken, selectedActivities,userActivities)
        this.setState({ isDataSaved: true })
    }

    onConfirm() {
        this.props.removeAssignAPIErrorMessage()
       // window.location.href = "/success"
       if(this.state.isDataSaved) {
        window.location.href = "/success"
       }
        this.setState({ isDataSaved: false })
    }

    handleHeaderCancel() {
        this.setState({ showUnSavedWarning: !this.state.isDataSaved })
    }

    setDataChangedFlag = () => {
        this.setState({ isDataSaved: false })
    }

    handleCloseWarning() {
        this.setState({ showUnSavedWarning: !this.state.showUnSavedWarning })
    }

    handleClickMenu(event, key) {
        this.setState({ anchorEl: event.currentTarget, editScheduleKey: key });
    }

    handleCloseMenu = () => {
        this.setState({ anchorEl: null });
    }

    handleCloseFilter = () => {
        this.setState({ ...this.state, showFilter: false });
    }

    handelShowFilter = () => {
        let { applyedFilterOptions } = this.state;
        this.setState({ ...this.state, showFilter: true, filterOptions: [...applyedFilterOptions] });
    }

    handelFilterOpitonsChange = (id) => {
        let tempState = { ...this.state };
        let index = tempState.filterOptions.findIndex(groupId => groupId === id);
        if (index >= 0) {
            tempState.filterOptions.splice(index, 1);
        }
        else {
            tempState.filterOptions.push(id);
        }
        this.setState(tempState);
    }

    clearFilter = () => {
        this.props.showAssingActivityLoading();
        setTimeout(() => { this.props.hideAssingActivityLoading() }, 100);
        console.log(" Filter cleared")
        this.setState({ ...this.state, showFilter: false, applyFiltersOnActivities: false, filterOptions: [], applyedFilterOptions: [], showInactiveActivities: false  });
    }

    applyFilter = (inActiveActivities) => {
        this.props.showAssingActivityLoading();
        setTimeout(() => { this.props.hideAssingActivityLoading() }, 100);
        let { filterOptions } = this.state;
        if(filterOptions.length > 0) {
            console.log(" Filter applied on activities");
            this.setState({ ...this.state, showFilter: false, applyFiltersOnActivities: true, applyedFilterOptions: [...filterOptions], showInactiveActivities: inActiveActivities});
        } else {
            console.log(" Filter cleared");
            this.setState({ ...this.state, showFilter: false, applyFiltersOnActivities: false, filterOptions: [], applyedFilterOptions: [], showInactiveActivities: inActiveActivities });
        }
    }

    handleCloseDetailsPopup = () => {
        this.setState({ ...this.state, showDetailsPopup: false, detailsList: [], detailsType: "" });
    }

    handleOpenDetailsPopup = (type, activityId,detailsList) => {
        let { activitiesGroups, activityMemebersList } = this.props;
        let list = [];
        if (type == "Steps") {
            detailsList && detailsList.map((detail, key) => {
                list.push({ title: detail.title })
            })
        } else if (type == "Users") {
            list = activityMemebersList ? activityMemebersList.filter((member,key) => member.role == "Client" ) : [];
        }else if (type == "Admins") {
            list = activityMemebersList ? activityMemebersList.filter((member,key) => member.role != "Client" ) : [];;
        }
         else if (type == "Activity Groups") {
            detailsList && Object.keys(detailsList).map((groupId, key) => {
                let group = activitiesGroups.find(ele => ele.groupId == groupId);
                if (group)
                    list.push({ title: group.name })
            })
        } else if (type == "Notifications") {
            list = detailsList;
        }
        this.setState({ ...this.state, showDetailsPopup: true, detailsList: list, detailsType: type, activityId });
    }

    handleOpenPreviewPopup = (routineId) => {
        this.setState({ ...this.state, previewPopup: true, routineId: routineId });
    }

    handleClosePreviewPopup = () => {
        this.setState({ ...this.state, previewPopup: false, routineId: "" });
    }

    setSelectedAllActivities = () => {
        let { selectedActivities = [],activityMemebersList } = this.props;
        let { commonAllActivities, filterOptions, filteredActivities } = this.props;
        let list = filterOptions ? filteredActivities : commonAllActivities;
        let activitiesIds = list && list.length > 0 ? list.map(ele => ele.id) : [];
        if (activitiesIds.length >= 0) {
            if (activitiesIds.length === selectedActivities.length) {
                this.props.setSelectedActivitiesForAssign([])
                this.setState({ ...this.state, selectedActivities: [] })
            } else if (activitiesIds.length > 0 && selectedActivities.length > 0) {
                this.props.setSelectedActivitiesForAssign([]);
                this.setState({ ...this.state, selectedActivities: [] });
            }
            else {
                this.props.setSelectedActivitiesForAssign(activitiesIds);
                this.setState({ ...this.state, selectedActivities: activitiesIds });
            }
        }
    }
    
    handelSearchActivities = (value) => {
        let {search} = this.state;
        if(value.length > 2) {
            this.setState({...this.state,processSearch:true,search:value})
            if(!this.state.processSearch)
            setTimeout(()=> {this.setState({...this.state,processSearch:false})},1000);   
         }
         else if(search.length == 3 && value.length == 2) {
            this.setState({...this.state,processSearch:true,search:""})
            if(!this.state.processSearch)
            setTimeout(()=> {this.setState({...this.state,processSearch:false})},1000);   
         } else if (value === "" && search == "") {

         } else if (value === "") {
            this.setState({...this.state,processSearch:true,search:""})
            if(!this.state.processSearch)
            setTimeout(()=> {this.setState({...this.state,processSearch:false})},1000);   
         }      
    }

    getFilteredList = (list) => {
        let {applyedFilterOptions } = this.state;
        let filteredlist = [];
        if(list) {
            filteredlist =  list.filter((activity) => applyedFilterOptions.find(gid => {
                if(activity.ActivityGroups) {
                    return Object.keys(activity.ActivityGroups).findIndex(activityGroupId => activityGroupId == gid && activity.ActivityGroups[activityGroupId]) >= 0
                } else {
                    return false;
                }
            }))
        }
        return filteredlist;
    }

    getScrollBarStyle() {
		return {
			height: 'calc(100vh - 165px)',
		}
	}

    render() {
        const { showUnSavedWarning, isDataSaved, openedActivity, showFilter, showDetailsPopup, detailsList, detailsType, idToken, userId, orgId, previewPopup, applyFiltersOnActivities ,search,processSearch, showInactiveActivities} = this.state
        const { scheduleLoading, assignLoading ,activityMemebersList, selectedActivities = [], commonAllActivities, activitiesGroups, errorTitle, screenThemeClass, popupThemeClass, errorMessage, userDetails = {},detailsLoading , userActivities, schedules } = this.props;
        let list = commonAllActivities ? [...commonAllActivities]: []; 
        let description = ""
        if (schedules && schedules.length > 0) {
            const filterdSchedules = schedules.filter(sch => sch.notify === false)
            description = filterdSchedules.length > 0 ? "(This notification will be sent only if Notify setting is enabled in schedule)" : ""
        }
        // if(!showInactiveActivities) {
        //     list = list.filter(activity =>  activity.active == false ? false : true);
        // }
        if (applyFiltersOnActivities) {
            list = this.getFilteredList(list);
        }
        list = search && search.length > 2 && list && list.length > 0 ? list.filter((li) => (li.title && li.title.toLowerCase().includes(search.toLowerCase())) || (li.detailedDescription && li.detailedDescription.toLowerCase().includes(search.toLowerCase()))) : list;
        const params = this.getParams();
        // console.log("list", list);
        // console.log("userActivityIds ----->", userActivities);
        // console.log("selectedActivities  -------> ", selectedActivities);
        return (
            <QueueAnim type="bottom" duration={2000} className="object-editor"> 
                <div>
                    {
                        assignLoading ?
                            <div className="d-flex justify-content-center loader-overlay">
                                <CircularProgress  disableShrink	 />
                            </div>
                            : errorMessage !== '' ?
                                <SweetAlert
                                    warning
                                    btnSize="sm"
                                    customClass="warningText"
                                    show={errorMessage !== ''}
                                    confirmBtnText="OK"
                                    confirmBtnBsStyle="warning"
                                    title={`${errorTitle}!`}
                                    onConfirm={this.onConfirm.bind(this)}
                                >
                                <div className="sweet-alert-text"> {errorMessage}</div>   
                                </SweetAlert>
                                : showUnSavedWarning && <WarningDialog open={true} handleCloseSuccessPopup={this.handleCloseWarning.bind(this)} popupThemeClass={popupThemeClass}/>
                    }
                </div>
                <div className={`${screenThemeClass} assign-activities-rct-session`} >
                    <AppBar position="static" className="session-header">
                        <Toolbar>
                            <AssignActivityHeader 
                            userDetails={userDetails} 
                            handelShowFilter={this.handelShowFilter} 
                            isDataSaved={isDataSaved} 
                            handleHeaderCancel={this.handleHeaderCancel.bind(this)} 
                            popupThemeClass={popupThemeClass} 
                            title={<IntlMessages id="newactivity.info_CreateActivity" />} 
                            href="/success" 
                            handleSave={this.handleSaveAssignedActivity.bind(this)} 
                            applyFiltersOnActivities={applyFiltersOnActivities}
                            />
                        </Toolbar>
                    </AppBar>
                    <div className="session-inner-wrapper assign-activity-search-header" > 
                        <div className="session-body p-0 text-center assign-activities-container">
                            <div className="session-head">
                                <SearchActivities handelSearchActivities={this.handelSearchActivities}/>
                            </div>
                        </div>
                    </div>
                    <Scrollbars
                        className="rct-scroll"
                        autoHide
                        autoHideDuration={100}
                        style={this.getScrollBarStyle()}
                    >
                    <div className="session-inner-wrapper pt-2" data-testid="assign-activities">
                        <div className="assign-activity">
                            <div className="row row-eq-height">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="session-body p-0 text-center assign-activities-container">
                                        <div className="session-head">

                                            {showFilter &&
                                                <ActivitiesFilter
                                                    popupThemeClass={popupThemeClass}
                                                    activitiesGroups={activitiesGroups}
                                                    showFilter={showFilter}
                                                    filterOptions={this.state.filterOptions}
                                                    clearFilter={this.clearFilter}
                                                    applyFilter={this.applyFilter}
                                                    handleCloseFilter={this.handleCloseFilter}
                                                    handelFilterOpitonsChange={this.handelFilterOpitonsChange}
                                                    showInactiveActivities = {showInactiveActivities}
                                                />
                                            }
                                            {showDetailsPopup &&
                                                <DetailsPopup
                                                    list={detailsList}
                                                    idToken={idToken}
                                                    userId={userId}
                                                    orgId={orgId}
                                                    activityId={this.state.activityId}
                                                    popupThemeClass={popupThemeClass}
                                                    detailsType={detailsType}
                                                    showDetailsPopup={showDetailsPopup}
                                                    handleCloseDetailsPopup={this.handleCloseDetailsPopup}
                                                />}
                                            {previewPopup &&
                                                <PreviewPopup
                                                    idToken={idToken}
                                                    userId={userId}
                                                    orgId={orgId}
                                                    popupThemeClass={popupThemeClass}
                                                    routineId={this.state.routineId}
                                                    previewPopup={this.state.previewPopup}
                                                    handleClosePreviewPopup={this.handleClosePreviewPopup}
                                                    theme={params && params.theme ? params.theme : "Default"}
                                                />}
                                                {processSearch &&
                                                    <DontsLoader/>
                                                }
                                            <RctCollapsibleCard contentCustomClasses="p-0 assign-activities-container" customClasses="assign-activities-list">
                                                {!processSearch && list && list.length > 0 ? list.map((activity, key) =>
                                                    <div key={key} data-testid={`activity-asssign-${key + 1}`} className="list-card mb-1">
                                                        <div className="d-flex mx-0 list-card border-bottom  pb-1 pt-1 px-1 justify-content-between  align-items-center">
                                                            <div className="d-flex pl-1 align-items-center text-left w-53 pr-1" >
                                                                <AssignActivityCheckBox 
                                                                    activityKey={key}
                                                                    selectedActivities={selectedActivities}
                                                                    setSelectedActivities={this.setSelectedActivities}
                                                                    activity={activity}
                                                                    userExclusive= {activity.userExclusive}
                                                                    userId={userId}
                                                                    showWarningAlertPopup={this.showWarningAlertPopup}
                                                                />
                                                                <div onClick={() => this.setOpenActivity(activity.id)} className="scheduleTitle">
                                                                    <div data-testid={`activity-title-${key + 1}`} /*className={` ${activity.active == false ? "text-decoration-through":""}`}*/ >
                                                                          {activity.title ? activity.title : ""}
                                                                          {activity.userExclusive && <StarIcon style={{verticalAlign:"middle",paddingBottom:"2px"}}/> }
                                                                     </div>
                                                                    {activity.detailedDescription ?
                                                                        <div data-testid={`activity-description-${key + 1}`} className={` font-sm sub-text-color `}>{activity.detailedDescription}</div>
                                                                         : ""
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center text-right w-47" >
                                                                <div className="d-flex ml-auto mr-0 align-items-center">
                                                                    {activity.steps && activity.steps.length > 0 &&
                                                                        <div data-testid={`${key + 1}-activity-steps`} className=" text-center activity-steps-details" onClick={() => this.handleOpenDetailsPopup("Steps", activity.id,activity.steps)}>
                                                                            <div className="steps-count">
                                                                                {activity.steps ? activity.steps.length : 0}
                                                                            </div>
                                                                            <div className="details-title primary-color ">
                                                                                Steps
                                                                            </div>
                                                                        </div>}
                                                                    <IconButton data-testid={`activity-preview-icon-${key + 1}`} className="d-none d-md-block" onClick={() => this.handleOpenPreviewPopup(activity.id)} title="Preview">
                                                                        <PlayCircleOutlineIcon fontSize="large" color="primary" />
                                                                    </IconButton>
                                                                    <PlayCircleOutlineIcon className="d-block d-md-none activity-icon-preview-expended" onClick={() => this.handleOpenPreviewPopup(activity.id)} title="Preview" fontSize="large" color="primary"  style={{cursor: "pointer"}}/>
                                                                    <IconButton onClick={() => this.setOpenActivity(activity.id)} className="d-none d-md-block">
                                                                        {
                                                                            openedActivity == activity.id ?
                                                                                <KeyboardArrowUpIcon data-testid={`activity-expended-${key + 1}`} fontSize="large" color="primary" style={{ margin: "0px", padding: "0px", cursor: "pointer", marginLeft: "0px" }}
                                                                                    className="m-0 p-0" /> :
                                                                                <KeyboardArrowDownIcon data-testid={`activity-unexpended-${key + 1}`} fontSize="large" color="primary" style={{ margin: "0px", padding: "0px", cursor: "pointer" }} className="m-0 p-0"
                                                                                />
                                                                        }
                                                                    </IconButton>
                                                                    {
                                                                        openedActivity == activity.id ?
                                                                            <KeyboardArrowUpIcon onClick={() => this.setOpenActivity(activity.id)} fontSize="large" color="primary" style={{ margin: "0px", padding: "0px", cursor: "pointer", marginLeft: "0px" }}
                                                                                className="m-0 p-0 d-block d-md-none activity-icon-preview-expended" /> :
                                                                            <KeyboardArrowDownIcon onClick={() => this.setOpenActivity(activity.id)} fontSize="large" color="primary" style={{ margin: "0px", padding: "0px", cursor: "pointer" }}
                                                                                className="m-0 p-0 d-block d-md-none activity-icon-preview-expended"
                                                                            />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {openedActivity == activity.id && <div>
                                                            <div className="d-flex border-bottom pb-2">
                                                                <div data-testid={`${key + 1}-activity-groups`} className="col-4 text-center" onClick={() => this.handleOpenDetailsPopup("Activity Groups",  activity.id, activity.ActivityGroups)}>
                                                                    <div className="details-count ">
                                                                        {activity.ActivityGroups ? Object.keys(activity.ActivityGroups).length : 0}
                                                                    </div>
                                                                    <div className="details-title primary-color">
                                                                        Groups
                                                                    </div>
                                                                </div>
                                                                <div data-testid={`${key + 1}-activity-users`} className="col-4 text-center" onClick={() => this.handleOpenDetailsPopup("Users", activity.id)}>
                                                                    <div className="details-count">
                                                                        {  
                                                                           activityMemebersList && activityMemebersList.reduce((acc,curr) => curr.role == "Client" ? acc+1 : acc ,0)
                                                                        }
                                                                    </div>
                                                                    <div className="details-title primary-color">
                                                                        Users
                                                                    </div>
                                                                </div>
                                                                <div data-testid={`${key + 1}-activity-admins`} className="col-4 text-center" onClick={() => this.handleOpenDetailsPopup("Admins", activity.id)}>
                                                                    <div className="details-count">
                                                                        { activityMemebersList && activityMemebersList.reduce((acc,curr) => curr.role == "Client" ? acc : acc+1 ,0)}
                                                                    </div>
                                                                    <div className="details-title primary-color">
                                                                        Admins
                                                                    </div>
                                                                </div>
                                                            </div>
                                                              {(scheduleLoading || detailsLoading)&&
                                                                 <div className="d-flex justify-content-center  loader-overlay">
                                                                     <CircularProgress disableShrink/>
                                                               </div>}
                                                            <div className="row detailsCard1">
                                                                <div className="col-12 text-left py-2 border-bottom" >
                                                                    <div data-testid={`${key + 1}-activity-notifications`} className=" d-flex align-items-center fw-semi-bold  details-heading mb-0" /*onClick={() => this.handleOpenDetailsPopup("Notifications", users)}*/>
                                                                        <NotificationsNoneIcon color="primary" className="pr-1 icon-font-size" />
                                                                        Notifications
                                                                    </div>
                                                                    <div className="descriptionStyle">
                                                                        {activity.doNotNotifyOnRoutineStart === false ? <li type="square">Notify on Routine Start</li> : ""}
                                                                        {activity.doNotNotifyOnRoutineCompletion === false ? <li type="square">Notify on Routine Completion</li> : ""}
                                                                        {activity.doNotNotifyOnRoutineCancel === false ? <li type="square">Notify on Routine Cancel</li> : ""}
                                                                        {activity.doNotShowRoutineCompletionReward === false ? <li type="square">Notify on Routine Reward Completion</li> : ""}
                                                                        {activity.notifyAdminOnStartActivityNotification === true ? <li type="square">{`Notify Admin on Start Activity Notification ${description}`}</li> : ""}
                                                                        {activity.notifyOnStepCompletion === true ? <li type="square">Notify on Step Completion</li> : ""}
                                                                       
                                                                        {activity.doNotNotifyOnRoutineStart !== false && 
                                                                            activity.doNotNotifyOnRoutineCompletion !== false &&
                                                                            activity.doNotNotifyOnRoutineCancel !== false &&
                                                                            activity.doNotShowRoutineCompletionReward !== false &&
                                                                            activity.notifyAdminOnStartActivityNotification !== true && 
                                                                            activity.notifyOnStepCompletion !== true && <li type="square">There are no notifications enabled right now.</li> 
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {activity.reportingList && activity.reportingList.findIndex(report => report.enabled) >= 0 && 
                                                                <div className="row detailsCard1">
                                                                    <div className="col-12 text-left py-2 border-bottom" >
                                                                        <div className=" d-flex align-items-center fw-semi-bold  details-heading mb-0" >
                                                                            <DescriptionOutlinedIcon color="primary" className="pr-1 icon-font-size" />
                                                                            <div className='mr-1'>Reporting</div>
                                                                            {activity.reportingList.findIndex(report => report.enabled) >= 0 ? 
                                                                                <CheckCircleRoundedIcon className='text-success'/>
                                                                                :
                                                                                <CancelRoundedIcon className='text-danger'/>
                                                                            }  
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                           
                                                            {<div className="d-flex justify-content-between pt-2 detailsCard">
                                                                <div data-testid={`${key + 1}-activity-schedules`} className="mb-0 d-flex align-items-center details-heading  fw-semi-bold justify-content-center">
                                                                    <EventNoteIcon color="primary" className="pr-1 icon-font-size" />
                                                                    Schedules
                                                                </div>
                                                            </div>}
                                                            <AssignActivitySchedule
                                                                orgId={this.state.orgId}
                                                                userId={this.state.userId}
                                                                idToken={this.state.idToken}
                                                                scheduleLoading={scheduleLoading}
                                                                activities={list}
                                                                activityId={activity.id}
                                                                activityType={activity.type}
                                                                selectedActivities={selectedActivities}
                                                                updateSelectedActivities={this.updateSelectedActivities}
                                                                setDataChangedFlag= {this.setDataChangedFlag}
                                                                userActivities={userActivities}
                                                            />

                                                        </div>}

                                                    </div>
                                                ) : !assignLoading && !processSearch &&
                                                <div className="d-flex justify-content-center py-4">
                                                    <div data-testid="activities-not-found" className="scheduleTitle">Activities not found</div>
                                                </div>
                                                }
                                            </RctCollapsibleCard>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-2 col-lg-2"></div>
                            </div>
                        </div>
                    </div>
                    <div className='footer-style listItem'>{process.env.BUILD_VERSION}</div>
                 </Scrollbars> 
                  {this.state.showWarningAlert &&
					<div className="col-12 p-4">
						<SweetAlert
						warning
						btnSize="sm"
						customClass="warningText"
						confirmBtnText="OK"
						confirmBtnBsStyle="warning"
						title={"Activity Warning"}
						onConfirm={() => this.closeWarningAlert()}
						>
						<div className="sweet-alert-text">{this.state.showWarningAlert}</div>
						</SweetAlert>
					</div>
					}
                </div>
            </QueueAnim >
        );
    }
}

// map state to props
const mapStateToProps = ({ ActivityTemplateReducer, settings, authUser, AssignActivity }) => {
    const { userAllActivities, filteredActivities, assignLoading, commonAllActivities, activitiesGroups, filterOptions, selectedActivities, successMessage, errorTitle, errorMessage, scheduleLoading, activityMemebersList, detailsLoading ,userActivities, schedules} = AssignActivity
    const { activityTemplates, groups, selectedTemplate, filteredTemplates, selectedGroups } = ActivityTemplateReducer
    const { screenThemeClass, popupThemeClass } = settings;
    const { userDetails } = authUser;
    return {userActivities, detailsLoading, activityMemebersList, assignLoading, scheduleLoading, userAllActivities, selectedActivities, userDetails, filteredActivities, filterOptions, commonAllActivities, activitiesGroups, errorMessage, activityTemplates, groups, selectedTemplate, screenThemeClass, popupThemeClass, filteredTemplates, selectedGroups, successMessage, errorTitle, schedules }
}

export default connect(mapStateToProps, {
    getUserById,
    clearAssingActivityData,
    removeAssignAPIErrorMessage,
    applyActivitiesFilter,
    clearActivitiesFilter,
    setSelectedActivitiesForAssign,
    getActivitiesGroupsList,
    assingActivitiesToUser,
    getCommonActivitiesByUserId,
    showAssingActivityLoading,
    hideAssingActivityLoading,
    getActivityMembersDetails,
})(AssignActivity);
