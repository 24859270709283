/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AppConfig from 'Constants/AppConfig';
import { connect } from 'react-redux';

class HeaderComponent extends Component {

   render() {
    const applogo= localStorage.getItem('activeTheme') === '5' || localStorage.getItem('activeTheme') === '7' || localStorage.getItem('activeTheme') === '10' ? AppConfig.appLogoWhite  : AppConfig.appLogo 
    let activeTheme = localStorage.getItem('theme') ? JSON.parse(localStorage.getItem('theme')) : {id:1, name:"primary"};
    let themeLogo= localStorage.getItem("loginLogoImage") || this.props.themeLogo;
    const poweredByLS = localStorage.getItem("loginLifeSherpaImage")
    //let { activeTheme } = this.props;
    if (process.env.REACT_APP === 'DES') {
        return (
            <div className="container text-center">
                <h1 className="text-white text-center">{'Autism@Work'}</h1>
                <img src={poweredByLS} alt="session-logo" width="auto" height="30" />
            </div>
          );
      } else if ( activeTheme && (activeTheme.name == 'primary' || activeTheme.name == 'Default')) {
        return (
            <div className="container text-center pt-3">
                <img src={themeLogo  || applogo} alt="session-logo" className="auto-resize-img" width="auto" height="auto" />
            </div>
          );
      } else {
        return (
            <div className="container text-center pt-3">
                <img src={themeLogo  || applogo} alt="session-logo" className="auto-resize-img" width="auto" height="auto" />
                {poweredByLS && <div><img src={poweredByLS} alt="session-logo" width="auto" height="30" /></div>}
            </div>
          );
      }
      
   }
}
const mapStateToProps = ({ authUser, settings }) => {
    const { screenThemeClass, popupThemeClass, themeLogo, activeTheme } = settings;
    return {authUser, screenThemeClass, themeLogo, activeTheme}
 }
export default connect(mapStateToProps, {})(HeaderComponent);
