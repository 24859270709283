module.exports = {
   "Header.Save": "Save",
   "NewNotification.TitlePlaceholder": "Notification Title",
   "NewNotification.DescriptionPlaceholder": "Notification Description",
   "NewNotification.Schedule": "Schedule",
   "NewNotification.ADDSCHEDULE": "ADD SCHEDULE",
   "NewNotification.Starts": "Starts",
   "NewNotification.Repeats": "Repeats",
   "NewNotification.Edit": "Edit",
   "NewNotification.Delete": "Delete",
   "NewNotification.info-CreateNotification":"Create Notification",
   "NewNotification.info-AddNotification":"Help : Add Notification",
   "NewNotification.info-AddImage":"Help : Add Background Image",
   "NewNotification.info-AddSchedule":"Help : Add Schedule",
   "NewNotification.info-ScheduleAdded":"Help : Schedule Added",
   "NewNotification.info-EditDeleteSchedule":"Help : Edit/Delete Schedule",
   "NewNotification.info-AddNotificationDesc":"To add a new notification, You have fill the title and description text field. The title field is mandatory. After filling the title and description field, You can click on the add schedule button.",
   "NewNotification.info-AddImageDesc":"You can choose image for notification background by clicking on image icon. You can also delete the selected background image by clicking on delete icon button.",
   "NewNotification.info-AddScheduleDesc":"When you click on the Add Schedule button then a dialog box comes on your screen. In this dialog box, you have to enter a valid date and time according to your schedule. After selecting all the details you can click on the Done button.",
   "NewNotification.info-ScheduleAddedDesc":"After clicking on the Done button your schedule is successfully added. You can also see the schedule details on your screen.",
   "NewNotification.info-EditDeleteScheduleDesc":"By clicking on the More Icon. You can edit or delete your schedule.",
   
   "newactivity.titlePlaceholder": "Activity Title",
   "newactivity.titleError1": "Please enter a title. It is a required field.",
   "newactivity.titleError2": "This title is already taken. Please choose another title.",
   "newactivity.titleError3": "The title should be a minimum of 3 characters in length",
   "newactivity.descriptionPlaceholder": "Activity Description",   
   "newactivity.options": "OPTIONS",
   "newactivity.steps": "STEPS",
   "newactivity.schedule": "Schedule",
   "newactivity.ADDSCHEDULEButton": "ADD SCHEDULE",
   "newactivity.Starts": "Starts",
   "newactivity.Repeats": "Repeats",
   "newactivity.Edit": "Edit",
   "newactivity.Photo": "Photo Library",
   "newactivity.Camera": "Take Photo",
   "newactivity.inactive": "Enabled",
   "newactivity.skipbutton": "Show Skip Button",
   "newactivity.showstartbutton": "Show Start Button",
   "newactivity.backgroundimageurl": "Add Activity Image",
   "newactivity.activitystepimageurl": "Step Image",
   "newactivity.autoTTSEnabled": "Automatically Read Cards Titles",
   "newactivity.Delete": "Delete",
   "newactivity.AddAStep": "Add A Step",
   "newactivity.StepTitlePlaceholder": "Title",
   "newactivity.StepTitleErr":"Please enter a title. It is a required field.",
   "newactivity.StepDescPlaceholder": "Add Notes",
   "newactivity.info_CreateActivity":"Create Activity",
   "newactivity.info_AddActivity":"Help : Add Activity",
   "newactivity.info_AddImage":"Help : Add Background Image",
   "newactivity.info_EditActivityOptions":"Help : Configure Activity Options",
   "newactivity.info_AddSchedule":"Help : Add Schedule",
   "newactivity.info_ScheduleAdded":"Help : Schedule Added",
   "newactivity.info_EditDeleteSchedule":"Help : Edit/Delete Schedule",
   "newactivity.info_Steps":"Help : Steps",
   "newactivity.info_AddStep":"Help : Add Step",
   "newactivity.info_AddStepImage":"Help : Add Step Image",
   "newactivity.info_StepsAdded":"Help : Steps Added",
   "newactivity.info_DragStep":"Help : Drag Step",
   "newactivity.info_EditStep":"Help : Edit Step",
   "newactivity.info_AddActivityDesc":"To add a new Activity,You have to fill the title and description field. The title is Required. After filling the title and description, You can click on the add schedule button.",
   "newactivity.info-AddImageDesc":"You can choose image for activity background by clicking on image icon. You can also delete the selected background image by clicking on delete icon button.",
   "newactivity.info_EditActivityOptionsDesc":"You can configure the activity options like if you would not like to show start and skip button then you can disable these options.",
   "newactivity.info-AddStepImageDesc":"You can choose step image by clicking on image icon in the step. You can also delete the selected step image by clicking on delete icon button.",
   "newactivity.info_AddScheduleDesc":"When you click on the Add Schedule button then a dialog box comes on your screen. In this dialog box, you have to enter a valid date and time according to your schedule. After selecting all the details you can click on the Done button.",
   "newactivity.info_ScheduleAddedDesc":"After clicking on the Done button your schedule is successfully added. You can also see the schedule details on your screen.",
   "newactivity.info_EditDeleteScheduleDesc":"By clicking on the More Icon. You can edit or delete your schedule.",
   "newactivity.info_StepsDesc":"To add steps to your routine select the step option. After clicking on the step, You can see a 'add a step' button on your screen.",
   "newactivity.info_AddStepDesc":"When you click on the 'add a step' button then it shows you the title and Add notes field. For adding a new step you have to fill the step title and step description field. The title field is mandatory. You can delete step by clicking on the delete icon.",
   "newactivity.info_StepsAddedDesc":"You can add a step by pressing the tab key. When you press the tab key then your step is successfully added. You can see the step title and description on your screen.",
   "newactivity.info_DragStepDesc":"You can also drag and drop a step to rearranging the steps list.",
   "newactivity.info_EditStepDesc":"You can also edit the step details by clicking on step details.",
   "newactivity.assign_activity": "Assign Activity",
   "newactivity.activities_filter": "Activities Filter",
   "NewQuicktask.titlePlaceholder": "Quick Task Title",
   "NewQuicktask.descriptionPlaceholder": "Quick Task Description",
   "NewQuicktask.OPTIONS": "OPTIONS",
   "NewQuicktask.STEPS": "STEPS",
   "NewQuicktask.Schedule": "Schedule",
   "NewQuicktask.ADDSCHEDULEButton": "ADD SCHEDULE",
   "newactivity.ActivityTemplate": "Create From Template",

   "NewQuicktask.Starts": "Starts",
   "NewQuicktask.Repeats": "Repeats",
   "NewQuicktask.Edit": "Edit",
   "NewQuicktask.Delete": "Delete",
   "NewQuicktask.AddAStep": "Add A Step",
   "NewQuicktask.StepTitlePlaceholder": "Title",
   "NewQuicktask.StepDescPlaceholder": "Add Notes",
   "NewQuicktask.ActivityTemplate": "Create From Template",
   "NewQuicktask.info-CreateQuickStepTask":"Create Quick Step Task",
   "NewQuicktask.info-AddQuickStepTask":"Help : Add Quick Step Task",
   "NewQuicktask.info-AddSchedule":"Help : Add Schedule",
   "NewQuicktask.info-ScheduleAdded":"Help : Schedule Added",
   "NewQuicktask.info-EditDeleteSchedule":"Help : Edit/Delete Schedule",
   "NewQuicktask.info-Steps":"Help : Steps",
   "NewQuicktask.info-AddStep":"Help : Add Step",
   "NewQuicktask.info-StepsAdded":"Help : Steps Added",
   "NewQuicktask.info-DragStep":"Help : Drag Step",
   "NewQuicktask.info-EditStep":"Help : Edit Step",
   "NewQuicktask.info-AddQuickStepTaskDesc":"To add a new Quick Step Task, you must have to write the title because the title is a required field. You may also add descriptions. It is an optional field. After filling in the title and description, you can click on the add schedule button to add schedule.",
   "NewQuicktask.info-AddScheduleDesc":"When you click on the Add Schedule button then a dialog box comes on your screen. In this dialog box, you have to enter a valid date and time according to your schedule. After selecting all the details you can click on the Done button.",
   "NewQuicktask.info-ScheduleAddedDesc":"After clicking on the Done button your schedule is successfully added. You can also see the schedule details on your screen.",
   "NewQuicktask.info-EditDeleteScheduleDesc":"By clicking on the More Icon. You can edit or delete your schedule.",
   "NewQuicktask.info-StepsDesc":"To add steps to your routine select the step option. After clicking on the step, You can see a 'add a step' button on your screen.",
   "NewQuicktask.info-AddStepDesc":"When you click on the 'add a step' button then it shows you the step title and step description field. For adding a new step you have to fill the step title field because title is mandatory for adding new step and the description field is optional. You can also see a delete icon you can delete step by clicking on the delete icon.",
   "NewQuicktask.info-StepsAddedDesc":"You can add a step by pressing the tab key. When you press the tab key then your step is successfully added. You can see the step title and description on your screen.",
   "NewQuicktask.info-DragStepDesc":"You can also drag and drop a step to rearranging the steps list.",
   
   "NewQuicktask.info-EditStepDesc":"You can also edit the step details by clicking on step details.",
   "scheduleUpdate.AddASchedule": "Add A Schedule",
   "scheduleUpdate.EditASchedule": "Edit A Schedule",
   "scheduleUpdate.StartDate": "Start Date",
   "scheduleUpdate.StartTime": "Start Time",
   "scheduleUpdate.EndTime": "End Time",
   "scheduleUpdate.Repeat": "Repeat",
   "scheduleUpdate.CustomReccurences": "Custom Recurrences",
   "scheduleUpdate.notify": "Notify",
   "scheduleUpdate.RepeatEvery": "Repeat Every:",
   "scheduleUpdate.Ends": "Ends:",
   "scheduleUpdate.Never": "Never",
   "scheduleUpdate.After": "After",
   "scheduleUpdate.Occurences": "occurance(s)",
   "scheduleUpdate.On": "On",
   "scheduleUpdate.DoneButton": "Done",   
   "Template.More": "More...",
   "Template.filter": "Filter",
   "Template.Apply": "APPLY FILTER",
   "Template.ClearFilters": "CLEAR FILTER",
   "Template.info_ActivityTemplate": "Create From Template",
   "Template.Confirmation": "Selecting this template will overwrite your existing edits to the activity",
   "Template.sure": "Are you sure?",
   "Template.info_template": "Help : Templates",
   "Template.info_more": "Help : More Options",
   "Template.info_SelectTemplate": "Help : Select Template",
   "Template.info_Confirmation" : "Help : Confirmation Selecting Template",
   "Template.info_templateDesc": "You can see templates on your screen which may contains Image, title, Steps and schedules and you can also see some filter groups and More option.",
   "Template.info_moreDesc": "When you click on the More option then a dialog box will open on your screen. In this dialog box, you can see some filter groups options. You can use these filter goups to filter your template list.",
   "Template.info_SelectTemplateDesc": "You can select a template from the list of templates.",
   "Template.info_ConfirmationDesc" : "When you select a template from the list of templates then a dialog will open on your screen. If you want to open the select template then click the OK button and for cancellation, you can click on the Cancel button.",
   "chart.fromDate": "From Date",
   "chart.toDate": "To Date",
   "assignActivity.searchActivity": "Search by title | description"
}
