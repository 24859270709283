/**
 * AsyncComponent
 * Code Splitting Component / Server Side Rendering
 */
import React from 'react';
import Loadable from 'react-loadable';

// rct page loader
import RctPageLoader from '../../components/RctPageLoader/RctPageLoader';

const AsyncAuth = Loadable({
	loader: () => import("../../Auth/Auth"),
	loading: () => <RctPageLoader />,
});

// app signin
const AsyncAppSignIn = Loadable({
	loader: () => import("../../container/SigninFirebase"),
	loading: () => <RctPageLoader />,
});
const AsyncAppSignUpLifesherpa = Loadable({
	loader: () => import("../../container/LifesherpaSignup"),
	loading: () => <RctPageLoader />,
});
const AsyncAppSignInWithToken = Loadable({
	loader: () => import("../../container/SignInFirebaseToken"),
	loading: () => <RctPageLoader />,
});
const AsyncAppConfirmation = Loadable({
	loader: () => import("../../components/LoginComponent/LoginConfirmation"),
	loading: () => <RctPageLoader />,
});
const AsyncAppInitialLaunchScreen = Loadable({
	loader: () => import("../../components/LaunchScreen/InitialLaunchScreen"),
	loading: () => <RctPageLoader />,
});
const AsyncAppSuccess = Loadable({
	loader: () => import("../../components/SuccessComponent/SuccessScreen"),
	loading: () => <RctPageLoader />,
});
const AsyncAppResetPassword = Loadable({
	loader: () => import("../../components/ResetPassword/ResetPassword"),
	loading: () => <RctPageLoader />,
});
const AsyncAppResetPasswordSuccess = Loadable({
	loader: () => import("../../components/ResetPassword/ResetPasswordSuccess"),
	loading: () => <RctPageLoader />,
});
const AsyncAppResendEmailVerificationLink = Loadable({
	loader: () => import("../../components/ResendEmailComponent/ResendVerificationLink"),
	loading: () => <RctPageLoader />,
});
const AsyncAppEmailVerify = Loadable({
	loader: () => import("../../components/EmailComponent/EmailVerification"),
	loading: () => <RctPageLoader />,
});
const AsyncAppEmailVerificationSuccess = Loadable({
	loader: () => import("../../components/EmailComponent/EmailVerificationSuccess"),
	loading: () => <RctPageLoader />,
});
const AsyncAppEmailVerificationFailure = Loadable({
	loader: () => import("../../components/EmailComponent/EmailVerificationFailure"),
	loading: () => <RctPageLoader />,
});
const AsyncAppResendEmailVerificationSuccess = Loadable({
	loader: () => import("../../components/ResendEmailComponent/ResendEmailVerificationSuccess"),
	loading: () => <RctPageLoader />,
});
const AsyncAppLoginSuccess = Loadable({
	loader: () => import("../../components/LoginComponent/LoginSuccess"),
	loading: () => <RctPageLoader />,
});
const AsyncAppAuth0LoginSuccess = Loadable({
	loader: () => import("../../components/LoginComponent/Auth0LoginSuccess"),
	loading: () => <RctPageLoader />,
});

const AsyncAppLoginWithToken = Loadable({
	loader: () => import("../../components/LoginComponent/LoginWithToken"),
	loading: () => <RctPageLoader />,
});

const AsyncAppReactCharts = Loadable({
	loader: () => import("../../components/Charts"),
	loading: () => <RctPageLoader />,
});

const AsyncAuth0RedirectSignIn = Loadable({
	loader: () => import("../../components/LaunchScreen/Auth0RedirectSignInPape"),
	loading: () => <RctPageLoader />,
});

const AsyncMyActivityPreview =  Loadable({
	loader: () => import("Components/MyActivityPreview"),
	loading: () => <RctPageLoader />,
});

const AsyncAuthoLogin =  Loadable({
	loader: () => import("../LaunchScreen/Auth0Login"),
	loading: () => <RctPageLoader />,
});

const AsyncAcccepPrivacyPolicy =   Loadable({
	loader: () => import("../LoginComponent/AcccepPrivacyPolicy"),
	loading: () => <RctPageLoader />,
});

export {
	AsyncAuth,
	AsyncAppSignIn,
	AsyncAppSignUpLifesherpa,
	AsyncAppSignInWithToken,
	AsyncAppConfirmation,
	AsyncAppInitialLaunchScreen,
	AsyncAppSuccess,
	AsyncAppResetPassword,
	AsyncAppResetPasswordSuccess,
	AsyncAppResendEmailVerificationLink,
	AsyncAppEmailVerify,
	AsyncAppEmailVerificationSuccess,
	AsyncAppEmailVerificationFailure,
	AsyncAppResendEmailVerificationSuccess,
	AsyncAppLoginSuccess,
	AsyncAppAuth0LoginSuccess,
	AsyncAppReactCharts,
	AsyncAppLoginWithToken,
	AsyncAuth0RedirectSignIn,
	AsyncMyActivityPreview,
	AsyncAuthoLogin,
	AsyncAcccepPrivacyPolicy
};
