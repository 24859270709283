/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import QueueAnim from 'rc-queue-anim';
import { Label } from 'reactstrap';
import HeaderComponent from '../Header/HeaderComponent'
import config from '../../config/config'



class ResendEmailVerificationSuccess extends Component {

   render() {
      return (
         <QueueAnim type="bottom" duration={2000}>
            <div className={config.rctSessionWrapper}>
               <AppBar position="static" className="session-header">
                  <Toolbar>
                    <HeaderComponent/>
                  </Toolbar>
               </AppBar>
               <div className="session-inner-wrapper">
                  <div className="container">
                     <div className="row row-eq-height">
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                           <div className="session-body text-center text-white">
                              <div className="session-head mb-30">
                                 <p className="mb-0">Congratulations!</p>
                                 <Label>We have sent an email to the address you provided. Click the contained link to verify that address. Your account will not be activated until you have completed the verification process.</Label>
                              </div>
                           </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                     </div>
                  </div>
               </div>
            </div>
         </QueueAnim>
      );
   }
}

export default ResendEmailVerificationSuccess;

