/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import QueueAnim from 'rc-queue-anim';
import { connect } from 'react-redux';
import config from '../../config/config'
import CircularProgress from '@mui/material/CircularProgress';


class Auth0LoginSuccess extends Component {

   componentDidMount(){
      let mode = localStorage.getItem("appMode");
      if(mode === "web"){
         window.close();
      }
      window.top.postMessage('close_iframe', '*')
   }

   render() {
      const {privacyPolicy} = this.props
      const defaultThemeName = privacyPolicy &&  privacyPolicy.themes && privacyPolicy.themes.default
      const defaultTheme = privacyPolicy && privacyPolicy.themes && privacyPolicy.themes.configuration && privacyPolicy.themes.configuration[defaultThemeName]
      const loginLogoIcon = privacyPolicy && defaultTheme  && defaultTheme.loginLogoImage === 'Logo' ? require('../../assets/img/lifesherpa-Icon.png') : privacyPolicy && defaultTheme && defaultTheme.loginLogoImage === 'LogoTheme1' ? require('../../assets/img/LogoTheme1.jpg') : privacyPolicy && defaultTheme && defaultTheme.loginLogoImage === 'CLE Logo' ? require('../../assets/img/CLE-Logo.png') : require('../../assets/img/lifesherpa-Icon.png')
      
      return (
         <QueueAnim type="bottom" duration={2000}>
            <div>
            {
               <div className="d-flex justify-content-center loader-overlay">
                  <CircularProgress className="theame-text" disableShrink/>
               </div>
            }
            </div>
            <div className={config.rctSessionWrapper}>
               <div className="session-inner-wrapper">
                  <div className="container">
                     <div className="row row-eq-height">
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                           <div className="session-body text-center">
                              <div className="session-head mb-30">
                                 {/* <p>Loading...</p> */}
                                </div>
                           </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                     </div>
                  </div>
               </div>
            </div>
         </QueueAnim>
      );
   }
}

export default Auth0LoginSuccess;

// map state to props
// const mapStateToProps = ({ authUser }) => {
//    const { user, loading, firebaseToken, profile, privacyPolicy } = authUser;
//    return { user, loading, firebaseToken, profile, privacyPolicy }
// }

// export default connect(mapStateToProps)(Auth0LoginSuccess);