/**
 * Sign Up With Firebase
 */
import React, { Component } from 'react';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input, FormFeedback } from 'reactstrap';
import CircularProgress from '@mui/material/CircularProgress';
import HeaderComponent from '../Header/HeaderComponent'
import config from '../../config/config'

import QueueAnim from 'rc-queue-anim';

// redux action
import {
   sendVerificationLink,
} from 'Actions';

class ResendVerificationLink extends Component {

   state = {
      email: '',
      emailEmpty: null,
      inValidEmail: null,
   }

   //validating the email address
   validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }

	/**
	 * On User Signup
	 */
   onSubmit(e) {
      e.preventDefault();
      const { email } = this.state;
      const userData = {
         "email": email,
      }

     if (email === ''){
      this.setState({emailEmpty: true})
    } else if (!(this.validateEmail(email))){
      this.setState({inValidEmail: true})
    } else {
      this.props.sendVerificationLink(userData, this.props.history);

    }
      
   }

   render() {
      const { email, emailEmpty, inValidEmail } = this.state;
      const { loading } = this.props;
      let showServerError = false //(this.props.resendverifyLinkStatus === "failed") && (this.props.resendverifyEmail === email)
      if ((this.props.resendverifyLinkStatus && this.props.resendverifyLinkStatus === "failed") || (this.props.resendverifyLinkStatus && this.props.resendverifyLinkStatus.status && this.props.resendverifyLinkStatus.status === "failed") || (this.props.resendverifyLinkStatus && this.props.resendverifyLinkStatus.code && this.props.resendverifyLinkStatus.code !== 200)){
         showServerError = true
      }
      return (
         <QueueAnim type="bottom" duration={2000}>
            <div>
            {
               loading &&
                  <div className="d-flex justify-content-center loader-overlay">
                     <CircularProgress className="theame-text" disableShrink/>
                  </div>
            }
            </div>
            <div className={config.rctSessionWrapper}>
               <AppBar position="static" className="session-header">
                  <Toolbar>
                     <HeaderComponent/>
                  </Toolbar>
               </AppBar>
               <div className="session-inner-wrapper">
                  <div className="container">
                     <div className="row row-eq-height">
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                           <div className={`session-body text-center ${config.headerTextcolor}`}>
                              <div className="session-head mb-15">
                                 <h3>Provide Email To Resend Verification Link</h3>
                              </div>
                              <Form>
                                 <FormGroup className="has-wrapper">
                                    <Input
                                       type="email"
                                       value={email}
                                       name="user-mail"
                                       autoCapitalize="none"
                                       id="user-mail"
                                       className="has-input input-lg text-center"
                                       placeholder="Enter Your Email"
                                       onKeyPress={e => {
                                          if (e.key === 'Enter') this.onSubmit(e);
                                          
                                        }}
                                       invalid={emailEmpty ? true : false}
                                       onChange={(e) => this.setState({ email: e.target.value, emailEmpty: e.target.value !== '' ? false : true, inValidEmail: !this.validateEmail(e.target.value) })}
                                    />
                                    {emailEmpty ? 
                                       <FormFeedback style={{fontSize: 'larger'}}>Please enter a valid email address. It is a required field.</FormFeedback>
                                       : inValidEmail ?
                                          <FormFeedback className="d-block" style={{fontSize: 'larger'}}>Invalid email address format.  Please provide a valid email address</FormFeedback>
                                       : showServerError &&
                                          <FormFeedback className="d-block" style={{fontSize: 'larger'}}>{this.props.resendverifyLinkStatus.message && this.props.resendverifyLinkStatus.message ? this.props.resendverifyLinkStatus.message :  "The email doesn't exist, please try another one."}</FormFeedback>
                                    }
                                 </FormGroup>
                                 <FormGroup className="mb-15">
                                    <Button
                                       className="text-white btn-block w-100"
                                       variant="contained"
                                       size="large"
                                       onClick={(e) => this.onSubmit(e)}>
                                       Submit
                            			</Button>
                                 </FormGroup>
                              </Form>
                           </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                     </div>
                  </div>
               </div>
            </div>
         </QueueAnim>
      );
   }
}

// map state to props
const mapStateToProps = ({ authUser }) => {
   const { loading, inviteCode, user, userData, resendverifyEmail, resendverifyLinkStatus } = authUser;
   return { loading, inviteCode, user, userData, resendverifyEmail, resendverifyLinkStatus };
};

export default connect(mapStateToProps, {
   sendVerificationLink,
})(ResendVerificationLink);
