/**
 * App Reducers
 */
import { combineReducers } from 'redux';
import authUserReducer from './AuthUserReducer';
import Geolocation from './Geolocation';
import ActivityReducer from './ActivityReducer'
import ActivityTemplateReducer from './ActivityTemplateReducer'
import settings from './settings'
import Charts from './ChartsReducer'
import MyActivity from './MyActivity'
import AssignActivity from './AssignActivityReducer'
import {
   RESET_REDUX_DATA
} from 'Actions/types';

const reducers = combineReducers({
   authUser: authUserReducer,
   Geolocation: Geolocation,
   ActivityReducer: ActivityReducer,
   ActivityTemplateReducer: ActivityTemplateReducer,
   settings,
   Charts: Charts,
   MyActivity,
   AssignActivity
});

const rootReducer = (state, action) => {
   // when RESET_REDUX_DATA action type is dispatched it will reset redux state 
   if (action.type == RESET_REDUX_DATA) {
      console.log("redux data cleared................");
     state = {};
   }
   return reducers(state, action);
 };

export default rootReducer;
