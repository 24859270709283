
import {sherpaAPI} from '../sherpaApi/index'
import { NotificationManager } from 'react-notifications';

import {
    GET_ACTIVITY_TEMPLATE_LIST,
    GET_SUCCESS_ACTIVITY_TEMPLATE_LIST,
    GET_ACTIVITY_TEMPLATE_GROUPS,
    GET_SUCCESS_ACTIVITY_TEMPLATE_GROUPS,
    SHOW_ACTIVITY_TEMPLATE_LOADING,
    HIDE_ACTIVITY_TEMPLATE_LOADING,
    CLEAR_ACTIVITY_TEMPLATE_DATA,
    REMOVE_ACTIVITY_TEMPLATE_API_ERROR_MESSAGE,
    ACTIVITY_TEMPLATE_SERVER_ERROR,
    SELECT_ACTIVITY_TEMPLATE,
    FILTER_ACTIVITY_TEMPLATE,
    SELECT_ACTIVITY_TEMPLATE_GROUP,
    FILTER_ACTIVITY_TEMPLATE_GROUPS_LIST
 } from '../actions/types';

export const getActivityTemplates = (userId, orgId, idToken, scope) => (dispatch) => {
    console.log(" Fetching activity templetes for userId:",userId,"......")
    let url = `/users/${userId}/activities/templates?orgId=${orgId}&scope=${scope||"private"}`
    dispatch({ type: GET_ACTIVITY_TEMPLATE_LIST });
    sherpaAPI.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
        })
        .then((response) => {
            if(response.status === 200){
                console.log("%c Fetched activity templetes for userId:"+userId,"color:green");
                dispatch({ type: GET_SUCCESS_ACTIVITY_TEMPLATE_LIST, payload: response.data });
                dispatch({ type: FILTER_ACTIVITY_TEMPLATE_GROUPS_LIST});
                if(response.data && response.data.length > 0) {
                    let consoleLogsTable = [];
                    response.data.map(template => {
                        consoleLogsTable.push({templateId:template.templateId, ownerId:template.ownerId, state:template.state})
                    });
                    console.table(consoleLogsTable);
                }
            } else {
                dispatch({ type: HIDE_ACTIVITY_TEMPLATE_LOADING });
                NotificationManager.error(response.data)
            }
        })
        .catch(error => {
            const errorMsg = error.response ? error.response.data.message : error.message;
            console.log("%c Error occured while fetching activity templetes for userId:"+userId,"color:red",errorMsg);
            dispatch({type: ACTIVITY_TEMPLATE_SERVER_ERROR, payload: errorMsg})
            dispatch({ type: HIDE_ACTIVITY_TEMPLATE_LOADING });
        })
}

export const getActivityGroups = (userId, orgId, idToken, scope) => (dispatch) => {
    console.log(" Fetching activity groups for userId:",userId,"......");
    let url = `/users/${userId}/activities/activity-groups?orgId=${orgId}&scope=${scope||"private"}`
    dispatch({ type: GET_ACTIVITY_TEMPLATE_GROUPS });
    sherpaAPI.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
        })
        .then((response) => {
            if(response.status === 200){
                dispatch({ type: GET_SUCCESS_ACTIVITY_TEMPLATE_GROUPS, payload: response.data });
                dispatch({ type: FILTER_ACTIVITY_TEMPLATE_GROUPS_LIST });
                console.log("%c Fetched activity groups for userId:"+userId,"color:green");
                if(response.data && response.data.length > 0) {
                    console.table(response.data);
                }
            } else {
                dispatch({ type: HIDE_ACTIVITY_TEMPLATE_LOADING });
                NotificationManager.error(response.data)
            }
        })
        .catch(error => {
            const errorMsg = error.response ? error.response.data.message : error.message;
            console.log("%c Error occured while fetching common activities for userId:"+userId,"color:red",errorMsg);
            dispatch({type: ACTIVITY_TEMPLATE_SERVER_ERROR, payload: error.response.data})
            dispatch({ type: HIDE_ACTIVITY_TEMPLATE_LOADING });
        })
}

export const showActivityTemplateLoading = () => (dispatch) =>{
    dispatch({type: SHOW_ACTIVITY_TEMPLATE_LOADING})
}

export const hideActivityTemplateLoading = () => (dispatch) =>{
    dispatch({type: HIDE_ACTIVITY_TEMPLATE_LOADING})
}

export const clearActivityTemplateData = () => (dispatch) => {
    dispatch({type: CLEAR_ACTIVITY_TEMPLATE_DATA})
}

export const erageTemplateErrorMessage = () => (dispatch) => {
    dispatch({type: REMOVE_ACTIVITY_TEMPLATE_API_ERROR_MESSAGE})
}

export const selectActivityTemplate = (template) => (dispatch) => {
    dispatch({type: SELECT_ACTIVITY_TEMPLATE, payload: template})
}

export const filterActivityTemplate = (groupIds, activityTemplateGroups) => (dispatch) => {
    dispatch({type: FILTER_ACTIVITY_TEMPLATE, payload: {groupIds, activityTemplateGroups}}) 
}

export const selectActivityTempGroup = (templateGroupIndex) => (dispatch) => {
    dispatch({type: SELECT_ACTIVITY_TEMPLATE_GROUP, payload: templateGroupIndex}) 
}