import React from 'react';
const DontsLoader = () => {
    return (
        <div className="d-flex justify-content-center my-4 py-4" >
        <div className="loading-dots">
            <div className="loading-dots--dot"></div>
            <div className="loading-dots--dot"></div>
            <div className="loading-dots--dot"></div>
        </div>
      </div>
    )
}
export default DontsLoader;