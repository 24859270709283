/**
 * Lifesherpa Object editor Header
 */

import React, { Component } from 'react';
import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded'
import {Button} from 'reactstrap'
import HelpDesk from '../Infopage/HelpDesk';
import HelpDeskV2 from '../Infopage/HelpDeskV2';

import IntlMessages from "../../util/IntlMessages"

class Header extends Component {
    constructor(props){
        super(props)
        this.state = {
            openHelpDesk: false,
        }
    }
    handleCloseHelpDesk(){
        this.setState({openHelpDesk: !this.state.openHelpDesk})
    }
   render() {
        const {version} = this.props;
        return (
            <div className="container mt-4">
                <div className="d-flex align-items-center justify-content-between" style={{color:'#454D51'}}>
                    { this.props.isDataSaved ? <div data-testid="back-button" className="backbutton"><a className="linkButton" target="_self" href="/success" ><ArrowBackRounded/></a></div>
                        :  <div data-testid="back-button" className="backbutton" onClick={this.props.handleHeaderCancel}><ArrowBackRounded/></div>
                    }
                    <div className="d-flex">
                        <button data-testid="info-button" title={this.props.title} onClick={() => this.handleCloseHelpDesk()} className="helpbutton mr-2">?</button>
                        <button data-testid="save-button" disabled={this.props.isDataSaved} className="headerButton" onClick={this.props.handleSave}><IntlMessages id="Header.Save"/></button>
                    </div>
                </div>
                {version === "v2" ?
                    <HelpDeskV2 popupThemeClass={this.props.popupThemeClass} open={this.state.openHelpDesk} closeHelper={this.handleCloseHelpDesk.bind(this)} title={this.props.title}/>
                    :
                    <HelpDesk popupThemeClass={this.props.popupThemeClass} open={this.state.openHelpDesk} closeHelper={this.handleCloseHelpDesk.bind(this)} title={this.props.title}/>
                }
                
                
            </div>
        );
   }
}
export default Header

