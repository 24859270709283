/**
 * Lifesherpa Object editor Header
 */

import React, { Component } from 'react';
import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded'
import HelpDesk from '../Infopage/HelpDesk'
import IntlMessages from "../../util/IntlMessages"
import LocalBarIcon from '@mui/icons-material/LocalBar';
class AssignActivityHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openHelpDesk: false,
        }
    }
    goToSuccessPage=() => {
        window.location.href = "/success";
     }
    handleCloseHelpDesk() {
        this.setState({ openHelpDesk: !this.state.openHelpDesk })
    }
    render() {
        let {userDetails, applyFiltersOnActivities} = this.props; 
        let userName= userDetails ? userDetails.displayName || (`${userDetails.firstName || "..."} ${userDetails.lastName || "User"}`) : "...";
        return (
            <div className="assign-activity mt-3 col-12">
                <div className="d-flex align-items-center justify-content-between " style={{ color: '#454D51' }}>
                    <div className="d-flex">
                        {this.props.isDataSaved ? <div data-testid="back-button" className="backbutton" onClick={() => this.goToSuccessPage()}><ArrowBackRounded /></div>
                            : <div data-testid="back-button" className="backbutton" onClick={this.props.handleHeaderCancel}><ArrowBackRounded /></div>
                        }
                    </div>
                    <div className="d-flex px-2">
                        <div className="user-details">
                            <div data-testid="user-name" className="assignActivityHeading">{userName}</div>
                            <div className="font-sm"><IntlMessages id="newactivity.assign_activity" /></div>
                        </div>
                    </div>
                    <div className="d-flex">
                        <button className={`filter-activities-button mr-2 ${applyFiltersOnActivities ? "activities-not-filtered": " activities-filtered "}`} data-testid="filter-button" title={"Filter Activities"} onClick={() => this.props.handelShowFilter()} >
                            <LocalBarIcon />
                        </button>
                        <button data-testid="save-button" disabled={this.props.isDataSaved} title="Assign Activities" className="headerButton" onClick={this.props.handleSave}><IntlMessages id="Header.Save" /></button>
                    </div>
                </div>
                <HelpDesk popupThemeClass={this.props.popupThemeClass}  open={this.state.openHelpDesk} closeHelper={this.handleCloseHelpDesk.bind(this)} title={this.props.title} />
            </div>
        );
    }
}
export default AssignActivityHeader