/**
 * Acivity Detail Reducers
 */
import {
    GET_ACTIVITIES_GROUPS_LIST,
    APPLY_FILTER_ON_ACTIVITY,
    CLEAR_FILTER_FROM_ACTIVITY,
    SET_ACTIVITIES_FILTERED_LIST,
    SET_SELECTED_ACTIVITIES_FOR_ASSIGN,
    GET_ACTIVITTIES_BY_USER_ID,
    GET_ACTIVITTIES_BY_USER_ID_SUCCESS,
    HIDE_assignLoading_INDICATOR_ACTIVITIES_LIST,
    ASSIGN_ACTIVITY,
    ASSIGN_ACTIVITY_SUCCESS,
    ASSIGN_ACTIVITY_FAILED,
    REMOVE_ASSIGN_ACTIVITY_API_ERROR_MESSAGE,
    GET_SCHEDULE_LOADING,
    HIDE_SCHEDULE_LOADING,
    SCHEDULE_ADDED_SUCCESSFULLY,
    SCHEDULE_EDITED_SUCCESSFULLY,
    SCHEDULE_DELETED_SUCCESSFULLY,
    CLEAR_ASSIGN_ACTIVITY_DATA,
    HIDE_LOADING_INDICATOR_ACTIVITIES_LIST,
    GET_ACTIVITIES_GROUPS_LIST_FAILED,
    GET_ACTIVITIES_GROUPS_LIST_SUCCESS,
    FETCH_COMMON_ACTIVITY_SUCCESS,
    FETCH_COMMON_ACTIVITY_LIST,
    GET_ASSIGN_ACTIVITY_LOADING,
    UPDATE_COMMON_ACTIVITY_LIST,
    HIDE_ASSIGN_ACTIVITY_LOADING,
    GET_ACTIVITY_SCHEDULES_LIST,
    UPDATE_ACTIVITY_SCHEDULES_LIST,
    GET_USER_ACTIVITY_SCHEDULES_LOADING,
    ASSIGN_ACTIVITY_APIS_FAILED_WARNING,
    GET_ACTIVITY_MEMBERS_DETAILS,
    GET_ACTIVITY_MEMBERS_DETAILS_FAILED,
    GET_ACTIVITY_MEMBERS_DETAILS_SUCCESS,
    UNASSIGN_ACTIVITY_TO_USER,
    UNASSIGN_ACTIVITY_TO_USER_SUCCESS,
    UNASSIGN_ACTIVITY_TO_USER_FAILED,
    SET_USER_ASSIGNED_ACTIVITIES
} from '../actions/types';

/**
 * initial auth user
 */
const INIT_STATE = {
    assignLoading: false,
    activitiesGroups: null,
    commonAllActivities: null,
    filterOptions: false,
    filteredActivities: [],
    selectedActivities: [],
    userAllActivities: [],
    errorMessage: '',
    errorTitle: '',
    successMessage: null,
    scheduleLoading: false,
    detailsLoading:false,
    detailsList:[],
    schedules:[],
    activityMemebersList:[],
    userActivities:[],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ASSIGN_ACTIVITY_LOADING:
            return { ...state, assignLoading: true };
        case FETCH_COMMON_ACTIVITY_LIST:
            return { ...state, assignLoading: true };
        case FETCH_COMMON_ACTIVITY_SUCCESS:
            return { ...state, commonAllActivities: action.payload, assignLoading: false };
        case UPDATE_COMMON_ACTIVITY_LIST:
            return { ...state, commonAllActivities: action.payload};
        case GET_ACTIVITIES_GROUPS_LIST:
            return { ...state };
        case GET_ACTIVITIES_GROUPS_LIST_SUCCESS:
            return { ...state, activitiesGroups: action.payload };
        case GET_ACTIVITIES_GROUPS_LIST_FAILED:
            return { ...state ,errorMessage: action.payload.message, errorTitle: action.payload.title };
        case APPLY_FILTER_ON_ACTIVITY:
            return { ...state, filterOptions:true};
        case CLEAR_FILTER_FROM_ACTIVITY:
            return { ...state, filterOptions: false, filteredActivities: [] };
        case SET_SELECTED_ACTIVITIES_FOR_ASSIGN:
            return { ...state, selectedActivities: action.payload }
        case SET_USER_ASSIGNED_ACTIVITIES:
            return { ...state, selectedActivities: action.payload, userActivities:[...action.payload]}
        case GET_ACTIVITTIES_BY_USER_ID:
            return { ...state, userAllActivities: [], assignLoading: true }
        case GET_ACTIVITTIES_BY_USER_ID_SUCCESS:
            return { ...state, userAllActivities: action.payload, assignLoading: false }
        case HIDE_LOADING_INDICATOR_ACTIVITIES_LIST:
            return { ...state, assignLoading: false , errorMessage: action.payload.message, errorTitle: action.payload.title }
    
        case HIDE_assignLoading_INDICATOR_ACTIVITIES_LIST:
            return { ...state, assignLoading: false }

        case ASSIGN_ACTIVITY:
            return { ...state, assignLoading: true }
        case ASSIGN_ACTIVITY_SUCCESS:
            return { ...state, assignLoading: false, errorMessage: '', errorTitle: '' }
        case ASSIGN_ACTIVITY_FAILED:
            return { ...state, assignLoading: false, errorMessage: action.payload.message, errorTitle: action.payload.title }
        case REMOVE_ASSIGN_ACTIVITY_API_ERROR_MESSAGE:
            return { ...state, errorMessage: '', errorTitle: '' };

        case GET_SCHEDULE_LOADING:
            return { ...state, scheduleLoading: true };
        case HIDE_SCHEDULE_LOADING:
            return { ...state, scheduleLoading: false , errorMessage: action.payload.message, errorTitle: action.payload.title };
        case SCHEDULE_ADDED_SUCCESSFULLY:
            return { ...state, schedules:action.payload,scheduleLoading: false };
        case SCHEDULE_EDITED_SUCCESSFULLY:
            return { ...state,schedules:action.payload, scheduleLoading: false };
        case SCHEDULE_DELETED_SUCCESSFULLY:
            return { ...state,schedules:action.payload, scheduleLoading: false };
        case SET_ACTIVITIES_FILTERED_LIST:
            return { ...state, filteredActivities: action.payload, assignLoading: false };
        case CLEAR_ASSIGN_ACTIVITY_DATA:
            return { ...state,commonAllActivities:null,filteredActivities: [], selectedActivities: [], userAllActivities: [], filterOptions: false,scheduleLoading: false,userActivities:[] };
        case HIDE_ASSIGN_ACTIVITY_LOADING:
            return { ...state, assignLoading: false };
        case GET_ACTIVITY_SCHEDULES_LIST:
            return { ...state, scheduleLoading: false, schedules:action.payload};
        case UPDATE_ACTIVITY_SCHEDULES_LIST:
            return { ...state, schedules:action.payload};
        case GET_USER_ACTIVITY_SCHEDULES_LOADING:
            return { ...state, scheduleLoading: true, schedules:[]};
        case ASSIGN_ACTIVITY_APIS_FAILED_WARNING:
            return { ...state, errorMessage: action.payload.message, errorTitle: action.payload.title }
        case GET_ACTIVITY_MEMBERS_DETAILS:
            return { ...state, detailsLoading: true,activityMemebersList:[]};
        case GET_ACTIVITY_MEMBERS_DETAILS_SUCCESS:
            return { ...state, detailsLoading: false,activityMemebersList:action.payload };
        case GET_ACTIVITY_MEMBERS_DETAILS_FAILED:
            return { ...state, detailsLoading: false, errorMessage: action.payload.message, errorTitle: action.payload.title };
            case UNASSIGN_ACTIVITY_TO_USER:
                return { ...state, assignLoading: true};
            case UNASSIGN_ACTIVITY_TO_USER_SUCCESS:
                return { ...state, assignLoading: false };
            case UNASSIGN_ACTIVITY_TO_USER_FAILED:
                return { ...state, assignLoading: false, errorMessage: action.payload.message, errorTitle: action.payload.title };
            default:
            return { ...state };
    }
}
