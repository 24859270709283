/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Form, FormGroup } from 'reactstrap';
import QueueAnim from 'rc-queue-anim';
import HeaderWithCancelButton from '../Header/HeaderWithCancelButton'
import config from '../../config/config'
import { connect } from 'react-redux';
import {
	getCookies
} from '../../helpers/helpers';
// redux action
import {
    getPrivacyPlocy,
} from 'Actions';
import {
   changeThemeColor,
  setLanguage
} from '../../actions/AppSettingsActions';

import queryString from 'query-string';

class InitialLaunchScreen extends Component {

    //handle token validation 
    componentDidMount() {
      console.group("%c[InitialLaunchScreen]%c Registration app lauchscreen screen rendered","color:black","color:green");
    }
    onSignInWithToken() {
      let params = queryString.parse(this.props.location.search);
      console.log(" Redirecting to login with token screen")
      console.groupEnd();
      if (params && params.mode === "web"){
         this.props.history.push('/login?mode=web')
      } else {
         this.props.history.push('/login')
      }
        
    }

   onSignInWithUsernameAndPassordForMobile(){
      let params = queryString.parse(this.props.location.search)
      console.groupEnd();
      if (params && params.mode === "web"){
         this.props.history.push('/success?mode=web')
      } else {
         // this.props.history.push('/success')
         window.location.href = '/success'
      }
   }

   //  //handle returning success url
    onSignInWithUsernameAndPassord(){
      console.log(" Redirecting to signinwithemail screen");
      console.groupEnd();
      let params = queryString.parse(this.props.location.search)
      if (params && params.mode === "web"){
         this.props.history.push('/signinwithemail?mode=web')
      } else {
         // this.props.history.push('/success')
         window.location.href = '/success'
      }
    }
 
   render() {
      let params = queryString.parse(this.props.location.search)
      let {themeData} = this.props;
      return (
         <QueueAnim type="bottom" duration={2000} >
            <div className={this.props.screenThemeClass} style={themeData && themeData.loginScreenFillPaternImage ?{backgroundImage:`url(${themeData.loginScreenFillPaternImage})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}:{}}>
               <AppBar position="static" className="session-header">
                  <Toolbar>
                     <HeaderWithCancelButton themeLogo={this.props.themeLogo} screenThemeClass={this.props.screenThemeClass} location={this.props.location} href={`/success`}/>
                  </Toolbar>
               </AppBar>
               <div className="session-inner-wrapper">
                  <div className="container">
                     <div className="row row-eq-height">
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                           <div className="session-body text-center">
                              {!params.email && !params.token && <div className="session-head mb-10">
                                 <p>{process.env.REACT_APP === 'DES' ? 'Thank you for downloading the Autism@Work App!' : 'Thank you for downloading the LifeSherpa App!'}</p>
                                 <p className="mb-0 ">If you have been given an invitation token, please tap the button below to complete your one-time registration.</p>
                              </div>}
                              <Form>
                                 {!params.email && !params.token && <FormGroup>
                                    <Button
                                       className="btn btn-block w-100 login-screen-button"
                                       variant="contained"
                                       size="large"
                                       onClick={() => this.onSignInWithToken()}
                                    >
                                       I Have a Token
                                        </Button>
                                 </FormGroup>}
                                 <FormGroup className="mb-10">
                                   { params.email ? <p className="mb-0">Configuration, please tap the button below to continue:</p>
                                    : params.token ? <p className="mb-0">Configuration, please tap the button below to continue:</p>
                                    : <p className="mb-0">If you already have a valid username and password, please tap the button below:</p>
                                   }
                                 </FormGroup>
                                 <FormGroup>
                                    <Button
                                       className="btn btn-block w-100 login-screen-button"
                                       variant="contained"
                                       size="large"
                                       onClick={() => this.onSignInWithUsernameAndPassordForMobile()}
                                    >
                                      {!params.email && !params.token ? "I have my Username and Password" : "Continue"}
                                        </Button>
                                 </FormGroup>
                              </Form>
                           </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                     </div>
                  </div>
               </div>
            </div>
         </QueueAnim>
      );
   }
}

// map state to props
const mapStateToProps = ({ authUser, settings }) => {
   const { screenThemeClass, popupThemeClass, themeLogo } = settings;
   const {themeData, themeLoading} = authUser
   return {authUser, screenThemeClass, themeLogo, themeData}
}

export default connect(mapStateToProps, {
    getPrivacyPlocy,
    changeThemeColor
})(InitialLaunchScreen);


