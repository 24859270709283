/**
 * Auth Actions
 * Auth Action With Google, Facebook, Twitter and Github
 */
// import firebase from 'firebase/app';
// import 'firebase/auth';
import {userProfileAPI, sherpaAPI, lifesherpaAuth, lifesherpaAuthBaseAPI, authAPI} from '../sherpaApi/index'
import { NotificationManager } from 'react-notifications';
import {checkEnterpriseConnection, getAvailableConnections} from "../helpers/helpers"
import themeMock from "../test/themeMock.json";

import {
   LOGIN_USER_SUCCESS,
   GET_USER_PROFILE,
   GET_USER_BY_ID,
   GET_SUCCESS_USER_BY_ID,
   RESET_USER_PASSWORD,
   RESET_USER__PASSWORD_SUCCESS,
   SET_USER_DETAIL,
   POST_LIFE_SHERPA_CREATE_USER,
   POST_LIFE_SHERPA_SUCCESS_CREATE_USER,
   GET_VALIDATE_INVITE_CODE,
   GET_SUCCESS_VALIDATE_CODE,
   SHOW_FIREBASE_AUTH_LOADING,
   HIDE_FIREBASE_AUTH_LOADING,
   VERIFY_USER_ACCOUNT_VIA_MAIL,
   VERIFY_SUCCESS_USER_ACCOUNT_VIA_MAIL,
   RESEND_VERIFICATION_LINK,
   RESEND_VERIFICATION_LINK_SUCCESS,
   GET_ORGANIZATION_PRIVACY_POLICY,
   GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY,
   POST_LIFE_SHERPA_LOGIN,
   POST_LIFE_SHERPA_LOGIN_SUCCESS,
   GET_USER_FIREBASE_TOKEN,
   GET_SUCCESS_USER_FIREBASE_TOKEN,
   GET_USER_ORGANIZATION,
   GET_SUCCESS_USER_ORGANIZATION,
   GET_USER_ORGANIZATION_FROM_TOKEN,
   GET_SUCCESS_USER_ORGANIZATION_FROM_TOKEN,
   CLEAR_PUBLIC_SETTING,
   CLEAR_FIREBASE_CUSTOM_DATA,
   RESET_REDUX_DATA,
   CLEAR_USER_FIREBASE_TOKEN_DATA,
   GET_THEME_LS_LOGO,
   GET_THEME_LS_LOGO_SUCCESS,
   GET_THEME_LS_LOGO_FAILED,
   SET_DYNAMIC_THEME_CLASS,
   SELECTED_IDP_TO_REGISTER,
   HIDE_LOADING_INDICATOR_ACTIVITIES_LIST,
   CHECK_FAILURE_DUPLICATE_ACTITY_TITLE,
   HIDE_PRIVATE_ACTIVITY_LOADING
} from '../actions/types';

export const setUserDetail = (userDetail) => (dispatch) => {
   dispatch({type: SET_USER_DETAIL, payload: userDetail})
}

export const showAuthLoading = () => ({
   type: SHOW_FIREBASE_AUTH_LOADING
})

export const hideAuthLoading = () => ({
   type: HIDE_FIREBASE_AUTH_LOADING
})

export const clearSetting = () => (dispatch) => {
   dispatch({type: CLEAR_PUBLIC_SETTING})
}

export const getPrivacyPlocy = (params, history) => (dispatch) => {
   const org = params.organization ? params.organization : process.env.REACT_APP === 'DES' ? '56NpRJMlV9XzWyf0LBmb' : 'Test'
   let url = `/organizations/${org}/settings`;
   dispatch({ type: GET_ORGANIZATION_PRIVACY_POLICY });
   sherpaAPI.get(url)
      .then((response) => {
         if (response.status === 200) {
            dispatch({ type: GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY, payload: response.data});
            if (params.organization && params.region && params.email){
               window.location.href = `/signin?organization=${params.organization}&region=${params.region}&email=${params.email}`
            } else if (params.organization && params.region && params.token){
               window.location.href = `/signin?organization=${params.organization}&region=${params.region}&token=${params.token}`
            }  
         } else {
            dispatch({ type: GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY, payload: response.data});
         }
      })
      .catch(error => {
         // error handling
         const errorData = (error.response && error.response.data) || error;
         dispatch({ type: GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY, payload: errorData});
         console.log("error occured ", errorData);
      })
}

export const getPrivacyPlocyFromToken = (params, history) => (dispatch) => {
   console.log(" Fetching privacy policy details.....")
   const org = params.organization ? params.organization : 'SbqnT7qt9kXY4robcR7u'
   let url = `/organizations/${org}/settings`
   dispatch({ type: GET_ORGANIZATION_PRIVACY_POLICY });
   sherpaAPI.get(url)
      .then((response) => {
         if (response.status === 200) {
            // console.log(params.organization, response.data)
            const appMode = localStorage.getItem("appMode")
            const enterpriseConnection = checkEnterpriseConnection(response.data, params.organization)
            const availableConections = getAvailableConnections(response.data, params.organization)
            dispatch({ type: GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY, payload: response.data});
            console.log("%c Fetched privacy policy details ","color:green")
            console.groupEnd();
            if (availableConections.length > 1) {
               window.location.href = `/selectoption?organization=${params.organization}&region=${params.region}&token=${params.token}&mode=${appMode}`
            } else {
               if (process.env.REACT_APP === 'DES') {
                  console.log(" Redirecting to accept privacy policy screen")
                  window.location.href = `/acceptpolicy?organization=${params.organization}&region=${params.region}&token=${params.token}&enterpriseConnection=${enterpriseConnection}&mode=${appMode}`
               } else {
                  console.log(" Redirecting to signuplifesherpa screen")
                  if(!enterpriseConnection){
                     history.push(`/signuplifesherpa`);
                  } else if (params.organization && params.region && params.token){
                     window.location.href = `/auth0login?organization=${params.organization}&region=${params.region}&token=${params.token}&mode=${appMode}`
                  }
               }
            }
            
         } else {
            dispatch({ type: GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY, payload: response.data});
         }
      }).catch(error => {
         const errorData = (error.response && error.response.data) || error;
         dispatch({ type: GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY, payload: errorData});
         const errorMsg = errorData.message;
         console.log("%c Error occured while fetching privacy policy details","color:red", errorMsg);
      })
}


//Validate the invite code
export const getUserConnectionByToken = (inviteCode, history) => (dispatch) => {
   console.log(" Authenticating token........")
   let url = `users/invite-code/${inviteCode}`
   dispatch({ type: GET_USER_ORGANIZATION_FROM_TOKEN });
   lifesherpaAuth.get(url).then((response) => {
      let tokenPayloadData = response.data 
      tokenPayloadData["inviteCode"] = inviteCode
      tokenPayloadData["status"] = response.data.status
      if (response.data) {
         localStorage.setItem("orgId", response.data.organization)
         dispatch({ type: GET_SUCCESS_USER_ORGANIZATION_FROM_TOKEN, payload: tokenPayloadData});
         dispatch({ type: GET_SUCCESS_VALIDATE_CODE, payload: tokenPayloadData});
         console.log("%c Token is authenticated ","color:green");
         const availableConections = response.data.auth0ClientConfig.connections
         dispatch(getPolicyFromToken({organization: response.data.organization, region: response.data.region, token: inviteCode},history, availableConections))
      } else {
         console.log("%c Invalid token.  Please enter a valid token","color:red");
         dispatch({ type: GET_SUCCESS_USER_ORGANIZATION_FROM_TOKEN, payload: tokenPayloadData});
      }
   }).catch(error => {
      console.log("%c Invalid token.  Please enter a valid token","color:red");
      const message = error.response && error.response.data ?  error.response.data.message : error.message
      let tokenRepose = {'inviteCode': inviteCode, 'status': 'failed', message}
      dispatch({ type: HIDE_FIREBASE_AUTH_LOADING});
      dispatch({ type: GET_SUCCESS_USER_ORGANIZATION_FROM_TOKEN, payload: tokenRepose});
   })
}

export const getPolicyFromToken = (params, history, availableConections) => (dispatch) => {
   console.log(" Fetching privacy policy details.....")
   const org = params.organization ? params.organization : 'SbqnT7qt9kXY4robcR7u'
   let url = `/organizations/${org}/settings`
   dispatch({ type: GET_ORGANIZATION_PRIVACY_POLICY });
   sherpaAPI.get(url)
      .then((response) => {
         if (response.status === 200) {
            const appMode = localStorage.getItem("appMode")
            dispatch({ type: GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY, payload: response.data});
            console.log("%c Fetched privacy policy details ","color:green")
            console.groupEnd();
            if (availableConections && availableConections.length > 1) {
               window.location.href = `/selectoption?organization=${params.organization}&region=${params.region}&token=${params.token}&mode=${appMode}`
            } else {
               if (process.env.REACT_APP === 'DES') {
                  console.log(" Redirecting to accept privacy policy screen")
                  window.location.href = `/acceptpolicy?organization=${params.organization}&region=${params.region}&token=${params.token}&enterpriseConnection=${availableConections && availableConections.length > 0 ? availableConections[0].type : null}&mode=${appMode}`
               } else {
                  console.log(" Redirecting to signuplifesherpa screen")
                  if(availableConections.length > 0 && availableConections[0].type === 'database'){
                     history.push(`/signuplifesherpa`);
                  } else if (params.organization && params.region && params.token){
                     window.location.href = `/auth0login?organization=${params.organization}&region=${params.region}&token=${params.token}&mode=${appMode}`
                  }
               }
            }
            
         } else {
            dispatch({ type: GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY, payload: response.data});
         }
      }).catch(error => {
         const errorData = (error.response && error.response.data) || error;
         dispatch({ type: GET_SUCCESS_ORGANIZATION_PRIVACY_POLICY, payload: errorData});
         const errorMsg = errorData.message;
         console.log("%c Error occured while fetching privacy policy details","color:red", errorMsg);
      })
}

export const sendVerificationLink = (userdata, history) => (dispatch) => {
   let url = '/users/resend-email-verification'
   dispatch({ type: RESEND_VERIFICATION_LINK });
   sherpaAPI.post(url,userdata)
      .then((response) => {
         if (response.status === 200) {
            dispatch({ type: RESEND_VERIFICATION_LINK_SUCCESS, payload: {status: response.data.status, email: userdata.email}});
            history.push('/resendverificationlinksuccess');
         } else {
            dispatch({ type: RESEND_VERIFICATION_LINK_SUCCESS, payload: {status: response.data, email: userdata.email }});
         }
      })
      .catch(error => {
         // error handling
         const errorData = (error.response && error.response.data) || error;
         dispatch({ type: RESEND_VERIFICATION_LINK_SUCCESS, payload: {status: errorData, email: userdata.email }});
         console.log("error occured ", errorData);
      })
}

//verify  email
export const verifyingEmail = (userdata, history) => (dispatch) => {
   let url = '/users/verify-email'
   dispatch({ type: VERIFY_USER_ACCOUNT_VIA_MAIL });
   sherpaAPI.post(url,userdata)
      .then((response) => {
         if (response.data.status === 'success') {
            dispatch({ type: VERIFY_SUCCESS_USER_ACCOUNT_VIA_MAIL, payload: {'status': response.data.status, 'code': userdata.code}});
            history.push('/emailverificationsuccess');
         } else {
            dispatch({ type: VERIFY_SUCCESS_USER_ACCOUNT_VIA_MAIL, payload: {'status': response.data.status, 'code': userdata.code}});
            history.push('/emailverificationfailure');
         }
      })
      .catch(error => {
         // error handling
         dispatch({ type: VERIFY_SUCCESS_USER_ACCOUNT_VIA_MAIL, payload: {'status': "failed", 'code': userdata.code}});
         history.push('/emailverificationfailure');
         console.log("error occured ", error);
      })
}

//Reset password
export const resetPassword = (userdata, history) => (dispatch) => {
   let url = '/users/confirm-code'
   dispatch({ type: RESET_USER_PASSWORD });
   sherpaAPI.post(url, userdata)
      .then((response) => {
         if (response.status === 200) {
            
            dispatch({ type: RESET_USER__PASSWORD_SUCCESS, payload: {'status': 'success', 'resetPassword': userdata.newPassword, data: response.data}});
            history.push('/resetpasswordsuccess');
         } else {
            
            dispatch({ type: RESET_USER__PASSWORD_SUCCESS, payload: {'status': 'failed', 'resetPassword': userdata.newPassword, data: response.data}});
         }
      })
      .catch(error => {
         // error handling
         const errorData = error.response && error.response.data ? error.response.data : error;
         dispatch({ type: RESET_USER__PASSWORD_SUCCESS, payload: {'status': "failed", 'resetPassword': userdata.newPassword, data: errorData}});
         console.log("error occured ", errorData);
      })
}


//register user
export const createUser = (userData, history) => async(dispatch) => {
   console.log(" Creating user.....")
   let url = 'users/register'
   dispatch({ type: POST_LIFE_SHERPA_CREATE_USER });
   lifesherpaAuth.post(url, userData)
      .then((response) => {
         let userPayload = {'user': response.data, 'userData': userData}
         if (response.status === 200) {
            console.log("%c User created","color:green")
            console.log(" Redirecting to login confirmation screen")
            console.groupEnd();
            dispatch({ type: POST_LIFE_SHERPA_SUCCESS_CREATE_USER, payload: userPayload});
            history.push('/welcome');
         } else {
            dispatch({ type: POST_LIFE_SHERPA_SUCCESS_CREATE_USER, payload: userPayload});
         }
      })
      .catch(error => {
         // error handling
         const errorData = error.response && error.response.data ? error.response.data : error;
         let userPayload = {'user': errorData, 'userData': userData}
         dispatch({ type: HIDE_FIREBASE_AUTH_LOADING});
         dispatch({ type: POST_LIFE_SHERPA_SUCCESS_CREATE_USER, payload: userPayload});
         const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
         console.log("%c Error occured while creating user","color:red", errorMsg);
      })
}

//Validate the invite code
export const validateInviteCode = (inviteCode, history) => (dispatch) => {
   let url = `users/invite-code/${inviteCode}`
   dispatch({ type: GET_VALIDATE_INVITE_CODE });
   lifesherpaAuth.get(url)
      .then((response) => {
         let tokenRepose = {'inviteCode': inviteCode, 'status': response.data.status}
         if (response.data.status === 'success') {
            dispatch({ type: GET_SUCCESS_VALIDATE_CODE, payload: tokenRepose});
            history.push('/signuplifesherpa');
         } else {
            dispatch({ type: GET_SUCCESS_VALIDATE_CODE, payload: tokenRepose});
         }
      })
      .catch(error => {
         // error handling
         let tokenRepose = {'inviteCode': inviteCode, 'status': 'failed'}
         dispatch({ type: HIDE_FIREBASE_AUTH_LOADING});
         dispatch({ type: GET_SUCCESS_VALIDATE_CODE, payload: tokenRepose});
         console.log("error occured ", error.response && error.response.data);
      })
}

//get user by id
export const getUserById = (userId, idToken) => (dispatch) => {
   let url = `/users/${userId}`;
   console.log(" Fetching user details for userId:",userId,"......")
   dispatch({ type: GET_USER_BY_ID });
   sherpaAPI.get(url, {
      headers: {
         'Authorization': `Bearer ${idToken}`
      }
      })
       .then((response) => {
          console.log("%c Fetched user details for userId:"+userId,"color:green");
         dispatch({ type: GET_SUCCESS_USER_BY_ID, payload: response.data});
         //console.log('organization--->',response.data.organization)
         localStorage.setItem("organization", response.data.organization);
         localStorage.setItem("pokeFormId", response.data.pokeFormId)
       })
       .catch(error => {
           const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
           console.log("%c Error occured while fetching details for userId:"+userId,"color:red",errorMsg);
           dispatch({ type: HIDE_FIREBASE_AUTH_LOADING});
       })
}

/**
 * Redux Action To Get User Profile Details
 */

export const getUserProfile = (user, history, idToken, refreshToken) => (dispatch) => {
   dispatch({ type: GET_USER_PROFILE });
   userProfileAPI.get(`${user.email}.json`)
       .then((response) => {
         dispatch({ type: LOGIN_USER_SUCCESS, payload: response.data.entry[0]});
         dispatch(getUserById(user.uid, idToken))
         history.push('/welcome');
         NotificationManager.success('User Login Successfully!');
       })
       .catch(error => {
           // error handling
           console.log("error occured ", error);
           dispatch({ type: LOGIN_USER_SUCCESS, payload: user });
           history.push('/welcome');
           NotificationManager.success('User Login Successfully!');
       })
}

//register user
export const authUser = (userData, history) => async(dispatch) => {
   let url = '/users/authenticate'
   dispatch({ type: POST_LIFE_SHERPA_LOGIN });
   lifesherpaAuthBaseAPI.post(url, userData)
      .then((response) => {
         let userPayload = {'user': response.data, 'userData': userData}
         if (response.status === 200) {
            localStorage.setItem('refreshToken', response.data.firebase_token)
            history.push('/loginsuccess');
            dispatch({ type: POST_LIFE_SHERPA_LOGIN_SUCCESS, payload: userPayload});
         } else {
            dispatch({ type: POST_LIFE_SHERPA_LOGIN_SUCCESS, payload: userPayload});
         }
      })
      .catch(error => {
         // error handling
         const errorData = (error.response && error.response.data) || error;
         let userPayload = {'user': errorData, 'userData': userData}
         dispatch({ type: HIDE_FIREBASE_AUTH_LOADING});
         dispatch({ type: POST_LIFE_SHERPA_LOGIN_SUCCESS, payload: userPayload});
         console.log("error occured ", errorData);
         if (errorData.message) {
            NotificationManager.error(errorData.message);
         }
      })
}

//get custom token user
export const getUserFirebaseToken = (userData, history) => async(dispatch) => {
   let url = '/auth/v3/getCustomToken'
   let orgId = localStorage.getItem("orgId")
   const {region, token, user, loginCode} = userData
   let data = {orgId, user, token, region, loginCode }
   console.log("Fetching user's firebase custom token...")
   dispatch({ type: GET_USER_FIREBASE_TOKEN });
   authAPI.post(url, data, {
      headers: {
         'Content-Type': 'application/json',
         'Authorization' : `Bearer ${token}`
         }
   })
   .then((response) => {
      console.log("%c Fetched user's firebase custom token ","color:green")
      if (response.status === 200) {
         dispatch({ type: GET_SUCCESS_USER_FIREBASE_TOKEN, payload: response.data.token});
      } else {
         dispatch({ type: GET_SUCCESS_USER_FIREBASE_TOKEN, payload: response.data});
      }
   })
   .catch(error => {
      // error handling
      const payloadData = error.response ? error.response.data : error 
      dispatch({ type: HIDE_FIREBASE_AUTH_LOADING});
      dispatch({ type: GET_SUCCESS_USER_FIREBASE_TOKEN, payload: payloadData});
      console.log("error occured ", error, payloadData);
   })
}
export const clearUserFirebaseToken = () => (dispatch) => {
   dispatch({ type: CLEAR_USER_FIREBASE_TOKEN_DATA});
}
export const getUserOrgAndRegion = (email, history) => async(dispatch) => {
   let url = '/users/search-email'
   let data = {email: email}
   dispatch({ type: GET_USER_ORGANIZATION });
   lifesherpaAuthBaseAPI.post(url, data)
      .then((response) => {
         let payloadData = response.data
         payloadData["email"] = email
         if (response.status === 200) {
            localStorage.setItem("orgId", response.data.organization)
            dispatch({ type: GET_SUCCESS_USER_ORGANIZATION, payload: payloadData});
            if(response.data.organization){
               dispatch(getPrivacyPlocy({organization: response.data.organization, region: response.data.region, email},history))
            }
         } else {
            dispatch({ type: GET_SUCCESS_USER_ORGANIZATION, payload: payloadData});
         }
      })
      .catch(error => {
         // error handling
         dispatch({ type: HIDE_FIREBASE_AUTH_LOADING});
         console.log("error occured ", error);
      })
}

//Validate the invite code
export const getUserDetailFromToken = (inviteCode, history) => (dispatch) => {
   console.log(" Authenticating token........")
   let url = `users/invite-code/${inviteCode}`
   dispatch({ type: GET_USER_ORGANIZATION_FROM_TOKEN });
   lifesherpaAuth.get(url).then((response) => {
         // console.log('response', response)
         let tokenPayloadData = response.data 
         tokenPayloadData["inviteCode"] = inviteCode
         tokenPayloadData["status"] = response.data.status
         if (response.data.status === 'success') {
            localStorage.setItem("orgId", response.data.organization)
            dispatch({ type: GET_SUCCESS_USER_ORGANIZATION_FROM_TOKEN, payload: tokenPayloadData});
            dispatch({ type: GET_SUCCESS_VALIDATE_CODE, payload: tokenPayloadData});
            console.log("%c Token is authenticated ","color:green");
            dispatch(getPrivacyPlocyFromToken({organization: response.data.organization, region: response.data.region, token: inviteCode},history))
         } else {
            console.log("%c Invalid token.  Please enter a valid token","color:red");
            dispatch({ type: GET_SUCCESS_USER_ORGANIZATION_FROM_TOKEN, payload: tokenPayloadData});
         }
      }).catch(error => {
         console.log("%c Invalid token.  Please enter a valid token","color:red");
         let tokenRepose = {'inviteCode': inviteCode, 'status': 'failed'}
         dispatch({ type: HIDE_FIREBASE_AUTH_LOADING});
         dispatch({ type: GET_SUCCESS_USER_ORGANIZATION_FROM_TOKEN, payload: tokenRepose});
      })
}
export const getLSThemeLogo = (theme) => (dispatch) => {
   let userTheme = theme.name;
   console.log(" Fetching theme data for "+theme.name+".....");
   let url = `/themes/settings?theme=${userTheme.charAt(0).toUpperCase()+userTheme.slice(1)}`;
   // console.log("theme url--->",url);
    let activeTheme = {id:1, name:"primary"};
    let defaultThemeClasses = {screenThemeClass : 'rct-session-wrapper-ls' ,popupThemeClass : 'activity-template-ls-default',themeLogo:null, activeTheme};
    if(process.env.REACT_APP === 'DES') {
      activeTheme =  {id:5, name:"danger"};
      defaultThemeClasses = {screenThemeClass : 'rct-session-wrapper' ,popupThemeClass : 'activity-template-aus-default',themeLogo:null, activeTheme}; 
    }

   dispatch({type: GET_THEME_LS_LOGO});
   localStorage.removeItem("loginLogoImage");
   lifesherpaAuth.get(url,{
      }).then((response) => {
         if (response.status === 200) {
             //console.log("theme settings data 200 --> ",response.data)
            if(response.data && response.data.primaryButtonNormalStateColor) {
               let themeObject = response.data;
               Object.keys(themeObject).map(themeAttribute=> {
                  document.body.style.setProperty(`--${themeAttribute}`,themeObject[themeAttribute])
               });
               dispatch({type: GET_THEME_LS_LOGO_SUCCESS,payload:response.data});
                  localStorage.setItem("loginLogoImage",response.data.loginLogoImage);
                  localStorage.setItem("loginLifeSherpaImage",response.data.loginLifeSherpaImage);
                  localStorage.setItem("loginScreenFillPaternImage",response.data.loginScreenFillPaternImage);
                  let themeInfo = {id: 1, name:userTheme}
                  let themeClasses  = null
                  if (process.env.REACT_APP === 'DES'){
                     themeClasses = {screenThemeClass:"session-wrapper-theme-support-des", popupThemeClass:"activity-template-theme-support",themeLogo:response.data.loginLogoImage, activeTheme: themeInfo}
                  }else if(process.env.REACT_APP === 'ObjectEditor') {
                     themeClasses = {screenThemeClass:"rct-session-wrapper-theme-support", popupThemeClass:"activity-template-theme-support",themeLogo:response.data.loginLogoImage, activeTheme: themeInfo}
                  } else {
                     themeClasses = {screenThemeClass:"session-wrapper-theme-support", popupThemeClass:"activity-template-theme-support",themeLogo:response.data.loginLogoImage, activeTheme: themeInfo}
                  }
                  console.log("%c Fetched theme data for "+theme.name,"color:green");
                  let consoleLogsTable = [];
                  Object.keys(themeObject).map(themeAttribute=> {
                     let obj = {key : themeAttribute};
                     if(themeObject[themeAttribute].charAt(0) != "#") {
                        obj.value =themeObject[themeAttribute];
                     } else {
                        obj.value = themeObject[themeAttribute];
                     }
                     consoleLogsTable.push(obj);
                  });
                  console.table(consoleLogsTable)

                  dispatch({type: SET_DYNAMIC_THEME_CLASS,payload:themeClasses})
            }
            else {
               console.log("%c Error occured while fetching theme data for "+theme.name,"color:red");
               let themeData = themeMock["Default"];
               Object.keys(themeData).map(themeAttribute=> {
                   document.body.style.setProperty(`--${themeAttribute}`,themeData[themeAttribute])
               });
               localStorage.setItem("theme",JSON.stringify(activeTheme));
               localStorage.removeItem("activeTheme");
               localStorage.removeItem("loginLogoImage");
               dispatch({type: GET_THEME_LS_LOGO_SUCCESS,payload:themeData})
               dispatch({type: SET_DYNAMIC_THEME_CLASS,payload:defaultThemeClasses})
            }
         } 
      }).catch((error) => {
         const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
         console.log("%c Error occured while fetching theme data for "+theme.name,"color:red",errorMsg);
        localStorage.setItem("theme",JSON.stringify(activeTheme));
        localStorage.removeItem("activeTheme");
        localStorage.removeItem("loginLogoImage");
        // localStorage.removeItem(response.data);
         let themeData = themeMock["Default"];
         Object.keys(themeData).map(themeAttribute=> {
            document.body.style.setProperty(`--${themeAttribute}`,themeData[themeAttribute])
         });
        dispatch({type: GET_THEME_LS_LOGO_SUCCESS,payload:themeData})
        dispatch({type: GET_THEME_LS_LOGO_FAILED})
        dispatch({type: SET_DYNAMIC_THEME_CLASS,payload:defaultThemeClasses})
       // const errorData = error.response ? { message: error.response.data.message || error.response.data.messsage, title: 'Warning' } : { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" };
      //  dispatch({ type: HIDE_LOADING_INDICATOR_ACTIVITIES_LIST,payload: errorData });
       // dispatch({ type: CHECK_FAILURE_DUPLICATE_ACTITY_TITLE, payload: errorData })
       // dispatch({ type: HIDE_PRIVATE_ACTIVITY_LOADING });
     })
}
export const clearCustomTokenData = () => (dispatch) => {
   localStorage.removeItem("region")
   localStorage.removeItem("connection")
   localStorage.removeItem("loginMode")
   dispatch({type: CLEAR_FIREBASE_CUSTOM_DATA})
}
export const resetReduxData =()=>(dispatch)=>{
   dispatch({ type: RESET_REDUX_DATA });
}
export const selectConnection =(selectedConnection, params, history)=>(dispatch)=>{
   dispatch({ type: SELECTED_IDP_TO_REGISTER, payload: selectedConnection });
   if (process.env.REACT_APP === 'DES') {
      console.log(" Redirecting to accept privacy policy screen")
      window.location.href = `/acceptpolicy?organization=${params.organization}&region=${params.region}&token=${params.token}&enterpriseConnection=${selectedConnection.type}&mode=${params.mode}`
   } else {
      console.log(" Redirecting to signuplifesherpa screen")
      if(selectedConnection.type === 'database'){
         history.push(`/signuplifesherpa`);
      } else if (params.organization && params.region && params.token){
         window.location.href = `/auth0login?organization=${params.organization}&region=${params.region}&token=${params.token}&mode=${params.mode}`
      }
   }
}
