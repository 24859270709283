import React, { useRef, useState, useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert'
import { Label } from 'reactstrap'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Dialog,
    Link,
    Typography,
    DialogContent,
    DialogTitle
} from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import Bowser from 'bowser';
import VideocamOffRoundedIcon from '@mui/icons-material/VideocamOffRounded';

import {
    MediaPermissionsErrorType,
    requestMediaPermissions,
} from 'mic-check';

const browser = Bowser.getParser(window.navigator.userAgent);

const DialogType = {
    explanation: 'explanation',

    systemDenied: 'systemDenied',
    userDenied: 'userDenied',
    trackError: 'trackError'
}


const DialogPopup = ({ title, children, className, handleClose }) => {
    return (
        <Dialog
            fullScreen={false}
            open={true}
            onClose={handleClose}
            fullWidth={true}
            maxWidth="sm"
            aria-labelledby="responsive-dialog-title"
            className={className}
        >
            {title &&
                <DialogTitle className="m-0 p-0">
                    <div className="d-flex align-items-center template-header justify-content-between p-3">
                        <Label data-testid="scheduleHeading" className="listItemTitle mb-0">
                            {title}
                        </Label>
                        <div data-testid="backButton" className="backbutton" onClick={handleClose}><CloseRoundedIcon /></div>
                    </div>
                </DialogTitle>
            }
            <DialogContent className="p-0">
                {children}
            </DialogContent>
        </Dialog>
    )

}

const MediaOnboardingDialog = ({ media, popupThemeClass }) => {
    const [showDialog, setShowDialog] = useState(null);

    const [audioAllowed, setAudioAllowed] = useState(false);
    const [videoAllowed, setVideoAllowed] = useState(false);
    const [mediaResponse, setMediaResponse] = useState(false);

    const [errorDetails, setErrorDetails] = useState();

    useEffect(() => {
        checkMediaPermissions();
    }, []);

    const checkForExplanationDialog = () => {
		if (!mediaResponse && !errorDetails) {
            setShowDialog(DialogType.explanation);
        }	
	};

   
    const checkMediaPermissions = () => {
        // TODO: listen to if there is a change on the audio/video piece?

        requestMediaPermissions(media)
            .then((value) => {
                console.log('Media sucess: ', value);
                if(media.audio) {
                    setAudioAllowed(true);
                } 
                if(media.video) {
                    setVideoAllowed(true);
                }
                setMediaResponse(value)

                setShowDialog(null);
            })
            .catch((error) => {
                console.log('MediaOnboardingDialog: ', error);
                if (
                    error.type ===
                    MediaPermissionsErrorType.SystemPermissionDenied
                ) {
                    // user denied permission
                    setShowDialog(DialogType.systemDenied);
                } else if (
                    error.type ===
                    MediaPermissionsErrorType.UserPermissionDenied
                ) {
                    // browser doesn't have access to devices
                    setShowDialog(DialogType.userDenied);
                } else if (
                    error.type ===
                    MediaPermissionsErrorType.CouldNotStartVideoSource
                ) {
                    // most likely when other apps or tabs are using the cam/mic (mostly windows)
                    setShowDialog(DialogType.trackError);
                } else {
                }
                setErrorDetails(error);
            });
            // setTimeout(() => {
            //     checkForExplanationDialog();
            // }, 500);
    };



    const _renderTryAgain = (text) => {
        return (
            <div style={{ width: '100%', marginTop: 20 }}>
                <Button
                    onClick={() => {
                        if (browser.getBrowserName() === 'Safari') {
                            // If on Safari, rechecking permissions results in glitches so just refresh the page
                            window.location.reload();
                        } else {
                            checkMediaPermissions();
                        }
                    }}
                    color="primary"
                    style={{ float: 'right' }}
                >
                    {text ? text : 'Retry'}
                </Button>
            </div>
        );
    };

    const _renderErrorMessage = () => {
        if (!errorDetails) return null;
        return (
            <div style={{ marginTop: 10 }}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreRoundedIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="caption" style={{ color: 'red' }}>
                            Error Details
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="caption">
                            {errorDetails.name}: {errorDetails.message}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    };

    const _renderExplanationDialog = () => {
        return (
            <SweetAlert
                warning
                btnSize="sm"
                customClass="warningText"
                showConfirm={true}
                confirmBtnText="OK"
                confirmBtnBsStyle="warning"
                onConfirm={() => setShowDialog(null)}
                show={showDialog === DialogType.explanation}
                title={`Allow App to use your ${(media.audio && media.video && 'camera and microphone') || (media.video && 'camera') || (media.audio && 'microphone')}!`}
            >
                <div className="sweet-alert-text">
                    App needs access to your {(media.audio && media.video && 'camera and microphone') || (media.video && 'camera') || (media.audio && 'microphone')} so that other
                    participants can see and hear you.
                </div>
            </SweetAlert>
        );
    };

    const _renderUserDeniedDialog = () => {
        return (
            <DialogPopup
                title={`${(media.audio && media.video && 'Your camera and microphone are') || (media.video && 'Your camera is') || (media.audio && 'Your microphone is')} blocked`}
                className={popupThemeClass}
                handleClose={() => setShowDialog(null)}
            >
                <div className='p-2'>
                    <div className="container">
                        <p>
                            App requires access to your {(media.audio && media.video && 'camera and microphone') || (media.video && 'camera') || (media.audio && 'microphone')}.{' '}
                            {browser.getBrowserName() === 'Safari' ? (
                                <span>Choose "Safari" > "Settings for this Website" > Select "Allow" for "Camera"</span>
                            ) : browser.getBrowserName() === 'Firefox' ? (
                                <span>
                                    Click the camera blocked icon{' '}
                                    <VideocamOffRoundedIcon style={{marginBottom: -6, position: "relative"}}/> {' '}
                                    in your browser's address bar and unblock the camera.
                                </span>
                            ) :  (
                                <span>
                                    Click the camera blocked icon{' '}
                                    <img
                                        alt="icon"
                                        src={'https://www.gstatic.com/meet/ic_blocked_camera_dark_f401bc8ec538ede48315b75286c1511b.svg'}
                                        style={{ display: 'inline' }}
                                    />{' '}
                                    in your browser's address bar and unblock the camera. 
                                </span>
                                )
                            }
                        </p>
                        {/* <div className="my-1">
                            {_renderErrorMessage()}
                        </div> */}
                    </div>
                </div>
            </DialogPopup >
        );
    };

    const _renderSystemDeniedDialog = () => {
        const settingsDataByOS = {
            macOS: {
                name: 'System Preferences',
                link: 'x-apple.systempreferences:com.apple.preference.security?Privacy_Camera',
            },
        };
        console.log("browser.getOSName() :", browser.getOSName());
        return (
            <DialogPopup
                title={`Can't use your ${(media.audio && media.video && 'camera or microphone') || (media.video && 'camera') || (media.audio && 'microphone')}`}
                className={popupThemeClass}
                handleClose={() => setShowDialog(null)}
            >
                <div className='p-2'>
                    <div className="container">
                        <p>
                            Your browser might not have access to your {(media.audio && media.video && 'camera or microphone') || (media.video && 'camera') || (media.audio && 'microphone')}.
                            To fix this problem, open{' '}
                            {
                                // @ts-ignore
                                settingsDataByOS[browser.getOSName()] ? (
                                    <Link
                                        onClick={() => {
                                            window.open(
                                                // @ts-ignore
                                                settingsDataByOS[browser.getOSName()]
                                                    .link,
                                                '_blank',
                                            );
                                        }}
                                        style={{color:"red"}}
                                    >
                                        {
                                            // @ts-ignore
                                            settingsDataByOS[browser.getOSName()].name
                                        }
                                    </Link>
                                ) : (
                                    'Settings'
                                )
                            }
                            .
                        </p>
                        <div className="my-1">
                            {_renderErrorMessage()}
                        </div>
                    </div>
                </div>
            </DialogPopup >
        )
    };

    const _renderTrackErrorDialog = () => {
        return (
            <DialogPopup
                title={`Can't start your ${(media.audio && media.video && 'camera or microphone') || (media.video && 'camera') || (media.audio && 'microphone')}`}
                className={popupThemeClass}
                handleClose={() => setShowDialog(null)}
            >
                <div className='p-2'>
                    <div className="container">
                        <p>
                            Another application (Zoom, Webex) or browser tab (Google
                            Meet, Messenger Video) might already be using your webcam.
                            Please turn off other cameras before proceeding.
                        </p>
                        <div className="my-1">
                            {_renderErrorMessage()}
                        </div>
                    </div>
                </div>
            </DialogPopup >
        )
    };
    return (
        <>
            {showDialog === DialogType.explanation ?
                _renderExplanationDialog()
                : showDialog === DialogType.userDenied ?
                    _renderUserDeniedDialog()
                    : showDialog === DialogType.systemDenied ?
                        _renderSystemDeniedDialog()
                        : showDialog === DialogType.trackError ?
                            _renderTrackErrorDialog()
                            : ""
            }
        </>
    );
};

export default MediaOnboardingDialog;