/**
 * Sign Up With Firebase
 */
import React, { Component } from 'react';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input, FormFeedback, Label } from 'reactstrap';
import CircularProgress from '@mui/material/CircularProgress';
import queryString from 'query-string';
import EmailVerification from '../EmailComponent/EmailVerification'
import config from '../../config/config'

import QueueAnim from 'rc-queue-anim';

// app config
import AppConfig from 'Constants/AppConfig';

// redux action
import {
   resetPassword,
} from 'Actions';

class ResetPassword extends Component {

   state = {
      
      password: '',
      verifyPassword: '',
      passwordEmpty: null,
      verifyPasswordEmpty: null,
      invalidPassword: null,
   }
  
   handleClose () {
      this.setState({open: !this.state.open})
    };

    //validating the password
   validatePassword(password) {
      let valid = password.length > 5 ? true : false
      return valid;
    }

	/**
	 * On User Signup
	 */
   onSubmit(e) {
      e.preventDefault();
      const { password, verifyPassword, passwordEmpty, verifyPasswordEmpty, invalidPassword } = this.state;
      let params = queryString.parse(this.props.location.search)
      let userData = {
         'newPassword': password,
         'code': params.oobCode
      }
   
      if (password === ''){
         this.setState({passwordEmpty: true})
      } else if (!this.validatePassword(password)){
         this.setState({invalidPassword: true})
      } else if (verifyPassword === ''){
         this.setState({verifyPasswordEmpty: true})
      } else if (verifyPassword !== password){

      } else {
         this.props.resetPassword(userData, this.props.history);
      }
      
   }

   render() {
      const { password, verifyPassword, passwordEmpty, verifyPasswordEmpty, invalidPassword, open, anchorEl } = this.state;
      const { loading } = this.props;
      const showServerError = this.props.resetPasswordStatus === "failed" && this.props.newPassword === password
      let params = queryString.parse(this.props.location.search)
      if (params && params.mode && params.mode === 'resetPassword') {
         return(
            <QueueAnim type="bottom" duration={2000}>
               <div>
               {
                  loading &&
                     <div className="d-flex justify-content-center loader-overlay">
                        <CircularProgress className="theame-text" disableShrink/>
                     </div>
               }
               </div>
               <div className={config.rctSessionWrapper}>
                  <AppBar position="static" className="session-header" style={{height: 100}}>
                     <Toolbar style={{alignItems: 'flex-end'}}>
                        {process.env.REACT_APP === 'DES' ?
                           <div className="container">
                              <div className="d-flex align-items-center justify-content-center mb-2">
                                 <p className="mb-0" style={{color: 'gray', marginRight: 10}}>Powered by LifeSherpa</p>
                                 <div className="session-logo">
                                    <Link to="/">
                                       <img src={require("Assets/img/lifesherpa-Icon.png")} alt="session-logo" className="img-fluid" width="30" height="30" />
                                    </Link>
                                 </div>
                              </div>
                           </div>
                           :
                           <div className="container text-center">
                              <img src={require("Assets/img/life-sherpa-logo-retina.png")} alt="session-logo" className="img-fluid" width="250" height="58" />
                           </div>
                        }
                     </Toolbar>
                  </AppBar>
                  <div className="session-inner-wrapper">
                     <div className="container">
                        <div className="row row-eq-height">
                           <div className="col-sm-12 col-md-3 col-lg-3"></div>
                           <div className="col-sm-12 col-md-6 col-lg-6">
                              {<div className={`session-body text-center ${config.headerTextcolor}`}>
                                 <div className="session-head mb-15">
                                    <h2>Enter your new password below:</h2>
                                 </div>
                                 <Form onKeyPress={e => {
                                    if (e.key === 'Enter')  this.onSubmit(e);
                                  }}>
                                    <FormGroup className="has-wrapper">
                                       <Input
                                          value={password}
                                          autofocus={false}
                                          type="Password"
                                          name="resetpassword"
                                          id="resetpassword"
                                          autofocus
                                          autoCapitalize="none"
                                          className="has-input input-lg text-center"
                                          placeholder="New Password"
                                          invalid={passwordEmpty ? true : false}
                                          onChange={(e) => this.setState({ password: e.target.value, invalidPassword: !this.validatePassword(e.target.value), passwordEmpty: e.target.value !== '' ? false : true})}
                                       />
                                       {passwordEmpty ? 
                                          <FormFeedback style={{fontSize: 'larger'}}>Please enter a valid password of a minimum length of 6 characters.</FormFeedback>
                                          : invalidPassword ?
                                          <FormFeedback className="d-block" style={{fontSize: 'larger'}}>Please enter a valid password of a minimum length of 6 characters.</FormFeedback>
                                          : showServerError && this.props.resetPassResponseData &&
                                          <FormFeedback className="d-block" style={{fontSize: 'larger'}}>{this.props.resetPassResponseData.message}</FormFeedback>
                                       }
                                    </FormGroup>
                                    <FormGroup className="has-wrapper">
                                       <Input
                                          value={verifyPassword}
                                          type="Password"
                                          name="verifyresetpassword"
                                          id="verifyresetpassword"
                                          autoCapitalize="none"
                                          key="verifyresetpassword"
                                          className="has-input input-lg text-center"
                                          placeholder="Confirm Password"
                                          invalid={(verifyPasswordEmpty ? true : false || verifyPassword ? true : false) && password && (verifyPassword !== password) ? true : false }
                                          valid={verifyPassword && password && (verifyPassword === password) ? true : false}
                                          onChange={(e) => this.setState({ verifyPassword: e.target.value, verifyPasswordEmpty: e.target.value !== '' ? false : true })}
                                       />
                                       {verifyPasswordEmpty ? 
                                          <FormFeedback style={{fontSize: 'larger'}}>Confirm password should not be empty </FormFeedback>
                                          : verifyPassword && password && verifyPassword !== password ?
                                          <FormFeedback style={{fontSize: 'larger'}}>Passwords do not match </FormFeedback>
                                          : verifyPassword && password && verifyPassword === password &&
                                          <FormFeedback valid style={{fontSize: 'larger'}}>Passwords match </FormFeedback>
                                       }
                                    </FormGroup>
                                    <FormGroup className="has-wrapper">
                                       <Label>The password should be a minimum of 6 characters in length, containing at least one uppercase letter, one lowercase letter, and one number.</Label>
                                    </FormGroup>
                                    <FormGroup className="mb-15">
                                       <Button
                                          className="btn-block text-white btn-block w-100"
                                          variant="contained"
                                          size="large"
                                          onClick={(e) => this.onSubmit(e)}>
                                          Submit
                                        </Button>
                                    </FormGroup>
                                 </Form>
                              </div>}
                           </div>
                           <div className="col-sm-12 col-md-3 col-lg-3"></div>
                        </div>
                     </div>
                  </div>
               </div>
            </QueueAnim>
         )
      } else if (params && params.mode && params.mode === 'verifyEmail') {
         return (
            <EmailVerification params={params} history={this.props.history}/>
         );
      } else {
         return (
            <></>
         );
      }
      
   }
}

// map state to props
const mapStateToProps = ({ authUser }) => {
   const { loading, inviteCode, user, userData, resetPasswordStatus, resetPassResponseData } = authUser;
   const newPassword = authUser.resetPassword
   return { loading, inviteCode, user, userData, newPassword, resetPasswordStatus, resetPassResponseData };
};

export default connect(mapStateToProps, {
   resetPassword,
})(ResetPassword);
