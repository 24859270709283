/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import QueueAnim from 'rc-queue-anim';
import { connect } from 'react-redux';
import {FormGroup, FormFeedback, Label} from 'reactstrap'
import CalendarTodayRounded from '@mui/icons-material/CalendarTodayRounded'
import AccessTimeRounded from '@mui/icons-material/AccessTimeRounded'
import RepeatRounded from '@mui/icons-material/RepeatRounded'
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import RctCollapsibleCard from '../RctCollapsibleCard/RctCollapsibleCard'
import moment from 'moment' 
import queryString from 'query-string';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Badge from '@mui/material/Badge'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DragIndicatorRoundedIcon from '@mui/icons-material/DragIndicatorRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SweetAlert from 'react-bootstrap-sweetalert'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import ScheduleUpdate from '../ActivitySchedule/ScheduleUpdate'
import Header from '../Header/Header'
import ActivityTemplate from '../ActivityTemplate/ActivityTemplate'
import IntlMessages from "../../util/IntlMessages"
import WarningDialog from '../../components/Dialog/WarningDialog'
import UserExclusiveWarning from "../../components/Dialog/UserExclusiveWarning"
// import TextField from '@mui/material/TextField';
import MatButton from '@mui/material/Button';

import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import PictureOptions from '../SherpaCamera/components/PictureOptions';
import SherpaCamera from "../SherpaCamera"

import LSInput from '../CustomComponent/LSInput';
import LSTextArea from "../CustomComponent/LSTextArea"

import { 
    generateRRule, 
    getDayOccurences, 
    refreshCurrentSchedule,
    handleCustomSchedule,
    getweekDayWithCheck,
    getISODateString,
    getCookies,
    getCustomeRecurrenceFromRrule,
    isMobileDevice,
    getBase64FromUrl,
    dataURItoBlob
    // ellipsify,
} from '../../helpers/helpers';

import {
    addPrivateActivity,
    updatePrivateActivity,
    getPrivateActivityById,
    checkDuplicateActivity,
    clearActivityData,
    checkDuplicateTitleAndUpdate,
    removeAPIErrorMessage,
    showActivitySuccess,
    unSubscribeActivity,
} from '../../actions/ActivityActions'

import {
    getActivityTemplates,
    getActivityGroups,
    selectActivityTemplate,
    filterActivityTemplate, 
} from '../../actions/ActivityTemplateActions'

import {getUserById} from "../../actions/AuthActions"

import PhotoLibrary from '../SherpaCamera/components/PhotoLibrary';
import imageCompression from 'browser-image-compression';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((item,index) => {
        item.position = index
    })
	return result;
};
// const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
	...draggableStyle,
});
const getListStyle = isDraggingOver => ({

});

class NewActivity extends Component {

    state = {
        idToken: "",
        userId: 'ODWYDyio2RhrEHJGq6fDXrSCpeI2',
        orgId: 'Test',
        activityId: '',
        select: 'options',
        scope: null,
        activeIndex: 0,
        activityTitle: '',
        activityDesc: '',
        stepTitle: '',
        stepDesc: '',
        currentSchedule: {
            startDate: moment().format('MMM DD YYYY'),
            startTime: moment().format('hh:mm A'),
            endTime: moment().format('hh:mm A'),
            text: "Daily",
            recurrenceMethod: "",
            rrule: "",
            objStartDate: moment(),
            objStartTime: moment(),
            objEndTime: moment().add(5, 'minutes'),
            nextSchedules: [],
            notify:true
        },
        openSchedule: false,
        schedules: [],
        steps: [],
        editStep: false,
        editableKey: 0,
        anchorEl: null,
        isTitleFilled: true,
        editSchedule: false,
        editScheduleKey: 0,
        customEnabled: false,
        weekSelected: false,
        monthSelected: false,
        customRepeatValue: 'Day',
        customCount: 1,
        monthlyOccurenceDay: '',
        customUntill: moment().add(1, 'd').format('MMM DD YYYY'),
        customInterval: 1,
        weekDayWithCheck: [
            {
                day: 'MO',
                checked: true,
                name: 'MON'
            },
            {
                day: 'TU',
                checked: false,
                name: 'TUE'
            },
            {
                day: 'WE',
                checked: false,
                name: 'WED'
            },
            {
                day: 'TH',
                checked: false,
                name: 'THU'
            },
            {
                day: 'FR',
                checked: false,
                name: 'FRI'
            },
            {
                day: 'SA',
                checked: false,
                name: 'SAT'
            },
            {
                day: 'SU',
                checked: false,
                name: 'SUN'
            },
        ],
        repeatType: [
            {
                type: 'Never',
                selected: true,
                value: null
            },
            {
                type: 'After',
                selected: false,
                value: null
            },
            {
                type: 'On',
                selected: false,
                value: null
            }
        ],
        stepTitleEmpty: false,
        invalidEndTime: false,
        propsUpdated: false,
        minCharLength: true,
        openActivityTemplate: false,
        textAreaField: React.createRef(),
        isDataSaved: true,
        showUnSavedWarning: false,
        showUserExclusiveWarning: false,
        repeatError: '',
        customCountError: '',
        repeatValue: 'Daily',
        activityType: "Routine",
        openCameraMenu: false,
        openScheduleMenu: false,
        openCamera: false,
        openPhotoLib: false,
        inputRef: React.createRef(),
        activityBgImageUrl: null,
        activityStepImageUrl: null,
        showSkipButton: false,
        showStartButton: true,
        activityEnabled: true,
        autoTTSEnabled: false,
        pickerType: "activity",
        createdFrom: [],
        initialEdit: true
    }

    onDragEnd(result) {
        // dropped outside the list
        let steps = this.state.steps
        const {stepTitle, editStep} =  this.state
		if (!result.destination) {
			return;
        }
		const updatedSteps = reorder(
			steps,
			result.source.index,
			result.destination.index
        );
        if(editStep){
            const index = this.getElemntIndex(updatedSteps, stepTitle)
            this.setState({steps: updatedSteps, editableKey: index});
        } else {
            this.setState({steps: updatedSteps, isDataSaved: false});
        }
    }

    getElemntIndex(array, element){
        let index = 0
        array.map((item, key)=>{
            if (item.title === element){
                index = key
            }
        })
        return index
    }
    
    componentDidMount(){
        let idToken = this.state.idToken
        let userId = this.state.userId
        let orgId = this.state.orgId
        let activityId = this.state.activityId
        let scope =  this.state.scope
        const params = this.getParams();
        console.group("%c[NewActivity]%c Rendered activity editor app screen","color:black","color:green");
        if (params && params.userId) {
            // let params = queryString.parse(this.props.location.search)
            this.props.clearActivityData()
            idToken = params.idToken
            userId = params.userId
            orgId = params.orgId
            activityId = params.activityId
            scope = params.scope
            if(activityId) {
                this.props.getUserById(userId, idToken);
                this.props.getPrivateActivityById(userId, orgId,  activityId, idToken, scope)
            }
            this.props.getActivityTemplates(userId, orgId, idToken, scope)
            this.props.getActivityGroups(userId, orgId, idToken, scope)
        } 
        // console.log("params : ", params);
        // if(params && params.theme) {
        //     userId = 'xqekAeFd3qgpZnuxeilfQfiw7QT2'
        //     idToken = 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImE4YmZhNzU2NDk4ZmRjNTZlNmVmODQ4YWY5NTI5ZThiZWZkZDM3NDUiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiIiwiRGVsZXRlSW1hZ2VzIjp0cnVlLCJvcmdhbml6YXRpb24iOiJUZXN0IiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL2xzLWNvbmZpZy1taWdyYXRpb24iLCJhdWQiOiJscy1jb25maWctbWlncmF0aW9uIiwiYXV0aF90aW1lIjoxNjYxNDUxMDE5LCJ1c2VyX2lkIjoieHFla0FlRmQzcWdwWm51eGVpbGZRZml3N1FUMiIsInN1YiI6InhxZWtBZUZkM3FncFpudXhlaWxmUWZpdzdRVDIiLCJpYXQiOjE2NjE0NTEwMTksImV4cCI6MTY2MTQ1NDYxOSwiZW1haWwiOiJsaWZlc2hlcnBhK2F1dGgwfDVmY2UwZjNjNWIzMjNmMDA2ZmE4NDY0MUAzcmJlaGF2aW9yYWxzb2x1dGlvbnMuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImxpZmVzaGVycGErYXV0aDB8NWZjZTBmM2M1YjMyM2YwMDZmYTg0NjQxQDNyYmVoYXZpb3JhbHNvbHV0aW9ucy5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJjdXN0b20ifX0.aI1kruRWKwPXzNhZl6lQ233ainYupSemGy5k8d1MyfJEtzl1yCOo61OsKAgDPM7wmh3wz_fpHBxjN5UrO8TWztrbbOzTvlzloJdYTSsZnv6rTMij3QuS5LLVbH4DOKBIBnBk8KrXakvKxG7yfspoCXbapJCuROkEU6dTGCJrHmgQQUej8fUue6-rWcRe-9NtrfGZnqGyWRiEiCRTlzshECt7SHkYz-qe1I2L4hLQAs1AyJv7kxMpTH3oMXHTNAoMQ2zTUi6y92nhQrlk9wMn-0qy7Au9bMoAGcz0AWCA_GVGaLPvoCPhr1lbFGrf6LC4S-9h-Decl6QuCb8OykQUBQ'
        //     orgId = 'Test';
        //     activityId = "-Mv3ObXp5FoqoGVXntOp";
        //     if(activityId) {
        //         this.props.getPrivateActivityById(userId, orgId,  activityId, idToken)
        //     }
        //     this.props.getActivityTemplates(userId, orgId, idToken)
        //     this.props.getActivityGroups(userId, orgId, idToken)
        // }
        

        this.setState({idToken, userId, orgId, activityId, scope, currentSchedule: refreshCurrentSchedule()})
    }

    getParams(){
        let params = null
        if (this.props.location.search) {
            params = queryString.parse(this.props.location.search)
        } else if (getCookies('objCookieData')) {
            params = JSON.parse(getCookies('objCookieData'))
        } 
        // console.log('params-->', params)
        return params
    }

    componentDidUpdate() {
        if (this.props.activity && !this.state.propsUpdated) {
            this.addActivityToState(this.props.activity, !this.state.propsUpdated)
        }
    }

    addActivityToState(activity, propsUpdated){
        const activityTitle = activity.Routine.title
        const activityDesc = activity.Routine.detailedDescription;
        const activityType = activity.Routine.type ||  'Routine';
        const activityBgImageUrl = activity.Routine.backgroundImageUrl || null
        const showSkipButton = activity.Routine.showSkipButton || false
        const showStartButton = activity.Routine.showStartButton || false
        const activityEnabled = !activity.Routine.inactive || true
        const autoTTSEnabled = activity.Routine.autoTTSEnabled || false
        let steps = []
        let schedules = []
        let showUserExclusiveWarning  = activity.members > 1 ? true : false

        let createdFrom = this.props.activity.createdFrom || []
        const activityId = this.props.activity.activityId
        const previousActivityData = {
            detailedDescription: activityDesc, 
            id: activityId, 
            organization: this.state.orgId,
            timestamp: new Date().toISOString(),
            title: activityTitle
        }
        createdFrom.push(previousActivityData)

        if (activity.Routine.newSchedule) {
            Object.keys(activity.Routine.newSchedule).map((scheduleKey, index) => {
                const activitySchedule = activity.Routine.newSchedule[scheduleKey]
                const schedule = {
                    startDate: activitySchedule.startDate,
                    startTime: moment(activitySchedule.startTime, 'HH:mm').format('hh:mm A'),
                    endTime: moment(activitySchedule.endTime, 'HH:mm').format('hh:mm A'),
                    text: activitySchedule.text,
                    recurrenceMethod: activitySchedule.recurrenceMethod,
                    rrule: activitySchedule.rrule,
                    objStartDate: moment(getISODateString(activitySchedule.startDate)),
                    objStartTime: moment(activitySchedule.startTime, 'HH:mm'),
                    objEndTime: moment(activitySchedule.endTime, 'HH:mm'),
                    scheduleId: scheduleKey,
                    notify: activitySchedule.notify === false ? activitySchedule.notify : true
                }
                schedules.push(schedule)
            })
        }
        if (activity.Steps) {
            Object.keys(activity.Steps).map((stepKey, index) => {
                const activityStep = activity.Steps[stepKey]
                const step = {
                    ...activityStep,
                    title: activityStep.title,
                    description: activityStep.description ? activityStep.description : '',
                    imageURL: activityStep.imageURL ? activityStep.imageURL : null,
                    position: activityStep.position ? activityStep.position : index,
                    stepId: stepKey
                }
                //remove imageURL for version 1
                if (!this.state.scope && !this.props.version){
                    delete step.imageURL;
                }
                steps.push(step)
            })
        }
        this.setState({createdFrom, showUserExclusiveWarning, autoTTSEnabled, activityBgImageUrl, showSkipButton, showStartButton, activityEnabled, activityTitle, activityDesc, steps, schedules, propsUpdated, openActivityTemplate: false, isDataSaved: true, activityType})
    }

    addActivityTemlateToState(activity){
        const activityTitle = activity.Routine.title
        const activityDesc = activity.Routine.detailedDescription;
        const activityType = activity.Routine.type || "Routine";
        const activityBgImageUrl = activity.Routine.backgroundImageUrl || null
        const showSkipButton = activity.Routine.showSkipButton || false
        const showStartButton = activity.Routine.showStartButton || false
        const autoTTSEnabled = activity.Routine.autoTTSEnabled || false
        let steps = []
        let schedules = []
        if (activity.Routine.newSchedule) {
            Object.keys(activity.Routine.newSchedule).map((scheduleKey, index) => {
                const activitySchedule = activity.Routine.newSchedule[scheduleKey]
                const schedule = {
                    startDate: activitySchedule.startDate,
                    startTime: moment(activitySchedule.startTime, 'HH:mm').format('hh:mm A'),
                    endTime: moment(activitySchedule.endTime, 'HH:mm').format('hh:mm A'),
                    text: activitySchedule.text,
                    recurrenceMethod: activitySchedule.recurrenceMethod,
                    rrule: activitySchedule.rrule,
                    objStartDate: moment(getISODateString(activitySchedule.startDate)),
                    objStartTime: moment(activitySchedule.startTime, 'HH:mm'),
                    objEndTime: moment(activitySchedule.endTime, 'HH:mm'),
                    notify: activitySchedule.notify === false ? activitySchedule.notify : true
                }
                schedules.push(schedule)
            })
        }
        if (activity.Steps) {
            Object.keys(activity.Steps).map((stepKey, index) => {
                const activityStep = activity.Steps[stepKey]
                const step = {
                    title: activityStep.title,
                    description: activityStep.description ? activityStep.description : '',
                    imageURL: activityStep.imageURL ? activityStep.imageURL : null,
                    position: activityStep.position ? activityStep.position : index,
                }
                //remove imageURL for version 1
                if (!this.state.scope && !this.props.version){
                    delete step.imageURL;
                }
                steps.push(step)
            })
        }
        this.setState({autoTTSEnabled, activityBgImageUrl, showSkipButton, showStartButton, activityTitle, activityDesc, steps, schedules, openActivityTemplate: false, editStep: false, editableKey: -1, isDataSaved: false, activityType})
    }

    handleChangeIndex(index) {
        if (this.state.activityTitle && this.state.activityTitle !== '') {
            this.setState({ activeIndex: index, isTitleFilled: true, isDataSaved: false });
        } else {
            this.setState({isTitleFilled: false, isDataSaved: false})
        }
     }
  
     handleChange(event, value) {
        if (this.state.activityTitle && this.state.activityTitle !== '') {
            this.setState({ activeIndex: value, isTitleFilled: true, isDataSaved: false });
        } else {
            this.setState({isTitleFilled: false, isDataSaved: false})
        }
     }

     handleOpenSchedule(){
        let {weekDayWithCheck, currentSchedule, editSchedule} = this.state;
        weekDayWithCheck=getweekDayWithCheck()
        if (!this.openSchedule) {
            currentSchedule = refreshCurrentSchedule()
            editSchedule = false;
            currentSchedule.notify = true;
        } 
        this.setState({ openSchedule: !this.state.openSchedule, isTitleFilled: true,weekDayWithCheck,customEnabled: false,currentSchedule, repeatError: '', customCountError: '', customInterval: 1, customCount: 1, editSchedule });
     }

     handleSaveSchedule(){
         let schedules = this.state.schedules
         let currentSchedule = this.state.currentSchedule
         if (currentSchedule.objEndTime && currentSchedule.objEndTime.diff(currentSchedule.objStartTime, 'minutes') < 5) {
            this.setState({invalidEndTime:true})
         } else if(this.state.weekDayWithCheck.filter(weekday => weekday.checked === true).length === 0 || this.state.repeatError !== '' || this.state.customCountError !== '') {
           // console.log('Please select a day form weekday.')
         } else {
            const schedule = {
                startDate: currentSchedule.startDate,
                startTime: moment(getISODateString(currentSchedule.objStartTime)).format('hh:mm A'),
                endTime: moment(getISODateString(currentSchedule.objEndTime)).format('hh:mm A'),
                text: currentSchedule.text,
                recurrenceMethod: currentSchedule.recurrenceMethod,
                rrule: currentSchedule.rrule,
                objStartDate: currentSchedule.objStartDate,
                objStartTime: currentSchedule.objStartTime,
                objEndTime: currentSchedule.objEndTime,
                notify: currentSchedule.notify
            }
           // console.log(schedule)
             if (this.state.editSchedule) {
                 console.log(" Schedule edited")
                schedules[this.state.editScheduleKey] = schedule
                this.setState({schedules, openSchedule: false, editSchedule: false, invalidEndTime:false, currentSchedule: refreshCurrentSchedule(), customEnabled: false, isDataSaved: false})
             } else {
                console.log(" Schedule added")
                schedules.push(schedule)
                this.setState({schedules, openSchedule: false, invalidEndTime:false, currentSchedule: refreshCurrentSchedule(), customEnabled: false, isDataSaved: false})
             }
         }
         
     }

     handleStepOnBlur(){
        console.log("handle On Blur Step!")
        let steps = this.state.steps 
        const emptyStep = steps.length > 0 ? steps.filter(item => item.title === '') : []
        if (emptyStep.length > 0 && emptyStep[0].title === '') {
            this.setState({stepTitleEmpty: true, editStep: true})
        } else {
            this.setState({editScheduleKey: -1, editStep: true})
        }
    }

    handleAddStep(){
        console.log("handle Add Step!")
        let steps = this.state.steps
        const emptyStep = steps.length > 0 ? steps.filter(item => item.title === '') : []
        if (emptyStep.length > 0 && emptyStep[0].title === '') {
            this.setState({stepTitleEmpty: true, editStep: true, isDataSaved: false, editableKey: 0})
        } else {
            const step = {title: '', description: '', position: this.state.steps.length}
            steps.push(step)
            this.setState({steps, editStep: true, stepTitle: '', stepDesc: '', editableKey: steps.length-1, isDataSaved: false})
        }
       
    }

    handleListItem(e, key) {
        console.log("handle List Step!")
        if(!this.state.openCameraMenu) {
            let steps = this.state.steps
            const updatedSteps = steps.filter(item => item.title !== '')
            let stepTitle = steps[key].title
            let stepDesc = steps[key].description
            this.setState({stepTitleEmpty: false, steps: updatedSteps, editStep: true, editableKey: key, stepTitle, stepDesc, isDataSaved: false, initialEdit: true})
        }
    }

    handleDeleteListItem(index){
        let steps = this.state.steps.filter((item, key) => key !== index)
       // console.log(steps)
        this.setState({steps, editStep: false, stepTitle: '', stepDesc: '',stepTitleEmpty:false, isDataSaved: false})
    }

    handleDeleteStepImg(index) {
        let steps = this.state.steps
        steps.map((item, key) => {
            if (key === index) {
                item.imageURL = null
            }
        })
        this.setState({steps, editStep: false, stepTitle: '', stepDesc: '',stepTitleEmpty:false, isDataSaved: false})
    }

    handleSaveActivity(){
        if (this.state.activityTitle !== '' && this.state.activityTitle.length > 2) {
            if(this.state.scope === 'general') {
                if (this.props.activity && this.props.activity.activityId && this.props.activity.members && this.props.activity.members > 1) {
                    const {idToken, userId, orgId} = this.state
                    this.props.unSubscribeActivity(userId, orgId, idToken, [this.props.activity.activityId]).then((result) => {
                        if (result === 'success') {
                            this.handleAddActivity()
                        }
                    } )
                } else if (this.props.activity && this.props.activity.activityId) {
                    this.handleUpdateActivity()
                } else {
                    this.handleAddActivity()
                }
            } else {
                if (this.props.activity && this.props.activity.activityId) {
                    this.handleUpdateActivity()
                } else {
                    this.handleAddActivity()
                }
            }
            this.setState({isDataSaved: true})
        } else if(this.state.activityTitle.length < 3) {
            this.setState({minCharLength: false });
        } else {
            this.setState({isTitleFilled: false });
        }
       
    }

    handleAddActivity(){
        const {idToken, userId, orgId, activityBgImageUrl, showSkipButton, showStartButton, activityEnabled, scope, autoTTSEnabled, activityType} = this.state
        const {version} = this.props
        let schedules =  []
        this.state.schedules.map((item, key) => {
            const {startDate, objStartTime, objEndTime,text,recurrenceMethod,rrule, notify} = item
            const time = moment(getISODateString(objStartTime)).format('HH:mm').toString()
            const dateStr = moment(getISODateString(startDate)).set({ hour: parseInt(time.split(":")[0]), minute: parseInt(time.split(":")[1]) }).toString();
            const newSchedule = {
                "startDate": new Date(dateStr).toISOString(), 
                "startTime": time,
                "endTime": moment(objEndTime).format('HH:mm').toString(),
                text,
                recurrenceMethod,rrule,
                notify
            }

            schedules.push(newSchedule)
        })

        let activity = null
        if (scope === "general") {
            const activityGroups = this.props.activity ? this.props.activity.activityGroups : null
            const createdFrom = this.props.activity ? this.props.activity.members ? this.props.activity.members > 1 ? this.state.createdFrom : [] : [] : []
            const userExclusive = this.props.activity ? this.props.activity.members ? this.props.activity.members > 1 ? true : false : false : true
            const cloneActivity = this.props.activity && this.props.activity.Routine ? {...this.props.activity.Routine} : {}

            delete cloneActivity.steps;
            delete cloneActivity.schedules;
            delete cloneActivity.newSchedules;

            activity = {
                ...cloneActivity,
                "title": this.state.activityTitle,
                "type": activityType,
                "detailedDescription": this.state.activityDesc,
                "doNotNotifyIfDecliningToStartRoutine": false,
                "doNotNotifyOnRoutineCancel": false,
                "doNotNotifyOnRoutineCompletion": false,
                "doNotNotifyOnRoutineStart": false,
                "doNotShowRoutineCompletionReward": false,
                "notifyOnStepCompletion": false,
                "schedules": schedules,
                "steps": this.state.steps,
                "backgroundImageUrl": activityBgImageUrl,
                "showSkipButton": showSkipButton,
                "showStartButton": showStartButton,
                "autoTTSEnabled": autoTTSEnabled,
                "inactive": false,
                userExclusive,
                activityGroups,
                createdFrom
            }
        } else {
            activity = {
                "title": this.state.activityTitle,
                "type": activityType,
                "detailedDescription": this.state.activityDesc,
                "doNotNotifyIfDecliningToStartRoutine": false,
                "doNotNotifyOnRoutineCancel": false,
                "doNotNotifyOnRoutineCompletion": false,
                "doNotNotifyOnRoutineStart": false,
                "doNotShowRoutineCompletionReward": false,
                "notifyOnStepCompletion": false,
                "schedules": schedules,
                "steps": this.state.steps
            }
        }

        if(version && (version === "v2")) {
            activity["backgroundImageUrl"] = activityBgImageUrl
            activity["showSkipButton"] = showSkipButton
            activity["showStartButton"] = showStartButton
            activity["autoTTSEnabled"] = autoTTSEnabled
        }
         
        console.log("activity Details: ", activity)
        this.props.checkDuplicateActivity(userId, orgId,  activity, idToken, scope)
    }

    handleUpdateActivity(){
        const {idToken, userId, orgId, activityBgImageUrl, showSkipButton, showStartButton, activityEnabled, scope, autoTTSEnabled, activityType} = this.state
        const {version} = this.props
        let activityId = this.props.activity.activityId
        let schedules =  []
        let steps = []
        if (this.props.activity.Routine.newSchedule && Object.keys(this.props.activity.Routine.newSchedule).length > 0) {
            this.state.schedules.map((item, key) => {
                const {startDate, objStartTime, objEndTime,text,recurrenceMethod,rrule, notify} = item
                const time = moment(getISODateString(objStartTime)).format('HH:mm').toString()
                const dateStr = moment(getISODateString(startDate)).set({ hour: parseInt(time.split(":")[0]), minute: parseInt(time.split(":")[1]) }).toString();
                const newSchedule = {}
                newSchedule['startDate'] = new Date(dateStr).toISOString()
                newSchedule['startTime'] = time
                newSchedule['endTime'] = moment(getISODateString(objEndTime)).format('HH:mm').toString()
                newSchedule['text'] = text
                newSchedule['recurrenceMethod'] = recurrenceMethod
                newSchedule['rrule'] = rrule;
                newSchedule['notify'] = notify
                if (Object.keys(this.props.activity.Routine.newSchedule)[key]) {
                    newSchedule['scheduleId'] = Object.keys(this.props.activity.Routine.newSchedule)[key]
                }
                schedules.push(newSchedule)
            })
        } else {
            this.state.schedules.map((item, key) => {
                const {startDate, objStartTime, objEndTime,text,recurrenceMethod,rrule, notify} = item
                const time = moment(getISODateString(objStartTime)).format('HH:mm').toString()
                const dateStr = moment(getISODateString(startDate)).set({ hour: parseInt(time.split(":")[0]), minute: parseInt(time.split(":")[1]) }).toString();
                const newSchedule = {
                    "startDate": new Date(dateStr).toISOString(), 
                    "startTime": time,
                    "endTime": moment(getISODateString(objEndTime)).format('HH:mm').toString(),
                    text,
                    recurrenceMethod,
                    rrule,
                    notify
                }
    
                schedules.push(newSchedule)
            })
        }
         if (this.props.activity.Steps && Object.keys(this.props.activity.Steps).length > 0) {
            this.state.steps.map((step, index) => {
                let stepItem = step
                if (Object.keys(this.props.activity.Steps)[index]) {
                    stepItem['stepId'] = Object.keys(this.props.activity.Steps)[index]
                }
                steps.push(stepItem)
            })
         } else {
            this.state.steps.map((step, index) => {
                let stepItem = step
                steps.push(stepItem)
            })
         }

         let activity = null
         
         if (scope === "general") {
            const activityGroups = this.props.activity ? this.props.activity.activityGroups : null
            const userExclusive = this.props.activity.userExclusive
            activity = {
                "title": this.state.activityTitle,
                "type": activityType,
                "detailedDescription": this.state.activityDesc,
                "doNotNotifyIfDecliningToStartRoutine": false,
                "doNotNotifyOnRoutineCancel": false,
                "doNotNotifyOnRoutineCompletion": false,
                "doNotNotifyOnRoutineStart": false,
                "doNotShowRoutineCompletionReward": false,
                "notifyOnStepCompletion": false,
                "schedules": schedules,
                "steps": steps,
                "backgroundImageUrl": activityBgImageUrl,
                "showSkipButton": showSkipButton,
                "showStartButton": showStartButton,
                "inactive": !activityEnabled,
                "autoTTSEnabled": autoTTSEnabled,
                userExclusive,
                activityGroups,
            }
         } else {
            activity = {
                "title": this.state.activityTitle,
                "type": activityType,
                "detailedDescription": this.state.activityDesc,
                "doNotNotifyIfDecliningToStartRoutine": false,
                "doNotNotifyOnRoutineCancel": false,
                "doNotNotifyOnRoutineCompletion": false,
                "doNotNotifyOnRoutineStart": false,
                "doNotShowRoutineCompletionReward": false,
                "notifyOnStepCompletion": false,
                "schedules": schedules,
                "steps": steps
            }
         }

        if(version && (version === "v2")) {
            activity["backgroundImageUrl"] = activityBgImageUrl
            activity["showSkipButton"] = showSkipButton
            activity["showStartButton"] = showStartButton
            activity["autoTTSEnabled"] = autoTTSEnabled
        }
        
        console.log("activity Details: ", activity)
        if (this.props.activity.Routine.title !== this.state.activityTitle) {
            this.props.checkDuplicateTitleAndUpdate(userId, orgId, activityId, activity, idToken, scope)
        } else {
            this.props.updatePrivateActivity(userId, orgId, activityId, activity, idToken, scope)
        }
    }

    handleClickMenu(event, key) {
        this.setState({ anchorEl: event.currentTarget,  editScheduleKey: key, openScheduleMenu: true, openCameraMenu: false});
      }

    handleOpenCamMenu(event){
        if(isMobileDevice()) {
            this.setState({pickerType: "step_level"}, () => this.handleOpenPhotoLib(event))
        } else {
            this.setState({ anchorEl: event.currentTarget,  openCameraMenu: true, openScheduleMenu: false, pickerType: "step_level"});
        }
    }

    handleOpenCamMenuForAct(event){
        if(isMobileDevice()) {
            this.setState({pickerType: "activity_level"}, () => this.handleOpenPhotoLib(event))
        } else {
            this.setState({ anchorEl: event.currentTarget,  openCameraMenu: true, openScheduleMenu: false, pickerType: "activity_level"});
        }
    }
    
    handleCloseMenu = () => {
        this.setState({ anchorEl: null, openCameraMenu: false, openScheduleMenu: false });
    };

    handleEditSchedule = event => {
        let currentSchedule = this.state.schedules[this.state.editScheduleKey]
        const recurrenceMethod = currentSchedule.recurrenceMethod
        let anchorEl = null
        let editSchedule = true
        let openSchedule = true
        let invalidEndTime = null

        let customEnabled = this.state.customEnabled
        if (recurrenceMethod === 'custom') {
            customEnabled = true;
            getCustomeRecurrenceFromRrule(currentSchedule, this);
        }
        currentSchedule.notify = currentSchedule.notify === false ? currentSchedule.notify : true;
        if (currentSchedule.objEndTime && currentSchedule.objEndTime.diff(currentSchedule.objStartTime, 'minutes') < 5) {
            invalidEndTime = true
         } else {
            invalidEndTime = false
         }
         this.setState({ anchorEl, editSchedule, openSchedule, currentSchedule, invalidEndTime, customEnabled,openCameraMenu: false, openScheduleMenu: false});
    };

    handleDeleteSchedule = event => {
        console.log(" Schedule deleted")
        let schedules = this.state.schedules
        schedules.splice(this.state.editScheduleKey, 1)
        this.setState({ anchorEl: null,  schedules, isDataSaved: false, openCameraMenu: false, openScheduleMenu: false});
    };

    //Schedules methods

    handleRepeat(repeatText){
        let currentSchedule = this.state.currentSchedule
        const rruleDic =  generateRRule(repeatText, null, this.state.currentSchedule)
        currentSchedule['rrule'] = rruleDic.rrule
        currentSchedule['text'] = rruleDic.text
        currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
        currentSchedule['nextSchedules'] = rruleDic.nextSchedules
        if (repeatText === 'Custom') {
            this.setState({repeatValue: repeatText, currentSchedule, customEnabled: true, customRepeatValue: 'Day', weekSelected: false, monthSelected: false})
        } else {
            this.setState({repeatValue: repeatText, currentSchedule, customEnabled: false})
        }
   }

   handleToggle(){
        let currentSchedule = this.state.currentSchedule
        let{weekDayWithCheck}=this.state;
        let repeatValue = ''
        weekDayWithCheck=getweekDayWithCheck()
        if (!this.state.customEnabled) {
            repeatValue = 'Custom'
            const rruleDic =  generateRRule('Custom', null, this.state.currentSchedule)
            currentSchedule['text'] = rruleDic.text
            currentSchedule['rrule'] = rruleDic.rrule
            currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
            currentSchedule['nextSchedules'] = rruleDic.nextSchedules
        } else {
            repeatValue = 'Daily'
            const rruleDic =  generateRRule('Daily', null, this.state.currentSchedule)
            currentSchedule['text'] = rruleDic.text
            currentSchedule['rrule'] = rruleDic.rrule
            currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
        }
        this.setState({repeatValue, customEnabled: !this.state.customEnabled,weekDayWithCheck, currentSchedule, customRepeatValue: 'Day', weekSelected: false, monthSelected: false})
   }

   handleStartDateChange(date){
        const {invalidEndTime, repeatValue, customEnabled, customRepeatValue, customInterval, customCount, monthlyOccurenceDay, repeatType, weekDayWithCheck, repeatError, customCountError} = this.state
        let currentSchedule = this.state.currentSchedule
        let customUntill = this.state.customUntill
        currentSchedule['startDate'] = date
        currentSchedule['objStartDate'] = date
        currentSchedule['objStartTime'] = date
        currentSchedule['objEndTime'] = date
        customUntill = moment(date).add(1, 'd').format('MMM DD YYYY')
        if (customEnabled) {
            const weekDays = customRepeatValue!= "Week" ? getweekDayWithCheck() : weekDayWithCheck;
            handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDays, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, this)
        } else {
            const rruleDic =  generateRRule(repeatValue, null, this.state.currentSchedule)
            currentSchedule['text'] = rruleDic.text
            currentSchedule['rrule'] = rruleDic.rrule
            currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
            this.setState({currentSchedule, customUntill})
        }
        
   }

   handleStartTimeChange(time){
        const {customUntill, repeatValue, customEnabled, customRepeatValue, customInterval, customCount, monthlyOccurenceDay, repeatType, weekDayWithCheck, repeatError, customCountError} = this.state
        let currentSchedule = this.state.currentSchedule
        currentSchedule['startTime'] = moment(time.toString()).format('hh:mm A')
        currentSchedule['objStartTime'] = time //moment(time.toString())
        console.log("Start and End Time differences--->", currentSchedule.objEndTime.diff(currentSchedule.objStartTime, 'minutes'))
        const invalidEndTime = currentSchedule.objEndTime && currentSchedule.objEndTime.diff(currentSchedule.objStartTime, 'minutes') < 5
        if (customEnabled) {
            const weekDays = customRepeatValue!= "Week" ? getweekDayWithCheck() : weekDayWithCheck;
            handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDays, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, this)
        } else {
            const rruleDic =  generateRRule(repeatValue, null, this.state.currentSchedule)
            currentSchedule['text'] = rruleDic.text
            currentSchedule['rrule'] = rruleDic.rrule
            currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
            this.setState({currentSchedule, customUntill, invalidEndTime})
        } 
   }
   handleEndTimeChange(time){
        const {customUntill, repeatValue, customEnabled, customRepeatValue, customInterval, customCount, monthlyOccurenceDay, repeatType, weekDayWithCheck, repeatError, customCountError} = this.state
        let currentSchedule = this.state.currentSchedule
        currentSchedule['endTime'] = moment(time.toString()).format('hh:mm A') //moment(time).format('hh:mm A')
        currentSchedule['objEndTime'] = time
        const invalidEndTime = currentSchedule.objEndTime && currentSchedule.objEndTime.diff(currentSchedule.objStartTime, 'minutes') < 5
        if (customEnabled) {
            const weekDays = customRepeatValue!= "Week" ? getweekDayWithCheck() : weekDayWithCheck;
            handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDays, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, this)
        } else {
            const rruleDic =  generateRRule(repeatValue, null, this.state.currentSchedule)
            currentSchedule['text'] = rruleDic.text
            currentSchedule['rrule'] = rruleDic.rrule
            currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
            this.setState({currentSchedule, customUntill, invalidEndTime})
        } 
   }

   handleNotifyStateChange(){
        let currentSchedule = this.state.currentSchedule
        currentSchedule['notify'] = !currentSchedule.notify
        this.setState({currentSchedule})
   }

handleRepeatSelection(e) {
    const {invalidEndTime, customInterval, customCount, monthlyOccurenceDay, repeatType, customUntill, weekDayWithCheck, repeatError, customCountError, currentSchedule} = this.state
    const customRepeatValue = e.target.value
    const weekDays = customRepeatValue!= "Week" ? getweekDayWithCheck() : weekDayWithCheck;
    handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDays, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, this)
}

handleCustomMonthlyRepeat(e) {
    const {customInterval, customCount, customRepeatValue, repeatType, customUntill, weekDayWithCheck, repeatError, customCountError, currentSchedule, invalidEndTime} = this.state
    let monthlyOccurenceDay = e.target.value
    handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, this)
}

handleWeekDayChecked(weekItem, index){
    const {customInterval, customCount, customRepeatValue, repeatType, customUntill, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime} = this.state
    let weekDayWithCheck = this.state.weekDayWithCheck
    weekDayWithCheck[index].checked = !weekItem.checked
    handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, this)

}

handleRepeatType(e, index) {
    const {customInterval, customCount, customRepeatValue, weekDayWithCheck, customUntill, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime} = this.state
    let repeatType = this.state.repeatType
    repeatType.map((item, key) => {
        if (key === index && e.target.checked) {
            item.selected = e.target.checked
        } else {
            item.selected = false
        }
    })
    handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, this)
}

handleCustomCount(e){
    const {customInterval, repeatType, customRepeatValue, weekDayWithCheck, customUntill, monthlyOccurenceDay, repeatError, currentSchedule, invalidEndTime} = this.state
    let customCountError =  Number(e.target.value) < Number(1) ? "Count should be greater than 0." : ''
    let customCount = Number(e.target.value) < Number(1) ? e.target.value : Number(e.target.value)
    if (customCountError === ''){
        handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, this)
    } else {
        this.setState({customCountError, customCount})
    }
}

handleCustomUntill(date){
    const {customInterval, repeatType, customRepeatValue, customCount, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime} = this.state
    let customUntill = date
    handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, this)
}

handleCustomInterval(e){
    const {repeatType, customRepeatValue, customCount, customUntill, weekDayWithCheck, monthlyOccurenceDay, customCountError, currentSchedule, invalidEndTime} = this.state
    let customInterval =  e.target.value
    let repeatError = ''
    if (this.state.customRepeatValue === 'Day') {
        repeatError = Number(e.target.value) < Number(1) ? "Repeat value should be greater than 0" : ''
        customInterval = e.target.value
    } else if (this.state.customRepeatValue === 'Week') {
        repeatError = (Number(e.target.value) < Number(1)) || ( Number(e.target.value) > Number(5)) ? "Repeat value should be between 0 and 6" : ''
        customInterval = e.target.value
    } else if (this.state.customRepeatValue === 'Month') {
        repeatError = Number(e.target.value) < Number(1) ? "Repeat value should be greater than 0" : ''
        customInterval = e.target.value
    } else if (this.state.customRepeatValue === 'Year') {
        repeatError = Number(e.target.value) !== Number(1) ? "Repeat value should be 1" : ''
        customInterval = e.target.value
    } 
    if (repeatError === '') {
        handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, this)
    } else {
        this.setState({repeatError, customInterval})
    }
}

onConfirm(){
    this.props.removeAPIErrorMessage()
    window.location.href = "/success"
}

openActivityTemplate(){
    this.setState({openActivityTemplate: !this.state.openActivityTemplate})
}

handleSelectTemplate(key){
    const activityTemplate = this.props.filteredTemplates[key]
    this.addActivityTemlateToState(activityTemplate.Detail)
    this.props.selectActivityTemplate(activityTemplate)
}

filterTemplateActivity(groupIds, activityTemplateGroups) {
    this.props.filterActivityTemplate(groupIds, activityTemplateGroups)
}
handleCloseSuccessPopup() {
    this.props.showActivitySuccess('')
}

handleStepTitleChange(e, index) {
    let steps = this.state.steps
    steps[index].title = e.target.value
    const stepTitleEmpty = e.target.value !== '' ? false : true
    this.setState({steps, editStep: true, stepTitle: e.target.value, stepTitleEmpty, isDataSaved: false, initialEdit: false})
}
handleStepDescChange(e, index) {
    let steps = this.state.steps
    steps[index].description = e.target.value
    this.setState({steps, editStep: true, stepDesc: e.target.value, isDataSaved: false, initialEdit: false})
}
stopEditStep(){
    console.log("handle stop edit step!")
    let steps = this.state.steps
    const emptyStep = steps.length > 0 ? steps.filter(item => item.title === '') : []
    if (this.state.initialEdit) {
        console.log("Initially editing steps!")
    } else if(emptyStep.length > 0 && emptyStep[0].title === '') {
        this.setState({stepTitleEmpty: true, editStep: true, anchorEl: null, initialEdit: false})
    } else {
        this.setState({editStep: false, editableKey: -1, anchorEl: null, initialEdit: false})
    }
}

adjustHeight(el){
    el.style.height = "1px";
    el.style.height = (25+el.scrollHeight)+"px";
}
textAreaOnBlur(el){
    el.style.height = "50px";
}

handleHeaderCancel(){
    this.setState({showUnSavedWarning: !this.state.isDataSaved})
}

handleCloseWarning(){
    this.setState({showUnSavedWarning: !this.state.showUnSavedWarning})
}
handleUserExclusiveWarning(){
    this.setState({showUnSavedWarning: false, showUserExclusiveWarning: !this.state.showUserExclusiveWarning})
}

handleOpenCamera = async(dataUri) => {
    console.log("Opening|closing camera!")
    var imageBase64String = await getBase64FromUrl(dataUri)
    if (imageBase64String && imageBase64String !== "") {
        const imgBlob = await dataURItoBlob(dataUri)
        console.log(`originalFile size ${imgBlob.size / 1024 / 1024} MB`);
        const options = {
          maxSizeMB: 2,
          maxWidthOrHeight: 512,
          useWebWorker: true
        }
        
        try {
            const compressedFile = await imageCompression(imgBlob, options);
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            imageBase64String = await getBase64FromUrl(URL.createObjectURL(compressedFile))
        } catch (error) {
          console.log(error);
        }
    }
    const {editStep, editableKey, pickerType} = this.state
    if (editStep && (editableKey === 0 || editableKey > 0) && pickerType === "step_level"){
         //👇️ adding data to exixting steps
        let steps = this.state.steps
        steps[editableKey].imageURL = imageBase64String && imageBase64String !== "" ? imageBase64String :  steps[editableKey].imageURL
        this.setState({activityStepImageUrl: imageBase64String, openCameraMenu: false, steps, editStep: true, isDataSaved: false, openPhotoLib: false, openCamera: !this.state.openCamera})
    } else {
        const activityBgImageUrl = imageBase64String && imageBase64String !== "" ? imageBase64String : this.state.activityBgImageUrl
        this.setState({openCameraMenu: false, openCamera: !this.state.openCamera, openPhotoLib: false, activityBgImageUrl, isDataSaved: false})
    }
}

handleCancelCam() {
    this.setState({openCameraMenu: false, openPhotoLib: false, openCamera: false})
}

handleOpenPhotoLib(event) {
    event.preventDefault()
    console.log("Opening|closing photo lib!")
    this.setState({openCameraMenu: false, openCamera: false, openPhotoLib: !this.state.openPhotoLib}, () => {
        this.state.inputRef.current.click();
    })
}

handlePhotoChange = async(event) => {
    event.preventDefault()
    console.log("handle photo change!")
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    console.log(`originalFile size ${fileObj.size / 1024 / 1024} MB`);
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 512,
      useWebWorker: true
    }
    
    try {
        const compressedFile = await imageCompression(fileObj, options);
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        var tmppath = await getBase64FromUrl(URL.createObjectURL(compressedFile))
        event.target.value = null;
        const {editStep, editableKey, pickerType} = this.state
        if (editStep && (editableKey === 0 || editableKey > 0) && pickerType === "step_level") {
            let steps = this.state.steps
            steps[editableKey].imageURL = tmppath
            this.setState({activityStepImageUrl: tmppath, openCameraMenu: false, steps, editStep: true, isDataSaved: false})
        } else {
            this.setState({activityBgImageUrl: tmppath, openCameraMenu: false, isDataSaved: false})
        }
    } catch (error) {
      console.log(error);
    }
    
}


toggleCustom(event){
    if (event.target.value === "showSkipButton"){
        this.setState({showSkipButton: event.target.checked, isDataSaved: false})
    } else if(event.target.value === "showStartButton") {
        this.setState({showStartButton: event.target.checked, isDataSaved: false})
    } else if(event.target.value === "activityEnabled"){
        this.setState({activityEnabled: event.target.checked, isDataSaved: false})
    } else if (event.target.value === "autoTTSEnabled") {
        this.setState({autoTTSEnabled: event.target.checked, isDataSaved: false})
    }
}
    
   render() {
      const {openActivityTemplate, weekDayWithCheck, customUntill, customCount,invalidEndTime, customInterval, repeatType,monthlyOccurenceDay, currentSchedule, weekSelected, monthSelected, customEnabled, openSchedule,customRepeatValue, activityTitle, activityDesc, schedules, steps, anchorEl, select, editStep, editableKey, stepTitleEmpty, isTitleFilled, minCharLength, showUnSavedWarning, isDataSaved, repeatError, customCountError, openCameraMenu, openScheduleMenu, openCamera, activityBgImageUrl, scope, showUserExclusiveWarning } = this.state
      const {errorTitle, loading, screenThemeClass, popupThemeClass, activityExist, errorMessage, displayName, version} = this.props
      const invalidateInput = !isTitleFilled || (activityExist &&(activityTitle !== '') && (this.props.activityTitle === activityTitle)) || (!minCharLength) ? true : false
      console.log("scope-->", scope, "version--->", version)

      return (
            <QueueAnim type="bottom" duration={2000} className="object-editor">
                <div>
                    {
                        loading ?
                            <div className="d-flex justify-content-center loader-overlay">
                                <CircularProgress  disableShrink	 />
                            </div>
                        : errorMessage !== '' ? 
                            <SweetAlert
                                warning
                                btnSize="sm"
                                customClass="warningText"
                                show={errorMessage !== ''}
                                confirmBtnText="OK"
                                confirmBtnBsStyle="warning"
                                title={ `${errorTitle}!`}
                                onConfirm={this.onConfirm.bind(this)}
                            >
                             <div className="sweet-alert-text"> {errorMessage}</div>   
                            </SweetAlert>
                        : showUnSavedWarning ? <WarningDialog open={showUnSavedWarning} handleCloseSuccessPopup={this.handleCloseWarning.bind(this)} popupThemeClass={this.props.popupThemeClass}/>
                        : showUserExclusiveWarning ? <UserExclusiveWarning activityTitle={activityTitle} displayName={displayName} open={showUserExclusiveWarning} handleClosePopup={this.handleUserExclusiveWarning.bind(this)} popupThemeClass={this.props.popupThemeClass}/> 
                        : <></>
                    }
                </div>
                <div className={screenThemeClass}>
                    <AppBar position="static" className="session-header">
                        <Toolbar>
                            <Header isDataSaved={isDataSaved} handleHeaderCancel={this.handleHeaderCancel.bind(this)} popupThemeClass={popupThemeClass} title={<IntlMessages id="newactivity.info_CreateActivity"/>} href="/success" handleSave={this.handleSaveActivity.bind(this)} version={version}/>
                        </Toolbar>
                    </AppBar>
                    <div className="session-inner-wrapper">
                        <div className="container">
                            <div className="row row-eq-height">
                                <div className="col-sm-12 col-md-2 col-lg-2"></div>
                                <div className="col-sm-12 col-md-8 col-lg-8">
                                    <div className="session-body text-center">
                                        <div className="session-head">
                                            <div className="d-flex">
                                                <div data-testid="IconActivity" className="titleIcon mr-1">
                                                    <img src={require("Assets/img/activity_icon.png")} alt="project logo" className="media-object rounded" width="30" height="30" />
                                                </div>
                                                <IntlMessages id="newactivity.titlePlaceholder">{placeholder=><LSInput datatestid="title" autoFocus value={activityTitle ? activityTitle : ''} type="text" placeholder={placeholder} className={invalidateInput ? "inputTitleErr w-100" : "inputTitle border-0 w-100"} onChange={(e) => this.setState({ activityTitle: e.target.value, isTitleFilled: e.target.value !== '', minCharLength:  e.target.value.length > 2, isDataSaved: false})} />}</IntlMessages>
                                            </div>
                                            {!isTitleFilled ? <FormFeedback data-testid="title-error" className="text-left fs-12 d-flex"><IntlMessages id="newactivity.titleError1" /></FormFeedback>
                                                : activityExist && activityTitle !== '' && this.props.activityTitle === activityTitle ? <FormFeedback className="text-left fs-12 d-flex"><IntlMessages id="newactivity.titleError2"/></FormFeedback>
                                                : !minCharLength && <FormFeedback className="text-left fs-12 d-flex"><IntlMessages id="newactivity.titleError3"/></FormFeedback>
                                            }
                                            <IntlMessages id="newactivity.descriptionPlaceholder">{placeholder=><LSTextArea textArearef={this.state.textAreaField} onKeyUp={() => this.adjustHeight(this.state.textAreaField.current)} datatestid="description" value={activityDesc} type="textarea" className={`desciption w-100 border-0 ${ !scope && !version ? "mb-4" : ""}`} placeholder={placeholder} onChange={(e) => this.setState({ activityDesc: e.target.value, isDataSaved: false})} onBlur={() => this.textAreaOnBlur(this.state.textAreaField.current)} />}</IntlMessages>
                                            {(scope === "general") || (version === "v2") ? 
                                                <div key={`new-item-bg-image`} data-testid={`new-item-bg-image`} className="pl-2 add-new-dropdown d-flex align-items-center justify-content-center mb-4 ">
                                                    {/* <Label data-testid="new-item-switch-label" className="menuEditItem mb-0"><IntlMessages id={"newactivity.backgroundimageurl"}/></Label> */}
                                                        <div /*className="titleIcon m-2"*/>
                                                            <PictureOptions
                                                                datatestid="bg-image-picker"
                                                                imageUrl={activityBgImageUrl}
                                                                openCameraMenu={openCameraMenu}
                                                                handleOpenCamMenu={this.handleOpenCamMenuForAct.bind(this)}
                                                                anchorEl={anchorEl}
                                                                handleCloseMenu={this.handleCloseMenu.bind(this)}
                                                                handleOpenCamera={this.handleOpenCamera.bind(this)}
                                                                handleOpenPhotoLib={this.handleOpenPhotoLib.bind(this)}
                                                            />
                                                        </div> 
                                                    {activityBgImageUrl && <IconButton data-testid={"delete-bg-img"} onClick={() => this.setState({activityBgImageUrl: null, isDataSaved: false})}> <DeleteOutlineIcon className="deleteButton" fontSize="large" /></IconButton>}
                                                </div>
                                                : <></>
                                            }
                                            <div className="row mx-auto optionsRow w-100 mb-4">
                                                <div data-testid="option" className={select==="options"?"text-center bg-white my-auto mx-auto selectBox":"text-center text-secondary unselectBox"} onClick={()=>this.setState({select:"options"})}>
                                                    <div className={select==="options" ? "tabtitle-selected" : "tabtitle"}><IntlMessages id="newactivity.options"/></div>
                                                </div>
                                                <div data-testid="steps"  className={select==="steps"?"text-center bg-white my-auto selectBox":"text-center text-secondary unselectBox"}  onClick={()=>this.setState({select:"steps"})}>
                                                    <div className={select==="steps" ? "tabtitle-selected" : "tabtitle"}><Badge className="p-1" badgeContent={steps.length} color="error"><IntlMessages id="newactivity.steps"/></Badge></div>
                                                </div>
                                            </div>
                                            { select === "options" ? <div>
                                                { this.props.activity && ((scope === 'general') && (version === "v2")) ?
                                                    <div key={`new-item-enable-switch-container`} data-testid={`new-item-enable-switch-container`} className="pl-2 add-new-dropdown d-flex align-items-center justify-content-between mb-2 ">
                                                        <Label data-testid="inactive-switch-label" className="menuEditItem mb-0"><IntlMessages id={"newactivity.inactive"}/></Label>
                                                        <div className="d-flex align-items-center">
                                                            <Switch
                                                                value={"activityEnabled"}
                                                                checked={this.state.activityEnabled}
                                                                onClick={(event) => this.toggleCustom(event)}
                                                                color="primary"
                                                                className="switch-btn"
                                                            />
                                                        </div>
                                                    </div>
                                                    : <></>
                                                }
                                                {(scope === 'general') || (version === "v2") ? 
                                                    <div key={`new-item-switch-container-skip-buuton`} data-testid={`skipbutton-switch-container`} className="pl-2 add-new-dropdown d-flex align-items-center justify-content-between mb-2 ">
                                                        <Label data-testid="skipbutton-switch-label" className="menuEditItem mb-0"><IntlMessages id={"newactivity.skipbutton"}/></Label>
                                                        <div className="d-flex align-items-center">
                                                            <Switch
                                                                value={"showSkipButton"}
                                                                checked={this.state.showSkipButton}
                                                                onClick={(event) => this.toggleCustom(event)}
                                                                color="primary"
                                                                className="switch-btn"
                                                            />
                                                        </div>
                                                    </div>
                                                    : <></>
                                                }
                                                {(scope === 'general') || (version === "v2") ? 
                                                    <div key={`new-item-switch-container-start-button`} data-testid={`showstartbutton-switch-container`} className="pl-2 add-new-dropdown d-flex align-items-center justify-content-between mb-2 ">
                                                        <Label data-testid="showstartbutton-switch-label" className="menuEditItem mb-0"><IntlMessages id={"newactivity.showstartbutton"}/></Label>
                                                        <div className="d-flex align-items-center">
                                                            <Switch
                                                                value={"showStartButton"}
                                                                checked={this.state.showStartButton}
                                                                onClick={(event) => this.toggleCustom(event)}
                                                                color="primary"
                                                                className="switch-btn"
                                                            />
                                                        </div>
                                                    </div>
                                                    : <></>
                                                }
                                                {(scope === 'general') || (version === "v2") ? 
                                                    <div key={`new-item-autoTTEnabled-switch-container`} data-testid={`autoTTEnabled-switch-container`} className="pl-2 add-new-dropdown d-flex align-items-center justify-content-between mb-2 ">
                                                        <Label data-testid="autoTTSEnabled-switch-label" className="menuEditItem mb-0"><IntlMessages id={"newactivity.autoTTSEnabled"}/></Label>
                                                        <div className="d-flex align-items-center">
                                                            <Switch
                                                                value={"autoTTSEnabled"}
                                                                checked={this.state.autoTTSEnabled}
                                                                onClick={(event) => this.toggleCustom(event)}
                                                                color="primary"
                                                                className="switch-btn"
                                                            />
                                                        </div>
                                                    </div>
                                                    : <></>
                                                }
                                                <RctCollapsibleCard contentCustomClasses="p-0">
                                                    <div className="d-flex justify-content-between pt-3 pb-4" style={{ paddingLeft: "0.75rem" }}>
                                                        <div data-testid="Schedule-heading" className="scheduleTitle"><IntlMessages id="newactivity.schedule"/></div>
                                                    </div>
                                                    <ul data-testid="Schedule-list" className="list-inline mb-0" >
                                                        {schedules && schedules.map((schedule, key) => (
                                                            <li data-testid={"Schedule"+key} key={key} className={"border-bottom pb-2 pt-2 "+(key % 2 == 0 ? "schedule-list-item-odd" : "")} style={{ paddingLeft: "0.75rem" }}>
                                                                <div className="d-flex justify-content-between mb-2">
                                                                    <div  data-testid={"Schedule"+key+"startDate"} className="mb-0 d-flex align-items-center justify-content-center listItem"><CalendarTodayRounded fontSize="medium" className="pr-1" />{`Starts ${moment(getISODateString(schedule.startDate)).format('DD MMM YYYY')}`}</div>
                                                                    <div data-testid={"Schedule"+key+"time"} className="mb-0 d-flex align-items-center justify-content-center listItem"><AccessTimeRounded fontSize="medium" className="pr-1" />
                                                                        {`${schedule.startTime} - ${schedule.endTime}`}
                                                                        <MoreVertIcon data-testid={"Schedule"+key+"more"} className="ml-2" aria-label="More" aria-owns={anchorEl ? 'long-menu' : null} aria-haspopup="true" onClick={(event) => this.handleClickMenu(event, key)} />
                                                                    </div>
                                                                    <Menu id={`"schedule-"${key}`} anchorEl={anchorEl} open={openScheduleMenu} onClose={this.handleCloseMenu} >
                                                                        <MenuItem data-testid={"Schedule"+key+"edit"} onClick={this.handleEditSchedule}><div className="menuEditItem"><IntlMessages id="newactivity.Edit"/></div></MenuItem>
                                                                        <MenuItem data-testid={"Schedule"+key+"delete"} onClick={this.handleDeleteSchedule}><div className="meniDeleteItem"><IntlMessages id="newactivity.Delete"/></div></MenuItem>
                                                                    </Menu>
                                                                </div>
                                                                <div className="mb-2">
                                                                    <div data-testid={"Schedule"+key+"text"} className="mb-2 d-flex text-left listItem"><RepeatRounded className="align-icons" fontSize="medium" />{`Repeats ${schedule.text}`}</div>
                                                                </div>
                                                                <div className="d-flex align-items-center mr-2">
                                                                    <div className="listItem mr-2 d-flex align-items-center justify-content-center"><NotificationsNoneRoundedIcon  className="align-icons" fontSize="medium"/>Notify:</div>
                                                                    {schedule.notify === false ? 
                                                                        <CancelRoundedIcon className='text-danger'/>
                                                                        :
                                                                        <CheckCircleRoundedIcon className="text-success"/>
                                                                    } 
                                                                </div> 
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <FormGroup className="align-items-center p-4"><button data-testid="add-Schedule" className="buttonWithBorder" onClick={(e) => this.handleOpenSchedule(e)}><IntlMessages id="newactivity.ADDSCHEDULEButton"/></button></FormGroup>
                                                    <ScheduleUpdate
                                                        currentSchedule={currentSchedule}
                                                        open={openSchedule}
                                                        editSchedule={this.state.editSchedule}
                                                        activityType={this.state.activityType}
                                                        handleClose={this.handleOpenSchedule.bind(this)}
                                                        handleSaveSchedule={this.handleSaveSchedule.bind(this)}
                                                        handleRepeat={this.handleRepeat.bind(this)}
                                                        handleStartDateChange={this.handleStartDateChange.bind(this)}
                                                        handleStartTimeChange={this.handleStartTimeChange.bind(this)}
                                                        handleEndTimeChange={this.handleEndTimeChange.bind(this)}
                                                        customEnabled={customEnabled}
                                                        handleToggle={this.handleToggle.bind(this)}
                                                        getDayOccurences={getDayOccurences}
                                                        weekSelected={weekSelected}
                                                        monthSelected={monthSelected}
                                                        customRepeatValue={customRepeatValue}
                                                        handleRepeatSelection={this.handleRepeatSelection.bind(this)}
                                                        weekDayWithCheck={weekDayWithCheck}
                                                        handleWeekDayChecked={this.handleWeekDayChecked.bind(this)}
                                                        handleRepeatType={this.handleRepeatType.bind(this)}
                                                        repeatType={repeatType}
                                                        handleCustomMonthlyRepeat={this.handleCustomMonthlyRepeat.bind(this)}
                                                        monthlyOccurenceDay={monthlyOccurenceDay}
                                                        customCount={customCount}
                                                        handleCustomCount={this.handleCustomCount.bind(this)}
                                                        handleNotifyStateChange={this.handleNotifyStateChange.bind(this)}
                                                        customUntill={customUntill}
                                                        handleCustomUntill={this.handleCustomUntill.bind(this)}
                                                        handleCustomInterval={this.handleCustomInterval.bind(this)}
                                                        customInterval={customInterval}
                                                        invalidEndTime={invalidEndTime}
                                                        popupThemeClass={popupThemeClass}
                                                        repeatError={repeatError}
                                                        customCountError={customCountError}
                                                    />
                                                </RctCollapsibleCard>
                                                <RctCollapsibleCard contentCustomClasses="p-0">
                                                        <div data-testid="Activity-Template" className="p-2 d-flex align-items-center oe-text-primary justify-content-between" onClick={this.openActivityTemplate.bind(this)}>
                                                            <button className="button-scratch border-0"><IntlMessages id="newactivity.ActivityTemplate"/></button>
                                                            <ChevronRightRoundedIcon fontSize="large"/>
                                                        </div>
                                                </RctCollapsibleCard>
                                                <ActivityTemplate 
                                                    popupThemeClass={popupThemeClass} 
                                                    title={<IntlMessages id="newactivity.ActivityTemplate"/>} 
                                                    filterTemplateActivity={this.filterTemplateActivity.bind(this)} 
                                                    groups={this.props.groups} 
                                                    scope={scope}
                                                    activityTemplates={this.props.filteredTemplates} 
                                                    handleSelectTemplate={this.handleSelectTemplate.bind(this)} 
                                                    open={openActivityTemplate} 
                                                    closeActivityTemplate={this.openActivityTemplate.bind(this)}  
                                                    activityTitle={activityTitle}
                                                    activityDesc={activityDesc}
                                                    steps={steps}
                                                    schedules={schedules}
                                                    />
                                            </div>
                                            : <div data-testid="step-list">
                                                    <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                                                        <Droppable droppableId="droppable">
                                                            {(provided, snapshot) => (
                                                                <ul className= "list-inline mb-0"  ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                                                    {steps && steps.map((step, index) => (
                                                                        <Draggable key={`This is dragable key ${index}`} draggableId={`This is dragable key ${index}`} index={index}>
                                                                            {(provided, snapshot) => (
                                                                                <li /*className={snapshot.isDragging ? "list-card-drag" : "list-card"}*/ data-testid={"step"+index} key={index} >
                                                                                    <div
                                                                                        className="media list-card"
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        style={getItemStyle(
                                                                                            snapshot.isDragging,
                                                                                            provided.draggableProps.style
                                                                                        )}>
                                                                                        {editStep && editableKey === index ?
                                                                                            <div className="media-body text-left ml-15 mb-15 mt-15">
                                                                                                <span onClick={() => this.stopEditStep()} className="d-block steptitle"><IntlMessages id="newactivity.StepTitlePlaceholder">{placeholder=><LSInput datatestid={"stepTitle"+index} autoFocus value={step.title} type="text" placeholder={placeholder} className={stepTitleEmpty ? "mb-2 step-input-titleErr w-100" : "mb-2 step-input-title border-0 w-100"} onChange={(e) => this.handleStepTitleChange(e, index)} />}</IntlMessages></span>
                                                                                                {stepTitleEmpty && <FormFeedback className="mb-2 text-left fs-12 d-flex"><IntlMessages id="newactivity.StepTitleErr" /></FormFeedback>}
                                                                                                <span className="d-block stepnote"><IntlMessages id="newactivity.StepDescPlaceholder">{placeholder=><LSTextArea datatestid={"stepDescription"+index} value={step.description} type="textarea" className="mb-2 step-input-note  border-0 w-100" placeholder={placeholder} onChange={(e) => this.handleStepDescChange(e, index)} onBlur={this.handleStepOnBlur.bind(this)} />}</IntlMessages></span>
                                                                                               {(scope === 'general') || (version === "v2") ? 
                                                                                                    <div key={`new-item-bg-image`} data-testid={`new-item-bg-image`} className="pl-2 add-new-dropdown d-flex align-items-center justify-content-center mb-2 ">
                                                                                                        {/* <span data-testid={"step"+index+"title"} className="d-block steptitle">Add Step Image</span> */}
                                                                                                        <div /*className="titleIcon m-2"*/>
                                                                                                            <PictureOptions
                                                                                                                imageUrl={step.imageURL}
                                                                                                                datatestid={`step-image-picker-${index}`}
                                                                                                                openCameraMenu={openCameraMenu}
                                                                                                                handleOpenCamMenu={this.handleOpenCamMenu.bind(this)}
                                                                                                                anchorEl={anchorEl}
                                                                                                                handleCloseMenu={this.handleCloseMenu.bind(this)}
                                                                                                                handleOpenCamera={this.handleOpenCamera.bind(this)}
                                                                                                                handleOpenPhotoLib={this.handleOpenPhotoLib.bind(this)}
                                                                                                            />
                                                                                                            {step.imageURL && <IconButton data-testid={"deleteStepImg"+index} onClick={() => this.handleDeleteStepImg(index)}> <DeleteOutlineIcon className="deleteButton" fontSize="large" /></IconButton>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    : <></>
                                                                                                }
                                                                                            </div>
                                                                                            :
                                                                                            <div data-testid={"step"+index+"details"} className="media-body text-left ml-15 ml-15 mb-15 mt-15" onClick={(e) => this.handleListItem(e, index)}>
                                                                                                <span data-testid={"step"+index+"title"} className="d-block steptitle">{step.title}</span>
                                                                                                <span data-testid={"step"+index+"desc"} className="d-block stepnote">{step.description}</span>
                                                                                                {(scope === 'general') || (version === "v2") ? 
                                                                                                    <div key={`new-item-bg-image`} data-testid={`new-item-bg-image`} className="pl-2 mt-2 add-new-dropdown d-flex align-items-center justify-content-center mb-2 ">
                                                                                                        {/* <span data-testid={"step"+index+"title"} className="d-block steptitle">Add Step Image</span> */}
                                                                                                        <div /*className="titleIcon m-2"*/>
                                                                                                            <PictureOptions
                                                                                                                imageUrl={step.imageURL}
                                                                                                                datatestid={`step-image-picker-${index}`}
                                                                                                                openCameraMenu={openCameraMenu}
                                                                                                                handleOpenCamMenu={this.handleOpenCamMenu.bind(this)}
                                                                                                                anchorEl={anchorEl}
                                                                                                                handleCloseMenu={this.handleCloseMenu.bind(this)}
                                                                                                                handleOpenCamera={this.handleOpenCamera.bind(this)}
                                                                                                                handleOpenPhotoLib={this.handleOpenPhotoLib.bind(this)}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    : <></>
                                                                                                }
                                                                                            </div>
                                                                                        }
                                                                                        {editStep && editableKey === index ? 
                                                                                            <IconButton data-testid={"deleteStep"+index} onClick={() => this.handleDeleteListItem(index)}> <DeleteOutlineIcon className="deleteButton" fontSize="large" /></IconButton>
                                                                                            :
                                                                                            <IconButton><DragIndicatorRoundedIcon/></IconButton>
                                                                                        }
                                                                                    </div>
                                                                                </li>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </ul>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                <RctCollapsibleCard contentCustomClasses="p-0">
                                                    <FormGroup className="add-step-container" ><MatButton data-testid="add-step" color="primary" className="w-100" onClick={() => this.handleAddStep()}><div className="w-100 text-left d-flex align-items-center"><AddRoundedIcon fontSize="large" /><IntlMessages id="newactivity.AddAStep"/></div></MatButton></FormGroup>
                                                    {/* <FormGroup data-testid="add-step" className="text-left oe-text-primary" onClick={() => this.handleAddStep()}><div className="d-flex p-2 align-items-center"><AddRoundedIcon fontSize="large" /> <span className="addstep"><IntlMessages id="newactivity.AddAStep"/></span></div></FormGroup> */}
                                                </RctCollapsibleCard>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                                    {openCamera && <SherpaCamera datatestid="desktop-camera" handleCloseCamera={this.handleOpenCamera.bind(this)} handleCancel={this.handleCancelCam.bind(this)} popupThemeClass={popupThemeClass}/>}
                                    <PhotoLibrary 
                                        inputRef={this.state.inputRef}
                                        handlePhotoChange={this.handlePhotoChange.bind(this)}
                                        datatestid="photo-img-lib"
                                    />
                                <div className="col-sm-12 col-md-2 col-lg-2"></div>
                            </div>
                        </div>
                    </div>
                    <div className='footer-style listItem'>{process.env.BUILD_VERSION}</div>
                </div>
            </QueueAnim>
        );
   }
}

// map state to props
const mapStateToProps = ({ ActivityReducer, ActivityTemplateReducer, settings, authUser }) => {
    const {activity, schedules, steps, loading, activityExist, activityTitle, errorMessage, successMessage, errorTitle, unSubscribeLoading} = ActivityReducer
    const {activityTemplates, groups, selectedTemplate, filteredTemplates, selectedGroups} = ActivityTemplateReducer
    const {screenThemeClass, popupThemeClass} = settings
    const { userDetails } = authUser;
    const displayName = userDetails ? userDetails.displayName || (`${userDetails.firstName || "..."} ${userDetails.lastName || "User"}`) : "...";
   return { activity, schedules, steps, loading, activityExist, activityTitle, errorMessage, activityTemplates, groups, selectedTemplate, screenThemeClass, popupThemeClass, filteredTemplates, selectedGroups, successMessage, errorTitle, unSubscribeLoading, displayName }
}

export default connect(mapStateToProps, {
    addPrivateActivity,
    updatePrivateActivity,
    getPrivateActivityById,
    checkDuplicateActivity,
    clearActivityData,
    checkDuplicateTitleAndUpdate,
    removeAPIErrorMessage,
    getActivityTemplates,
    getActivityGroups,
    selectActivityTemplate,
    filterActivityTemplate, 
    showActivitySuccess,
    unSubscribeActivity,
    getUserById
})(NewActivity);
