
import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {Label} from 'reactstrap'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import addRoutine from "../../assets/imgv2/addRoutine.png";
import img1 from "../../assets/imgv2/work1.png";
import addTitle from "../../assets/imgv2/addTitle.png";
import onStep from "../../assets/imgv2/onStep.png";
import stepAdded from "../../assets/imgv2/stepAdded.png";
import setTime from "../../assets/imgv2/SetTime.png";
import editDrop from "../../assets/imgv2/editDrop.png";
import editNotiDrop from '../../assets/imgv2/editNotiDrop.png'
import dragStep from "../../assets/imgv2/dragStep.png";
import qdragStep from "../../assets/img/qdragStep.png";
import editStep from "../../assets/imgv2/editStep.png";
import addNotification from "../../assets/imgv2/addNotification.png";
import addNotiImage from "../../assets/imgv2/addNotiImage.png";
import addStepImage from "../../assets/imgv2/addStepImage.png";
import editActivityOptions from "../../assets/imgv2/addOptions.png";
import addActivityImage from "../../assets/imgv2/addImage.png";
import addSchedule from "../../assets/imgv2/AddSchedule.png";
import notificationAdded from "../../assets/imgv2/notificationAdded.png";
import qscheduleAdded from "../../assets/img/qscheduleAdded.png"
import qscheduleEdit from "../../assets/img/qscheduleEdit.png"
import quicktaskimg from '../../assets/img/quickTask.png';
import qaddstep from "../../assets/img/qaddstep.png"
import qstepAdd from '../../assets/img/qstepAdd.png'
import qeditStep from "../../assets/img/qeditStep.png"
import qstepsadded from "../../assets/img/qstepsadded.png"
import templates from "../../assets/img/template.png"
import moreFilter from "../../assets/img/moreFilter.png"
import confirmation from "../../assets/img/confirmation.png"
import IntlMessages from '../../util/IntlMessages'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

class HelpDesk extends Component {
    state = {  }
    handleClose=()=>{
        this.props.closeHelper();
    }
    render() {
        const { fullScreen,title } = this.props; 
        const activity=<IntlMessages id="newactivity.info_CreateActivity" />
        const notification=<IntlMessages id="NewNotification.info-CreateNotification" />
        const template=<IntlMessages id="Template.info_ActivityTemplate" />
        return (
            <div>
                <Dialog
                    fullScreen={true}
                    open={this.props.open}
                    onClose={this.handleClose}
                    fullWidth={true}
                    maxWidth="xl"
                    aria-labelledby="responsive-dialog-title"
                    // PaperProps={
                    //     { style: { borderRadius: 8} }
                    // }
                    className={`${this.props.popupThemeClass} help-page-popup`}
                >
                    <AppBar position="static" className="session-header">
                        <Toolbar>
                            <div className="d-flex template-header align-items-center justify-content-between p-3 w-100" >
                                <Label data-testid="title-name" className="listItemTitle mb-0">{title}</Label>
                                <div data-testid="close-button" className="backbutton" onClick={this.handleClose}><CloseRoundedIcon /></div>
                            </div>
                        </Toolbar>
                    </AppBar>
                    {title.props.id === notification.props.id ?
                        <DialogContent data-testid="stepsToAdd">
                            <div data-testid="stepsToNotification" className="listItemTitle mb-2"><IntlMessages id="NewNotification.info-AddNotification"/></div>
                            <p><IntlMessages id="NewNotification.info-AddNotificationDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit border" src={addNotification} alt="" /></div>
                            <div data-testid="stepsToNotification" className="listItemTitle mb-2"><IntlMessages id="NewNotification.info-AddImage"/></div>
                            <p><IntlMessages id="NewNotification.info-AddImageDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit border" src={addNotiImage} alt="" /></div>
                            <div className="listItemTitle mb-2 mt-3"><IntlMessages id="NewNotification.info-AddSchedule"/></div>
                            <p><IntlMessages id="NewNotification.info-AddScheduleDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit mb-2 border" src={addSchedule} alt="" /></div>
                            <div className="listItemTitle mb-2 mt-3"><IntlMessages id="NewNotification.info-ScheduleAdded"/></div>
                            <p><IntlMessages id="NewNotification.info-ScheduleAddedDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit border" src={notificationAdded} alt="" /></div>
                            <div className="listItemTitle mb-2 mt-3"><IntlMessages id="NewNotification.info-EditDeleteSchedule"/></div>
                            <p><IntlMessages id="NewNotification.info-EditDeleteScheduleDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit mb-4 border" src={editNotiDrop} alt="" /></div>
                        </DialogContent>
                    : title.props.id === activity.props.id ?
                        <DialogContent>
                            <div data-testid="stepsToActivity" className="listItemTitle mb-2 mt-3"><IntlMessages id="newactivity.info_AddActivity"/></div>
                            <p><IntlMessages id="newactivity.info_AddActivityDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit mb-2 border" src={addRoutine} alt="" /></div>
                            <div data-testid="stepsToNotification" className="listItemTitle mb-2"><IntlMessages id="newactivity.info_AddImage"/></div>
                            <p><IntlMessages id="newactivity.info-AddImageDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit border" src={addActivityImage} alt="" /></div>
                            <div className="listItemTitle mb-2 mt-3"><IntlMessages id="newactivity.info_EditActivityOptions"/></div>
                            <p><IntlMessages id="newactivity.info_EditActivityOptionsDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit border" src={editActivityOptions} alt="" /></div>
                            <div className="listItemTitle mb-2 mt-3"><IntlMessages id="newactivity.info_AddSchedule"/></div>
                            <p><IntlMessages id="newactivity.info_AddScheduleDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit border" src={setTime} alt="" /></div>
                            <div className="listItemTitle mb-2 mt-3"><IntlMessages id="newactivity.info_ScheduleAdded"/></div>
                            <p><IntlMessages id="newactivity.info_ScheduleAddedDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit border" src={img1} alt="" /></div>
                            <div className="listItemTitle mb-2 mt-3"><IntlMessages id="newactivity.info_EditDeleteSchedule"/></div>
                            <p><IntlMessages id="newactivity.info_EditDeleteScheduleDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit border" src={editDrop} alt="" /></div>
                            <div className="listItemTitle mb-2 mt-3"><IntlMessages id="newactivity.info_Steps"/></div>
                            <p><IntlMessages id="newactivity.info_StepsDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit border" src={onStep} alt="" /></div>
                            <div className="listItemTitle mb-2 mt-3"><IntlMessages id="newactivity.info_AddStep"/></div>
                            <p><IntlMessages id="newactivity.info_AddStepDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit border" src={addTitle} alt="" /></div>
                            <div data-testid="stepsToNotification" className="listItemTitle mb-2"><IntlMessages id="newactivity.info_AddStepImage"/></div>
                            <p><IntlMessages id="newactivity.info-AddStepImageDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit border" src={addStepImage} alt="" /></div>
                            <div className="listItemTitle mb-2 mt-3"><IntlMessages id="newactivity.info_StepsAdded"/></div>
                            <p><IntlMessages id="newactivity.info_StepsAddedDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit border" src={stepAdded} alt="" /></div>
                            <div className="listItemTitle mb-2 mt-3"><IntlMessages id="newactivity.info_DragStep"/></div>
                            <p><IntlMessages id="newactivity.info_DragStepDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit border" src={dragStep} alt="" /></div>
                            <div className="listItemTitle mb-2 mt-3"><IntlMessages id="newactivity.info_EditStep"/></div>
                            <p><IntlMessages id="newactivity.info_EditStepDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit mb-4 border" src={editStep} alt="" /></div>
                        </DialogContent>
                    :
                        title.props.id === template.props.id ?
                        <DialogContent>
                            <div data-testid="stepsToActivity" className="listItemTitle mb-2 mt-3"><IntlMessages id="Template.info_template"/></div>
                            <p><IntlMessages id="Template.info_templateDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit mb-2 border" src={templates} alt="" /></div>
                            <div className="listItemTitle mb-2 mt-3"><IntlMessages id="Template.info_more"/></div>
                            <p><IntlMessages id="Template.info_moreDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit border" src={moreFilter} alt="" /></div>
                            <div className="listItemTitle mb-2 mt-3"><IntlMessages id="Template.info_SelectTemplate"/></div>
                            <p><IntlMessages id="Template.info_SelectTemplateDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit border" src={templates} alt="" /></div>
                            <div className="listItemTitle mb-2 mt-3"><IntlMessages id="Template.info_Confirmation"/></div>
                            <p><IntlMessages id="Template.info_ConfirmationDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit border" src={confirmation} alt="" /></div>
                        </DialogContent>
                    :
                        <DialogContent>
                            <div data-testid="stepsToQuicktask"className="listItemTitle mb-2 mt-3"><IntlMessages id="NewQuicktask.info-AddQuickStepTask"/></div>
                            <p><IntlMessages id="NewQuicktask.info-AddQuickStepTaskDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit mb-2 border" src={quicktaskimg} alt="" /></div>
                            <div className="listItemTitle mb-2 mt-3"><IntlMessages id="NewQuicktask.info-AddSchedule"/></div>
                            <p><IntlMessages id="NewQuicktask.info-AddScheduleDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit border" src={setTime} alt="" /></div>
                            <div className="listItemTitle mb-2 mt-3"><IntlMessages id="NewQuicktask.info-ScheduleAdded"/></div>
                            <p><IntlMessages id="NewQuicktask.info-ScheduleAddedDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit border" src={qscheduleAdded} alt="" /></div>
                            <div className="listItemTitle mb-2 mt-3"><IntlMessages id="NewQuicktask.info-EditDeleteSchedule"/></div>
                            <p><IntlMessages id="NewQuicktask.info-EditDeleteScheduleDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit border" src={qscheduleEdit} alt="" /></div>
                            <div className="listItemTitle mb-2 mt-3"><IntlMessages id="NewQuicktask.info-Steps"/></div>
                            <p><IntlMessages id="NewQuicktask.info-StepsDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit border" src={qaddstep} alt="" /></div>
                            <div className="listItemTitle mb-2 mt-3"><IntlMessages id="NewQuicktask.info-AddStep"/></div>
                            <p><IntlMessages id="NewQuicktask.info-AddStepDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit border" src={qstepAdd} alt="" /></div>
                            <div className="listItemTitle mb-2 mt-3"><IntlMessages id="NewQuicktask.info-StepsAdded"/></div>
                            <p><IntlMessages id="NewQuicktask.info-StepsAddedDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit border" src={qstepsadded} alt="" /></div>
                            <div className="listItemTitle mb-2 mt-3"><IntlMessages id="NewQuicktask.info-DragStep"/></div>
                            <p><IntlMessages id="NewQuicktask.info-DragStepDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit border" src={qdragStep} alt="" /></div>
                            <div className="listItemTitle mb-2 mt-3"><IntlMessages id="NewQuicktask.info-EditStep"/></div>
                            <p><IntlMessages id="NewQuicktask.info-EditStepDesc"/></p>
                            <div className="row text-center mx-auto"><img className="img-fluid mx-auto img-object-fit mb-4 border" src={qeditStep} alt="" /></div>
                        </DialogContent>
                    }
                </Dialog>
            </div>
        );
    }
}
export default HelpDesk;