/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Label } from 'reactstrap'
import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment'
import queryString from 'query-string';
import Button from '@mui/material/Button';
import AssingActivityScheduleUpdate from '../ActivitySchedule/AssingActivityScheduleUpdate'
import IntlMessages from "../../util/IntlMessages"
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import {
    generateRRule,
    getDayOccurences,
    getMonthOccurence,
    parseCustomWeekDay,
    parseCustomFreq,
    refreshCurrentSchedule,
    handleCustomSchedule,
    getweekDayWithCheck,
    getISODateString,
    getCookies,
    getCustomeRecurrenceFromRrule
    // ellipsify,
} from '../../helpers/helpers';

import {
    addPrivateActivity,
    updatePrivateActivity,
    getPrivateActivityById,
    checkDuplicateActivity,
    clearActivityData,
    checkDuplicateTitleAndUpdate,
    removeAPIErrorMessage,
    showActivitySuccess,
} from '../../actions/ActivityActions'
import {
    addActivitySchedules,
    addNewActivitySchedule,
    editActivitySchedule,
    deleteActivitySchedule,
    removeAssignAPIErrorMessage,
    getActivitySchedulesList
} from '../../actions/AssignActivityActions'

import {
    getActivityTemplates,
    getActivityGroups,
    selectActivityTemplate,
    filterActivityTemplate,
} from '../../actions/ActivityTemplateActions'

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((item, index) => {
        item.position = index
    })
    return result;
};
// const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
});
const getListStyle = isDraggingOver => ({

});

class AssignActivitySchedule extends Component {
    state = {
        idToken: "",
        userId: 'ODWYDyio2RhrEHJGq6fDXrSCpeI2',
        orgId: 'Test',
        activityId: '',
        select: 'options',
        activeIndex: 0,
        activityTitle: '',
        activityDesc: '',
        stepTitle: '',
        stepDesc: '',
        currentSchedule: {
            startDate: moment().format('MMM DD YYYY'),
            startTime: moment().format('hh:mm A'),
            endTime: moment().format('hh:mm A'),
            text: "Daily",
            recurrenceMethod: "",
            rrule: "",
            objStartDate: moment(),
            objStartTime: moment(),
            objEndTime: moment().add(5, 'minutes'),
            nextSchedules: []
        },
        openSchedule: false,
        schedules: this.props.schedules,
        steps: [],
        editStep: false,
        editableKey: 0,
        anchorEl: null,
        isTitleFilled: true,
        editSchedule: false,
        editScheduleKey: 0,
        customEnabled: false,
        weekSelected: false,
        monthSelected: false,
        customRepeatValue: 'Day',
        customCount: 1,
        monthlyOccurenceDay: '',
        customUntill: moment().add(1, 'd').format('MMM DD YYYY'),
        customInterval: 1,
        weekDayWithCheck: [
            {
                day: 'MO',
                checked: true,
                name: 'MON'
            },
            {
                day: 'TU',
                checked: false,
                name: 'TUE'
            },
            {
                day: 'WE',
                checked: false,
                name: 'WED'
            },
            {
                day: 'TH',
                checked: false,
                name: 'THU'
            },
            {
                day: 'FR',
                checked: false,
                name: 'FRI'
            },
            {
                day: 'SA',
                checked: false,
                name: 'SAT'
            },
            {
                day: 'SU',
                checked: false,
                name: 'SUN'
            },
        ],
        repeatType: [
            {
                type: 'Never',
                selected: true,
                value: null
            },
            {
                type: 'After',
                selected: false,
                value: null
            },
            {
                type: 'On',
                selected: false,
                value: null
            }
        ],
        stepTitleEmpty: false,
        invalidEndTime: false,
        propsUpdated: false,
        minCharLength: true,
        openActivityTemplate: false,
        textAreaField: React.createRef(),
        isDataSaved: true,
        showUnSavedWarning: false,
        repeatError: '',
        customCountError: '',
        repeatValue: 'Daily',
    }

    onDragEnd(result) {
        // dropped outside the list
        let steps = JSON.parse(JSON.stringify(this.state.steps))
        const { stepTitle, editStep } = this.state
        if (!result.destination) {
            return;
        }
        const updatedSteps = reorder(
            steps,
            result.source.index,
            result.destination.index
        );
        if (editStep) {
            const index = this.getElemntIndex(updatedSteps, stepTitle)
            this.setState({ steps: updatedSteps, editableKey: index });
        } else {
            this.setState({ steps: updatedSteps, isDataSaved: false });
        }
    }

    getElemntIndex(array, element) {
        let index = 0
        array.map((item, key) => {
            if (item.title === element) {
                index = key
            }
        })
        return index
    }

    componentDidMount() {
        let {idToken,userId, orgId, activityId} = this.props;
        
        this.props.getActivitySchedulesList(userId, orgId, activityId, idToken);
        this.setState({ schedules:[], idToken, userId, orgId, activityId, currentSchedule: refreshCurrentSchedule() })
    }


    getParams() {
        let params = null
        if (this.props.location.search) {
            params = queryString.parse(this.props.location.search)
        } else if (getCookies('objCookieData')) {
            params = JSON.parse(getCookies('objCookieData'))
        }
        // console.log('params-->', params)
        return params
    }
  
    componentDidUpdate() {
        if (this.props.activity && !this.state.propsUpdated) {
            this.addActivityToState(this.props.activity, !this.state.propsUpdated)
        }
    }

    addActivityToState(activity, propsUpdated) {
        const activityTitle = activity.Routine.title
        const activityDesc = activity.Routine.detailedDescription
        let steps = []
        let schedules = []
        if (activity.Routine.newSchedule) {
            Object.keys(activity.Routine.newSchedule).map((scheduleKey, index) => {
                const activitySchedule = activity.Routine.newSchedule[scheduleKey]
                const schedule = {
                    startDate: activitySchedule.startDate,
                    startTime: moment(activitySchedule.startTime, 'HH:mm').format('hh:mm A'),
                    endTime: moment(activitySchedule.endTime, 'HH:mm').format('hh:mm A'),
                    text: activitySchedule.text,
                    recurrenceMethod: activitySchedule.recurrenceMethod,
                    rrule: activitySchedule.rrule,
                    objStartDate: moment(getISODateString(activitySchedule.startDate)),
                    objStartTime: moment(activitySchedule.startTime, 'HH:mm'),
                    objEndTime: moment(activitySchedule.endTime, 'HH:mm'),
                    scheduleId: scheduleKey
                }
                schedules.push(schedule)
            })
        }
        if (activity.Steps) {
            Object.keys(activity.Steps).map((stepKey, index) => {
                const activityStep = activity.Steps[stepKey]
                const step = {
                    title: activityStep.title,
                    description: activityStep.description ? activityStep.description : '',
                    position: activityStep.position ? activityStep.position : index,
                    stepId: stepKey
                }
                steps.push(step)
            })
        }
        this.setState({ activityTitle, activityDesc, steps, schedules, propsUpdated, openActivityTemplate: false, isDataSaved: true })
    }

    addActivityTemlateToState(activity) {
        const activityTitle = activity.Routine.title
        const activityDesc = activity.Routine.detailedDescription
        let steps = []
        let schedules = []
        if (activity.Routine.newSchedule) {
            Object.keys(activity.Routine.newSchedule).map((scheduleKey, index) => {
                const activitySchedule = activity.Routine.newSchedule[scheduleKey]
                const schedule = {
                    startDate: activitySchedule.startDate,
                    startTime: moment(activitySchedule.startTime, 'HH:mm').format('hh:mm A'),
                    endTime: moment(activitySchedule.endTime, 'HH:mm').format('hh:mm A'),
                    text: activitySchedule.text,
                    recurrenceMethod: activitySchedule.recurrenceMethod,
                    rrule: activitySchedule.rrule,
                    objStartDate: moment(getISODateString(activitySchedule.startDate)),
                    objStartTime: moment(activitySchedule.startTime, 'HH:mm'),
                    objEndTime: moment(activitySchedule.endTime, 'HH:mm'),
                }
                schedules.push(schedule)
            })
        }
        if (activity.Steps) {
            Object.keys(activity.Steps).map((stepKey, index) => {
                const activityStep = activity.Steps[stepKey]
                const step = {
                    title: activityStep.title,
                    description: activityStep.description ? activityStep.description : '',
                    position: activityStep.position ? activityStep.position : index,
                }
                steps.push(step)
            })
        }
        this.setState({ activityTitle, activityDesc, steps, schedules, openActivityTemplate: false, editStep: false, editableKey: -1, isDataSaved: false })
    }

    handleChangeIndex(index) {
        if (this.state.activityTitle && this.state.activityTitle !== '') {
            this.setState({ activeIndex: index, isTitleFilled: true, isDataSaved: false });
        } else {
            this.setState({ isTitleFilled: false, isDataSaved: false })
        }
    }

    handleChange(event, value) {
        if (this.state.activityTitle && this.state.activityTitle !== '') {
            this.setState({ activeIndex: value, isTitleFilled: true, isDataSaved: false });
        } else {
            this.setState({ isTitleFilled: false, isDataSaved: false })
        }
    }

    handleOpenSchedule() {
        let { weekDayWithCheck, currentSchedule, editSchedule } = this.state;
        weekDayWithCheck = getweekDayWithCheck()
        if (!this.openSchedule) {
            currentSchedule = refreshCurrentSchedule()
            editSchedule = false;
            currentSchedule.notify = this.getActivityNotifyStatus(this.props.activityType, currentSchedule);
        }
        this.setState({ openSchedule: !this.state.openSchedule, isTitleFilled: true, weekDayWithCheck, customEnabled: false, currentSchedule, repeatError: '', customCountError: '', customInterval: 1, customCount: 1, editSchedule });
    }

    handleSaveSchedule() {
        let schedules = this.state.schedules
       
        let { activityId, userId ,commonAllActivities} = this.props;
        let currentSchedule = this.state.currentSchedule
        if (currentSchedule.objEndTime && currentSchedule.objEndTime.diff(currentSchedule.objStartTime, 'minutes') < 5) {
            this.setState({ invalidEndTime: true })
        } else if (this.state.weekDayWithCheck.filter(weekday => weekday.checked === true).length === 0 || this.state.repeatError !== '' || this.state.customCountError !== '') {
            console.log('Please select a day form weekday.')
        } else {
            const schedule = {
                startDate: moment(getISODateString(currentSchedule.startDate)).format('MMM DD YYYY'),
                startTime: moment(getISODateString(currentSchedule.objStartTime)).format('hh:mm A'),
                endTime: moment(getISODateString(currentSchedule.objEndTime)).format('hh:mm A'),
                text: currentSchedule.text,
                recurrenceMethod: currentSchedule.recurrenceMethod,
                rrule: currentSchedule.rrule,
                objStartDate: currentSchedule.objStartDate,
                objStartTime: currentSchedule.objStartTime,
                objEndTime: currentSchedule.objEndTime,
                notify: currentSchedule.notify
            }
           
            if (this.state.editSchedule) {
                schedule.scheduleId = currentSchedule.scheduleId;
                schedules[this.state.editScheduleKey] = schedule
                this.props.editActivitySchedule(userId,this.props.orgId, activityId, this.props.idToken, schedule)
                this.setState({ schedules, openSchedule: false, editSchedule: false, invalidEndTime: false, currentSchedule: refreshCurrentSchedule(), customEnabled: false, isDataSaved: false })
            } else {
                this.props.addNewActivitySchedule(userId,this.props.orgId, activityId, this.props.idToken, {...schedule})
                schedule.scheduleId = `${activityId + schedules.length}`
                schedules.push(schedule)
                //console.log(schedules);
                this.setState({ schedules, openSchedule: false, invalidEndTime: false, currentSchedule: refreshCurrentSchedule(), customEnabled: false, isDataSaved: false })
            }
            this.props.setDataChangedFlag();
        }

    }

    handleStepOnBlur() {
        let steps = JSON.parse(JSON.stringify(this.state.steps))
        const emptyStep = steps.length > 0 ? steps.filter(item => item.title === '') : []
        if (emptyStep.length > 0 && emptyStep[0].title === '') {
            this.setState({ stepTitleEmpty: true, editStep: true })
        } else {
            this.setState({ editScheduleKey: -1, editStep: true })
        }
    }

    handleAddStep() {
        let steps = JSON.parse(JSON.stringify(this.state.steps))
        const emptyStep = steps.length > 0 ? steps.filter(item => item.title === '') : []
        if (emptyStep.length > 0 && emptyStep[0].title === '') {
            this.setState({ stepTitleEmpty: true, editStep: true, isDataSaved: false })
        } else {
            const step = { title: '', description: '', position: this.state.steps.length }
            steps.push(step)
            this.setState({ steps, editStep: true, stepTitle: '', stepDesc: '', editableKey: steps.length - 1, isDataSaved: false })
        }

    }

    handleListItem(e, key) {
        let steps = JSON.parse(JSON.stringify(this.state.steps))
        const updatedSteps = steps.filter(item => item.title !== '')
        let stepTitle = steps[key].title
        let stepDesc = steps[key].description
        this.setState({ stepTitleEmpty: false, steps: updatedSteps, editStep: true, editableKey: key, stepTitle, stepDesc, isDataSaved: false })
    }

    handleDeleteListItem(index) {
        let steps = this.state.steps.filter((item, key) => key !== index)
        console.log(steps)
        this.setState({ steps, editStep: false, stepTitle: '', stepDesc: '', stepTitleEmpty: false, isDataSaved: false })
    }

    handleSaveActivity() {
        if (this.state.activityTitle !== '' && this.state.activityTitle.length > 2) {
            if (this.props.activity && this.props.activity.activityId) {
                this.handleUpdateActivity()
            } else {
                this.handleAddActivity()
            }
            this.setState({ isDataSaved: true })
        } else if (this.state.activityTitle.length < 3) {
            this.setState({ minCharLength: false });
        } else {
            this.setState({ isTitleFilled: false });
        }

    }

    handleAddActivity() {
        const { idToken, userId, orgId } = this.state
        let schedules = []
        const activityType = this.props.selectedTemplate && this.props.selectedTemplate.Detail.Routine.type ? this.props.selectedTemplate.Detail.Routine.type : "Routine"
        this.state.schedules.map((item, key) => {
            const { startDate, objStartTime, objEndTime, text, recurrenceMethod, rrule } = item
            const time = moment(getISODateString(objStartTime)).format('HH:mm').toString()
            const dateStr = moment(getISODateString(startDate)).set({ hour: parseInt(time.split(":")[0]), minute: parseInt(time.split(":")[1]) }).toString();
            const newSchedule = {
                "startDate": new Date(dateStr).toISOString(),
                "startTime": time,
                "endTime": moment(objEndTime).format('HH:mm').toString(),
                text,
                recurrenceMethod, rrule
            }

            schedules.push(newSchedule)
        })
        const activity = {
            "title": this.state.activityTitle,
            "type": activityType,
            "detailedDescription": this.state.activityDesc,
            "doNotNotifyIfDecliningToStartRoutine": false,
            "doNotNotifyOnRoutineCancel": false,
            "doNotNotifyOnRoutineCompletion": false,
            "doNotNotifyOnRoutineStart": false,
            "doNotShowRoutineCompletionReward": false,
            "notifyOnStepCompletion": false,
            "schedules": schedules,
            "steps": this.state.steps

        }
        this.props.checkDuplicateActivity(userId, orgId, activity, idToken)
    }

    handleUpdateActivity() {
        const { idToken, userId, orgId } = this.state
        let activityId = this.props.activity.activityId
        const activityType = this.props.selectedTemplate && this.props.selectedTemplate.Detail.Routine.type ? this.props.selectedTemplate.Detail.Routine.type : "Routine"
        let schedules = []
        let steps = []
        if (this.props.activity.Routine.newSchedule && Object.keys(this.props.activity.Routine.newSchedule).length > 0) {
            this.state.schedules.map((item, key) => {
                const { startDate, objStartTime, objEndTime, text, recurrenceMethod, rrule } = item
                const time = moment(getISODateString(objStartTime)).format('HH:mm').toString()
                const dateStr = moment(getISODateString(startDate)).set({ hour: parseInt(time.split(":")[0]), minute: parseInt(time.split(":")[1]) }).toString();
                const newSchedule = {}
                newSchedule['startDate'] = new Date(dateStr).toISOString()
                newSchedule['startTime'] = time
                newSchedule['endTime'] = moment(getISODateString(objEndTime)).format('HH:mm').toString()
                newSchedule['text'] = text
                newSchedule['recurrenceMethod'] = recurrenceMethod
                newSchedule['rrule'] = rrule
                if (Object.keys(this.props.activity.Routine.newSchedule)[key]) {
                    newSchedule['scheduleId'] = Object.keys(this.props.activity.Routine.newSchedule)[key]
                }
                schedules.push(newSchedule)
            })
        } else {
            this.state.schedules.map((item, key) => {
                const { startDate, objStartTime, objEndTime, text, recurrenceMethod, rrule } = item
                const time = moment(getISODateString(objStartTime)).format('HH:mm').toString()
                const dateStr = moment(getISODateString(startDate)).set({ hour: parseInt(time.split(":")[0]), minute: parseInt(time.split(":")[1]) }).toString();
                const newSchedule = {
                    "startDate": new Date(dateStr).toISOString(),
                    "startTime": time,
                    "endTime": moment(getISODateString(objEndTime)).format('HH:mm').toString(),
                    text,
                    recurrenceMethod,
                    rrule,
                }

                schedules.push(newSchedule)
            })
        }
        if (this.props.activity.Steps && Object.keys(this.props.activity.Steps).length > 0) {
            this.state.steps.map((step, index) => {
                let stepItem = step
                if (Object.keys(this.props.activity.Steps)[index]) {
                    stepItem['stepId'] = Object.keys(this.props.activity.Steps)[index]
                }
                steps.push(stepItem)
            })
        } else {
            this.state.steps.map((step, index) => {
                let stepItem = step
                steps.push(stepItem)
            })
        }
        const activity = {
            "title": this.state.activityTitle,
            "type": activityType,
            "detailedDescription": this.state.activityDesc,
            "doNotNotifyIfDecliningToStartRoutine": false,
            "doNotNotifyOnRoutineCancel": false,
            "doNotNotifyOnRoutineCompletion": false,
            "doNotNotifyOnRoutineStart": false,
            "doNotShowRoutineCompletionReward": false,
            "notifyOnStepCompletion": false,
            "schedules": schedules,
            "steps": steps

        }
        if (this.props.activity.Routine.title !== this.state.activityTitle) {
            this.props.checkDuplicateTitleAndUpdate(userId, orgId, activityId, activity, idToken)
        } else {
            this.props.updatePrivateActivity(userId, orgId, activityId, activity, idToken)
        }
    }

    handleClickMenu(event, key) {
        this.setState({ anchorEl: event.currentTarget, editScheduleKey: key });
    };

    handleCloseMenu = () => {
        this.setState({ anchorEl: null });
    };

    handleEditSchedule = event => {
        let {schedules} = this.props;
        let currentSchedule = schedules[this.state.editScheduleKey]
        const recurrenceMethod = currentSchedule.recurrenceMethod
        let anchorEl = null
        let editSchedule = true
        let openSchedule = true
        let invalidEndTime = null

        let customEnabled = this.state.customEnabled
        if (recurrenceMethod === 'custom') {
            customEnabled = true
            const options = currentSchedule.rrule.split(':')[2].split(';')
            getCustomeRecurrenceFromRrule(currentSchedule, this);
        }
        currentSchedule.notify = this.getActivityNotifyStatus(this.props.activityType, currentSchedule);
        if (currentSchedule.objEndTime && currentSchedule.objEndTime.diff(currentSchedule.objStartTime, 'minutes') < 5) {
            invalidEndTime = true
        } else {
            invalidEndTime = false
        }
        this.setState({ anchorEl, editSchedule, openSchedule, currentSchedule, invalidEndTime, customEnabled});
        this.props.setDataChangedFlag();
    };

    getActivityNotifyStatus = (activityType, currentSchedule) => {
        if(activityType === "Touchpoint") {
            return false;
        } else if(activityType === "QT-Reminder") {
            return true
        } else {
            let notify = currentSchedule.notify === false ? currentSchedule.notify : true;
            return notify;
        }   
    }

    handleDeleteSchedule = event => {
        let {commonAllActivities,schedules} = this.props;
        this.props.deleteActivitySchedule(this.props.userId,this.props.orgId, this.props.activityId, this.props.idToken, schedules[this.state.editScheduleKey],schedules);
        schedules.splice(this.state.editScheduleKey, 1)
        this.setState({ anchorEl: null, schedules, isDataSaved: false });
        this.props.setDataChangedFlag();
    };

    //Schedules methods

    handleRepeat(repeatText) {
        let currentSchedule = this.state.currentSchedule
        const rruleDic = generateRRule(repeatText, null, this.state.currentSchedule)
        currentSchedule['rrule'] = rruleDic.rrule
        currentSchedule['text'] = rruleDic.text
        currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
        currentSchedule['nextSchedules'] = rruleDic.nextSchedules
        if (repeatText === 'Custom') {
            this.setState({ repeatValue: repeatText, currentSchedule, customEnabled: true, customRepeatValue: 'Day', weekSelected: false, monthSelected: false })
        } else {
            this.setState({ repeatValue: repeatText, currentSchedule, customEnabled: false })
        }
    }

    handleToggle() {
        let currentSchedule = this.state.currentSchedule
        let { weekDayWithCheck } = this.state;
        let repeatValue = ''
        weekDayWithCheck = getweekDayWithCheck()
        if (!this.state.customEnabled) {
            repeatValue = 'Custom'
            const rruleDic = generateRRule('Custom', null, this.state.currentSchedule)
            currentSchedule['text'] = rruleDic.text
            currentSchedule['rrule'] = rruleDic.rrule
            currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
            currentSchedule['nextSchedules'] = rruleDic.nextSchedules
        } else {
            repeatValue = 'Daily'
            const rruleDic = generateRRule('Daily', null, this.state.currentSchedule)
            currentSchedule['text'] = rruleDic.text
            currentSchedule['rrule'] = rruleDic.rrule
            currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
        }
        this.setState({ repeatValue, customEnabled: !this.state.customEnabled, weekDayWithCheck, currentSchedule, customRepeatValue: 'Day', weekSelected: false, monthSelected: false })
    }

    handleStartDateChange(date) {
        const { invalidEndTime, repeatValue, customEnabled, customRepeatValue, customInterval, customCount, monthlyOccurenceDay, repeatType, weekDayWithCheck, repeatError, customCountError } = this.state
        let currentSchedule = this.state.currentSchedule
        let customUntill = this.state.customUntill
        currentSchedule['startDate'] = date
        currentSchedule['objStartDate'] = date
        currentSchedule['objStartTime'] = date
        currentSchedule['objEndTime'] = date
        customUntill = moment(date).add(1, 'd').format('MMM DD YYYY')
        if (customEnabled) {
            const weekDays = customRepeatValue != "Week" ? getweekDayWithCheck() : weekDayWithCheck;
            handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDays, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, this)
        } else {
            const rruleDic = generateRRule(repeatValue, null, this.state.currentSchedule)
            currentSchedule['text'] = rruleDic.text
            currentSchedule['rrule'] = rruleDic.rrule
            currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
            this.setState({ currentSchedule, customUntill })
        }

    }

    handleStartTimeChange(time) {
        const { customUntill, repeatValue, customEnabled, customRepeatValue, customInterval, customCount, monthlyOccurenceDay, repeatType, weekDayWithCheck, repeatError, customCountError } = this.state
        let currentSchedule = this.state.currentSchedule
        currentSchedule['startTime'] = time //moment(time).format('hh:mm A')
        currentSchedule['objStartTime'] = time
        const invalidEndTime = currentSchedule.objEndTime && currentSchedule.objEndTime.diff(currentSchedule.objStartTime, 'minutes') < 5
        if (customEnabled) {
            const weekDays = customRepeatValue != "Week" ? getweekDayWithCheck() : weekDayWithCheck;
            handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDays, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, this)
        } else {
            const rruleDic = generateRRule(repeatValue, null, this.state.currentSchedule)
            currentSchedule['text'] = rruleDic.text
            currentSchedule['rrule'] = rruleDic.rrule
            currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
            this.setState({ currentSchedule, customUntill, invalidEndTime })
        }
    }
    handleEndTimeChange(time) {
        const { customUntill, repeatValue, customEnabled, customRepeatValue, customInterval, customCount, monthlyOccurenceDay, repeatType, weekDayWithCheck, repeatError, customCountError } = this.state
        let currentSchedule = this.state.currentSchedule
        currentSchedule['endTime'] = time //moment(time).format('hh:mm A')
        currentSchedule['objEndTime'] = time
        const invalidEndTime = currentSchedule.objEndTime && currentSchedule.objEndTime.diff(currentSchedule.objStartTime, 'minutes') < 5
        if (customEnabled) {
            const weekDays = customRepeatValue != "Week" ? getweekDayWithCheck() : weekDayWithCheck;
            handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDays, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, this)
        } else {
            const rruleDic = generateRRule(repeatValue, null, this.state.currentSchedule)
            currentSchedule['text'] = rruleDic.text
            currentSchedule['rrule'] = rruleDic.rrule
            currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
            this.setState({ currentSchedule, customUntill, invalidEndTime })
        }
    }

    handleNotifyStateChange(){
        let currentSchedule = this.state.currentSchedule
        currentSchedule['notify'] = !currentSchedule.notify
        this.setState({currentSchedule})
    }

    handleRepeatSelection(e) {
        const { invalidEndTime, customInterval, customCount, monthlyOccurenceDay, repeatType, customUntill, weekDayWithCheck, repeatError, customCountError, currentSchedule } = this.state
        const customRepeatValue = e.target.value
        const weekDays = customRepeatValue != "Week" ? getweekDayWithCheck() : weekDayWithCheck;
        handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDays, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, this)
    }

    handleCustomMonthlyRepeat(e) {
        const { customInterval, customCount, customRepeatValue, repeatType, customUntill, weekDayWithCheck, repeatError, customCountError, currentSchedule, invalidEndTime } = this.state
        let monthlyOccurenceDay = e.target.value
        handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, this)
    }

    handleWeekDayChecked(weekItem, index) {
        const { customInterval, customCount, customRepeatValue, repeatType, customUntill, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime } = this.state
        let weekDayWithCheck = this.state.weekDayWithCheck
        weekDayWithCheck[index].checked = !weekItem.checked
        handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, this)

    }

    handleRepeatType(e, index) {
        const { customInterval, customCount, customRepeatValue, weekDayWithCheck, customUntill, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime } = this.state
        let repeatType = this.state.repeatType
        repeatType.map((item, key) => {
            if (key === index && e.target.checked) {
                item.selected = e.target.checked
            } else {
                item.selected = false
            }
        })
        handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, this)
    }

    handleCustomCount(e) {
        const { customInterval, repeatType, customRepeatValue, weekDayWithCheck, customUntill, monthlyOccurenceDay, repeatError, currentSchedule, invalidEndTime } = this.state
        let customCountError = Number(e.target.value) < Number(1) ? "Count should be greater than 0." : ''
        let customCount = Number(e.target.value) < Number(1) ? e.target.value : Number(e.target.value)
        if (customCountError === '') {
            handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, this)
        } else {
            this.setState({ customCountError, customCount })
        }
    }

    handleCustomUntill(date) {
        const { customInterval, repeatType, customRepeatValue, customCount, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime } = this.state
        let customUntill = date
        handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, this)
    }

    handleCustomInterval(e) {
        const { repeatType, customRepeatValue, customCount, customUntill, weekDayWithCheck, monthlyOccurenceDay, customCountError, currentSchedule, invalidEndTime } = this.state
        let customInterval = e.target.value
        let repeatError = ''
        if (this.state.customRepeatValue === 'Day') {
            repeatError = Number(e.target.value) < Number(1) ? "Repeat value should be greater than 0" : ''
            customInterval = e.target.value
        } else if (this.state.customRepeatValue === 'Week') {
            repeatError = (Number(e.target.value) < Number(1)) || (Number(e.target.value) > Number(5)) ? "Repeat value should be between 0 and 6" : ''
            customInterval = e.target.value
        } else if (this.state.customRepeatValue === 'Month') {
            repeatError = Number(e.target.value) < Number(1) ? "Repeat value should be greater than 0" : ''
            customInterval = e.target.value
        } else if (this.state.customRepeatValue === 'Year') {
            repeatError = Number(e.target.value) !== Number(1) ? "Repeat value should be 1" : ''
            customInterval = e.target.value
        }
        if (repeatError === '') {
            handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, this)
        } else {
            this.setState({ repeatError, customInterval })
        }
    }

    onConfirm() {
        this.props.removeAssignAPIErrorMessage()
        window.location.href = "/success"
    }

    openActivityTemplate() {
        this.setState({ openActivityTemplate: !this.state.openActivityTemplate })
    }

    handleSelectTemplate(key) {
        const activityTemplate = this.props.filteredTemplates[key]
        this.addActivityTemlateToState(activityTemplate.Detail)
        this.props.selectActivityTemplate(activityTemplate)
    }

    filterTemplateActivity(groupIds, activityTemplateGroups) {
        this.props.filterActivityTemplate(groupIds, activityTemplateGroups)
    }
    handleCloseSuccessPopup() {
        this.props.showActivitySuccess('')
    }

    handleStepTitleChange(e, index) {
        let steps = JSON.parse(JSON.stringify(this.state.steps))
        steps[index].title = e.target.value
        const stepTitleEmpty = e.target.value !== '' ? false : true
        this.setState({ steps, editStep: true, stepTitle: e.target.value, stepTitleEmpty, isDataSaved: false })
    }
    handleStepDescChange(e, index) {
        let steps = JSON.parse(JSON.stringify(this.state.steps))
        steps[index].description = e.target.value
        this.setState({ steps, editStep: true, stepDesc: e.target.value, isDataSaved: false })
    }
    stopEditStep() {
        let steps = JSON.parse(JSON.stringify(this.state.steps))
        const emptyStep = steps.length > 0 ? steps.filter(item => item.title === '') : []
        if (emptyStep.length > 0 && emptyStep[0].title === '') {
            this.setState({ stepTitleEmpty: true, editStep: true })
        } else {
            this.setState({ editStep: false })
        }
    }

    adjustHeight(el) {
        el.style.height = "1px";
        el.style.height = (25 + el.scrollHeight) + "px";
    }
    textAreaOnBlur(el) {
        el.style.height = "50px";
    }

    handleHeaderCancel() {
        this.setState({ showUnSavedWarning: !this.state.isDataSaved })
    }

    handleCloseWarning() {
        this.setState({ showUnSavedWarning: !this.state.showUnSavedWarning })
    }


    render() {
        const { weekDayWithCheck, customUntill, customCount, invalidEndTime, customInterval, repeatType, monthlyOccurenceDay, currentSchedule, weekSelected, monthSelected, customEnabled, openSchedule, customRepeatValue,  anchorEl, repeatError, customCountError } = this.state
        const {  popupThemeClass, schedules, userActivities, activityId, activityType } = this.props
        console.log('activity------>', activityType)
        const isUserActivity = userActivities && userActivities.findIndex(userActivityId => userActivityId == activityId) >= 0 ? true : false;
        return (
            <div data-testid="Schedule-list" >
               {schedules &&  schedules.length > 0 ? schedules.map((schedule, key) => (
                    <div data-testid={"Schedule" + key} key={key} className={'d-flex  py-2 border-bottom '+(key % 2 == 0 ? "schedule-list-item-odd" : "")} style={{ paddingLeft: "14px" }}>
                        <div className="d-flex w-60" >
                            <div className="">
                                <div className="calender-schedule">
                                    <div className="month-header">
                                        {schedule.startDate ? moment(getISODateString(schedule.startDate)).format('MMM') :moment().format('MMM')
                                        //moment(schedule.startDate,'MMM DD YYYY').format('MMM')
                                        }

                                    </div>
                                    <div className="schedule-date py-2 fw-semi-bold">
                                        {schedule.startDate ? moment(getISODateString(schedule.startDate)).format('DD'):moment().format('DD')}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center pl-2 text-left">
                                {`Repeats ${schedule.text}`}
                            </div>
                        </div>
                        <div className="d-flex w-40">
                            <div className="ml-auto">
                                <div className="d-block mb-1">
                                    <div className="d-flex align-items-center">
                                        <div className="d-block d-md-none month-header timing-round-icon">S</div>
                                        <div className="d-none d-md-block fw-semi-bold mr-1 details-heading"> Start : </div>
                                        {`${schedule.startTime}`}
                                    </div>
                                </div>
                                <div className="d-block mb-1">
                                    <div className="d-flex align-items-center">
                                        <div className="d-block d-md-none month-header timing-round-icon">E</div>
                                        <div className="d-none d-md-block fw-semi-bold mr-1 details-heading"> End <div className="d-inline-block" style={{ marginLeft: "9px" }}>:</div> </div>
                                        {`${schedule.endTime}`}
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="fw-semi-bold details-heading d-flex"><div className="d-block d-md-none month-header timing-round-icon"><NotificationsRoundedIcon className='text-white align-icons-assign'/></div><IntlMessages id="scheduleUpdate.notify"/>:</div>
                                    {this.getActivityNotifyStatus(activityType, schedule) ? 
                                        <CheckCircleRoundedIcon className='text-success'/>
                                        :
                                        <CancelRoundedIcon className='text-danger'/>
                                    }  
                                </div>
                            </div>
                            <div className=' mr-2 ml-1' style={{ marginTop: "0px" }}>
                                {isUserActivity &&
                                  <MoreVertIcon onClick={(event) => this.handleClickMenu(event, key)} color="primary" data-testid={"Schedule" + key + "more"} className="" aria-label="More" aria-owns={anchorEl ? 'long-menu' : null} aria-haspopup="true" />
                                }
                            </div>
                            <Menu id={`"schedule-"${key}`} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleCloseMenu} >
                                <MenuItem data-testid={"Schedule" + key + "edit"} onClick={this.handleEditSchedule}><div className="menuEditItem"><IntlMessages id="newactivity.Edit" /></div></MenuItem>
                                <MenuItem data-testid={"Schedule" + key + "delete"} onClick={this.handleDeleteSchedule}><div className="meniDeleteItem"><IntlMessages id="newactivity.Delete" /></div></MenuItem>
                            </Menu>

                        </div>

                    </div>
                )) : 
                    <div className="align-items-center my-2 text-center justify-content-center">
                          <div className="">There are no schedules found. </div>
                   </div>
                }
                {isUserActivity ?
                    <FormGroup className="align-items-center p-4">
                        <button data-testid="add-Schedule" className="buttonWithBorder" onClick={(e) => this.handleOpenSchedule(e)}><IntlMessages id="newactivity.ADDSCHEDULEButton" /></button>
                    </FormGroup> : <div className='py-3'></div>
                }
                <AssingActivityScheduleUpdate
                    currentSchedule={currentSchedule}
                    open={openSchedule}
                    activityType={activityType}
                    editSchedule = {this.state.editSchedule}
                    handleClose={this.handleOpenSchedule.bind(this)}
                    handleSaveSchedule={this.handleSaveSchedule.bind(this)}
                    handleRepeat={this.handleRepeat.bind(this)}
                    handleStartDateChange={this.handleStartDateChange.bind(this)}
                    handleStartTimeChange={this.handleStartTimeChange.bind(this)}
                    handleEndTimeChange={this.handleEndTimeChange.bind(this)}
                    customEnabled={customEnabled}
                    handleToggle={this.handleToggle.bind(this)}
                    getDayOccurences={getDayOccurences}
                    weekSelected={weekSelected}
                    monthSelected={monthSelected}
                    customRepeatValue={customRepeatValue}
                    handleRepeatSelection={this.handleRepeatSelection.bind(this)}
                    handleNotifyStateChange={this.handleNotifyStateChange.bind(this)}
                    weekDayWithCheck={weekDayWithCheck}
                    handleWeekDayChecked={this.handleWeekDayChecked.bind(this)}
                    handleRepeatType={this.handleRepeatType.bind(this)}
                    repeatType={repeatType}
                    handleCustomMonthlyRepeat={this.handleCustomMonthlyRepeat.bind(this)}
                    monthlyOccurenceDay={monthlyOccurenceDay}
                    customCount={customCount}
                    handleCustomCount={this.handleCustomCount.bind(this)}
                    customUntill={customUntill}
                    handleCustomUntill={this.handleCustomUntill.bind(this)}
                    handleCustomInterval={this.handleCustomInterval.bind(this)}
                    customInterval={customInterval}
                    invalidEndTime={invalidEndTime}
                    popupThemeClass={popupThemeClass}
                    repeatError={repeatError}
                    customCountError={customCountError}
                />

            </div>
        );
    }
}

// map state to props
const mapStateToProps = ({ ActivityReducer, ActivityTemplateReducer, settings,AssignActivity }) => {
    const {  activity, steps, loading, activityExist, activityTitle, errorMessage, successMessage, errorTitle } = ActivityReducer
    const { filteredActivities, commonAllActivities, filterOptions, selectedActivities, schedules} = AssignActivity
    const { activityTemplates, groups, selectedTemplate, filteredTemplates, selectedGroups } = ActivityTemplateReducer
    const { screenThemeClass, popupThemeClass } = settings
    return { filteredActivities, filterOptions, commonAllActivities, activity, schedules, steps, loading, activityExist, activityTitle, errorMessage, activityTemplates, groups, selectedTemplate, screenThemeClass, popupThemeClass, filteredTemplates, selectedGroups, successMessage, errorTitle }
}

export default connect(mapStateToProps, {
    addPrivateActivity,
    updatePrivateActivity,
    getPrivateActivityById,
    checkDuplicateActivity,
    clearActivityData,
    checkDuplicateTitleAndUpdate,
    removeAssignAPIErrorMessage,
    getActivityTemplates,
    getActivityGroups,
    selectActivityTemplate,
    filterActivityTemplate,
    showActivitySuccess,
    addActivitySchedules,
    addNewActivitySchedule,
    editActivitySchedule,
    deleteActivitySchedule,
    getActivitySchedulesList
})(AssignActivitySchedule);
