// import { createStore, applyMiddleware, compose } from 'redux';
// import Thunk from 'redux-thunk';
// import reducers from '../reducers';

// export function configureStore(initialState) {

//     const store = createStore(
//         reducers,
//         initialState,
//         compose(applyMiddleware(Thunk))
//     );

//     if (module.hot) {
//         // Enable Webpack hot module replacement for reducers
//         module.hot.accept('../reducers/index', () => {
//             const nextRootReducer = require('../reducers/index');
//             store.replaceReducer(nextRootReducer);
//         });
//     }

//     return store;
// }

import { createStore, applyMiddleware, compose } from 'redux';
import Thunk from 'redux-thunk';
import reducers from '../reducers';
import config from '../config/config'

const loadState = (initialState) =>{
    try {
        let serializedState = localStorage.getItem(config.storageBucket);

        if (serializedState === null) {
            return initialState;
        }

        return JSON.parse(serializedState);
    }
    catch (err) {
        return initialState;
    }
}

const saveState = (store) => {
    try {
        let serializedState = JSON.stringify(store.getState());
        localStorage.setItem(config.storageBucket, serializedState);

    }
    catch (err) {
    }
}


export function configureStore(initialState) {

    const store = createStore(
        reducers,
        loadState(initialState),
        compose(applyMiddleware(Thunk))
    );

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers/index', () => {
            const nextRootReducer = require('../reducers/index');
            store.replaceReducer(nextRootReducer);
        });
    }

    store.subscribe(() => {
        saveState(store)
    });

    return store;
}
