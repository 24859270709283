import { assignActivity, sherpaAPI } from '../sherpaApi/index'
import {
    getISODateString,
} from '../helpers/helpers';

import {
    GET_ALL_COMMON_ACTIVITIES,
    GET_ACTIVITIES_GROUPS_LIST,
    APPLY_FILTER_ON_ACTIVITY,
    CLEAR_FILTER_FROM_ACTIVITY,
    SET_ACTIVITIES_FILTERED_LIST,
    SET_SELECTED_ACTIVITIES_FOR_ASSIGN,
    HIDE_LOADING_INDICATOR_ACTIVITIES_LIST,
    ASSIGN_ACTIVITY,
    ASSIGN_ACTIVITY_SUCCESS,
    ASSIGN_ACTIVITY_FAILED,
    REMOVE_ASSIGN_ACTIVITY_API_ERROR_MESSAGE,
    GET_SCHEDULE_LOADING,
    HIDE_SCHEDULE_LOADING,
    SCHEDULE_ADDED_SUCCESSFULLY,
    SCHEDULE_EDITED_SUCCESSFULLY,
    SCHEDULE_DELETED_SUCCESSFULLY,
    CLEAR_ASSIGN_ACTIVITY_DATA,
    GET_ACTIVITIES_GROUPS_LIST_FAILED,
    GET_ACTIVITIES_GROUPS_LIST_SUCCESS,
    FETCH_COMMON_ACTIVITY_SUCCESS,
    FETCH_COMMON_ACTIVITY_LIST,
    GET_ASSIGN_ACTIVITY_LOADING,
    UPDATE_COMMON_ACTIVITY_LIST,
    HIDE_ASSIGN_ACTIVITY_LOADING,
    GET_ACTIVITY_SCHEDULES_LIST,
    UPDATE_ACTIVITY_SCHEDULES_LIST,
    GET_USER_ACTIVITY_SCHEDULES_LOADING,
    GET_ACTIVITY_MEMBERS_DETAILS,
    GET_ACTIVITY_MEMBERS_DETAILS_FAILED,
    GET_ACTIVITY_MEMBERS_DETAILS_SUCCESS,
    UNASSIGN_ACTIVITY_TO_USER,
    UNASSIGN_ACTIVITY_TO_USER_SUCCESS,
    UNASSIGN_ACTIVITY_TO_USER_FAILED,
    SET_USER_ASSIGNED_ACTIVITIES,
    CLEAR_USER_DATA_FOR_ASSIGN_ACTIVITY
} from './types';
import moment from 'moment';

const sortActivitiesList = (list) => {
    list.sort((activity1, activity2) => {
        let title1 = activity1.title ? activity1.title : "ZZZ";
        let title2 = activity2.title ? activity2.title : "ZZZ";
        return title1.localeCompare(title2);
    });
}

const getActiveActivitiesList = (list) => {
   return list.filter(activity => activity.active ? true : false);
}

const getInActiveActivitiesList = (list) => {
    return list.filter(activity => activity.active ? false : true);
 }

export const getSortedCommmonActivitiesList = (selectedActivities,list) =>  {
    let checkedList = list.filter(activity => selectedActivities.findIndex(id => id == activity.id) >= 0);
    let unCheckedList = list.filter(activity => selectedActivities.findIndex(id => id == activity.id) == -1);

    let activeCheckedList = getActiveActivitiesList(checkedList);
    sortActivitiesList(activeCheckedList);
    let inActiveCheckedList = getInActiveActivitiesList(checkedList);
    sortActivitiesList(inActiveCheckedList);
    let sortedCheckedList = activeCheckedList.concat(inActiveCheckedList);
     sortActivitiesList(unCheckedList);
    return sortedCheckedList.concat(unCheckedList);
}

export const getCommonActivitiesByUserId = (userId, idToken, orgId) => (dispatch) => {
    let url = `/users/${userId}/common-activities?orgId=${orgId}&scope=General&includeDisabled=true` //`/users​/${memberUerId}​/activities`
    console.log(" Fetching common activities for userId:",userId,"......");
    dispatch({ type: FETCH_COMMON_ACTIVITY_LIST });
    if (userId) {
        sherpaAPI.get(url, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        }).then((response) => {
                console.log("%c Fetched common activities for userId:"+userId,"color:green");
                let routines = response.data;
                let activitiesList = [];
                let userActivityIds = [];
                routines && routines.map((activity, key) => {
                    if(!(activity.routineState === false)) {
                    let activityData = activity.Detail ? { ...activity.Detail.Routine } : {};
                    activityData.steps = activity.Detail && activity.Detail.Steps && Object.values(activity.Detail.Steps);
                    activityData.ActivityGroups = activity.ActivityGroups;
                    activityData.Members = activity.Members;
                    activityData.id = activity.routineId;
                    if(activity.userExclusive) {
                        activityData.userExclusive = activity.userExclusive;
                    } else {
                        activityData.userExclusive = false;
                    }
                    activityData.routineState = activity.routineState;
                    activity.Members && Object.keys(activity.Members).map((memberId, key) => {
                         if(memberId == userId ) {
                            // userActivityIds.push(activity.routineId);
                            // activityData.active = activity.Members[memberId];
                            activityData.active = activity.Members[memberId];
                            if(activityData.active) {
                                userActivityIds.push(activity.routineId);
                            }
                         }
                    })
                    let schedules = [];
                    activityData && activityData.newSchedule && Object.keys(activityData.newSchedule).map((scheduleKey, key) => {
                        let schedule = activityData.newSchedule[scheduleKey];
                        schedule.scheduleId = scheduleKey;
                        schedules.push(schedule);
                    });
                    activityData.newSchedule = schedules;
                    const reportingList = getActivityReportingsDetails(activity);
                    if(reportingList.length > 0) {
                        activityData.reportingList = reportingList;
                    }
                    activitiesList.push(activityData);
                   }
                })
                dispatch({ type: SET_USER_ASSIGNED_ACTIVITIES, payload: userActivityIds });
                let list = getSortedCommmonActivitiesList(userActivityIds,activitiesList)
                dispatch({ type: FETCH_COMMON_ACTIVITY_SUCCESS, payload: list});
            }).catch(error => {
                const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
                console.log("%c Error occured while fetching common activities for userId:"+userId,"color:red",errorMsg);
                const errorData = error.response ? { message: error.response.data && error.response.data.messsage, title: 'Warning' } : { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" };
                dispatch({ type: HIDE_LOADING_INDICATOR_ACTIVITIES_LIST,payload: errorData });
            })
    } else {
            const errorData = { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" };
            dispatch({ type: HIDE_LOADING_INDICATOR_ACTIVITIES_LIST,payload: errorData });
    }
}

const getActivityReportingsDetails = (routine) => {
    const activity = (routine.Detail && routine.Detail.Routine) || {}
    const steps = (routine.Detail && routine.Detail.Steps) || {}
    let reportingList = [];
    if(activity.Reporting) {
        for(const formIdKey in activity.Reporting) {
            const reporting = activity.Reporting[formIdKey];
            if(activity[formIdKey]) {
                const detailsObject = {enabled: reporting.bulletinDelivery}
                detailsObject[formIdKey] = activity[formIdKey];
                reportingList.push(detailsObject)
            } 
        }
    }
    for(const stepId in steps) {
        const step = steps[stepId];
        if(step.Reporting) {
            for(const formIdKey in step.Reporting) {
                const reporting = step.Reporting[formIdKey];
                if(step[formIdKey]) {
                    const detailsObject = {enabled: reporting.bulletinDelivery, stepId}
                    detailsObject[formIdKey] = step[formIdKey];
                    reportingList.push(detailsObject)
                } 
            }
        }
    }
    return reportingList;
}

export const setSelectedActivitiesForAssign = (selectedActivities,list) => (dispatch) => {
    //dispatch({type: GET_ASSIGN_ACTIVITY_LOADING})
    dispatch({ type: SET_SELECTED_ACTIVITIES_FOR_ASSIGN, payload: selectedActivities });
    // let updatedList = getSortedCommmonActivitiesList(selectedActivities,list)
    dispatch({ type: UPDATE_COMMON_ACTIVITY_LIST, payload: list });
}

export const assingActivitiesToUser = (userId,orgId, idToken, list, userActivities) => (dispatch) => {
    let checkedList = list.filter((activityId) => userActivities.findIndex((userActivityId) => userActivityId == activityId) == -1);
    let unCheckedList = userActivities.filter((userActivityId) => list.findIndex((activityId) => activityId == userActivityId) == -1);
    if(checkedList.length > 0) {
        dispatch(assingCheckedActivitiesToUser(userId,orgId, idToken,checkedList, unCheckedList));
    }
    else if(unCheckedList.length > 0)
         dispatch(assingUnCheckedActivitiesToUser(userId,orgId, idToken, unCheckedList));
    else {
        window.location.href = "/success";
    }
 
}

export const assingCheckedActivitiesToUser = (userId,orgId, idToken, checkedList,unCheckedList) => (dispatch) => {
    console.log(" Assigning checked activities for userId:",userId,"......");
    dispatch({ type: ASSIGN_ACTIVITY });
    sherpaAPI.post(`/users/${userId}/activities/assign?orgId=${orgId}&scope=General`, { activityIds: checkedList }, {
        headers: {
            'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        
        if (response.status === 200) {
            console.log("%c Assigned checked activities for userId:"+userId,"color:green");
            if(unCheckedList && unCheckedList.length > 0) {
                dispatch(assingUnCheckedActivitiesToUser(userId,orgId, idToken, unCheckedList));
            }
            else {
                dispatch({ type: ASSIGN_ACTIVITY_SUCCESS });
                window.location.href = "/success";
            }
            
        } else {
            dispatch({ type: HIDE_ASSIGN_ACTIVITY_LOADING });
        }
    }).catch(error => {
        const errorData = error.response ? { message: error.response.data && error.response.data.messsage, title: 'Warning' } : { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" };
        // const errorData = { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" }
        dispatch({ type: ASSIGN_ACTIVITY_FAILED, payload: errorData })
        const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
        console.log("%c Error occured while assigning checked activities for userId:"+userId,"color:red",errorMsg);
    })
}

export const assingUnCheckedActivitiesToUser = (userId,orgId, idToken, unCheckedList) => (dispatch) => {
    console.log(" Unassigning unchecked activities for userId:",userId,"......");
    dispatch({ type: UNASSIGN_ACTIVITY_TO_USER });
    sherpaAPI.post(`/users/${userId}/activities/un-assign?orgId=${orgId}&scope=General`, { activityIds: unCheckedList }, {
        headers: {
            'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        // console.log("UN ASSIGN ACTIVITY =>", response.data);
        if (response.status === 200) {   
            console.log("%c Unassigned unchecked activities for userId:"+userId,"color:green"); 
            dispatch({ type: UNASSIGN_ACTIVITY_TO_USER_SUCCESS });
            window.location.href = "/success";    
        } else {
            dispatch({ type: HIDE_ASSIGN_ACTIVITY_LOADING });
        }
            
    }).catch(error => {
        const errorData = error.response ? { message: (error.response.data && error.response.data.messsage), title: 'Warning' } : { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" };
        // const errorData = { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" }
        dispatch({ type: UNASSIGN_ACTIVITY_TO_USER_FAILED, payload: errorData })
        const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
        console.log("%c Error occured while unassigning unchecked activities for userId:"+userId,"color:red",errorMsg);
    })
}

export const removeAssignAPIErrorMessage = () => (dispatch) => {
    dispatch({ type: REMOVE_ASSIGN_ACTIVITY_API_ERROR_MESSAGE })
}

export const getActivityMembersDetails = (userId, orgId, activityId, idToken) => (dispatch) => {
    console.log(" Fetching activity members for activityId:",activityId,"......");
    dispatch({ type: GET_ACTIVITY_MEMBERS_DETAILS });
    sherpaAPI.get(`/users/${userId}/activities/${activityId}/members?orgId=${orgId}&scope=General`, {
        headers: {
            'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Fetched activity members for activityId:"+activityId,"color:green");
        let activityMemberList = []
        response.data && Object.keys(response.data).map((memberId,key) => {
            let member= response.data && response.data[memberId] ? response.data[memberId] : {};
            member.memberId = memberId;
            member.title = member.displayName || `${member.firstName || "Display"} ${member.lastName || "Name"}`;
            activityMemberList.push(member)
        })
        if(activityMemberList.length > 0) {
            let consoleLogsTable = [];
            activityMemberList.map(member => {
                consoleLogsTable.push({memberId:member.memberId, name: member.title, role:member.role});
            });
            console.table(consoleLogsTable)
        }
        dispatch({ type: GET_ACTIVITY_MEMBERS_DETAILS_SUCCESS, payload: activityMemberList});
    }).catch(error => {
        const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
        console.log("%c Error occured while fetching activity members for activityId:"+activityId,"color:red",errorMsg);
        const errorData = error.response ? { message: (error.response.data && error.response.data.messsage), title: 'Warning' } : { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" };
        // const errorData = { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" }
        dispatch({ type: GET_ACTIVITY_MEMBERS_DETAILS_FAILED, payload: errorData })
    })
}

const getScheduleFormat = schedules => {
    let newSchedules = schedules ? schedules.map(schedule => {
        schedule.startDate = schedule.startDate || moment().format('MMM DD YYYY');
        schedule.endTime = schedule.endTime ? moment(schedule.endTime, 'hh:mm A').format('hh:mm A') : moment().format('hh:mm A');
        schedule.startTime = schedule.startTime ? moment(schedule.startTime, 'hh:mm A').format('hh:mm A') : moment().format('hh:mm A');
        schedule.objStartDate = schedule.startDate ? moment(getISODateString(schedule.startDate)) : moment();
        schedule.objStartTime = moment(schedule.startTime, 'hh:mm A');
        schedule.objEndTime = moment(schedule.endTime, 'hh:mm A');
        return schedule;
    }) : [];
    return newSchedules;
}

export const getActivitySchedulesList = (userId,orgId, activityId, idToken) => (dispatch) => {
    console.log(" Fetching activity schedules for activityId:",activityId,"......");
    dispatch({ type: GET_USER_ACTIVITY_SCHEDULES_LOADING });
    sherpaAPI.get(`/users/${userId}/activity/${activityId}/user-specific-schedules?orgId=${orgId}&scope=General`, {
        headers: {
            'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Fetched activity schedules for activityId:"+activityId,"color:green");
        dispatch({ type: GET_ACTIVITY_SCHEDULES_LIST ,payload:getScheduleFormat(response.data)});
        if(response.data && response.data.length > 0) {
            let consoleLogsTable = [];
            getScheduleFormat(response.data).map(sche => {
                consoleLogsTable.push({scheduleId:sche.scheduleId,startDate:sche.startDate, startTime:sche.startTime, endTime:sche.endTime, text:sche.text})
            });
            console.table(consoleLogsTable);
        }
    }).catch(error => {
        const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
        console.log("%c Error occured while fetching activity schedules for activityId:"+activityId,"color:red",errorMsg);
        const errorData = error.response ? { message: error.response.data && error.response.data.messsage, title: 'Warning' } : { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" };
        dispatch({ type: HIDE_SCHEDULE_LOADING,payload: errorData });
    })
}

export const addNewActivitySchedule = (userId,orgId, activityId, idToken, schedule) => (dispatch) => {
   let payloadSchedule = {
        startTime:moment(schedule.startTime, 'hh:mm A').format('HH:mm'),
        endTime:moment(schedule.endTime, 'hh:mm A').format('HH:mm'),
        rrule:schedule.rrule,
        startDate:schedule.startDate,
        recurrenceMethod:schedule.recurrenceMethod,
        text:schedule.text,
        notify: schedule.notify
    }
    let data = {schedules:[payloadSchedule]};
    console.log(" Adding new schedule for activityId:",activityId,"......");
    dispatch({ type: GET_SCHEDULE_LOADING });
    sherpaAPI.post(`/users/${userId}/activity/${activityId}/user-specific-schedules?orgId=${orgId}&scope=General`, data, {
        headers: {
            'Authorization': `Bearer ${idToken}`
        }}).then((response) => {
        console.log("%c Schedule added for activityId:"+activityId,"color:green");
        let schedules= response.data.schedulesObj;
        let newSchedule = [];
        schedules && schedules && Object.keys(schedules).map((scheduleKey, key) => {
            let schedule = schedules[scheduleKey];
            schedule.scheduleId = scheduleKey;
            newSchedule.push(schedule);
        })
        dispatch({ type: SCHEDULE_ADDED_SUCCESSFULLY ,payload:getScheduleFormat(newSchedule)});
        if(newSchedule && newSchedule.length > 0) {
            let consoleLogsTable = [];
            getScheduleFormat(newSchedule).map(sche => {
                consoleLogsTable.push({scheduleId:sche.scheduleId,startDate:sche.startDate, startTime:sche.startTime, endTime:sche.endTime, text:sche.text})
            });
            console.table(consoleLogsTable);
        }
    }).catch(error => {
        const errorData = error.response ? { message: error.response.data && error.response.data.messsage, title: 'Warning' } : { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" };
        dispatch({ type: HIDE_SCHEDULE_LOADING,payload: errorData });
        const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
        console.log("%c Error occured while adding new schedule for activityId:"+activityId,"color:red",errorMsg);
    })
}

export const editActivitySchedule = (userId ,orgId, activityId, idToken, schedule) => (dispatch) => {
    let payloadSchedule = {
        startTime:moment(schedule.startTime, 'hh:mm A').format('HH:mm'),
        endTime:moment(schedule.endTime, 'hh:mm A').format('HH:mm'),
        rrule:schedule.rrule,
        startDate:schedule.startDate,
        recurrenceMethod:schedule.recurrenceMethod,
        text:schedule.text,
        notify: schedule.notify
    }
    let data = {schedules:[{...payloadSchedule,scheduleId:schedule.scheduleId}] };
    console.log(" Updating schedule for scheduleId:"+schedule.scheduleId,"......");
    dispatch({ type: GET_SCHEDULE_LOADING });
    sherpaAPI.post(`/users/${userId}/activity/${activityId}/user-specific-schedules?orgId=${orgId}&scope=General`, data, {
        headers: {
            'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Schedule updated for scheduleId:"+schedule.scheduleId,"color:green");
        let schedules=response.data.schedulesObj;
        let newSchedule = [];
        schedules && schedules && Object.keys(schedules).map((scheduleKey, key) => {
            let schedule = schedules[scheduleKey];
            schedule.scheduleId = scheduleKey;
            newSchedule.push(schedule);
        })
        dispatch({ type: SCHEDULE_EDITED_SUCCESSFULLY,payload: getScheduleFormat(newSchedule) });
        if(newSchedule && newSchedule.length > 0) {
            let consoleLogsTable = [];
            getScheduleFormat(newSchedule).map(sche => {
                consoleLogsTable.push({scheduleId:sche.scheduleId,startDate:sche.startDate, startTime:sche.startTime, endTime:sche.endTime, text:sche.text})
            });
            console.table(consoleLogsTable);
        }
    }).catch(error => {
        const errorData = error.response ? { message: (error.response.data && error.response.data.messsage) || error.message, title: 'Warning' } : { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" };
        dispatch({ type: HIDE_SCHEDULE_LOADING,payload: errorData });
        const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
        console.log("%c Error occured while updating schedule for scheduleId:"+schedule.scheduleId,"color:red",errorMsg);
    })
}

export const deleteActivitySchedule = (userId ,orgId, activityId, idToken, schedule,schedulesList) => (dispatch) => {
    console.log(" Deleting schedule for scheduleId:"+schedule.scheduleId,"......");
    dispatch({ type: GET_SCHEDULE_LOADING });
    sherpaAPI.delete(`/users/${userId}/activity/${activityId}/user-specific-schedules/${schedule.scheduleId}?orgId=${orgId}&scope=General`, {
        headers: {
            'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {   
            console.log("%c Schedule deleted for scheduleId:"+schedule.scheduleId,"color:green");;
            // console.log("deleted schedule =>", response.data);
            let scheduleIndex = schedulesList && schedulesList.findIndex(schedulesItem => schedulesItem.scheduleId == schedule.scheduleId);
            if (scheduleIndex >= 0) schedulesList.splice(scheduleIndex,1)
             dispatch({ type: SCHEDULE_DELETED_SUCCESSFULLY, payload: schedulesList });
    }).catch(error => {
            const errorData = error.response ? { message: error.response.data && error.response.data.messsage, title: 'Warning' } : { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" };
            dispatch({ type: HIDE_SCHEDULE_LOADING,payload: errorData });
            const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
            console.log("%c Error occured while deleting schedule for scheduleId:"+schedule.scheduleId,"color:red",errorMsg);
    })
}

export const getActivitiesGroupsList = (userId, idToken,orgId) => (dispatch) => {
    let url = `/users/${userId}/activities/activity-groups?orgId=${orgId}` //users/{userId}/activities/groups
    console.log(" Fetching activity groups for userId:",userId,"......");
    dispatch({ type: GET_ACTIVITIES_GROUPS_LIST});
    sherpaAPI.get(url, {
        headers: {
            'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
        console.log("%c Fetched activity groups for userId:"+userId,"color:green");
        if(response.data && response.data.length > 0) {
            console.table(response.data);
        }
        dispatch({ type: GET_ACTIVITIES_GROUPS_LIST_SUCCESS, payload: response.data });
    }).catch(error => {
        const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
        console.log("%c Error occured while fetching common activities for userId:"+userId,"color:red",errorMsg);
        const errorData = error.response ? { message: error.response.data && error.response.data.messsage, title: 'Warning' } : { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" };
        dispatch({ type: GET_ACTIVITIES_GROUPS_LIST_FAILED,payload: errorData });
    })
}



export const addActivitySchedules = (updatedActivitiesList) => (dispatch) => {
    dispatch({ type: GET_ALL_COMMON_ACTIVITIES, payload: updatedActivitiesList });
}

export const applyActivitiesFilter = (filteredList) => (dispatch) => {
    dispatch({ type: SET_ACTIVITIES_FILTERED_LIST, payload: filteredList });
    dispatch({ type: APPLY_FILTER_ON_ACTIVITY });
}

export const clearActivitiesFilter = () => (dispatch) => {
    dispatch({ type: CLEAR_FILTER_FROM_ACTIVITY });
}

export const clearAssingActivityData = () => (dispatch) => {
    dispatch({ type: CLEAR_ASSIGN_ACTIVITY_DATA });
    dispatch({ type: CLEAR_USER_DATA_FOR_ASSIGN_ACTIVITY });
}

export const showAssingActivityLoading = () => (dispatch) => {
    dispatch({ type: GET_ASSIGN_ACTIVITY_LOADING });
}

export const hideAssingActivityLoading = () => (dispatch) => {
    dispatch({ type: HIDE_ASSIGN_ACTIVITY_LOADING });
}
