/**
 * Reactify - A Material Design Admin Template
 * Copyright 2018 All Rights Reserved
 * Made With Love
 * Created By The Iron Network, LLC
 */
import React from 'react';
import { createRoot } from 'react-dom/client';

// Save a reference to the root element for reuse
const container = document.getElementById("root");
const root = createRoot(container)
const MainApp = require('./App').default;
// Create a reusable render method that we can call more than once

root.render(<MainApp />)

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    if (container){
      root.render(<NextApp />)
    }
  });
}

// let render = () => {
//   // Dynamically import our main App component, and render it
//   ReactDOM.render(
//     <MainApp />,
//     rootEl
//   );
// };

// if (module.hot) {
//   module.hot.accept('./App', () => {
//     const NextApp = require('./App').default;
//     render(
//       <NextApp />,
//       rootEl
//     );
//   });
// }
 
// render();