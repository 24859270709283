// Time Picker
import React, { Component } from 'react';
import moment from 'moment';
import {MobileTimePicker} from '@mui/x-date-pickers';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { parseISO } from 'date-fns';

export default class TimePickers extends Component {

	state = {
		selectedDate: moment(),
	};

	handleTimeChange = (time) => {
		this.props.handleOnTimeChange(moment(time));
	}

	render() {
		let timestring = new Date().toISOString();
		try {
			if(this.props.value) {
				timestring =  new Date(this.props.value).toISOString()
			}
		} catch(e) {
			console.error("Invalid time, please enter valid date")
		}
		return (
			<div className="rct-picker">
				<div className="picker">
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<MobileTimePicker
							className='w-100'
							label={this.props.label}
							value={ parseISO(timestring)}
							inputFormat="hh:mm a"
							onChange={this.handleTimeChange}
							fullWidth
							renderInput={(params) => <TextField data-testid={this.props.testid} error={this.props.invalidEndTime ? this.props.invalidEndTime : false} helperText={ this.props.invalidEndTime && "Invalid Schedule!  Please ensure that the End Time is greater than Start Time and minimum schedule gap duration 5 minutes"} variant="standard" {...params} />}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start" className="date-picker-icon">
										<ScheduleRoundedIcon />
									</InputAdornment>
								),
							}}
						/>
					</LocalizationProvider>
				</div>
			</div>
		)
	}
}
