/**
 * Signin Firebase
 */

 import React, { Component } from 'react';
 import { connect } from 'react-redux';
 import Button from '@mui/material/Button';
 import AppBar from '@mui/material/AppBar';
 import Toolbar from '@mui/material/Toolbar';
 import { Form, FormGroup} from 'reactstrap';
 import CircularProgress from '@mui/material/CircularProgress';
 import QueueAnim from 'rc-queue-anim';
 import HeaderWithCancelButton from '../Header/HeaderWithCancelButton'
 import queryString from 'query-string';

 import {
   Card, CardImg, CardBody
 } from 'reactstrap';
 
 // redux action
 import {
    selectConnection
 } from 'Actions';
 
 class IDPSectionComponent extends Component {

   state = {
      availableOptions: [
         {connection: 0, name: "Azure IdP", imageUrl: "https://ne-arc.org/wp-content/uploads/2019/07/NeArc-Logo-Teal.jpg"}, 
         {connection: 1, name: "LifeSherpa IdP", imageUrl: "https://lifesherpapp.com/wp-content/uploads/2021/10/life-sherpa-logo-300x109.png"}
      ]
   }
 
    handleContinue(e, selectedConnection) {
      const params = queryString.parse(this.props.location.search)
      this.props.selectConnection(selectedConnection, params, this.props.history);
    }
 
    render() {
       const { availableOptions } = this.state;
       const { loading, themeData, userOrgFromToken } = this.props;
       const connections = userOrgFromToken && userOrgFromToken.auth0ClientConfig && userOrgFromToken.auth0ClientConfig.connections ? userOrgFromToken.auth0ClientConfig.connections : availableOptions

       return (
          <QueueAnim type="bottom" duration={2000}>
             <div>
             {
                loading &&
                   <div className="d-flex justify-content-center loader-overlay">
                      <CircularProgress className="theame-text" disableShrink/>
                   </div>
             }
             </div>
             <div className={this.props.screenThemeClass} style={themeData && themeData.loginScreenFillPaternImage ?{backgroundImage:`url(${themeData.loginScreenFillPaternImage})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}:{}}>
                <AppBar position="static" className="session-header">
                   <Toolbar>
                      <HeaderWithCancelButton screenThemeClass={this.props.screenThemeClass} themeLogo={this.props.themeLogo} location={this.props.location} href={`/success`}/>
                   </Toolbar>
                </AppBar>
                <div className="session-inner-wrapper">
                   <div className="container">
                      <div className="row row-eq-height">
                         <div className="col-sm-12 col-md-3 col-lg-3"></div>
                         <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className={"session-body text-center text-white p-3"}>
                               <div className="session-head mb-30">
                                  <p className="base-font-color">How do you want to register?</p>
                                 </div>
                               <Form className='row'>
                                  {connections.length > 0 && connections.map((connection, key) => (
                                    <FormGroup key={key} className='col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                                       <Card>
                                          <div className='pt-3 pb-0 pl-3 pr-3 text-center'>
                                             <img title={connection.name} className='card-img-fixed-height' src={connection.imageUrl} alt="Card image cap" />
                                          </div>
                                          <CardBody>
                                             <Button 
                                                className="btn-block login-screen-button"
                                                variant="contained"
                                                size="large"
                                                key={key} 
                                                title={connection.name}
                                                onClick={(e) => this.handleContinue(e, connection)}
                                                >
                                                Continue
                                             </Button>
                                          </CardBody>
                                       </Card>
                                    </FormGroup>
                                  ))
                                  }
                               </Form>
                            </div>
                         </div>
                         <div className="col-sm-12 col-md-3 col-lg-3"></div>
                      </div>
                   </div>
                </div>
             </div>
          </QueueAnim>
       );
    }
 }
 
 // map state to props
 const mapStateToProps = ({ authUser, settings}) => {
    const { user, loading, privacyPolicy, firebaseToken , themeData, userOrgFromToken} = authUser;
    const { screenThemeClass, themeLogo } = settings;
    return { user, loading, privacyPolicy, firebaseToken, screenThemeClass, themeLogo, themeData, userOrgFromToken }
 }
 
 export default connect(mapStateToProps, {
   selectConnection
 })(IDPSectionComponent);
 