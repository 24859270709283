import {
    GET_MY_ACTIVITY_BY_ID,
    GET_SUCCESS_MY_ACTIVITY_BY_ID,
    SHOW_MY_ACTIVITY_LOADING,
    HIDE_MY_ACTIVITY_LOADING,
    
} from '../actions/types';

const INITIAL_STATE = {
    loading: false,
    activity: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case GET_MY_ACTIVITY_BY_ID:
            return { ...state, loading: true };
        case GET_SUCCESS_MY_ACTIVITY_BY_ID:
            return { ...state, activity: action.payload, loading: false };
        case HIDE_MY_ACTIVITY_LOADING:
            return { ...state, loading: false };
        case SHOW_MY_ACTIVITY_LOADING:
            return { ...state, loading: true };

        default: return { ...state };
    }
}
