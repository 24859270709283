/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Form, FormGroup } from 'reactstrap';
import QueueAnim from 'rc-queue-anim';
import HeaderWithCancelButton from '../Header/HeaderWithCancelButton'
import config from '../../config/config'
import { connect } from 'react-redux';
import { withAuth0 } from "@auth0/auth0-react";
import CircularProgress from '@mui/material/CircularProgress';
import SweetAlert from 'react-bootstrap-sweetalert'
import { configureStore } from '../../store';
import {
   resetReduxData
} from 'Actions';
import queryString from 'query-string';


class Auth0RedirectSignInPape extends Component {
   state = {
      getTokenAccessFailed:"",
      error: "",
      error_description: ""
   }
   //  componentDidMount(){
   //    //   const {loginWithRedirect} = this.props.auth0;
   //    //   loginWithRedirect()
   // }
 
    getAuth0AccessToken = async(audience, getAccessTokenSilently, user, isAuthenticated) => {
        try {
            const token = await getAccessTokenSilently({audience: audience, scope: 'read:posts',});
            if (isAuthenticated){
                const authResult = {accessToken: token}
                const profileResult = user
                let store = configureStore();
                store.dispatch({ type: 'LOGIN_USER_SUCCESS', payload: authResult })
                store.dispatch({ type: 'GET_USER_INFO', payload: profileResult })
                console.log("Redirecting to login success screen...")
                window.location.replace('/loginsuccess')
            }
        } catch(e) {
            let {getTokenAccessFailed} = this.state;
            console.log(e)
            const params = queryString.parse(this.props.location.search);
            if(params && params.error && getTokenAccessFailed === ''){
               this.setState({getTokenAccessFailed:"Something went wrong, try again!", error: params.error,error_description: params.error_description});
            } else if(!getTokenAccessFailed){
               this.setState({getTokenAccessFailed:"Something went wrong, try again!", error: "auth0 Error",error_description: "Something went wrong, try again!"});
            }
        }
    }
    onConfirm() {
      this.setState({getTokenAccessFailed:"", error: "", error_description: ""});
      //this.props.resetReduxData();
      let mode = localStorage.getItem("appMode");
      if(mode === "web")
         window.location="/login?mode=web"
      else 
         window.location="/login"
    }

   render() {
      const { user, isAuthenticated, isLoading, getAccessTokenSilently  } = this.props.auth0;
      const {loading} = this.props
      if (this.props.privacyPolicy && this.props.privacyPolicy.publicSettings && this.state.getTokenAccessFailed === ''){
         this.getAuth0AccessToken(this.props.privacyPolicy.publicSettings.audience, getAccessTokenSilently, user, isAuthenticated)
      } 
      return (
         <QueueAnim type="bottom" duration={2000}>
            <div>
            {
               <div className="d-flex justify-content-center loader-overlay">
                  <CircularProgress className="theame-text" disableShrink/>
               </div>
            }
            </div>
            <div className={this.props.screenThemeClass}>
               <AppBar position="static" className="session-header">
                  <Toolbar>
                     <HeaderWithCancelButton themeLogo={this.props.themeLogo} screenThemeClass={this.props.screenThemeClass} location={this.props.location} href={`/success`}/>
                  </Toolbar>
               </AppBar>
               <div className="session-inner-wrapper">
                  <div className="container">
                     <div className="row row-eq-height">
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                           <div className="session-body text-center">
                              <Form>
                                 <FormGroup className="mb-10">
                                  <p className="mb-0">Please wait fetching user info...</p>
                                 </FormGroup>
                              </Form>
                           </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                     </div>
                  </div>
               </div>
            </div>
            { this.state.getTokenAccessFailed &&
            <SweetAlert
               warning
               btnSize="sm"
               customClass="warningText"
               show={this.state.getTokenAccessFailed !== ''}
               confirmBtnText="Back To Token"
               confirmBtnBsStyle="warning"
               title={this.state.error ? this.state.error.charAt(0).toUpperCase() + this.state.error.slice(1) : ""}
               onConfirm={this.onConfirm.bind(this)}
            >
               {this.state.error_description}
            </SweetAlert>
           }
         </QueueAnim>
      );
   }
}

// map state to props
const mapStateToProps = ({ authUser, settings }) => {
   const { user, loading, privacyPolicy } = authUser;
   const { screenThemeClass, themeLogo } = settings; 
   return { user, loading, privacyPolicy, screenThemeClass, themeLogo}
}

export default connect(mapStateToProps,{resetReduxData})(withAuth0(Auth0RedirectSignInPape));