/**
 * Lifesherpa Object editor Header
 */

import React, { Component } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';

class WarningDialog extends Component {

    handleClose() {
        this.props.handleCloseSuccessPopup()
    }
    goToSuccessPage=() => {
        window.location.href = "/success";
     }
   render() {
        return (
            <Dialog
                fullScreen={false}
                open={this.props.open}
                onClose={this.handleClose}
                fullWidth={true}
                maxWidth="sm"
                aria-labelledby="responsive-dialog-title"
                className={this.props.popupThemeClass}
                > 
                <div className="warning-div">
                    <span className="w-span-1"></span>
                    <span className="w-span-2"></span>
                </div>
                <DialogContent className="p-0">
                    <div className="container text-center">
                        <h2 className=" exitDialog text-center my-4">Any changes will be lost, are you sure?</h2>
                        <div className="row d-flex align-items-center justify-content-center">
                        <button className="btn btn-grey mb-3 mr-2" onClick={()=> this.handleClose()}>Cancel</button>
                        <button className="btn btn-warning mb-3 ml-2" onClick={()=> this.goToSuccessPage()}>Ok</button>
                        {/* <button className="btn btn-warning mb-3 ml-2" onClick={()=> this.handleClose()}><a className="text-white" target="_self" href="/success">ok</a></button>*/}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
   }
}
export default WarningDialog
