import React, { Component } from 'react';
import { connect } from 'react-redux';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

class AssignActivityCheckBox extends Component {
    state = {
       checked:false
    }
    // componentDidMount() {
    //     let {selectedActivities , activity} = this.props;
    //     if(selectedActivities.find(id => id == activity.id))
    //     {
    //         this.setState({...this.state, checked:true});
    //         console.log("id --->",activity.id);
    //     } else {
    //         this.setState({...this.state, checked:false});
    //     }
    // }
    setSelectedActivities = (id) => {
       // console.log("checked ---->",id , this.state.checked);
       let {activity, userId} = this.props;
       const members = activity.Members ? Object.keys( activity.Members) : []
       const userIndex = members.length > 0 ? members.findIndex(user => user === userId) : -1
       if(activity.userExclusive && userIndex < 0 ) {
            this.props.showWarningAlertPopup("This is a user exclusive activity. You can neither assign nor unassign this activity.") 
       } else {
            this.setState({...this.state,checked: !this.state.checked});
            this.props.setSelectedActivities(id);
       }
    }
    render() {
     let {checked} = this.state;
     let {activity, activityKey, selectedActivities} = this.props;

       if(selectedActivities.find(id => id == activity.id)) {
           return (  <CheckBoxIcon className="checkedActivity" data-testid={`checked-activity-${activityKey + 1}`} onClick={() => this.setSelectedActivities(activity.id)} color="primary" style={{ fontSize: "24", marginRight: "5px", cursor: "pointer" }} />);
       } else {
          return ( <CheckBoxOutlineBlankIcon className="UncheckedActivity" data-testid={`unchecked-activity-${activityKey + 1}`} onClick={() => this.setSelectedActivities(activity.id)} style={{ fontSize: "24", marginRight: "5px", color: "rgb(165, 163, 163)", cursor: "pointer" }} />          )
       }
    }
}
const mapStateToProps = (settings) => {
    const { screenThemeClass, popupThemeClass } = settings
    return { screenThemeClass };
}

export default connect(mapStateToProps, {})
    (AssignActivityCheckBox);