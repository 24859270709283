/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Form, FormGroup, Input, Label, FormFeedback} from 'reactstrap';
import QueueAnim from 'rc-queue-anim';
import HeaderWithCancelButton from '../Header/HeaderWithCancelButton'
import config from '../../config/config'
import queryString from 'query-string';
import Iframe from 'react-iframe'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// redux action
import {
   validateInviteCode,
   getPrivacyPlocy,
   getUserDetailFromToken,
   getUserConnectionByToken
} from 'Actions';


const checkBoxStyle = {
   position: 'absolute',
   marginTop: '0.1rem',
   marginLeft: '-1.7rem',
   height: 20,
   width: 20
}

class AcccepPrivacyPolicy extends Component {

   state = {
      enabledPrivacy: false,
      showError: false,
      isContinueEnabled: false
   }
   componentDidMount(){
      console.group("%c[AcceptPrivacyPolicy]%c Rendered accept privacy policy screen","color:black","color:green");
   }
   handlePrivacypolicy(){
       if (this.state.enabledPrivacy){
            this.setState({enabledPrivacy: false, showError: true, isContinueEnabled: false})
       } else {
            this.setState({enabledPrivacy: true, showError: false, isContinueEnabled: true})
       }
   }
   handleLSPrivacyPolicy = (value) => {
      if (this.state.enabledPrivacy){
         console.log("%c Privacy policy not accepted","color:red");
         this.setState({enabledPrivacy: false, showError: true, isContinueEnabled: false})
      } else {
         console.log("%c Privacy policy accepted","color:green");
         this.setState({enabledPrivacy: true, showError: false, isContinueEnabled: true})
      }
   }
   componentWillUnmount() {
      console.groupEnd()
   }
   handleContinue(){
        const params = queryString.parse(this.props.location.search)
       if(params.enterpriseConnection && params.enterpriseConnection !== "null" && params.enterpriseConnection !== "undefined" && params.enterpriseConnection !== null && params.enterpriseConnection === 'sso' && this.state.enabledPrivacy){
            window.location.href = `/auth0login?organization=${params.organization}&&region=${params.region}&&token=${params.token}`
        } else {
           console.log(" Redirecting to signuplifesherpa screen")
            window.location.href = `/signuplifesherpa`
        }  
   }

   render() {
      let {themeData} = this.props;
       const {enabledPrivacy, showError, isContinueEnabled} = this.state
      return (
         <QueueAnim type="bottom" duration={2000}>
            <div className={this.props.screenThemeClass} style={themeData && themeData.loginScreenFillPaternImage ?{backgroundImage:`url(${themeData.loginScreenFillPaternImage})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}:{}}>
               <AppBar position="static" className="session-header" >
                  <Toolbar style={{minHeight: 60, top: 30}}>
                     <HeaderWithCancelButton themeLogo={this.props.themeLogo} screenThemeClass={this.props.screenThemeClass} location={this.props.location} href={`/success`}/>
                  </Toolbar>
               </AppBar>
               <div className="session-inner-wrapper"> 
                  <div className="container">
                     <div className="row row-eq-height">
                        <div className="col-sm-12 col-md-2 col-lg-3"></div>
                        <div className="col-sm-12 col-md-8 col-lg-6">
                           <div className={"session-body text-center text-white"}>
                              <Form onKeyPress={e => {
                                 if (e.key === 'Enter') this.handleContinue(e);
                               }}>
                                 <FormGroup className="has-wrapper mb-1">
                                    <Iframe 
                                       url={this.props.privacyPolicy && this.props.privacyPolicy.privacyURL && this.props.privacyPolicy.privacyURL !== '' ? this.props.privacyPolicy.privacyURL : "https://lifesherpapp.com/privacy-policy/"}
                                        width="100%"
                                        id="myId"
                                        display="initial"
                                        position="relative"
                                        sandbox="allow-scripts"
                                        />
                                 </FormGroup>
                                 <FormGroup className={`has-wrapper border pt-2 pb-2 mb-2`}>
                                   {/*  <Label check className="pl-3 base-font-color">
                                        <Input checked={enabledPrivacy ? enabledPrivacy : false} type="checkbox" id="checkbox1" style={checkBoxStyle} onChange={(e) => this.handlePrivacypolicy(e)} />{" I have reviewed & accepted the privacy policy."}
                                    </Label>*/}
                                     <div className="d-flex pl-1 align-items-left text-left pr-1" >
                                             {enabledPrivacy ?
                                                <CheckBoxIcon  onClick={() => this.handleLSPrivacyPolicy(false)} color="primary" style={{ fontSize: "30px", marginRight: "5px", cursor: "pointer" }} />
                                                :
                                                <CheckBoxOutlineBlankIcon  onClick={() => this.handleLSPrivacyPolicy(true)} style={{ fontSize: "30px", marginRight: "5px", color: "rgb(165, 163, 163)", cursor: "pointer" }} />
                                             }
                                             <div className="base-font-color mr-1">
                                                {" I have reviewed & accepted the privacy policy."}
                                             </div>
                                    </div>
                                    {showError && <FormFeedback className="d-block" style={{fontSize: 'larger'}}>Please acknowledge your consent to the Privacy Policy to continue</FormFeedback>}
                                 </FormGroup>
                                 <FormGroup className="mb-2">
                                    <Button
                                       className={`btn-block  w-100 login-screen-button`}
                                       variant="contained"
                                       size="large"
                                       onClick={() => this.handleContinue()}
                                       disabled={!isContinueEnabled}
                                    >
                                       Continue
                                        </Button>
                                 </FormGroup>
                              </Form>
                           </div>
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-3"></div>
                     </div>
                  </div>
               </div>
            </div>
         </QueueAnim>
      );
   }
}

// map state to props
const mapStateToProps = ({ authUser , settings}) => {
   const { user, loading, privacyPolicy, userOrgFromToken, themeData } = authUser;
   const inviteCode = userOrgFromToken ? userOrgFromToken.inviteCode : null
   const status = userOrgFromToken ? userOrgFromToken.status : null
   const { screenThemeClass, themeLogo } = settings;
   return { user, loading, inviteCode, status, privacyPolicy, screenThemeClass , themeLogo, themeData}
}

export default connect(mapStateToProps, {
   validateInviteCode,
   getPrivacyPlocy,
   getUserDetailFromToken,
   getUserConnectionByToken
})(AcccepPrivacyPolicy);
