import React, { Component } from 'react';
import { connect } from 'react-redux';
import { } from 'Actions'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { Label } from 'reactstrap'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from "@mui/material/DialogTitle";
import Button from '@mui/material/Button';

class DetailsPopup extends Component {
    componentDidMount() {
        console.log("%c["+this.props.detailsType+"Popup]%c Rendered "+this.props.detailsType+" details popup","color:black","color:green")
    }
    componentWillUnmount() {
        console.log(" "+this.props.detailsType+" details popup closed");
    }
    render() {
        let { list, detailsType, detailsLoading} = this.props;
       
        return (
            <Dialog
                fullScreen={false}
                open={this.props.showDetailsPopup}
                onClose={() => this.props.handleCloseDetailsPopup()}
                fullWidth={true}
                maxWidth="sm"
                aria-labelledby="responsive-dialog-title"
                className={this.props.popupThemeClass}
            >
                <DialogTitle className="p-0 m-0">
                    <div className="d-flex align-items-center template-header border-bottom justify-content-between p-3">
                        <Label data-testid="scheduleHeading" className="listItemTitle mb-0">{detailsType}</Label>
                        <div data-testid="detailsBackButton" className="backbutton" onClick={() => this.props.handleCloseDetailsPopup()}><CloseRoundedIcon /></div>
                    </div>
                </DialogTitle>
                <DialogContent  data-testid={`${detailsType}-details-list`} className="pl-4 pr-4">
                    {detailsLoading &&
                        <div className="d-flex justify-content-center loader-overlay">
                            <CircularProgress className="theame-text" disableShrink/>
                        </div>
                    }
                    {list && list.length > 0 && 
                        <Grid
                            container
                            direction="row"
                            justifycontent="flex-start"
                            alignItems="flex-start"   
                        >
                            {list && list.map((detail, key) => (
                                <Grid item key={key}>
                                    <Chip
                                        key={key}
                                        className={" mr-1 my-1  chipStyle"}
                                        label={detail.title}
                                        style={{ fontSize: "16px" }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    }
                    {list.length == 0 && 
                       <div className="d-flex justify-content-center ">
                           {`No ${detailsType} Details Found`}
                        </div>
                    }
                </DialogContent>
                <DialogActions className="d-flex p-3 justify-content-center dialog-popup-actions-container">
                   <Button color="primary" variant="contained" data-testid="detailsOkButton" className="primary-button" onClick={() => this.props.handleCloseDetailsPopup()}>OK</Button>
                </DialogActions>
            </Dialog>

        );
    }
}
const mapStateToProps = ({ AssignActivity }) => {
    const { detailsLoading} = AssignActivity
    return {detailsLoading};
}

export default connect(mapStateToProps, {})
    (DetailsPopup);