/**
 * Picture  Options
 */

import React from 'react';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IntlMessages from "../../../util/IntlMessages"

import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';

const PictureOptions = ({anchorEl, handleOpenCamMenu, openCameraMenu, handleOpenCamera, handleOpenPhotoLib, handleCloseMenu, imageUrl, datatestid}) => {
    return (
        <>
            <IconButton data-testid={datatestid} aria-haspopup="true" onClick={(event) => handleOpenCamMenu(event)}>
                {imageUrl ? 
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                        <Avatar sx={{width: 22,height: 22}} alt="activity|step badge"><PhotoCameraOutlinedIcon/></Avatar>
                        }
                    >
                        <Avatar sx={{width: 60,height: 60}} alt="activity|step image" src={imageUrl}/>
                    </Badge>
                    :
                    <Avatar sx={{width: 60,height: 60}}>
                        <CollectionsOutlinedIcon fontSize='large'/>
                    </Avatar> 
                }
            </IconButton>
            <Menu id="add-photo-menu" data-testid={`add-photo-menu-${datatestid}`} anchorEl={anchorEl} open={openCameraMenu} onClose={handleCloseMenu} >
                <MenuItem data-testid={`option-add-photo-camera-${datatestid}`} className="align-items-center justify-content-between" onClick={handleOpenCamera}><div className="menuEditItem pr-2"><IntlMessages id="newactivity.Camera"/></div><PhotoCameraOutlinedIcon/></MenuItem>
                <MenuItem data-testid={`option-add-photo-library-${datatestid}`} className="align-items-center justify-content-between" onClick={handleOpenPhotoLib}><div className="menuEditItem pr-2"><IntlMessages id="newactivity.Photo"/></div><PhotoOutlinedIcon/></MenuItem>
            </Menu>
        </>
    )
}

export default PictureOptions