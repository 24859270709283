/**
 * App  Theme3
 */
import { createTheme } from '@mui/material/styles';
import AppConfig from '../../constants/AppConfig';

const theme = createTheme({
    palette: {
        primary: {
            main: AppConfig.themeColors.theme3
        },
        secondary: {
            main: AppConfig.themeColors.grey
        }
    }
});

export default theme;