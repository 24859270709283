import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CircularProgress from '@mui/material/CircularProgress';
import config from '../../../config/config'

class PreviewPopup extends Component {
    state = {
        loading: true,
    }
    componentDidMount() {
        console.group("%c[ActivityPreviev]%c Rendered assign activity preview popup","color:black","color:green");
        this.setState({inActiveActivities:this.props.showInactiveActivities})
    }
    componentWillUnmount() {
        console.log("%c Assign Activity preview popup closed popup closed","color:red");
        console.groupEnd();
    }
    hideSpinner = () => {
       // console.log("activity preview iframe loaded .....................................");
        this.setState({ ...this.state, loading: false });
    }
    render() {
        let { idToken, userId, orgId, routineId, theme } = this.props;
        let url = `${config.lsPortalAppURL}activity/preview` //"https://lifesherpa-landing-page.web.app/activity/preview"
        //let url="http://localhost:8000/activity/preview";
        const scope = 'Routine'
        url = `${url}?idToken=${idToken}&userId=${userId}&orgId=${orgId}&routineId=${routineId}&scope=${scope}&theme=${theme}`;
        return (
            <Dialog
                fullScreen={false}
                open={this.props.previewPopup}
                onClose={() => this.props.handleClosePreviewPopup()}
                maxWidth="sm"
                aria-labelledby="responsive-dialog-title"
                data-testid="activityPreview"
                className={`previewdialogbox ${this.props.popupThemeClass}`}
            >
                <DialogContent className="p-0 m-0" >
                <div data-testid="activityPreviewBackbutton" className="activityPreviewCloseButton activityPreviewClose" onClick={() => this.props.handleClosePreviewPopup()}><CloseRoundedIcon style={{fontSize:"20px"}}/></div>
                   { <iframe    
                        src={url}  
                        id="myId"
                        onLoad={this.hideSpinner}
                        className="activity-preview-iframe"
                        display="initial"
                        position="relative"></iframe>}
                    {this.state.loading && 
                    <div className="d-flex justify-content-center loader-overlay">
                        <CircularProgress className="theame-text" disableShrink	 />
                    </div> }
                </DialogContent>
            </Dialog>

        );
    }
}

export default PreviewPopup;