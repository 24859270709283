
import { sherpaAPI } from '../sherpaApi/index'
import { NotificationManager } from 'react-notifications';

import {
    GET_ACTIVITIES_LIST_DETAIL,
    GET_SUCCESS_ACTIVITIES_LIST_DETAIL,
    SHOW_PRIVATE_ACTIVITY_LOADING,
    HIDE_PRIVATE_ACTIVITY_LOADING,
    ADD_PRIVATE_ACTIVITY,
    ADD_PRIVATE_ACTIVITY_SUCCESS,
    UPDATE_PRIVATE_ACTIVITY,
    UPDATE_PRIVATE_ACTIVITY_SUCCESS,
    GET_PRIVATE_ACTIVITY,
    GET_PRIVATE_ACTIVITY_SUCCESS,
    CHECK_DUPLICATE_ACTITY_TITLE,
    CHECK_SUCCESS_DUPLICATE_ACTITY_TITLE,
    CHECK_FAILURE_DUPLICATE_ACTITY_TITLE,
    CLEAR_ACTIVITY_DATA,
    REMOVE_API_ERROR_MESSAGE,
    SHOW_ACTIVITY_CRETAED_MESSAGE,
    UN_SUBSSCRIBE_USER_FROM_ACTIVITY,
    SUCCESS_UN_SUBSSCRIBE_USER_FROM_ACTIVITY
} from '../actions/types';
export const addPrivateActivity = (userId, orgId, activityData, idToken, scope) => (dispatch) => {
    console.log(" Adding private activity for userId:",userId,"......");
    let url = `/users/${userId}/activities/add?orgId=${orgId}&scope=${scope||"private"}`
    let data = activityData
    dispatch({ type: ADD_PRIVATE_ACTIVITY });
    sherpaAPI.post(url, data, {
        headers: {
            'Authorization': `Bearer ${idToken}`
        }
    })
        .then((response) => {
            if (response.status === 200) {
                let activity = activityData
                let activityId = response.data.activityId
                activity['activityId'] = activityId
                console.log('Activity created')
                console.log("%c Added private activity for userId:"+userId,"color:green");
                // dispatch(getPrivateActivityById(userId, orgId, activityId, idToken));
                // dispatch(showActivitySuccess(response.data.messsage || response.data.message))
                dispatch({ type: ADD_PRIVATE_ACTIVITY_SUCCESS, payload: activity });
                window.location.href = "/success"
            } else {
                dispatch({ type: HIDE_PRIVATE_ACTIVITY_LOADING });
                NotificationManager.error(response.data)
            }
        })
        .catch(error => {
            const errorMsg = error.response ? error.response.data.message : error.message;
            console.log("%c Error occured while adding private activity for userId:"+userId,"color:red",errorMsg);
            const errorData = error.response ? { message: error.response.data.message || error.response.data.messsage, title: 'Warning' } : { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" }
            dispatch({ type: CHECK_FAILURE_DUPLICATE_ACTITY_TITLE, payload: errorData })
            dispatch({ type: HIDE_PRIVATE_ACTIVITY_LOADING });
        })
}

export const updatePrivateActivity = (userId, orgId, activityId, activityData, idToken, scope) => (dispatch) => {
    console.log(" Updating private activity for activityId:",activityId,"......");
    let url = `/users/${userId}/activities/${activityId}?orgId=${orgId}&scope=${scope||"private"}`
    let data = activityData
    dispatch({ type: UPDATE_PRIVATE_ACTIVITY });
    sherpaAPI.post(url, data, {
        headers: {
            'Authorization': `Bearer ${idToken}`
        }
    })
        .then((response) => {
            if (response.status === 200) {
                console.log("%c Updated private activity for activityId:"+activityId,"color:green");
                // dispatch(getPrivateActivityById(userId, orgId, activityId, idToken));
                // dispatch(showActivitySuccess(response.data.messsage || response.data.message))
                dispatch({ type: UPDATE_PRIVATE_ACTIVITY_SUCCESS, payload: activityData });
                window.location.href = "/success"

            } else {
                dispatch({ type: HIDE_PRIVATE_ACTIVITY_LOADING });
                NotificationManager.error(response.data)
            }
        })
        .catch(error => {
            const errorMsg = error.response ? error.response.data.message : error.message;
            console.log("%c Error occured while updating private activity for activityId:"+activityId,"color:red",errorMsg);
            const errorData = error.response ? { message: error.response.data.message || error.response.data.messsage, title: 'Warning' } : { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" }
            dispatch({ type: CHECK_FAILURE_DUPLICATE_ACTITY_TITLE, payload: errorData })
            dispatch({ type: HIDE_PRIVATE_ACTIVITY_LOADING });
        })
}

export const checkDuplicateTitleAndUpdate = (userId, orgId, activityId, activityData, idToken, scope) => (dispatch) => {
    let url = `/users/${userId}/activities/check-title?orgId=${orgId}&scope=${scope||"private"}`
    let title = activityData.title
    let data = { title }
    dispatch({ type: CHECK_DUPLICATE_ACTITY_TITLE });
    sherpaAPI.post(url, data, {
        headers: {
            'Authorization': `Bearer ${idToken}`
        }
    })
        .then((response) => {
            if (response.status === 200) {
                if (response.data.isExist) {
                    const duplicateActivity = { isExist: response.data.isExist, title }
                    dispatch({ type: CHECK_SUCCESS_DUPLICATE_ACTITY_TITLE, payload: duplicateActivity });
                } else {
                    dispatch(updatePrivateActivity(userId, orgId, activityId, activityData, idToken, scope))
                }
            } else {
                dispatch({ type: CHECK_FAILURE_DUPLICATE_ACTITY_TITLE, payload: response.data })
                dispatch({ type: HIDE_PRIVATE_ACTIVITY_LOADING });
            }
        })
        .catch(error => {
            // error handling
            console.log("error occured ", error);
            const errorData = error.response ? { message: error.response.data.message || error.response.data.messsage, title: 'Warning' } : { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" }
            dispatch({ type: CHECK_FAILURE_DUPLICATE_ACTITY_TITLE, payload: errorData })
            dispatch({ type: HIDE_PRIVATE_ACTIVITY_LOADING });
        })
}

export const getPrivateActivityById = (userId, orgId, activityId, idToken, scope) => (dispatch) => {
    console.log(" Fetching private activity details for activityId:",activityId,"......")
    let url = `/users/${userId}/activities/view/${activityId}?orgId=${orgId}&scope=${scope||"private"}`
    dispatch({ type: GET_PRIVATE_ACTIVITY });
    sherpaAPI.get(url, {
        headers: {
            'Authorization': `Bearer ${idToken}`
        }
    })
        .then((response) => {
            if (response.status === 200) {
                console.log("%c Fetched private activity details for activityId:"+activityId,"color:green");
                let activity = response.data.Detail
                activity['activityId'] = activityId
                activity["activityGroups"] = response.data.ActivityGroups
                activity["createdFrom"] = response.data.createdFrom
                activity["userExclusive"] = response.data.userExclusive ? response.data.userExclusive : false
                let membersCount = 0
                if (response.data.Members) {
                    Object.keys(response.data.Members).map((key) => {
                        if (response.data.Members[key]) {
                            membersCount = membersCount + 1
                        }
                    })
                }
                activity["members"] = membersCount
                dispatch({ type: GET_PRIVATE_ACTIVITY_SUCCESS, payload: activity });
            } else {
                dispatch({ type: HIDE_PRIVATE_ACTIVITY_LOADING });
                NotificationManager.error(response.data)
            }
        })
        .catch(error => {
            const errorMsg = error.response ? error.response.data.message : error.message;
            console.log("%c Error occured while Fetching private activity details for activityId:"+activityId,"color:red",errorMsg);
            const errorData = error.response ? { message: error.response.data.message || error.response.data.messsage, title: 'Warning' } : { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" }
            dispatch({ type: CHECK_FAILURE_DUPLICATE_ACTITY_TITLE, payload: errorData })
            dispatch({ type: HIDE_PRIVATE_ACTIVITY_LOADING });
        })
}


export const getActivityDetail = (userId, activityId, idToken) => (dispatch) => {
    let url = `/users/${userId}/activity/${activityId}`
    dispatch({ type: GET_ACTIVITIES_LIST_DETAIL });
    sherpaAPI.get(url, {
        headers: {
            'Authorization': `Bearer ${idToken}`
        }
    })
        .then((response) => {
            dispatch({ type: GET_SUCCESS_ACTIVITIES_LIST_DETAIL, payload: response.data });
        })
        .catch(error => {
            // error handling
            console.log("error occured ", error);
            dispatch({ type: HIDE_PRIVATE_ACTIVITY_LOADING });
        })
}

export const showActivityLoading = () => (dispatch) => {
    dispatch({ type: SHOW_PRIVATE_ACTIVITY_LOADING })
}

export const hideActivityLoading = () => (dispatch) => {
    dispatch({ type: HIDE_PRIVATE_ACTIVITY_LOADING })
}

export const checkDuplicateActivity = (userId, orgId, activityData, idToken, scope) => (dispatch) => {
    let url = `/users/${userId}/activities/check-title?orgId=${orgId}&scope=${scope||"private"}`
    let title = activityData.title
    let data = { title };
    console.log(" Checking dublicate "+title+" activity.....");
    dispatch({ type: CHECK_DUPLICATE_ACTITY_TITLE });
    sherpaAPI.post(url, data, {
        headers: {
            'Authorization': `Bearer ${idToken}`
        }
    }).then((response) => {
            if (response.status === 200) {
                if (response.data.isExist) {
                    const duplicateActivity = { isExist: response.data.isExist, title }
                    console.log("%c Found dublicate "+title+" activity","color:green");
                    dispatch({ type: CHECK_SUCCESS_DUPLICATE_ACTITY_TITLE, payload: duplicateActivity });
                } else {
                    console.log("%c Dublicate "+title+" activity not found","color:green");
                    dispatch(addPrivateActivity(userId, orgId, activityData, idToken, scope))
                }
            } else {
                dispatch({ type: CHECK_FAILURE_DUPLICATE_ACTITY_TITLE, payload: response.data })
                dispatch({ type: HIDE_PRIVATE_ACTIVITY_LOADING });
            }
        })
        .catch(error => {
            const errorMsg = error.response ? error.response.data.message : error.message;
            console.log("%c Error occured while checking dublicate "+title+" activity","color:red",errorMsg);
            const errorData = error.response ? { message: error.response.data.message || error.response.data.messsage, title: 'Warning' } : { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" }
            dispatch({ type: CHECK_FAILURE_DUPLICATE_ACTITY_TITLE, payload: errorData })
            dispatch({ type: HIDE_PRIVATE_ACTIVITY_LOADING });
        })
}

export const clearActivityData = () => (dispatch) => {
    dispatch({ type: CLEAR_ACTIVITY_DATA })
}

export const removeAPIErrorMessage = () => (dispatch) => {
    dispatch({ type: REMOVE_API_ERROR_MESSAGE })
}

export const showActivitySuccess = (message) => (dispatch) => {
    dispatch({ type: SHOW_ACTIVITY_CRETAED_MESSAGE, payload: message })
}

export const unSubscribeActivity = (userId,orgId, idToken, unCheckedList) => (dispatch) => {
    console.log(" Unsubscribing user from activity for userId:",userId,"......");
    dispatch({ type: UN_SUBSSCRIBE_USER_FROM_ACTIVITY });
    const userUnSubscribe =  new Promise(function(resolve, reject) {
        sherpaAPI.post(`/users/${userId}/activities/un-assign?orgId=${orgId}&scope=General`, { activityIds: unCheckedList, action: "remove" }, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        }).then((response) => {
            // console.log("UN ASSIGN ACTIVITY =>", response.data);
            if (response.status === 200) {   
                console.log("%c Unsubscribed user from activity for userId:"+userId,"color:green"); 
                dispatch({ type: SUCCESS_UN_SUBSSCRIBE_USER_FROM_ACTIVITY });
            } else {
                dispatch({ type: HIDE_PRIVATE_ACTIVITY_LOADING });
            }
            resolve("success")
        }).catch(error => {
            const errorData = error.response ? { message: (error.response.data && error.response.data.messsage), title: 'Warning' } : { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" };
            // const errorData = { message: "Network connection is not available. Changes cannot be saved at this time", title: "Network Disconnected" }
            dispatch({ type: CHECK_FAILURE_DUPLICATE_ACTITY_TITLE, payload: errorData })
            dispatch({ type: HIDE_PRIVATE_ACTIVITY_LOADING });
            const errorMsg = error.response && error.response.data ? error.response.data.message : error.message;
            console.log("%c Error occured while Unsubscribing user from activity for userId:"+userId,"color:red",errorMsg);
            reject("failure")
        })
    })
    return userUnSubscribe
}