// Date Picker
import React, { Fragment, PureComponent } from 'react';
import moment from 'moment';
import {MobileDatePicker} from '@mui/x-date-pickers'
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import TextField from '@mui/material/TextField';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { parseISO } from 'date-fns';

export default class DatePickers extends PureComponent {

	state = {
		selectedDate: moment().format('MMM DD YYYY'),
	};

	componentDidMount() {
		try {
			let datestring =  new Date(this.props.value).toISOString();
			this.setState({selectedDate: datestring})
		} catch(e) {
			console.log("Invalid date, please enter valid date", e)
		}
		
	}

	handleDateChange = (date) => {
		this.setState({...this.state, selectedDate: date });
		try {
			console.log("picker value --> ",date);
			let dateObj = new Date(date);
			let month = dateObj.getMonth();
			let day = dateObj.getDate();
			let year = dateObj.getFullYear();
			month = month >= 0 && month < 12 ? true : false;
			day = day >= 1 && day < 32 ? true : false;
			year = year > 1900 && year < 2050 ? true : false;
			if(month && day && year) {
				this.props.handleOnDateChange(moment(date))
			}
		} catch(e) {
			console.log("Invalid date, please enter valid date", e)
		}
		
	};

	render() {
		const { selectedDate } = this.state;
		let datestring = new Date().toISOString();
		let invalidDate = false;
		try {
			datestring =  new Date(this.props.value).toISOString();
		} catch(e) {
			invalidDate = true
			console.error("Invalid date, please enter valid date")
		}
		const minDateString = this.props.minDate ? parseISO(new Date(moment(this.props.minDate, "MMM DD YYYY")).toISOString()) : 0;
		return (
			<Fragment>
				<div className="rct-picker mt-2">
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<MobileDatePicker
							className='w-100'
							label={this.props.label}
							value={parseISO(datestring)}
							inputFormat="MMM dd yyyy"
							data-testid={this.props.testid}
							onChange={this.handleDateChange}
							animateYearScrolling={true}
							minDate={minDateString}
							minDateMessage={"Invalid Schedule!  Please ensure that the End Date is greater than Start Date"}
							fullWidth
							renderInput={(params) => <TextField data-testid={this.props.testid} variant="standard" {...params} error={invalidDate} helperText={ invalidDate ? "Invalid Schedule!  Please fill valid date": ""} />}
							InputProps={{"data-testid": "content-input",
								startAdornment: (
									<InputAdornment position="start" className="date-picker-icon">
										<CalendarTodayRoundedIcon fontSize="small" />
									</InputAdornment>
								),
							}}
							components={{
								LeftArrowIcon: ArrowBackRoundedIcon,
								RightArrowIcon: ArrowForwardRoundedIcon,
							}}
							//showToolbar={false}
							toolbarFormat="MMM dd yyyy"
						/>
					</LocalizationProvider>
				</div>
			</Fragment>
		)

	}
}