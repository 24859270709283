/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import AppConfig from 'Constants/AppConfig';
import { connect } from 'react-redux';
class HeaderWithCancelButton extends Component {

   render() {
    const params = queryString.parse(this.props.location.search);
    const showCancelButton = params && params.mode === "web" ? false : true;
    const applogo= localStorage.getItem('activeTheme') === '5' || localStorage.getItem('activeTheme') === '7' || localStorage.getItem('activeTheme') === '10' ? AppConfig.appLogoWhite  : AppConfig.appLogo 
    let activeTheme = localStorage.getItem('theme') ? JSON.parse(localStorage.getItem('theme')) : {id:1, name:"primary"};
    const themeLogo= localStorage.getItem("loginLogoImage") || this.props.themeLogo;
    //let { activeTheme } = this.props;
    const poweredByLS = localStorage.getItem("loginLifeSherpaImage")
    //console.log("activeTheme-->",activeTheme);
    if (process.env.REACT_APP === 'DES') {
        return (
            <div className="container text-center">
                {showCancelButton && <p className="text-right" style={{fontSize:"large" ,position: 'absolute', right: 12, top: -30}}><a target="_self" href={this.props.href} className="text-white">Cancel</a></p>}
                <h1 className="text-white text-center">{'Autism@Work'}</h1>
                <img src={poweredByLS} alt="session-logo" width="auto" height="30" />
            </div>
          );
        } else if (activeTheme && (activeTheme.name == 'primary' || activeTheme.name == 'Default')) {
            return (
                <div className="container text-center">
                    {showCancelButton && <p className="text-right" style={{fontSize:"large" ,position: 'absolute', right: 12, top: -30}}><a target="_self" href={this.props.href} >Cancel</a></p>}
                    <img src={themeLogo || applogo} alt="session-logo"  width="auto" height="auto" className='auto-resize-img' />
                </div>
              );
      } else {
        return (
            <div className="container text-center">
                {showCancelButton && <p className="text-right" style={{fontSize:"large" ,position: 'absolute', right: 12, top: -30}}><a target="_self" href={this.props.href} >Cancel</a></p>}
                <img src={themeLogo || applogo} alt="session-logo"  width="auto" height="auto" className='auto-resize-img' />
                {poweredByLS && <div><img src={poweredByLS} alt="session-logo" width="auto" height="30" /></div>}
            </div>
          );
      }
      
   }
}
const mapStateToProps = ({ authUser, settings }) => {
    const { screenThemeClass, popupThemeClass, themeLogo, activeTheme } = settings;
    return {authUser, screenThemeClass, themeLogo, activeTheme}
 }
export default connect(mapStateToProps, {})(HeaderWithCancelButton);