import React,{Component} from "react";
import SearchIcon from "@mui/icons-material/Search";
import IntlMessages from "../../util/IntlMessages"
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from "@mui/material/InputAdornment";

class SearchActivities extends Component
{
    state={
        search:''
    }
    handelSearch = (value) => {
         this.setState({...this.state,search:value});
         this.props.handelSearchActivities(value)
    }
    render() {
        let {search} = this.state;
        return (
        <div className="row mt-2 assign-activity justify-content-center assign-activity-search">
            <div className="d-flex col-lg-6 col-md-12">
               <div data-testid="searchIcon" className="titleIcon searchFieldIcon mr-1">
                   <SearchIcon color="primary"/>
               </div>
               <IntlMessages id="assignActivity.searchActivity">{
                 placeholder =>
                    <TextField id="standard-search" 
                       data-testid="searchField" 
                       onChange={(e) => this.handelSearch(e.target.value)}
                        type="search" 
                        placeholder={`${placeholder}`}
                        value={search ? search: ''}
                        variant="standard"
                        className={ "inputTitle border-0 w-100"} 
                        InputProps={{
                        endAdornment: ( search && search.length > 0 ?
                            <InputAdornment position="end">
                                <CloseIcon color="primary" onClick={() => this.handelSearch("")} style={{cursor:"pointer",marginRight:"7px"}}/>
                            </InputAdornment> :  <InputAdornment position="end"> </InputAdornment>
                        )
                        }}
                        />
                 }
               </IntlMessages>
           </div>
       </div>
        )
    }
}
export default SearchActivities;