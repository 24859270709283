/**
 * Auth Actions
 * Auth Action With Google, Facebook, Twitter and Github
 */

import {geoCoding} from '../sherpaApi/index'
import config from '../config/config'

import {
   GET_USER_GEOLOCATION,
   GET_SUCCESS_USER_GEOLOCATION,
   SHOW_LOCATION_LOADING,
   HIDE_LOCATION_LOADING,
} from '../actions/types';

export const showLocationLoading = () => ({
   type: SHOW_LOCATION_LOADING
})

export const hideLocationLoading = () => ({
   type: HIDE_LOCATION_LOADING
})

export const getGeoLocation = (location) => (dispatch) => {
   ///json?latlng=40.714224,-73.961452&key=AIzaSyCN1cxrmOJkhbwnM6p96O9rzqW3TqXMOJo
   const url = '/json'
   const param = {
      latlng: `${location.lat},${location.lng}`,
      key: config.API_KEY
   }
   dispatch({ type: GET_USER_GEOLOCATION });
   geoCoding.get(url, {params: param})
      .then((response) => {
         if (response.status === 200) {
            const address = response.data.results[0]['address_components']
            const countryInfo = address.filter(info => info.types[0] === 'country')
            const geoData = {countryName: countryInfo[0].long_name, country_code: countryInfo[0].short_name}
            console.log('geo data ------->',geoData)
            dispatch({ type: GET_SUCCESS_USER_GEOLOCATION, payload: geoData});
         } else {
            dispatch({ type: GET_SUCCESS_USER_GEOLOCATION, payload: response.data});
         }
      })
      .catch(error => {
         // error handling
         dispatch({ type: GET_SUCCESS_USER_GEOLOCATION, payload: error.response.data});
         console.log("error occured ", error.response.data);
      })
}
