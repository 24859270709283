
import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {Label, Card, CardImg, CardBody, CardTitle, Badge} from 'reactstrap'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Chip from '@mui/material/Chip'
import FilterActivityTemplate from './FilterActivityTemplate'
import IntlMessages from "../../util/IntlMessages"
import HelpDesk from '../Infopage/HelpDesk'
import SweetAlert from 'react-bootstrap-sweetalert'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Grid from '@mui/material/Grid';
class ActivityTemplate extends Component {
    state = { 
        showAlert:false,
        openKey:-1,
        openHelpDesk: false,
        filterOpen: false,
        groupIds: [],
        activityTemplateGroups: [],
     }
    handleClose=()=>{
        this.props.closeActivityTemplate();
    }
    handleFilterOpen(){
        const activityTemplateGroups = JSON.parse(JSON.stringify(this.props.groups)) //Object.assign({}, this.props.groups);
        this.setState({filterOpen: !this.state.filterOpen, activityTemplateGroups})
    }
    handleSelect(){
        this.props.handleSelectTemplate(this.state.openKey)
        this.setState({showAlert:false,openKey:-1})
    }

    handleAlert(key){
        const {activityTitle, activityDesc, steps, schedules} = this.props
        if (activityTitle === '' && activityDesc === '' && steps.length === 0 && schedules.length === 0) {
            this.props.handleSelectTemplate(key)
        } else {
            if(this.state.showAlert){
                this.setState({showAlert:false,openKey:-1})
            } else {
                this.setState({showAlert:true,openKey:key})
            } 
        }
    }

    handleSelectGroup = (key) => {
        //console.log("key----->",key);
        const activityTemplateGroups = this.state.activityTemplateGroups
        //console.log("fileter select key=>",activityTemplateGroups);
        let  groupIds = this.state.groupIds
        activityTemplateGroups[key].selected = !activityTemplateGroups[key].selected
        let index = groupIds.indexOf(activityTemplateGroups[key].groupId)
        if (index === -1) {
            groupIds.push(activityTemplateGroups[key].groupId)
        } else {
            groupIds.splice(index, 1)
        }
        this.setState({activityTemplateGroups, groupIds});

    }
    handleClearFilters() {
        const {activityTemplateGroups} = this.state;
        activityTemplateGroups && activityTemplateGroups.forEach(group => {
            group.selected = false;
        });
        this.setState({filterOpen: false, groupIds: [], activityTemplateGroups: activityTemplateGroups});
        this.props.filterTemplateActivity([], activityTemplateGroups)
    }


    filterTemplateActivity(){
        const {groupIds, activityTemplateGroups} = this.state
        this.handleFilterOpen()
        this.props.filterTemplateActivity(groupIds, activityTemplateGroups)
    }
    handleCloseHelpDesk(){
        this.setState({openHelpDesk: !this.state.openHelpDesk})
    }
   
    render() {
        const { title, activityTemplates, groups, popupThemeClass, scope } = this.props; 
        return (
            <div className="object-editor">
                <Dialog
                    fullScreen={true}
                    open={this.props.open}
                    onClose={this.handleClose}
                    fullWidth={true}
                    maxWidth="xl"
                    aria-labelledby="responsive-dialog-title"
                    className={`${popupThemeClass} activity-templates-filter-popup`}
                >
                    {   this.state.showAlert &&
                            <SweetAlert
                                warning
                                showCancel
                                btnSize="sm"
                                customClass="warningText"
                                confirmBtnText="OK"
                                confirmBtnBsStyle="warning"
                                cancelBtnBsStyle="default"
                                title={<IntlMessages id="Template.sure" />}
                                onConfirm={()=>this.handleSelect()}
                                onCancel={()=>this.handleAlert()}
                                >
                            <div style={{fontFamily:"'Open sans', sans-serif",fontWeight:400}}><IntlMessages id="Template.Confirmation" /></div>
                        </SweetAlert>
                    }
                    <AppBar position="static" className="session-header">
                        <Toolbar>
                            <div className="d-flex align-items-center template-header justify-content-between p-3 pb-4 mt-1 w-100" >
                                <Label data-testid="template-title" className="listItemTitle mb-0">{title}</Label>
                                <button data-testid="info-button" className="helpbutton mr-2 ml-auto" onClick={() => this.handleCloseHelpDesk()}>?</button>                        
                                <div data-testid="template-back" className="backbutton" onClick={this.handleClose}><CloseRoundedIcon /></div>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <HelpDesk popupThemeClass={this.props.popupThemeClass} open={this.state.openHelpDesk} closeHelper={this.handleCloseHelpDesk.bind(this)} title={<IntlMessages id="Template.info_ActivityTemplate" />}/>
                        <DialogContent className="p-3">
                            <div className="steptitle mb-3">Select a template to be the starting point for creating your Activity</div>
                            <Grid
                                container
                                direction="row"
                                justifycontent="flex-start"
                                alignItems="flex-start"
                                className="mb-3"
                                >
                                {/*groups && groups.map((group, key) => (
                                    key < 2 && <div key={key} className={group.selected ? "chip-array-selected" : "chip-array"}>
                                        <Chip onClick={this.handleFilterOpen.bind(this)} className="mr-1 mb-1"  key={key} label={group.name} /></div>
                                ))*/}
                                {groups && groups.map((group, key) => (
                                    key < 2 && <Grid item key={key}>
                                           {group.selected ?
                                            <Chip
                                                className={" mr-1  mb-1 chipSelected"}
                                                avatar={<CheckCircleOutlinedIcon data-testid={`check-category-${key + 1}`} />}
                                                label={group.name} 
                                                style={{ fontSize: "16px" }}
                                                onClick={this.handleFilterOpen.bind(this)} />
                                                :
                                                <Chip
                                                className={" mr-1 mb-1  chipStyle"}
                                                label={group.name} 
                                                style={{ fontSize: "16px" }}
                                                onClick={this.handleFilterOpen.bind(this)} />
                                            }
                                        </Grid>
                                ))}
                               {groups && groups.length > 2 && 
                                 // <div key="more-chip" className="chip-array-selected">
                                 //  <Chip onClick={this.handleFilterOpen.bind(this)} className="mr-1 mb-1" key="more" label={<div><div className="mr-2"><IntlMessages id="Template.More"/></div><Badge color="danger" className="badge-xs badge-top-right rct-notify">{groups.length-2}</Badge></div>} />
                                 //  </div>
                                 <Grid key="more-chip" item>
                                     {groups.find((group,key)=> key > 1 && group.selected ? true : false) ?
                                        <Chip
                                            className={" mr-1 mb-1 chipSelected"}
                                            avatar={<CheckCircleOutlinedIcon   />}
                                            label={<div><div className="mr-2"><IntlMessages id="Template.More"/></div><Badge color="danger" className="badge-xs badge-top-right rct-notify">{groups.length-2}</Badge></div>} 
                                            style={{ fontSize: "16px" }}
                                            onClick={this.handleFilterOpen.bind(this)} />
                                            :
                                        <Chip
                                            className={" mr-1 mb-1  chipStyle"}
                                            label={<div><div className="mr-2"><IntlMessages id="Template.More"/></div><Badge color="danger" className="badge-xs badge-top-right rct-notify">{groups.length-2}</Badge></div>} 
                                            style={{ fontSize: "16px" }}
                                            onClick={this.handleFilterOpen.bind(this)} />
                                     }
                                 
                                </Grid>
                                }
                            </Grid>
                            <div className="row">
                                {activityTemplates && activityTemplates.length > 0 ? activityTemplates.map((activityTemplate, key) => (
                                    <div className="col-6 mb-30" key={key} onClick={() => this.handleAlert(key)}>
                                        <Card key={key}>
                                            <CardImg top width="100%" className="img-fluid ripple-effect" src={require(`Assets/img/prepare.png`)} alt="Card image cap" />
                                            <CardBody className="p-2">
                                                <CardTitle>{activityTemplate.Detail.Routine.title}</CardTitle>
                                            </CardBody>
                                        </Card>
                                    </div>
                                ))
                                :
                                <div className="col-12" key='info-message'>
                                    <Card key='info-message-card'>
                                        <CardBody className="p-2">
                                            <CardTitle>No {scope === 'general' ? 'general': 'private'} activity templates are available. Please contact your administrator.</CardTitle>
                                        </CardBody>
                                    </Card>
                                </div>
                            } 
                            </div>
                        </DialogContent>
                </Dialog>
                <FilterActivityTemplate 
                    popupThemeClass={popupThemeClass} 
                    filterTemplateActivity={this.filterTemplateActivity.bind(this)} 
                    groups={this.state.activityTemplateGroups} 
                    open={this.state.filterOpen} 
                    handleClose={this.handleFilterOpen.bind(this)} 
                    handleSelectGroup={this.handleSelectGroup}
                    handleClearFilters={this.handleClearFilters.bind(this)}
                    />
            </div>
        );
    }
}
 
export default ActivityTemplate;
