/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import QueueAnim from 'rc-queue-anim';
import { connect } from 'react-redux';
import Header from '../Header/Header'
import {FormGroup, FormFeedback, Label} from 'reactstrap'
import Switch from '@mui/material/Switch';
// import TextField from '@mui/material/TextField';
import CalendarTodayRounded from '@mui/icons-material/CalendarTodayRounded'
import AccessTimeRounded from '@mui/icons-material/AccessTimeRounded'
import RepeatRounded from '@mui/icons-material/RepeatRounded'
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import RctCollapsibleCard from '../RctCollapsibleCard/RctCollapsibleCard'
import ScheduleUpdate from '../ActivitySchedule/ScheduleUpdate'
import moment from 'moment' 
import queryString from 'query-string';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import SweetAlert from 'react-bootstrap-sweetalert'
import IntlMessages from "../../util/IntlMessages"
import WarningDialog from '../../components/Dialog/WarningDialog'
import UserExclusiveWarning from "../../components/Dialog/UserExclusiveWarning"
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PictureOptions from '../SherpaCamera/components/PictureOptions';
import SherpaCamera from "../SherpaCamera"
import PhotoLibrary from '../SherpaCamera/components/PhotoLibrary';
import imageCompression from 'browser-image-compression';

import {
    addPrivateActivity,
    updatePrivateActivity,
    getPrivateActivityById,
    checkDuplicateActivity,
    clearActivityData,
    checkDuplicateTitleAndUpdate,
    removeAPIErrorMessage,
    showActivitySuccess,
    unSubscribeActivity
} from '../../actions/ActivityActions'
import {getUserById} from "../../actions/AuthActions"
import { generateRRule, 
    getDayOccurences, 
    refreshCurrentSchedule,
    handleCustomSchedule,
    getweekDayWithCheck,
    getISODateString,
    getCookies,
    getCustomeRecurrenceFromRrule,
    isMobileDevice,
    getBase64FromUrl,
    dataURItoBlob
 } from '../../helpers/helpers';
 import LSInput from '../CustomComponent/LSInput';
 import LSTextArea from "../CustomComponent/LSTextArea"

class NewNotification extends Component {
    state = {
        idToken: "",
        userId: 'ODWYDyio2RhrEHJGq6fDXrSCpeI2',
        orgId: 'Test',
        activityId: '',
        activityTitle: '',
        activityDesc: '',
        currentSchedule: {
            startDate: moment().format('MMM DD YYYY'),
            startTime: moment().format('hh:mm A'),
            endTime: moment().format('hh:mm A'),
            text: "Daily",
            recurrenceMethod: "",
            rrule: "",
            objStartDate: moment(),
            objStartTime: moment(),
            objEndTime: moment().add(5, 'minutes'),
            nextSchedules: [],
            notify: true
        },
        openSchedule: false,
        schedules: [],
        anchorEl: null,
        isTitleFilled: true,
        editSchedule: false,
        editScheduleKey: 0,
        customEnabled: false,
        weekSelected: false,
        monthSelected: false,
        customRepeatValue: 'Day',
        customCount: 1,
        monthlyOccurenceDay: '',
        customUntill: moment().add(1, 'd').format('MMM DD YYYY'),
        customInterval: 1,
        weekDayWithCheck: [
            {
                day: 'MO',
                checked: true,
                name: 'MON'
            },
            {
                day: 'TU',
                checked: false,
                name: 'TUE'
            },
            {
                day: 'WE',
                checked: false,
                name: 'WED'
            },
            {
                day: 'TH',
                checked: false,
                name: 'THU'
            },
            {
                day: 'FR',
                checked: false,
                name: 'FRI'
            },
            {
                day: 'SA',
                checked: false,
                name: 'SAT'
            },
            {
                day: 'SU',
                checked: false,
                name: 'SUN'
            },
        ],
        repeatType: [
            {
                type: 'Never',
                selected: true,
                value: null
            },
            {
                type: 'After',
                selected: false,
                value: null
            },
            {
                type: 'On',
                selected: false,
                value: null
            }
        ],
        invalidEndTime: false,
        propsUpdated: false,
        minCharLength: true,
        textAreaField: React.createRef(),
        isDataSaved: true,
        showUnSavedWarning: false,
        repeatError: '',
        customCountError: '',
        repeatValue: 'Daily',
        activityType: "QT-Reminder",
        openCameraMenu: false,
        openScheduleMenu: false,
        openCamera: false,
        openPhotoLib: false,
        inputRef: React.createRef(),
        activityBgImageUrl: null,
        activityEnabled: true,
        scope: null,
        showUserExclusiveWarning: false,
        createdFrom: []

    }

    componentDidMount(){
        let idToken = this.state.idToken
        let userId = this.state.userId
        let orgId = this.state.orgId
        let activityId = this.state.activityId
        let scope = this.state.scope
        const params = this.getParams();
        console.group("%c[NewNotification]%c Rendered notification editor screen","color:black","color:green");
        if (params) {
            this.props.clearActivityData()
            // let params = queryString.parse(this.props.location.search)
            idToken = params.idToken
            userId = params.userId
            orgId = params.orgId
            activityId = params.activityId
            scope = params.scope
            if(activityId) {
                this.props.getUserById(userId, idToken);
                this.props.getPrivateActivityById(userId, orgId,  activityId, idToken, scope)
            } 
        }
        this.setState({scope, idToken, userId, orgId, activityId, currentSchedule: refreshCurrentSchedule()})
    }

    //get params from query string or cookie
	getParams(){
        let params = null
        if (this.props.location.search) {
            params = queryString.parse(this.props.location.search)
        } else if (getCookies('objCookieData')) {
            params = JSON.parse(getCookies('objCookieData'))
        } 
        // console.log('params-->', params)
        return params
	}

    componentDidUpdate() {
        if (this.props.activity && !this.state.propsUpdated) {
            const activityTitle = this.props.activity.Routine.title
            const activityDesc = this.props.activity.Routine.detailedDescription;
            const activityType = this.props.activity.Routine.type;
            const activityEnabled = !this.props.activity.Routine.inactive || true
            const activityBgImageUrl = this.props.activity.Routine.backgroundImageUrl || null
            let createdFrom = this.props.activity.createdFrom || []
            const activityId = this.props.activity.activityId
            const previousActivityData = {
                detailedDescription: activityDesc, 
                id: activityId, 
                organization: this.state.orgId,
                timestamp: new Date().toISOString(),
                title: activityTitle
            }
            createdFrom.push(previousActivityData)
            let schedules = []
            let showUserExclusiveWarning  = this.props.activity.members > 1 ? true : false
            
            if (this.props.activity.Routine.newSchedule) {
                Object.keys(this.props.activity.Routine.newSchedule).map((scheduleKey, index) => {
                    const activitySchedule = this.props.activity.Routine.newSchedule[scheduleKey]
                    const schedule = {
                        startDate: activitySchedule.startDate,
                        startTime: moment(activitySchedule.startTime, 'HH:mm').format('hh:mm A'),
                        endTime: moment(activitySchedule.endTime, 'HH:mm').format('hh:mm A'),
                        text: activitySchedule.text,
                        recurrenceMethod: activitySchedule.recurrenceMethod,
                        rrule: activitySchedule.rrule,
                        objStartDate: moment(getISODateString(activitySchedule.startDate)),
                        objStartTime: moment(activitySchedule.startTime, 'HH:mm'),
                        objEndTime: moment(activitySchedule.endTime, 'HH:mm'),
                        notify: activitySchedule.notify === false ? activitySchedule.notify : true
                    }
                    schedules.push(schedule)
                })
            }
            this.setState({createdFrom, showUserExclusiveWarning, activityEnabled, activityBgImageUrl, activityTitle, activityDesc, schedules, propsUpdated: true, activityType})
        }
    }
  
     handleChange(event, value) {
        if (this.state.activityTitle && this.state.activityTitle !== '') {
            this.setState({ activeIndex: value, isTitleFilled: true, isDataSaved: false });
        } else {
            this.setState({isTitleFilled: false})
        }
     }

     handleOpenSchedule(){
        let {weekDayWithCheck, currentSchedule, editSchedule} = this.state;
        weekDayWithCheck=getweekDayWithCheck()
        if (!this.openSchedule) {
            currentSchedule = refreshCurrentSchedule()
            editSchedule = false;
            currentSchedule.notify = true;
        } 
        this.setState({ openSchedule: !this.state.openSchedule, isTitleFilled: true,weekDayWithCheck,customEnabled: false,currentSchedule, repeatError: '', customCountError: '', customInterval: 1, customCount: 1, editSchedule });
     }

     handleSaveSchedule(){
        let schedules = this.state.schedules
        let currentSchedule = this.state.currentSchedule
        if (currentSchedule.objEndTime && currentSchedule.objEndTime.diff(currentSchedule.objStartTime, 'minutes') < 5) {
           this.setState({invalidEndTime:true})
        } else if(this.state.weekDayWithCheck.filter(weekday => weekday.checked === true).length === 0 || this.state.repeatError !== '' || this.state.customCountError !== '') {
            //console.log('Please select a day form weekday.')
         } else {
           const schedule = {
               startDate: currentSchedule.startDate,
               startTime: moment(getISODateString(currentSchedule.objStartTime)).format('hh:mm A'),
               endTime: moment(getISODateString(currentSchedule.objEndTime)).format('hh:mm A'),
               text: currentSchedule.text,
               recurrenceMethod: currentSchedule.recurrenceMethod,
               rrule: currentSchedule.rrule,
               objStartDate: currentSchedule.objStartDate,
               objStartTime: currentSchedule.objStartTime,
               objEndTime: currentSchedule.objEndTime,
               notify: currentSchedule.notify
           }
            if (this.state.editSchedule) {
               schedules[this.state.editScheduleKey] = schedule
               this.setState({schedules, openSchedule: false, editSchedule: false, invalidEndTime:false, currentSchedule: refreshCurrentSchedule(), customEnabled: false, isDataSaved: false})
            } else {
               schedules.push(schedule)
               this.setState({schedules, openSchedule: false, invalidEndTime:false, currentSchedule: refreshCurrentSchedule(), customEnabled: false, isDataSaved: false})
            }
        }
     }

    handleSaveActivity(){
        if (this.state.activityTitle !== '' && this.state.activityTitle.length > 2) {
            if (this.state.scope === 'general') {
                if (this.props.activity && this.props.activity.activityId && this.props.activity.members && this.props.activity.members > 1) {
                    const {idToken, userId, orgId} = this.state
                    this.props.unSubscribeActivity(userId, orgId, idToken, [this.props.activity.activityId]).then((result) => {
                        if (result === 'success') {
                            this.handleAddActivity()
                        }
                    } )
                } else if (this.props.activity && this.props.activity.activityId) {
                    this.handleUpdateActivity()
                } else {
                    this.handleAddActivity()
                }
            } else {
                if (this.props.activity && this.props.activity.activityId) {
                    this.handleUpdateActivity()
                } else {
                    this.handleAddActivity()
                }
            }
            this.setState({isDataSaved: true})
        } else if(this.state.activityTitle.length < 3) {
            this.setState({minCharLength: false });
        } else {
            this.setState({isTitleFilled: false });
        }
    }

    handleAddActivity(){
        const {idToken, userId, orgId, scope, activityBgImageUrl, activityType} = this.state
        const {version} = this.props
        let schedules =  []
        this.state.schedules.map((item, key) => {
            const {startDate, objStartTime, objEndTime,text,recurrenceMethod,rrule, notify} = item
            const time = moment(getISODateString(objStartTime)).format('HH:mm').toString()
            const dateStr = moment(getISODateString(startDate)).set({ hour: parseInt(time.split(":")[0]), minute: parseInt(time.split(":")[1]) }).toString();
            const newSchedule = {
                "startDate": new Date(dateStr).toISOString(), 
                "startTime": time,
                "endTime": moment(getISODateString(objEndTime)).format('HH:mm').toString(),
                text,
                recurrenceMethod,rrule,
                notify
            }

            schedules.push(newSchedule)
        })
        let activity = null
        if (scope === "general") {
            const activityGroups = this.props.activity ? this.props.activity.activityGroups : null
            const createdFrom = this.props.activity ? this.props.activity.members ? this.props.activity.members > 1 ?  this.state.createdFrom : [] : [] : []
            const userExclusive = this.props.activity ? this.props.activity.members ? this.props.activity.members > 1 ? true : false : false : true
            const cloneActivity = this.props.activity && this.props.activity.Routine ? {...this.props.activity.Routine} : {}

            delete cloneActivity.steps;
            delete cloneActivity.schedules;
            delete cloneActivity.newSchedules;

            activity = {
                ...cloneActivity,
                "title": this.state.activityTitle,
                "type": activityType || "QT-Reminder",
                "detailedDescription": this.state.activityDesc,
                "doNotNotifyIfDecliningToStartRoutine": false,
                "doNotNotifyOnRoutineCancel": false,
                "doNotNotifyOnRoutineCompletion": false,
                "doNotNotifyOnRoutineStart": false,
                "doNotShowRoutineCompletionReward": false,
                "notifyOnStepCompletion": false,
                "schedules": schedules,
                "backgroundImageUrl": activityBgImageUrl,
                "inactive": false,
                userExclusive,
                activityGroups,
                createdFrom
            }
        } else {
            activity = {
                "title": this.state.activityTitle,
                "type": activityType || "QT-Reminder",
                "detailedDescription": this.state.activityDesc,
                "doNotNotifyIfDecliningToStartRoutine": false,
                "doNotNotifyOnRoutineCancel": false,
                "doNotNotifyOnRoutineCompletion": false,
                "doNotNotifyOnRoutineStart": false,
                "doNotShowRoutineCompletionReward": false,
                "notifyOnStepCompletion": false,
                "schedules": schedules
            }
        }

        if (version && version === "v2") {
            activity["backgroundImageUrl"] = activityBgImageUrl
        }

        console.log("Activity Data: ", activity)
        this.props.checkDuplicateActivity(userId, orgId,  activity, idToken, scope)
        // this.props.addPrivateActivity(userId, orgId,  activity, idToken)
    }

    handleUpdateActivity(){
        const {idToken, userId, orgId, scope, activityEnabled, activityBgImageUrl, activityType} = this.state
        const {version} = this.props
        let activityId = this.props.activity.activityId
        let schedules =  []
        if (this.props.activity.Routine.newSchedule && Object.keys(this.props.activity.Routine.newSchedule).length > 0) {
            this.state.schedules.map((item, key) => {
                const {startDate, objStartTime, objEndTime,text,recurrenceMethod,rrule, notify} = item
                const time = moment(getISODateString(objStartTime)).format('HH:mm').toString()
                const dateStr = moment(getISODateString(startDate)).set({ hour: parseInt(time.split(":")[0]), minute: parseInt(time.split(":")[1]) }).toString();
                const newSchedule = {}
                newSchedule['startDate'] = new Date(dateStr).toISOString()
                newSchedule['startTime'] = time
                newSchedule['endTime'] = moment(getISODateString(objEndTime)).format('HH:mm').toString()
                newSchedule['text'] = text
                newSchedule['recurrenceMethod'] = recurrenceMethod
                newSchedule['rrule'] = rrule;
                newSchedule['notify'] = notify
                if (Object.keys(this.props.activity.Routine.newSchedule)[key]) {
                    newSchedule['scheduleId'] = Object.keys(this.props.activity.Routine.newSchedule)[key]
                }
                schedules.push(newSchedule)
            })
        } else {
            this.state.schedules.map((item, key) => {
                const {startDate, objStartTime, objEndTime,text,recurrenceMethod,rrule, notify} = item
                const time = moment(getISODateString(objStartTime)).format('HH:mm').toString()
                const dateStr = moment(getISODateString(startDate)).set({ hour: parseInt(time.split(":")[0]), minute: parseInt(time.split(":")[1]) }).toString();
                const newSchedule = {
                    "startDate": new Date(dateStr).toISOString(), 
                    "startTime": time,
                    "endTime": moment(getISODateString(objEndTime)).format('HH:mm').toString(),
                    text,
                    recurrenceMethod,
                    rrule,
                    notify
                }
    
                schedules.push(newSchedule)
            })
        }

        let activity = null
         if (scope === "general") {
            const activityGroups = this.props.activity ? this.props.activity.activityGroups : null
            const userExclusive = this.props.activity.userExclusive
            activity = {
                "title": this.state.activityTitle,
                "type": activityType || "QT-Reminder",
                "detailedDescription": this.state.activityDesc,
                "doNotNotifyIfDecliningToStartRoutine": false,
                "doNotNotifyOnRoutineCancel": false,
                "doNotNotifyOnRoutineCompletion": false,
                "doNotNotifyOnRoutineStart": false,
                "doNotShowRoutineCompletionReward": false,
                "notifyOnStepCompletion": false,
                "schedules": schedules,
                "backgroundImageUrl": activityBgImageUrl,
                "inactive": !activityEnabled,
                userExclusive,
                activityGroups,
            }
         } else {
            activity = {
                "title": this.state.activityTitle,
                "type": activityType || "QT-Reminder",
                "detailedDescription": this.state.activityDesc,
                "doNotNotifyIfDecliningToStartRoutine": false,
                "doNotNotifyOnRoutineCancel": false,
                "doNotNotifyOnRoutineCompletion": false,
                "doNotNotifyOnRoutineStart": false,
                "doNotShowRoutineCompletionReward": false,
                "notifyOnStepCompletion": false,
                "schedules": schedules
            }
         }

        if (version && version === "v2") {
            activity["backgroundImageUrl"] = activityBgImageUrl
        }

         
        if (this.props.activity.Routine.title !== this.state.activityTitle) {
            this.props.checkDuplicateTitleAndUpdate(userId, orgId, activityId, activity, idToken, scope)
        } else {
            this.props.updatePrivateActivity(userId, orgId, activityId, activity, idToken, scope)
        }
    }

    handleClickMenu(event, key) {
        this.setState({ anchorEl: event.currentTarget,  editScheduleKey: key, openScheduleMenu:true, openCameraMenu: false});
    }
    
    handleCloseMenu = () => {
        this.setState({ anchorEl: null, openScheduleMenu:false, openCameraMenu: false });
    }

    handleOpenCamMenu(event){
        if(isMobileDevice()) {
            this.handleOpenPhotoLib(event)
        } else {
            this.setState({ anchorEl: event.currentTarget,  openCameraMenu: true, openScheduleMenu: false});
        }
    }

    handleEditSchedule = event => {
        let currentSchedule = this.state.schedules[this.state.editScheduleKey]
        const recurrenceMethod = currentSchedule.recurrenceMethod
        let anchorEl = null
        let editSchedule = true
        let openSchedule = true
        let invalidEndTime = null

        let customEnabled = this.state.customEnabled;
        if (recurrenceMethod === 'custom') {
            customEnabled = true
            getCustomeRecurrenceFromRrule(currentSchedule, this);
        }
        currentSchedule.notify = currentSchedule.notify === false ? currentSchedule.notify : true;
        if (currentSchedule.objEndTime && currentSchedule.objEndTime.diff(currentSchedule.objStartTime, 'minutes') < 5) {
            invalidEndTime = true
         } else {
            invalidEndTime = false
         }
         this.setState({ anchorEl, editSchedule, openSchedule, currentSchedule, invalidEndTime, customEnabled});
    };

    handleDeleteSchedule = event => {
        let schedules = this.state.schedules
        schedules.splice(this.state.editScheduleKey, 1)
        this.setState({ anchorEl: null,  schedules, isDataSaved: false, openScheduleMenu: false});
    };

    //Schedules methods

    handleRepeat(repeatText){
        let currentSchedule = this.state.currentSchedule
        const rruleDic =  generateRRule(repeatText, null, this.state.currentSchedule)
        currentSchedule['rrule'] = rruleDic.rrule
        currentSchedule['text'] = rruleDic.text
        currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
        currentSchedule['nextSchedules'] = rruleDic.nextSchedules
        if (repeatText === 'Custom') {
            this.setState({repeatValue: repeatText,currentSchedule, customEnabled: true, customRepeatValue: 'Day', weekSelected: false, monthSelected: false})
        } else {
            this.setState({repeatValue: repeatText,currentSchedule, customEnabled: false})
        }
   }

   handleToggle(){
        let currentSchedule = this.state.currentSchedule
        let repeatValue = ''
        let{weekDayWithCheck}=this.state;
        weekDayWithCheck=getweekDayWithCheck()
        if (!this.state.customEnabled) {
            repeatValue = 'Custom'
            const rruleDic =  generateRRule('Custom', null, this.state.currentSchedule)
            currentSchedule['text'] = rruleDic.text
            currentSchedule['rrule'] = rruleDic.rrule
            currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
            currentSchedule['nextSchedules'] = rruleDic.nextSchedules
        } else {
            repeatValue = 'Daily'
            const rruleDic =  generateRRule('Daily', null, this.state.currentSchedule)
            currentSchedule['rrule'] = rruleDic.rrule
            currentSchedule['text'] = rruleDic.text
            currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
        }
        this.setState({repeatValue, customEnabled: !this.state.customEnabled,weekDayWithCheck, currentSchedule, customRepeatValue: 'Day', weekSelected: false, monthSelected: false})
   }

   handleStartDateChange(date){
        const {repeatValue, customEnabled, customRepeatValue, customInterval, customCount, monthlyOccurenceDay, repeatType, weekDayWithCheck, repeatError, customCountError, invalidEndTime} = this.state
        let currentSchedule = this.state.currentSchedule
        let customUntill = this.state.customUntill
        currentSchedule['startDate'] = date
        currentSchedule['objStartDate'] = date
        currentSchedule['objStartTime'] = date
        currentSchedule['objEndTime'] = date
        customUntill = moment(date).add(1, 'd').format('MMM DD YYYY')
        if (customEnabled) {
            const weekDays = customRepeatValue!= "Week" ? getweekDayWithCheck() : weekDayWithCheck;
            handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDays, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, this)
        } else {
            const rruleDic =  generateRRule(repeatValue, null, this.state.currentSchedule)
            currentSchedule['text'] = rruleDic.text
            currentSchedule['rrule'] = rruleDic.rrule
            currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
            this.setState({currentSchedule, customUntill})
        }
   }

   handleStartTimeChange(time){
        const {customUntill, repeatValue, customEnabled, customRepeatValue, customInterval, customCount, monthlyOccurenceDay, repeatType, weekDayWithCheck, repeatError, customCountError} = this.state
        let currentSchedule = this.state.currentSchedule
        currentSchedule['startTime'] = time //moment(time).format('hh:mm A')
        currentSchedule['objStartTime'] = time
        const invalidEndTime = currentSchedule.objEndTime && currentSchedule.objEndTime.diff(currentSchedule.objStartTime, 'minutes') < 5
        if (customEnabled) {
            const weekDays = customRepeatValue!= "Week" ? getweekDayWithCheck() : weekDayWithCheck;
            handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDays, monthlyOccurenceDay, repeatError, customCountError, currentSchedule,invalidEndTime, this)
        } else {
            const rruleDic =  generateRRule(repeatValue, null, this.state.currentSchedule)
            currentSchedule['text'] = rruleDic.text
            currentSchedule['rrule'] = rruleDic.rrule
            currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
            this.setState({currentSchedule, customUntill, invalidEndTime})
        }
    }
    handleEndTimeChange(time){
        const {customUntill, repeatValue, customEnabled, customRepeatValue, customInterval, customCount, monthlyOccurenceDay, repeatType, weekDayWithCheck, repeatError, customCountError} = this.state
        let currentSchedule = this.state.currentSchedule
        currentSchedule['endTime'] = time //moment(time).format('hh:mm A')
        currentSchedule['objEndTime'] = time
        const invalidEndTime = currentSchedule.objEndTime && currentSchedule.objEndTime.diff(currentSchedule.objStartTime, 'minutes') < 5
        if (customEnabled) {
            const weekDays = customRepeatValue!= "Week" ? getweekDayWithCheck() : weekDayWithCheck;
            handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDays, monthlyOccurenceDay, repeatError, customCountError, currentSchedule,invalidEndTime, this)
        } else {
            const rruleDic =  generateRRule(repeatValue, null, this.state.currentSchedule)
            currentSchedule['text'] = rruleDic.text
            currentSchedule['rrule'] = rruleDic.rrule
            currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
            this.setState({currentSchedule, customUntill, invalidEndTime})
        } 
    }
    handleNotifyStateChange(){
        let currentSchedule = this.state.currentSchedule
        currentSchedule['notify'] = !currentSchedule.notify
        this.setState({currentSchedule})
    }

handleRepeatSelection(e) {
    const {customInterval, customCount, monthlyOccurenceDay, repeatType, customUntill, weekDayWithCheck, repeatError, customCountError, currentSchedule, invalidEndTime} = this.state
    let customRepeatValue = e.target.value
    const weekDays = customRepeatValue!= "Week" ? getweekDayWithCheck() : weekDayWithCheck;
    handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDays, monthlyOccurenceDay, repeatError, customCountError, currentSchedule,invalidEndTime, this)
}

handleCustomMonthlyRepeat(e) {
    const {customInterval, customCount, customRepeatValue, repeatType, customUntill, weekDayWithCheck, repeatError, customCountError, currentSchedule,invalidEndTime} = this.state
    let monthlyOccurenceDay = e.target.value
    handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, currentSchedule,invalidEndTime, this)
}

handleWeekDayChecked(weekItem, index){
    const {customInterval, customCount, customRepeatValue, repeatType, customUntill, monthlyOccurenceDay, repeatError, customCountError, currentSchedule,invalidEndTime} = this.state
    let weekDayWithCheck = this.state.weekDayWithCheck
    weekDayWithCheck[index].checked = !weekItem.checked
    handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, currentSchedule,invalidEndTime, this)

}

handleRepeatType(e, index) {
    const {customInterval, customCount, customRepeatValue, weekDayWithCheck, customUntill, monthlyOccurenceDay, repeatError, customCountError, currentSchedule,invalidEndTime} = this.state
    let repeatType = this.state.repeatType
    repeatType.map((item, key) => {
        if (key === index && e.target.checked) {
            item.selected = e.target.checked
        } else {
            item.selected = false
        }
    })
    handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, currentSchedule,invalidEndTime, this)
}

handleCustomCount(e){
    const {customInterval, repeatType, customRepeatValue, weekDayWithCheck, customUntill, monthlyOccurenceDay, repeatError, currentSchedule,invalidEndTime} = this.state
    let customCountError =  Number(e.target.value) < Number(1) ? "Count should be greater than 0." : ''
    let customCount = Number(e.target.value) < Number(1) ? e.target.value : Number(e.target.value)
    if (customCountError === ''){
        handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, currentSchedule,invalidEndTime, this)
    } else {
        this.setState({customCountError, customCount})
    }
}

handleCustomUntill(date){
    const {customInterval, repeatType, customRepeatValue, customCount, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime} = this.state
    let customUntill = date
    handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, this)
}

handleCustomInterval(e){
    const {repeatType, customRepeatValue, customCount, customUntill, weekDayWithCheck, monthlyOccurenceDay, customCountError, currentSchedule, invalidEndTime} = this.state
    let customInterval =  e.target.value
    let repeatError = ''
    if (this.state.customRepeatValue === 'Day') {
        repeatError = Number(e.target.value) < Number(1) ? "Repeat value should be greater than 0" : ''
        customInterval = e.target.value
    } else if (this.state.customRepeatValue === 'Week') {
        repeatError = (Number(e.target.value) < Number(1)) || ( Number(e.target.value) > Number(5)) ? "Repeat value should be between 0 and 6" : ''
        customInterval = e.target.value
    } else if (this.state.customRepeatValue === 'Month') {
        repeatError = Number(e.target.value) < Number(1) ? "Repeat value should be greater than 0" : ''
        customInterval = e.target.value
    } else if (this.state.customRepeatValue === 'Year') {
        repeatError = Number(e.target.value) !== Number(1) ? "Repeat value should be 1" : ''
        customInterval = e.target.value
    } 
    if (repeatError === '') {
        handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, this)
    } else {
        this.setState({repeatError, customInterval})
    }
}

onConfirm(){
    this.props.removeAPIErrorMessage()
    window.location.href = "/success"
}
handleCloseSuccessPopup() {
    this.props.showActivitySuccess('')
}
adjustHeight(el){
    el.style.height = "1px";
    el.style.height = (25+el.scrollHeight)+"px";
}
textAreaOnBlur(el){
    el.style.height = "50px";
}
handleHeaderCancel(){
    this.setState({showUnSavedWarning: !this.state.isDataSaved})
}
handleCloseWarning(){
    this.setState({showUnSavedWarning: !this.state.showUnSavedWarning})
}

handleOpenCamera = async(dataUri) => {
    console.log("Opening|closing camera!")
    var imagebase64String = await getBase64FromUrl(dataUri)

    if (imagebase64String && imagebase64String !== "") {
        const imgBlob = await dataURItoBlob(dataUri)
        console.log(`originalFile size ${imgBlob.size / 1024 / 1024} MB`);
        const options = {
          maxSizeMB: 2,
          maxWidthOrHeight: 512,
          useWebWorker: true
        }
        
        try {
            const compressedFile = await imageCompression(imgBlob, options);
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
            imagebase64String = await getBase64FromUrl(URL.createObjectURL(compressedFile))
        } catch (error) {
          console.log(error);
        }
    }

    const activityBgImageUrl = imagebase64String && imagebase64String !== "" ? imagebase64String :  this.state.activityBgImageUrl
    this.setState({openCameraMenu: false, openCamera: !this.state.openCamera, openPhotoLib: false, activityBgImageUrl, isDataSaved: false})
}

handleOpenPhotoLib(event) {
    event.preventDefault()
    console.log("Opening|closing photo lib!")
    this.setState({openCameraMenu: false, openCamera: false, openPhotoLib: !this.state.openPhotoLib}, () => {
        this.state.inputRef.current.click();
    })
}

handlePhotoChange = async(event) => {
    event.preventDefault();
    event.stopPropagation();
    console.log("handle photo change!")
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    console.log(`originalFile size ${fileObj.size / 1024 / 1024} MB`);
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 512,
      useWebWorker: true
    }
    
    try {
        const compressedFile = await imageCompression(fileObj, options);
        console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        var tmppath = await getBase64FromUrl(URL.createObjectURL(compressedFile))
        event.target.value = null;
        this.setState({activityBgImageUrl: tmppath, openCameraMenu: false, isDataSaved: false})
    } catch (error) {
      console.log(error);
    }
}


toggleCustom(event){
    this.setState({activityEnabled: event.target.checked, isDataSaved: false})
}

handleUserExclusiveWarning(){
    this.setState({showUnSavedWarning: false, showUserExclusiveWarning: !this.state.showUserExclusiveWarning})
}

handleCancelCam() {
    this.setState({openCameraMenu: false, openPhotoLib: false, openCamera: false})
}

    
   render() {
      const { schedules, steps, anchorEl, select, showUnSavedWarning, isDataSaved, repeatError, customCountError, openCameraMenu, openScheduleMenu, openCamera, activityBgImageUrl, scope, showUserExclusiveWarning, activityTitle} = this.state;
      const {loading, screenThemeClass, popupThemeClass, successMessage, errorTitle, errorMessage, displayName, version} = this.props
      const invalidateInput = !this.state.isTitleFilled || (this.props.activityExist &&(this.state.activityTitle !== '') && (this.props.activityTitle === this.state.activityTitle)) || (!this.state.minCharLength) ? true : false

      return (
          <QueueAnim type="bottom" duration={2000} className="object-editor">
              <div>
                {
                loading ?
                    <div className="d-flex justify-content-center loader-overlay">
                        <CircularProgress className="theame-text" disableShrink	 />
                    </div>
                    : errorMessage !== '' ? 
                        <SweetAlert
                            warning
                            btnSize="sm"
                            show={errorMessage !== ''}
                            customClass="warningText"
                            confirmBtnText="OK"
                            confirmBtnBsStyle="warning"
                            title={ `${errorTitle}!`}
                            onConfirm={this.onConfirm.bind(this)}
                        >
                            {errorMessage}
                        </SweetAlert>
                    : showUnSavedWarning ? <WarningDialog open={showUnSavedWarning} handleCloseSuccessPopup={this.handleCloseWarning.bind(this)} popupThemeClass={this.props.popupThemeClass}/>
                    : showUserExclusiveWarning ? <UserExclusiveWarning activityTitle={activityTitle} displayName={displayName} open={showUserExclusiveWarning} handleClosePopup={this.handleUserExclusiveWarning.bind(this)} popupThemeClass={this.props.popupThemeClass}/> : <></>
                }
              </div>
              <div className={screenThemeClass}>
                  <AppBar position="static" className="session-header">
                      <Toolbar>
                          <Header isDataSaved={isDataSaved} handleHeaderCancel={this.handleHeaderCancel.bind(this)} popupThemeClass={popupThemeClass} title={<IntlMessages id="NewNotification.info-CreateNotification"/>} href="/success" handleSave={this.handleSaveActivity.bind(this)} version={version}/>
                      </Toolbar>
                  </AppBar>
                  <div className="session-inner-wrapper">
                      <div className="container">
                          <div className="row row-eq-height">
                              <div className="col-sm-12 col-md-2 col-lg-2"></div>
                              <div className="col-sm-12 col-md-8 col-lg-8">
                                  <div className="session-body text-center">
                                      <div className="session-head">
                                                <div className="d-flex">
                                                    <div data-testid="IconBell" className="titleIcon mr-1">
                                                        <NotificationsNoneRoundedIcon fontSize='large'/>
                                                    </div>
                                                    <IntlMessages id="NewNotification.TitlePlaceholder">{placeholder=><LSInput datatestid="title" autoFocus value={this.state.activityTitle} type="text" placeholder={placeholder} className={invalidateInput ? "inputTitleErr w-100" : "inputTitle border-0 w-100"} onChange={(e) => this.setState({ activityTitle: e.target.value, isTitleFilled: e.target.value !== '', minCharLength:  e.target.value.length > 2, isDataSaved: false })} />}</IntlMessages>
                                                </div>
                                                {!this.state.isTitleFilled ? <FormFeedback data-testid="title-error" className="text-left fs-12 d-flex"><IntlMessages id="newactivity.titleError1" /></FormFeedback>
                                                    : this.props.activityExist && this.state.activityTitle !== '' && this.props.activityTitle === this.state.activityTitle ? <FormFeedback className="text-left fs-12 d-flex"><IntlMessages id="newactivity.titleError2" /></FormFeedback>
                                                    : !this.state.minCharLength && <FormFeedback data-testid="errormsg" className="text-left fs-12 d-flex"><IntlMessages id="newactivity.titleError3" /></FormFeedback>
                                                }
                                                <IntlMessages id="NewNotification.DescriptionPlaceholder">{placeholder=><LSTextArea textArearef={this.state.textAreaField} onKeyUp={() => this.adjustHeight(this.state.textAreaField.current)} datatestid="description" value={this.state.activityDesc} type="textarea" className={`desciption w-100 border-0 ${!scope && !version ? "mb-4" : ""}`} placeholder={placeholder} onChange={(e) => this.setState({ activityDesc: e.target.value, isDataSaved: false })} onBlur={() => this.textAreaOnBlur(this.state.textAreaField.current)} />}</IntlMessages>
                                                { this.props.activity && ((scope === 'general') && (version === "v2")) ?
                                                    <div key={`new-item-enable-switch-container`} data-testid={`new-item-enable-switch-container`} className="pl-2 add-new-dropdown d-flex align-items-center justify-content-between mb-2 ">
                                                        <Label data-testid="new-item-switch-label" className="menuEditItem mb-0"><IntlMessages id={"newactivity.inactive"}/></Label>
                                                        <div className="d-flex align-items-center">
                                                            <Switch
                                                                value={"activityEnabled"}
                                                                checked={this.state.activityEnabled}
                                                                onClick={(event) => this.toggleCustom(event)}
                                                                color="primary"
                                                                className="switch-btn"
                                                            />
                                                        </div>
                                                    </div>
                                                    : <></>
                                                }
                                                {(scope === 'general') || (version === "v2") ? 
                                                    <div key={`new-item-bg-image`} data-testid={`new-item-bg-image`} className="pl-2 add-new-dropdown d-flex align-items-center justify-content-center mb-4 ">
                                                        {/* <Label data-testid="new-item-switch-label" className="menuEditItem mb-0"><IntlMessages id={"newactivity.backgroundimageurl"}/></Label> */}
                                                            <div /*className="titleIcon m-2"*/>
                                                                <PictureOptions
                                                                    imageUrl={activityBgImageUrl}
                                                                    datatestid="bg-image-picker"
                                                                    openCameraMenu={openCameraMenu}
                                                                    handleOpenCamMenu={this.handleOpenCamMenu.bind(this)}
                                                                    anchorEl={anchorEl}
                                                                    handleCloseMenu={this.handleCloseMenu.bind(this)}
                                                                    handleOpenCamera={this.handleOpenCamera.bind(this)}
                                                                    handleOpenPhotoLib={this.handleOpenPhotoLib.bind(this)}
                                                                />
                                                                {activityBgImageUrl && <IconButton data-testid={"delete-bg-img"} onClick={() => this.setState({activityBgImageUrl: null, isDataSaved: false})}> <DeleteOutlineIcon className="deleteButton" fontSize="large" /></IconButton>}
                                                            </div> 
                                                    </div>
                                                    : <></>
                                                }
                                              <RctCollapsibleCard contentCustomClasses="p-0">
                                                  <div className="d-flex justify-content-between pt-3 pb-4" style={{ paddingLeft: "0.75rem" }}>
                                                      <div data-testid="Schedule-heading" className="scheduleTitle"><IntlMessages id="NewNotification.Schedule"/></div>
                                                  </div>
                                                  <ul data-testid="Schedule-list" className="list-inline mb-0" >
                                                      {schedules && schedules.map((schedule, key) => (
                                                          <li key={key} data-testid={"Schedule"+key} className={"border-bottom pb-2 pt-2 "+(key % 2 == 0 ? "schedule-list-item-odd" : "")} style={{ paddingLeft: "0.75rem" }}>
                                                              <div className="d-flex justify-content-between mb-2">
                                                                  <div  data-testid={"Schedule"+key+"startDate"} className="mb-0 d-flex align-items-center justify-content-center listItem"><CalendarTodayRounded fontSize="medium" className="pr-1" />{`Starts ${moment(getISODateString(schedule.startDate)).format('DD MMM YYYY')}`}</div>
                                                                  <div data-testid={"Schedule"+key+"time"} className="mb-0 d-flex align-items-center justify-content-center listItem"><AccessTimeRounded fontSize="medium" className="pr-1" />
                                                                      {`${schedule.startTime} - ${schedule.endTime}`}
                                                                      <MoreVertIcon data-testid={"Schedule"+key+"more"} className="ml-2" aria-label="More" aria-owns={anchorEl ? 'long-menu' : null} aria-haspopup="true" onClick={(event) => this.handleClickMenu(event, key)} />
                                                                  </div>
                                                                  <Menu id={`"schedule-"${key}`} anchorEl={anchorEl} open={openScheduleMenu} onClose={this.handleCloseMenu} >
                                                                      <MenuItem data-testid={"Schedule"+key+"edit"} onClick={this.handleEditSchedule}><div className="menuEditItem"><IntlMessages id="NewNotification.Edit"/></div></MenuItem>
                                                                      <MenuItem data-testid={"Schedule"+key+"delete"} onClick={this.handleDeleteSchedule}><div className="meniDeleteItem"><IntlMessages id="NewNotification.Delete"/></div></MenuItem>
                                                                  </Menu>
                                                              </div>
                                                              <div className="mb-2">
                                                                  <div data-testid={"Schedule"+key+"text"} className="mb-2 d-flex text-left listItem"><RepeatRounded className="align-icons" fontSize="medium" />{`Repeats ${schedule.text}`}</div>
                                                              </div>
                                                              <div className="d-flex align-items-center mr-2">
                                                              <div className="listItem mr-2 d-flex align-items-center justify-content-center"><NotificationsNoneRoundedIcon className="align-icons" fontSize="medium"/>Notify:</div>
                                                                    {schedule.notify === false ? 
                                                                        <CancelRoundedIcon className='text-danger'/>
                                                                        :
                                                                        <CheckCircleRoundedIcon className="text-success"/>
                                                                    } 
                                                                </div> 
                                                          </li>
                                                      ))}
                                                  </ul>
                                                  <FormGroup data-testid="add-Schedule" className="align-items-center p-4"><button className="buttonWithBorder" onClick={(e) => this.handleOpenSchedule(e)}><IntlMessages id="NewNotification.ADDSCHEDULE"/></button></FormGroup>
                                                  <ScheduleUpdate
                                                      currentSchedule={this.state.currentSchedule}
                                                      open={this.state.openSchedule}
                                                      editSchedule={this.state.editSchedule}
                                                      activityType={this.state.activityType}
                                                      handleNotifyStateChange={this.handleNotifyStateChange.bind(this)}
                                                      handleClose={this.handleOpenSchedule.bind(this)}
                                                      handleSaveSchedule={this.handleSaveSchedule.bind(this)}
                                                      handleRepeat={this.handleRepeat.bind(this)}
                                                      handleStartDateChange={this.handleStartDateChange.bind(this)}
                                                      handleStartTimeChange={this.handleStartTimeChange.bind(this)}
                                                      handleEndTimeChange={this.handleEndTimeChange.bind(this)}
                                                      customEnabled={this.state.customEnabled}
                                                      handleToggle={this.handleToggle.bind(this)}
                                                      getDayOccurences={getDayOccurences}
                                                      weekSelected={this.state.weekSelected}
                                                      monthSelected={this.state.monthSelected}
                                                      customRepeatValue={this.state.customRepeatValue}
                                                      handleRepeatSelection={this.handleRepeatSelection.bind(this)}
                                                      weekDayWithCheck={this.state.weekDayWithCheck}
                                                      handleWeekDayChecked={this.handleWeekDayChecked.bind(this)}
                                                      handleRepeatType={this.handleRepeatType.bind(this)}
                                                      repeatType={this.state.repeatType}
                                                      handleCustomMonthlyRepeat={this.handleCustomMonthlyRepeat.bind(this)}
                                                      monthlyOccurenceDay={this.state.monthlyOccurenceDay}
                                                      customCount={this.state.customCount}
                                                      handleCustomCount={this.handleCustomCount.bind(this)}
                                                      customUntill={this.state.customUntill}
                                                      handleCustomUntill={this.handleCustomUntill.bind(this)}
                                                      handleCustomInterval={this.handleCustomInterval.bind(this)}
                                                      customInterval={this.state.customInterval}
                                                      invalidEndTime={this.state.invalidEndTime}
                                                      popupThemeClass={popupThemeClass}
                                                      repeatError={repeatError}
                                                      customCountError={customCountError}
                                                  />
                                              </RctCollapsibleCard>
                                      </div>
                                  </div>
                              </div>
                              {openCamera ? <SherpaCamera datatestid="desktop-camera" handleCloseCamera={this.handleOpenCamera.bind(this)} handleCancel={this.handleCancelCam.bind(this)} /> : <></>}
                                <PhotoLibrary 
                                    inputRef={this.state.inputRef}
                                    handlePhotoChange={this.handlePhotoChange.bind(this)}
                                    datatestid="photo-img-lib"
                                />
                              <div className="col-sm-12 col-md-2 col-lg-2"></div>
                          </div>
                      </div>
                  </div>
                  <div className='footer-style listItem'>{process.env.BUILD_VERSION}</div>
              </div>
          </QueueAnim>
      );
   }
}

// map state to props
const mapStateToProps = ({ ActivityReducer, settings, authUser }) => {
    const {activity, schedules, steps, loading, errorMessage, successMessage, errorTitle, unSubscribeLoading} = ActivityReducer
    const {screenThemeClass, popupThemeClass} = settings
    const { userDetails } = authUser;
    const displayName = userDetails ? userDetails.displayName || (`${userDetails.firstName || "..."} ${userDetails.lastName || "User"}`) : "...";
   return { activity, schedules, steps, loading, errorMessage, screenThemeClass, popupThemeClass, successMessage, errorTitle, unSubscribeLoading, displayName }
}

export default connect(mapStateToProps, {
    addPrivateActivity,
    updatePrivateActivity,
    getPrivateActivityById,
    checkDuplicateActivity,
    clearActivityData,
    checkDuplicateTitleAndUpdate,
    removeAPIErrorMessage,
    showActivitySuccess,
    unSubscribeActivity,
    getUserById
})(NewNotification);
