
import {sherpaAPI} from '../sherpaApi/index'
import { NotificationManager } from 'react-notifications';

import {
    GET_CHARTS_LIST,
    GET_SUCCESS_CHARTS_LIST,
    GET_CHART_BY_CHART_ID,
    GET_SUCCESS_CHART_BY_CHART_ID,
    FILTER_CHART_DATA,
    FILTER_SUCCESS_CHART_DATA,
    SHOW_CHARTS_LOADING,
    HIDE_CHARTS_LOADING,
 } from '../actions/types';

export const getChartsList = (id, orgId, idToken, moduleName) => (dispatch) => {
    let url = `reports/${moduleName}/${id}/charts?orgId=${orgId}`
    dispatch({ type: GET_CHARTS_LIST });
    sherpaAPI.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
        })
        .then((response) => {
            if(response.status === 200){
                dispatch({ type: GET_SUCCESS_CHARTS_LIST, payload: response.data });
            } else {
                dispatch({ type: HIDE_CHARTS_LOADING });
                NotificationManager.error(response.data)
            }
        })
        .catch(error => {
            // error handling
            dispatch({ type: HIDE_CHARTS_LOADING });
            console.log("error occured ", error);
        })
}

export const getChartById = (id, orgId, idToken, moduleName, chartId) => (dispatch) => {
    let url = `reports/${moduleName}/${id}/charts/${chartId}?orgId=${orgId}`
    dispatch({ type: GET_CHART_BY_CHART_ID });
    sherpaAPI.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
        })
        .then((response) => {
            if(response.status === 200){
                dispatch({ type: GET_SUCCESS_CHART_BY_CHART_ID, payload: {data: response.data, chartId} });
            } else {
                dispatch({ type: HIDE_CHARTS_LOADING });
                NotificationManager.error(response.data)
            }
        })
        .catch(error => {
            // error handling
            dispatch({ type: HIDE_CHARTS_LOADING });
            console.log("error occured ", error);
        })
}

export const getFilterChartById = (id, orgId, idToken, moduleName, chartId, filterData) => (dispatch) => {
    let url = `reports/${moduleName}/${id}/charts/${chartId}?orgId=${orgId}&fromDate=${encodeURIComponent(filterData.fromDate)}&toDate=${encodeURIComponent(filterData.toDate)}`
    dispatch({ type: FILTER_CHART_DATA });
    sherpaAPI.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
        }
        })
        .then((response) => {
            if(response.status === 200){
                dispatch({ type: FILTER_SUCCESS_CHART_DATA, payload: {filterData: response.data, filterChartId: chartId} });
            } else {
                dispatch({ type: HIDE_CHARTS_LOADING });
                NotificationManager.error(response.data)
            }
        })
        .catch(error => {
            // error handling
            dispatch({ type: HIDE_CHARTS_LOADING });
            console.log("error occured ", error);
        })
}

export const showActivityChartsLoading = () => (dispatch) =>{
    dispatch({type: SHOW_CHARTS_LOADING})
}

export const hideActivityChartsLoading = () => (dispatch) =>{
    dispatch({type: HIDE_CHARTS_LOADING})
}
