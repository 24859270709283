/**
 * Rct Theme Provider
 */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// App locale
import AppLocale from '../lang';

// themes
import primaryTheme from './themes/primaryTheme';
import darkTheme from './themes/darkTheme';
import secondaryTheme from './themes/secondaryTheme';
import warningTheme from './themes/warningTheme';
import dangerTheme from './themes/dangerTheme';
import infoTheme from './themes/infoTheme';
import successTheme from './themes/successTheme';
import theme1 from './themes/theme1';
import theme2 from './themes/theme2';
import theme3 from './themes/theme3';
import theme5 from './themes/theme5';
import queryString from 'query-string';
import 'fontsource-heebo/latin.css';
import 'fontsource-open-sans/latin.css';

import {
	getCookies
} from '../helpers/helpers'
import {
    changeThemeColor,
	setLanguage
} from '../actions/AppSettingsActions'
import {
    getLSThemeLogo
} from 'Actions';


class RctThemeProvider extends Component {
	componentDidMount(){
		let theme = {}
		let params = this.getParams();
		let userTheme = localStorage.getItem("theme");
		/*if (params && params.theme) {
			// let params = queryString.parse(this.props.location.search)
			if (params.theme === 'Theme1'){
				theme['id'] = 7
				theme['name'] = 'theme1'
			} else if (params.theme === 'Theme2'){
				theme['id'] = 8
				theme['name'] = 'theme2'
			} else if (params.theme === 'Theme3'){
				theme['id'] = 9
				theme['name'] = 'theme3'
			} else if (params.theme === 'Theme4') {
				theme['id'] = 5
				theme['name'] = 'danger'
			} else if (params.theme === 'Theme5') {
				theme['id'] = 10
				theme['name'] = 'theme5'
			} else {
				theme['id'] = 1
				theme['name'] = 'primary'
			}
        } else if (process.env.REACT_APP === 'DES') {
			theme['id'] = 5
			theme['name'] = 'danger'
		} else {
			theme['id'] = 1
			theme['name'] = 'primary'
		}*/
		if (params && params.theme) {	
			theme['id'] = 1
			theme['name'] = params.theme
		} else if (userTheme) {
			theme = JSON.parse(userTheme);
		} else if (process.env.REACT_APP === 'DES') {
			theme['id'] = 5
			theme['name'] = 'Theme4'
		} else {
			theme['id'] = 1
			theme['name'] = 'Default'
		}
		const language = params ? params.language : '' //queryString.parse(this.props.location.search)
		let lang={}
		if(language==='fr'){
			lang={languageId: 'french',
			locale: 'fr',
			name: 'French',
			icon: 'fr',}
		}
		else if(language==='zh'){
			lang={languageId: 'chinese',
			locale: 'zh',
			name: 'Chinese',
			icon: 'zh',}
		}
		else if(language==='ru'){
			lang={languageId: 'russian',
			locale: 'ru',
			name: 'Russian',
			icon: 'ru',}
		}
		else if(language==='he'){
			lang={languageId: 'hebrew',
			locale: 'he',
			name: 'Hebrew',
			icon: 'he',}
		}
		else if(language==='ar'){
			lang={languageId: 'saudi-arabia',
			locale: 'ar',
			name: 'Arabic',
			icon: 'ar',}
		}
		else if(language==='de'){
			lang={languageId: 'german',
			locale: 'de',
			name: 'German',
			icon: 'de',}
		}
		else if(language==='es'){
			lang={languageId: 'spanish',
			locale: 'es',
			name: 'Spanish',
			icon: 'es',}
		}
		else if(language==='ja'){
			lang={languageId: 'japanese',
			locale: 'ja',
			name: 'Japanese',
			icon: 'ja',}
		}
		else if(language==='ko'){
			lang={languageId: 'korean',
			locale: 'ko',
			name: 'Korean',
			icon: 'ko',}
		}
		else if(language==='it'){
			lang={languageId: 'italian',
			locale: 'it',
			name: 'Italian',
			icon: 'it',}
		}
		else if(language==='hu'){
			lang={languageId: 'hungarian',
			locale: 'hu',
			name: 'Hungarian',
			icon: 'hu',}
		}
		else{
			lang={languageId: 'english',
			locale: 'en',
			name: 'English',
			icon: 'en',}
		}
		if((params && params.theme) || !userTheme) {
			this.props.getLSThemeLogo(theme);
			localStorage.setItem("theme",JSON.stringify(theme));
			localStorage.setItem("activeTheme",theme.id);
			this.props.changeThemeColor(theme,this.props.themeData)
		} 
		this.props.setLanguage(lang);
	}

	//get params from query string or cookie
	getParams(){
        let params = null
        if (this.props.location.search) {
            params = queryString.parse(this.props.location.search)
        } else if (getCookies('objCookieData')) {
            params = JSON.parse(getCookies('objCookieData'))
        } 
        return params
	}
	
	render() {
		const { locale, darkMode, rtlLayout, activeTheme, children, themeData, themeLoading} = this.props;
		const currentAppLocale = AppLocale[locale.locale];
		if (document.domain && document.domain.includes("3rbehavioralsolutions.com")){
			document.domain = "3rbehavioralsolutions.com"
		 }
		//console.log("themedata",themeData,themeLoading)
		// theme changes
		let theme = '';
		switch (activeTheme.id) {
			case 1:
				theme = primaryTheme
				break;
			case 2:
				theme = secondaryTheme
				break;
			case 3:
				theme = warningTheme
				break;
			case 4:
				theme = infoTheme
				break;
			case 5:
				theme = dangerTheme
				break;
			case 6:
				theme = successTheme
				break;
			case 7:
				theme = theme1
				break;
			case 8:
				theme = theme2
				break;
			case 9:
				theme = theme3
				break;
			case 10:
				theme = theme5
				break;
			default:
				break;
		}
		if(!themeLoading && themeData && themeData.primaryButtonNormalStateColor &&  themeData.alternativeSecondaryButtonNormalStateColor) {
			theme = createTheme({
			   palette: {
				   primary: {
					   main: themeData.primaryButtonNormalStateColor
				   },
				   secondary: {
					   main: themeData.alternativeSecondaryButtonNormalStateColor
				   }
			   }
		   });
		   Object.keys(themeData).map(themeAttribute=> {
			  document.body.style.setProperty(`--${themeAttribute}`,themeData[themeAttribute])
		   });
		} else if(!theme) {
			theme = primaryTheme;
		}
		if (darkMode) {
			theme = darkTheme
		}

		if (rtlLayout) {
			theme.direction = 'rtl'
		} else {
			theme.direction = 'ltr'
		}

		return (
			<ThemeProvider theme={theme}>
				<IntlProvider
					locale={currentAppLocale.locale}
					messages={currentAppLocale.messages}
				>
					<Fragment>
					         {(!themeData || themeLoading) &&
								<div className="d-flex justify-content-center complete-loader-overlay">
									<CircularProgress className="theame-text" disableShrink	 />
								</div> 
							 }
							{children}
						
					</Fragment>
				</IntlProvider>
			</ThemeProvider>
		);
	}
}

// map state to props
const mapStateToProps = ({ settings, authUser }) => {
	const { locale, darkMode, rtlLayout, activeTheme } = settings
	const {themeData, themeLoading} = authUser
	return {locale, darkMode, rtlLayout, activeTheme, themeData, themeLoading}
}

export default connect(mapStateToProps, {changeThemeColor,setLanguage, getLSThemeLogo})(RctThemeProvider);
