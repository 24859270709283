/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Form, FormGroup } from 'reactstrap';
import QueueAnim from 'rc-queue-anim';
import HeaderWithCancelButton from '../Header/HeaderWithCancelButton'
import config from '../../config/config'
import { withAuth0 } from "@auth0/auth0-react";
import CircularProgress from '@mui/material/CircularProgress';
import { connect } from 'react-redux';
class Auth0Login extends Component {

    componentDidMount(){
        const {loginWithRedirect} = this.props.auth0;
        const loginMode = localStorage.getItem("loginMode")
        if (loginMode === 'idp-initiated-sso') {
            loginWithRedirect()
        } else {
            loginWithRedirect({"prompt": "login"})
        }
   }
 
   render() {
      return (
         <QueueAnim type="bottom" duration={2000}>
            <div>
            {
               <div className="d-flex justify-content-center loader-overlay">
                  <CircularProgress className="theame-text" disableShrink/>
               </div>
            }
            </div>
            <div className={this.props.screenThemeClass}>
               <AppBar position="static" className="session-header">
                  <Toolbar>
                     <HeaderWithCancelButton themeLogo={this.props.themeLogo} screenThemeClass={this.props.screenThemeClass} location={this.props.location} href={`/success`}/>
                  </Toolbar>
               </AppBar>
               <div className="session-inner-wrapper">
                  <div className="container">
                     <div className="row row-eq-height">
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                           <div className="session-body text-center">
                              <Form>
                                 <FormGroup className="mb-10">
                                  <p className="mb-0">Please wait redirecting to authentication page...</p>
                                 </FormGroup>
                              </Form>
                           </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3"></div>
                     </div>
                  </div>
               </div>
            </div>
         </QueueAnim>
      );
   }
}

const mapStateToProps = ({settings}) => {
   const { screenThemeClass, themeLogo } = settings; 
   return { screenThemeClass, themeLogo }
}
export default withAuth0(connect(mapStateToProps, {})(Auth0Login));